import { PublishedObjectModel } from "src/FSD/entities/publishedObject";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";

export const useModalExecutorUpdate = () => {
  const dispatch = useAppDispatch();

  const { executorUpdate } = useAppSelector(
    PublishedObjectModel.modals.getModalsData
  );

  const handleOpenExecutorUpdate = (projectId?: number) => {
    dispatch(
      PublishedObjectModel.modals.setExecutorUpdateActive({
        isOpen: true,
        projectId: projectId || null,
      })
    );
  };

  const handleCloseExecutorUpdate = () => {
    dispatch(
      PublishedObjectModel.modals.setExecutorUpdateActive({
        isOpen: false,
        projectId: null,
      })
    );
  };

  return {
    executorUpdate,
    handleOpenExecutorUpdate,
    handleCloseExecutorUpdate,
  } as const;
};
