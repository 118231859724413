import { createSlice, isFulfilled } from "@reduxjs/toolkit";
import { initialState, initialSelectOptions } from "./model";
import * as KanbanThunks from "../api/thunks";
import * as KanbanDataMapper from "../lib/dataMappers";
import { RootState } from "../../../../app/store";
import {
  arrayToSelect,
  toSelect,
} from "../../../../app/feature/project/projectProcessPublication";
import { arrayToSelectMultilevel } from "../../../shared/lib/helpers/arrayToSelectMultilevel";
import { TaskApi } from "../../../shared/api/v1/task";
import { KanbanApi } from "..";
import * as KanbanSliceHelpers from "../lib/helpers";

const kanbanSlice = createSlice({
  name: "kanbanSlice",
  initialState,
  reducers: {
    setAllTasks(state, action) {
      state.tasks.allTasks = action.payload;
    },
    setRowTasks(state, action) {
      const { rowId, updatedTasks } = action.payload;
      state.tasks.rowTasks[rowId].columns = updatedTasks;
    },
    setCache(state, action) {
      state.tasks.cache = action.payload;
    },
    setTaskCompletion(state, action) {
      state.data.taskCompletion = action.payload;
    },
    setViewTask(state, action) {
      state.data.viewTask = action.payload;
    },
    setGroupByValue(state, action) {
      state.data.groupBy = action.payload;
    },
    setSortValue(state, action) {
      state.data.sort = action.payload;
    },
    setCreateFreeTask(state, action) {
      state.data.createFreeTask = action.payload;
    },
    setEditFreeTask(state, action) {
      state.data.editFreeTask = action.payload;
    },
    setFilterGroupsList(state, action) {
      state.data.selectOptions.groups = action.payload;
    },
    setFilterUsersList(state, action) {
      state.data.selectOptions.users = action.payload;
    },
    setSortFilter(state, action) {
      state.data.sort = action.payload;
    },
    dropToRowTasks(state, action) {
      KanbanSliceHelpers.dropToRowTasks(state, action);
    },
    updateFreeTask(state, action) {
      KanbanSliceHelpers.updateFreeTask(state, action);
    },
    removeFreeTask(state, action) {
      KanbanSliceHelpers.removeFreeTask(state, action);
    },
    dropCompletedTaskReducer(state, action) {
      KanbanSliceHelpers.dropCompletedTask(state, action);
    },
    clearFilterData(state) {
      state.data.selectOptions = { ...initialSelectOptions };
    },
    clearTaskCompletion(state) {
      state.data.taskCompletion = null;
    },
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // Получение задач в канбане
    builder.addCase(
      KanbanThunks.fetchViewByUserTypeId.pending,
      (state, action) => {
        state.pending.tasks.getTasks = action.meta.arg.showSpinner;
      }
    );
    builder.addCase(
      KanbanThunks.fetchViewByUserTypeId.fulfilled,
      (state, action) => {
        const kanbanRawData = action.payload;

        state.data.setting = kanbanRawData.setting;

        state.pending.tasks.getTasks = false;

        const { allTasks, rowTasks } = KanbanDataMapper.mapTasks(kanbanRawData);

        state.tasks.allTasks = allTasks;
        state.tasks.rowTasks = rowTasks;
      }
    );
    builder.addCase(
      KanbanThunks.fetchViewByUserTypeId.rejected,
      (state, action) => {
        state.pending.tasks.getTasks = false;
        state.error.getTasks = action.payload as string;
      }
    );

    // Получение списка проектов
    builder.addCase(KanbanThunks.fetchProjectsByUserTypeId.pending, (state) => {
      state.pending.filter.getProjects = true;
    });
    builder.addCase(
      KanbanThunks.fetchProjectsByUserTypeId.fulfilled,
      (state, action) => {
        state.pending.filter.getProjects = false;
        state.data.selectOptions.projects = arrayToSelectMultilevel(
          action.payload
        );
      }
    );
    builder.addCase(
      KanbanThunks.fetchProjectsByUserTypeId.rejected,
      (state) => {
        state.pending.filter.getProjects = false;
      }
    );

    // Получение списка типов задач
    builder.addCase(KanbanThunks.fetchWorkFlowList.pending, (state) => {
      state.pending.filter.getWfList = true;
    });
    builder.addCase(
      KanbanThunks.fetchWorkFlowList.fulfilled,
      (state, action) => {
        state.pending.filter.getWfList = false;
        state.data.selectOptions.workFlows = toSelect(action.payload, false);
      }
    );
    builder.addCase(KanbanThunks.fetchWorkFlowList.rejected, (state) => {
      state.pending.filter.getWfList = false;
    });

    // Получение списка групп
    builder.addCase(
      KanbanThunks.fetchGroupsByFirmIdAndPartGroupId.pending,
      (state) => {
        state.pending.filter.getGroups = true;
      }
    );
    builder.addCase(
      KanbanThunks.fetchGroupsByFirmIdAndPartGroupId.fulfilled,
      (state, action) => {
        state.pending.filter.getGroups = false;
        state.data.selectOptions.groups = arrayToSelect(action.payload);
      }
    );
    builder.addCase(
      KanbanThunks.fetchGroupsByFirmIdAndPartGroupId.rejected,
      (state) => {
        state.pending.filter.getGroups = false;
      }
    );

    // Получение списка пользователей
    builder.addCase(
      KanbanThunks.fetchUsersListByUserTypeId.pending,
      (state) => {
        state.pending.filter.getUsersList = true;
      }
    );
    builder.addCase(
      KanbanThunks.fetchUsersListByUserTypeId.fulfilled,
      (state, action) => {
        state.pending.filter.getUsersList = false;
        state.data.selectOptions.users = toSelect(action.payload);
      }
    );
    builder.addCase(
      KanbanThunks.fetchUsersListByUserTypeId.rejected,
      (state) => {
        state.pending.filter.getUsersList = false;
      }
    );

    // Добавление оперативной задачи
    builder.addCase(KanbanThunks.createFreeTask.pending, (state) => {
      state.pending.tasks.creation = true;
    });
    builder.addCase(KanbanThunks.createFreeTask.fulfilled, (state, action) => {
      state.pending.tasks.creation = false;
      KanbanSliceHelpers.createAndDropFreeTask(state, action);
    });
    builder.addCase(KanbanThunks.createFreeTask.rejected, (state, action) => {
      state.pending.tasks.creation = false;
      state.error.creation = action.payload as string;
    });

    builder.addMatcher(isFulfilled(TaskApi.complete), (state, action) => {
      KanbanSliceHelpers.dropCompletedTask(state, action);
    });

    builder.addMatcher(isFulfilled(KanbanApi.complete), (state, action) => {
      KanbanSliceHelpers.dropCheckedTask(state, action);
    });
  },
});

export const {
  setFilterGroupsList,
  setFilterUsersList,
  setCreateFreeTask,
  setEditFreeTask,
  updateFreeTask,
  removeFreeTask,
  setSortFilter,
  clearState,
  clearFilterData,
  clearTaskCompletion,
  setTaskCompletion,
  setViewTask,
  setRowTasks,
  setAllTasks,
  dropToRowTasks,
  setCache,
  setGroupByValue,
  setSortValue,
  dropCompletedTaskReducer,
} = kanbanSlice.actions;

export const getSortValue = (state: RootState) => state.kanban.data.sort;

export const getSelectOptions = (state: RootState) =>
  state.kanban.data.selectOptions;

export const getFilterPending = (state: RootState) =>
  state.kanban.pending.filter;

export const getCreateFreeTask = (state: RootState) =>
  state.kanban.data.createFreeTask;

export const getEditFreeTask = (state: RootState) =>
  state.kanban.data.editFreeTask;

export const getIsViewPending = (state: RootState) =>
  state.kanban.pending.tasks.getTasks;

export const getIsTaskCreationPending = (state: RootState) =>
  state.kanban.pending.tasks.creation;

export const getTaskCompletion = (state: RootState) =>
  state.kanban.data.taskCompletion;

export const getSetting = (state: RootState) => state.kanban.data.setting;

export const getViewTask = (state: RootState) => state.kanban.data.viewTask;

export const getTasks = (state: RootState) => state.kanban.tasks;

export const getCache = (state: RootState) => state.kanban.tasks.cache;

export const getGroupByValue = (state: RootState) => state.kanban.data.groupBy;

export default kanbanSlice.reducer;
