import { useConclusionDeleteMutation } from "src/FSD/entities/expertise/conclusion/api";

type TProps = {
  id: number;
  fail: boolean;
};

export const useConclusionCard = ({ id, fail }: TProps) => {
  const failText = fail ? "Отрицательное" : "Положительное";
  const [deleteConclusion] = useConclusionDeleteMutation();

  async function handleDeleteConclusion() {
    const result = window.confirm(
      "Вы уверены, что хотите удалить данное заключение?"
    );
    if (result) {
      await deleteConclusion({ id });
    }
  }

  return { failText, handleDeleteConclusion } as const;
};
