import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  id: number;
  data: {
    value: number;
  };
}>;

export const freezeFunds = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/process-work/freeze-funds`,
      params: {
        id,
      },
      method: HTTPMethods.POST,
      data,
    }),
    invalidatesTags: [ApiTags.ProjectProcess.VIEW, ApiTags.ProcessWork.VIEW],
  });
