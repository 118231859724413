import { SourceDataUpdateModal } from "./SourceDataUpdate";
import { AddCommentModal } from "./AddComment/ui/AddCommentModal";
import { AddFilesModal } from "./AddFiles/ui/AddFilesModal";
import { EditDate } from "./EditDate";
import { GipHelperAccept } from "./GipHelperAccept";
import { CreateFreeTaskForm } from "./CreateFreeTask";
import { EditFreeTaskForm } from "./EditFreeTask";
import { RequestRemoveGipForm } from "./RequestRemoveGip";
import { ViewExpertiseInfo } from "./ViewExpertiseInfo";

const ProjectModals = {
  AddCommentModal,
  AddFilesModal,
  SourceDataUpdateModal,
  EditDate,
  GipHelperAccept,
  CreateFreeTaskForm,
  EditFreeTaskForm,
  RequestRemoveGipForm,
  ViewExpertiseInfo,
};

export default ProjectModals;
