import { QuestionCircle } from "react-bootstrap-icons";
import { IconButton } from "@mui/material";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { Tooltip } from "src/FSD/shared/uiKit/v2/Tooltip";
import styles from "../StandardForm/style.module.scss";

type Props = {
  name: string;
  info: string;
};

export const MoreInfoButton = ({ info, name }: Props) => {
  const { handleOpenModal } = useModal();

  return (
    <Tooltip title="Подробная информация">
      <IconButton
        className={styles.expertiseCheckbox__tooltip}
        onClick={() =>
          handleOpenModal({
            name: ModalNames.Project.VIEW_EXPERTISE_INFO,
            info,
            header: name,
          })
        }
      >
        <QuestionCircle width={"20"} height={"20"} />
      </IconButton>
    </Tooltip>
  );
};
