import { useProjectDeleteMutation } from "src/FSD/entities/project/api";
import { useThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton/useThreeDotButton";
import { TPropsObject } from "./useObjects";

export const useObject = ({ object }: TPropsObject) => {
  const { threeDotButtonRef, closeThreeDot } = useThreeDotButton();

  const [deleteProject] = useProjectDeleteMutation();

  const handleDeleteProject = async (id: number) => {
    closeThreeDot();

    if (!window.confirm("Вы уверены, что хотите удалить проект?")) {
      return;
    }

    await deleteProject({ id });
  };

  const { can } = object;

  const showThreeDotButton =
    can.requestRemoveGip || can.updateGip || can.delete;

  return {
    handleDeleteProject,
    showThreeDotButton,
    can,
    threeDotButtonRef,
    closeThreeDot,
  } as const;
};
