import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getCanProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { iconBtnSx } from "src/styles/restyle";
import { setVisible } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/slice/expertiseRemarks";
import { getExpertiseFilterVisible } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarkFilter";
import { FilterSquare, PlusSquareFill } from "react-bootstrap-icons";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import cls from "./HeaderRemarks.module.scss";
import { Filter } from "./Filter/Filter";

export const HeaderRemarks = memo(() => {
  const dispatch = useAppDispatch();
  const can = useAppSelector(getCanProcessInfo);
  const filterVisible = useAppSelector(getExpertiseFilterVisible);
  function toggleVisibleFilter() {
    dispatch(setVisible());
  }

  const { name, handleCloseModal, handleOpenModal } = useModal();

  const handleRemarkCreateForm = () => {
    handleOpenModal({
      name: ModalNames.ProjectProcess.REMARK_CREATE,
    });
  };

  return (
    <div className={cls.wrapper}>
      <div className={cls.header}>
        <h1>Замечания</h1>
        <div className={cls.header_btn}>
          {can?.edit && (
            <>
              <IconButton sx={iconBtnSx} onClick={handleRemarkCreateForm}>
                <PlusSquareFill />
              </IconButton>
              <Modal
                isActive={name === ModalNames.ProjectProcess.REMARK_CREATE}
                handleClose={handleCloseModal}
              >
                <ProjectProcessModals.RemarkCreateForm />
              </Modal>
            </>
          )}
          <IconButton sx={iconBtnSx} onClick={toggleVisibleFilter}>
            <FilterSquare />
          </IconButton>
        </div>
      </div>
      {filterVisible && <Filter />}
    </div>
  );
});
