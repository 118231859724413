import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { concatenateParams } from "src/FSD/shared/lib/helpers/concatenateParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiPrimaryProjectType } from "../../../../../types/api/primaryTypes/apiPrimaryProjectType";

type TParams = RequestParams<{
  id: number;
}>;

export const getProjectProcessTree = (builder: QueryBuilder) =>
  builder.query<ApiPrimaryProjectType, TParams>({
    query: (params) => ({
      url: `v1/project-process/get-project-process-tree`,
      params: {
        id: params.id,
        fields: concatenateParams(params.fields),
        expand: concatenateParams(params.expand),
      },
    }),
    providesTags: [ApiTags.ProjectProcess.GET_PROJECT_PROCESS_TREE],
  });
