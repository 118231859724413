import { AddSourceFiles } from "./AddSourceFiles";
import { CheckCustomerAlignForm } from "./CheckCustomerAlign";
import { CheckExecuteProcess } from "./CheckExecuteProcess";
import { CheckExpertiseRemarkForm } from "./CheckExpertiseRemark";
import { CheckSubCheckForm } from "./CheckSubCheck";
import { CheckSubCheckProcessForm } from "./CheckSubCheckProcess";
import { CheckSubTaskForm } from "./CheckSubTask";
import { Checks } from "./Checks";
import { ConclusionCreateForm } from "./ConclusionCreate";
import { CreateOutTaskForm } from "./CreateOutTask";
import { UpdateRemarkRowForm } from "./UpdateRemarkRow";
import { CustomerAlignCompleteForm } from "./CustomerAlignComplete";
import { ExecuteProcessForm } from "./ExecuteProcess";
import { ExpertiseContractForm } from "./ExpertiseContract";
import { ExpertiseRemarkForm } from "./ExpertiseRemark";
import { ModalGear } from "./ModalGear";
import { OutputTaskForm } from "./OutputTask";
import { RequestCustomerAlignCompleteForm } from "./RequestCustomerAlignComplete";
import { RequestOutTaskForm } from "./RequestOutTask";
import { RemarkCreateForm } from "./RemarkCreateForm";
import { SubTaskForm } from "./SubTask";
import { TaskPublishUpdate } from "./TaskPublishUpdate";
import { CommentRemarkRowForm } from "./CommentRemarkRow";

const ProjectProcessModals = {
  AddSourceFiles,
  CheckCustomerAlignForm,
  CheckExecuteProcess,
  CheckExpertiseRemarkForm,
  CheckSubCheckForm,
  CheckSubCheckProcessForm,
  CheckSubTaskForm,
  Checks,
  ConclusionCreateForm,
  CreateOutTaskForm,
  UpdateRemarkRowForm,
  CustomerAlignCompleteForm,
  ExecuteProcessForm,
  ExpertiseContractForm,
  ExpertiseRemarkForm,
  ModalGear,
  OutputTaskForm,
  RequestCustomerAlignCompleteForm,
  RemarkCreateForm,
  RequestOutTaskForm,
  SubTaskForm,
  TaskPublishUpdate,
  CommentRemarkRowForm,
};

export default ProjectProcessModals;
