import { Paragraph } from "src/FSD/shared/uiKit/v2";
import { SpecialIcons } from "src/FSD/shared/uiKit/v2/SpecialIcons";
import { formInitialsUserName } from "src/FSD/shared/lib/helpers/formInitialsUserName";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import styles from "./CheckTasks.module.scss";
import { getTaskStatus } from "../lib/helpers/getTaskStatus";

type Props = ApiTaskResponses.View;

export const Process = (check: Props) => {
  const { executor, status, date_deadline, taskProcess } = check;

  const {
    projectPart: { code },
  } = taskProcess!;

  const initialsExecutorName = formInitialsUserName(executor);

  const taskStatus = getTaskStatus(status.key, date_deadline);

  return (
    <div className={styles.process}>
      <Paragraph className={styles.process__status}>
        <SpecialIcons.TaskStatus status={taskStatus} />
        <p>{code}</p>
      </Paragraph>
      <Paragraph className={styles.process__executor}>
        {initialsExecutorName}
      </Paragraph>
      <Paragraph color="light" isBold>
        {status.value}
      </Paragraph>
    </div>
  );
};
