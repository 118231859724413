export enum Statuses {
  UNREADY = 1, // Черновик
  WORK = 2, // В работе
  DONE = 3, // Выполнено
  ACCEPT = 4, // Принято
  REJECT = 5, // Отказано
  IS_DONE = 6, // На проверке
  IS_REJECT = 7, // На проверке
  CANCELLED = 8, // Отменено
  WAITING_REQUIRED = 9, // Ожидает входящей информации
  WAITING_SUBCHECK = 10, // Ожидает проверки смежниками
  WAITING_DOCUMENTSIGN = 11, // Заключение договора
  WAITING_ACT_SIGN = 12, // Подписание завершающих документов
  EXPERTISE = 14, // На экспертизе
  PAUSED = 15, // Приостановлен
  PREAGREED = 16, // Предварительно согласовано
  PLAN = 17, // План
  VERIFICATION = 18, // Нормоконтроль
}
