import { useProjectAccessUpdateMutation } from "src/FSD/entities/projectAccess/api";
import { useProcessWorkViewQuery } from "src/FSD/entities/processWork/api";
import { useProjectProcessViewQuery } from "src/FSD/entities/projectProcess/api";
import { ProjectAccessControllingEntities } from "src/FSD/entities/projectAccess/model/controllingEntities";
import { useProjectViewQuery } from "../../../../../../../entities/project/api";

type UseModalProps = {
  id: number;
  type: ProjectAccessControllingEntities;
};

export const useQueries = ({ id, type }: UseModalProps) => {
  const executorMap: any = {
    ProcessWork: useProcessWorkViewQuery,
    ProjectProcess: useProjectProcessViewQuery,
    Project: useProjectViewQuery,
  };

  const useViewQuery = executorMap[type as keyof any];

  const { data: viewData, isFetching } = useViewQuery({
    id,
    expand: ["activeAccess.user", "activeAccess.userType"],
  });

  const [updateExecutor] = useProjectAccessUpdateMutation();

  return {
    updateExecutor,
    viewData,
    isFetching,
  } as const;
};
