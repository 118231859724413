import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { useProjectCheckListListByProjectQuery } from "src/FSD/entities/projectChecklist/api";
import { useProjectSourceDataUpdateMutation } from "src/FSD/entities/projectSourceData/api";
import { TProjectProcessCheckList } from "src/app/feature/ProjectProcessView/InitialData/types/projectProcessCheckList";

type TProps = {
  parentId: number;
  type: number;
  projectId: number;
};

export const useModalGear = ({ projectId, parentId, type }: TProps) => {
  const { handleClose } = useModalContext();

  const { data = [], isLoading: isLoadingList } =
    useProjectCheckListListByProjectQuery({
      projectId,
      parentId,
      type,
    });

  const isLoading = isLoadingList || !data;

  const [update, { isLoading: isUpdating }] =
    useProjectSourceDataUpdateMutation();

  const formSubmitHandler = async (dataValue: TProjectProcessCheckList[]) => {
    const data = dataValue.map((el) => {
      return {
        id: el.id,
        select: el.author,
        executor: el.executor,
        critical: el.critical,
        hidden: el.hidden,
        name: el.name,
      };
    });

    if (projectId) {
      update({ projectId, parentId, type, data });
      handleClose();
    }
  };

  return {
    isLoading,
    formSubmitHandler,
    data,
    isUpdating,
  };
};
