import { useCallback, useEffect } from "react";
import { useResolution } from "src/FSD/shared/lib/hooks/useResolution";
import { KanbanModel } from "src/FSD/entities/kanban";
import { useAppDispatch } from "../../../../../../app/store";
import { pushSuccessMessage } from "../../../../../../app/feature/errorTrace";

export const useKanbanPage = () => {
  const isMobile = useResolution(680);
  const isTablet = useResolution(680); // заменить, когда дойдем до планшетной версии

  const dispatch = useAppDispatch();

  const onFreeTaskDelete = useCallback(({ taskId, status, partGroupId }) => {
    dispatch(KanbanModel.removeFreeTask({ taskId, status, partGroupId }));
    dispatch(pushSuccessMessage());
  }, []);

  const onFreeTaskUpdate = useCallback((newTask) => {
    dispatch(KanbanModel.updateFreeTask(newTask));
    dispatch(pushSuccessMessage());
  }, []);

  useEffect(
    () => () => {
      dispatch(KanbanModel.clearState());
    },
    []
  );

  return {
    isMobile,
    isTablet,
    onFreeTaskDelete,
    onFreeTaskUpdate,
  } as const;
};
