import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { set, ModalState, getModal } from "./modalSlice";

export const useModal = () => {
  const dispatch = useAppDispatch();

  const { name, ...rest } = useAppSelector(getModal);

  const restData = { ...rest };

  const handleOpenModal = (payload: ModalState) => {
    dispatch(set(payload));
  };

  const handleCloseModal = () => {
    dispatch(
      set({
        name: null,
      })
    );
  };

  return { name, handleOpenModal, handleCloseModal, restData } as const;
};
