import { memo } from "react";
import cn from "classnames";
import { Heading } from "src/FSD/shared/uiKit/v2/Heading";
import styles from "./ViewKanbanTask.module.scss";
import { StatusColors } from "../lib/constants/statusColors";
import { ApiTaskResponses } from "../../../../../../entities/task/model/responseTypes";

type Props = ApiTaskResponses.Comment;

export const TaskComment = memo(
  ({
    author,
    date_create,
    status,
    project_name,
    projectPart,
    text,
    is_executor,
  }: Props) => {
    const color = StatusColors[status.key as keyof typeof StatusColors];

    return (
      <div
        className={styles.taskComment}
        style={{ borderLeft: `1px solid ${color}` }}
      >
        <p className={styles.taskComment__status}>
          <span style={{ color }}>{status.value}</span>
          <span>{date_create}</span>
        </p>
        {Boolean(projectPart) && <Heading>{projectPart!.code}</Heading>}
        {Boolean(project_name) && (
          <p className={styles.taskComment__project}>{project_name}</p>
        )}
        <p className={styles.taskComment__executor}>
          <span>Исполнитель: </span>
          <span>{author}</span>
        </p>
        <div
          className={cn(
            styles.taskComment__comment,
            is_executor && styles.taskComment__comment_isExecutor
          )}
        >
          {text}
        </div>
      </div>
    );
  }
);
