import {
  TabChildrenHeader,
  useTabsPanelContext,
} from "src/FSD/widgets/v2/TabsPanel";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import { formFullUserName } from "src/FSD/shared/lib/helpers";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { BackToWorkButton } from "src/FSD/features/v2/BackToWorkButton";
import { ExecutionTask } from "src/FSD/widgets/v2/processWork/ExecutionTask/ui/ExecutionTask";
import { ExecutionTaskDates } from "src/FSD/widgets/v2/processWork/ExecutionTask/ui/ExecutionTaskDates";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Warnings } from "src/FSD/widgets/v2/projectProcess/std/Info/ui";
import { ExecutionChecks } from "src/FSD/widgets/v2/projectProcess/std/ExecutionTasks/ui/ExecutionChecks";
import { ExecutionChildren } from "src/FSD/widgets/v2/projectProcess/std/ExecutionTasks/ui/ExecutionChildren";
import { List } from "src/FSD/shared/uiKit/v2/List";
import { WorkFlowClasses } from "src/FSD/shared/lib/constants/workFlowClasses";
import { useExecutionTasks } from "../lib/hooks/useExecutionTasks";
import styles from "./ExecutionTasks.module.scss";
import PublishedObjectModals from "../../../../publishedObject/modals";
import TaskModals from "../../../../task/modals";
import ProjectProcessModals from "../../../modals";

type Props = {
  projectProcessId: number;
  can: ApiProjectProcessResponses.View["can"];
};

export const ExecutionTasks = ({ projectProcessId, can }: Props) => {
  const {
    executionTasks,
    isExecutionTaskLoading,
    canCustomerAlignStart,
    canRequestCustomerAlignStart,
    warning,
    isCustomerAlignTab,
  } = useExecutionTasks({ projectProcessId, can });

  const { currentChildTab } = useTabsPanelContext();

  const { name, handleOpenModal, handleCloseModal, restData } = useModal();

  const modalNameCheckProcess = isCustomerAlignTab
    ? ModalNames.ProjectProcess.CHECK_CUSTOMER_ALIGN
    : ModalNames.ProjectProcess.CHECK_EXECUTE_PROCESS;

  return (
    <>
      <Modal
        isActive={
          name === ModalNames.PublishedObject.REQUEST_CUSTOMER_ALIGN_START
        }
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.RequestCustomerAlignStartForm
          id={projectProcessId}
          type={PublishedObjectControllingEntities.PROJECT_PROCESS}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.CUSTOMER_ALIGN_START}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.CustomerAlignStartForm
          id={projectProcessId}
          type={PublishedObjectControllingEntities.PROJECT_PROCESS}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.Task.COMMENTS}
        handleClose={handleCloseModal}
      >
        <TaskModals.CommentsForm taskId={restData.taskId} />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProjectProcess.CHECK_CUSTOMER_ALIGN}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.CheckCustomerAlignForm
          id={restData.taskId}
          header={restData.header}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProjectProcess.CHECK_EXECUTE_PROCESS}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.CheckExecuteCompleteObject
          taskId={restData.taskId}
          id={restData.processId}
          header={restData.header}
          type={PublishedObjectControllingEntities.PROJECT_PROCESS}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.EXECUTE_COMPLETE}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.ExecuteCompleteForm
          id={restData.taskId}
          header={restData.header}
          isCustomerAlign={isCustomerAlignTab}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProjectProcess.EXPERTISE_REMARK}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.ExpertiseRemarkForm
          taskId={restData.taskId}
          header={restData.header}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProjectProcess.SUB_TASK}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.SubTaskForm
          taskId={restData.taskId}
          header={restData.header}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProjectProcess.CHECK_SUB_TASK}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.CheckSubTaskForm
          taskId={restData.taskId}
          header={restData.header}
        />
      </Modal>
      <TabChildrenHeader
        title="Документация"
        mediaClassName={styles.mediaClassName}
        media={
          <>
            {canRequestCustomerAlignStart && (
              <Button
                onClick={() => {
                  handleOpenModal({
                    name: ModalNames.PublishedObject
                      .REQUEST_CUSTOMER_ALIGN_START,
                  });
                }}
              >
                Запросить
              </Button>
            )}
            {canCustomerAlignStart && (
              <Button
                onClick={() => {
                  handleOpenModal({
                    name: ModalNames.PublishedObject.CUSTOMER_ALIGN_START,
                  });
                }}
              >
                Выдать
              </Button>
            )}
          </>
        }
      />
      <List maxHeight="865px">
        {isExecutionTaskLoading ? (
          <Skeleton.List />
        ) : (
          <>
            {warning && <Warnings warnings={warning} />}
            <ListMapper>
              {executionTasks.map((executionTask) => {
                const { header, task, checks, children } = executionTask;
                const { status, name } = task;
                const title = currentChildTab === "task" ? header : name;

                const executorName = formFullUserName(task.executor);

                const actions = (
                  <div className={styles.actions}>
                    {task.can.check && (
                      <Button
                        color="primary"
                        onClick={() => {
                          handleOpenModal({
                            name: modalNameCheckProcess,
                            taskId: task.id,
                            header: task.name,
                            processId: projectProcessId,
                          });
                        }}
                      >
                        Проверить
                      </Button>
                    )}
                    {task.can.complete &&
                      task.workflow_class ===
                        WorkFlowClasses.EXECUTE_PROCESS && (
                        <Button
                          color="primary"
                          onClick={() => {
                            handleOpenModal({
                              name: ModalNames.PublishedObject.EXECUTE_COMPLETE,
                              taskId: task.id,
                              header: task.name,
                              comment: task.comment,
                            });
                          }}
                        >
                          Выдать
                        </Button>
                      )}
                    {task.can.complete &&
                      task.workflow_class ===
                        WorkFlowClasses.EXPERTISE_REMARK && (
                        <Button
                          color="primary"
                          onClick={() => {
                            handleOpenModal({
                              name: ModalNames.ProjectProcess.EXPERTISE_REMARK,
                              taskId: task.id,
                              header: task.name,
                              comment: task.comment,
                            });
                          }}
                        >
                          Выдать
                        </Button>
                      )}
                    {task.can.backToWork && (
                      <BackToWorkButton taskId={task.id} />
                    )}
                  </div>
                );
                return (
                  <ExecutionTask
                    key={task.id}
                    parentId={task.parent_id}
                    taskId={task.id}
                    filesCount={task.files.length}
                    dates={
                      <ExecutionTaskDates
                        status={status}
                        task={task}
                        isRequest={false}
                      />
                    }
                    checks={
                      <ExecutionChecks checks={checks} modalHeader={title} />
                    }
                    className={styles.executionTask}
                    taskName={title}
                    userName={executorName}
                    actions={actions}
                    media={<ExecutionChildren children={children} />}
                  />
                );
              })}
            </ListMapper>
          </>
        )}
      </List>
    </>
  );
};
