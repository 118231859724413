import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProjectResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
  userId: number;
}>;

export const getUserTypeToAssign = (builder: QueryBuilder) =>
  builder.query<ApiProjectResponses.TGetUserToAssign, TParams>({
    query: (params) => ({
      url: `v1/project/get-user-type-to-assign`,
      params: {
        id: params.id,
        userId: params.userId,
      },
    }),
  });
