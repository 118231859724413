import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiPublishedObjectRequests } from "../../../publishedObject/model/requestsTypes";

type TParams = RequestParams<{
  id: number;
  data: ApiPublishedObjectRequests.PublishObject;
}>;

export const publish = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/project-process/publish`,
      params: {
        id,
      },
      data,
      method: HTTPMethods.PUT,
    }),
    invalidatesTags: [
      ApiTags.ProjectProcess.VIEW,
      ApiTags.ProjectProcess.GET_WORKS,
      ApiTags.ProjectProcess.GET_PROJECT_PROCESS_TREE,
    ],
  });
