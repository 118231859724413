import { ApiProcessWorkResponses } from "../../../../../entities/processWork/model/responseTypes";

export const getBreadcrumbs = (processWork: ApiProcessWorkResponses.View) => {
  if (!processWork) {
    return [];
  }

  return [
    {
      isActive: true,
      title: "Главная",
      url: `/user/new-dashboard`,
    },
    {
      isActive: true,
      title: processWork.project!.name,
      url: `/project/test/${processWork.project!.id}`,
    },
    {
      isActive: true,
      title: processWork.projectPart.code,
      url: `/project-process/test/${processWork.process_id}`,
    },
    {
      isActive: false,
      title: processWork.title,
    },
  ];
};
