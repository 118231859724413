import { ChangeEvent } from "react";
import { UseFormSetValue } from "react-hook-form";
import { TFieldValues } from "../hooks/useExpertise";

export const checkMainExperise = (
  processValues: TFieldValues,
  id: number,
  value: ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<TFieldValues>
) => {
  if (processValues.main.includes(id) && value) {
    processValues.main.forEach((processId) => {
      const expertiseProcess = processValues[processId];

      if (typeof expertiseProcess === "boolean") {
        setValue(String(processId), false);
      } else {
        Object.keys(expertiseProcess).forEach((subProcessId) => {
          setValue(String(subProcessId), false);
        });
      }
    });
  }
};
