import { UseFormSetValue } from "react-hook-form";
import { TFieldValues } from "../hooks/useExpertise";

export const calculateAllSelected = (
  processValues: TFieldValues,
  id: string,
  setValue: UseFormSetValue<TFieldValues>
) => {
  if (processValues.areAllSelected.includes(id)) {
    setValue(
      "areAllSelected",
      processValues.areAllSelected.filter((processId) => processId !== id)
    );
  } else {
    setValue("areAllSelected", [...processValues.areAllSelected, id]);
  }
};
