import React from "react";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { useTabsPanelContext } from "src/FSD/widgets/v2/TabsPanel";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";

type TProps = {
  paramsId: number;
};

export const ExchangeTasksHeader = ({ paramsId }: TProps) => {
  const { currentChildTab } = useTabsPanelContext();
  const { name, handleCloseModal, handleOpenModal } = useModal();

  const handleOpenRequestOutTask = () => {
    handleOpenModal({
      name: ModalNames.ProjectProcess.REQUEST_OUT_TASK,
    });
  };

  const handleOpenCreateOutTask = () => {
    handleOpenModal({
      name: ModalNames.ProjectProcess.CREATE_OUT_TASK,
    });
  };

  return (
    <div>
      <Modal
        isActive={name === ModalNames.ProjectProcess.REQUEST_OUT_TASK}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.RequestOutTaskForm processId={paramsId} />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProjectProcess.CREATE_OUT_TASK}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.CreateOutTaskForm processId={paramsId} />
      </Modal>
      {currentChildTab === "inputTask" && (
        <Button onClick={handleOpenRequestOutTask} color="primary">
          Запросить
        </Button>
      )}
      {currentChildTab === "outputTask" && (
        <Button onClick={handleOpenCreateOutTask} color="primary">
          Выдать
        </Button>
      )}
    </div>
  );
};
