import {
  httpRequestDelete,
  httpRequestGet,
  httpRequestPost,
  httpRequestPostMultiData,
} from "../api";
import { config } from "../../../../app.cofig";

export const ApiProjectProcessDocumentations = (
  processId: number
): Promise<Response> => {
  // получить дерево договоры
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/get-documentations?id=${processId}&expand=svgIcon,data`
  );
};

export const ApiProjectProcessBackToWork = (
  taskId: number
): Promise<Response> => {
  // вернуть задачу
  return httpRequestPost(
    `${config.localDomain}/v1/task/back-to-work?id=${taskId}`
  );
};

export const ApiProjectProcessTaskComplete = (
  taskId: number,
  data: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/task/complete?id=${taskId}`,
    {},
    data
  );
};

export const ApiProjectProcessTaskCompleteNotMulti = (
  taskId: number,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${taskId}`,
    {},
    data
  );
};

export const ApiProjectProcessCheckTask = (
  taskId: number,
  data: any
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/task/complete?id=${taskId}`,
    {},
    data
  );
};

export const ApiProjectProcessDepositFunds = (
  processId: number
): Promise<Response> => {
  return httpRequestPost(
    `${config.localDomain}/v1/project-process/deposit-funds?id=${processId}`
  );
};

export const ApiProjectProcessCanCheck = (
  processId: number
): Promise<Response> => {
  // получить can на проверить в гот док
  return httpRequestGet(
    `${config.localDomain}/v1/project-process/can-check?id=${processId}`
  );
};

export const ApiProjectProcessDonePreliminaryByTask = (
  processId: number,
  taskId: number,
  formData: any
): Promise<Response> => {
  return httpRequestPostMultiData(
    `${config.localDomain}/v1/project-process/start-customer-align?id=${processId}&taskId=${taskId}`,
    {},
    formData
  );
};

export const ApiDeleteDocumentationTask = (
  taskId: number
): Promise<Response> => {
  return httpRequestDelete(`${config.localDomain}/v1/task/delete?id=${taskId}`);
};
