import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchExpertiseRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import {
  getExpertiseRemarks,
  getExpertiseRemarksIsLoad,
} from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarks";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getExpertiseRemarksFilterIsLoad } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarkFilter";
import { getTabShowProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getTabShowProcessInfo";
import { ExpertiseFilterRemarks } from "src/components/UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { HeaderRemarks } from "./HeaderRemarks/HeaderRemarks";
import cls from "./RemarksExpertise.module.scss";
import { RemarkCard } from "./RemarkCard/RemarkCard";

export const RemarksExpertise = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const processId = useAppSelector(getProjectProcessInfoId);
  const tabShow = useAppSelector(getTabShowProcessInfo);
  const expertiseRemarks = useAppSelector(getExpertiseRemarks);
  const isLoadExpertiseRemarks = useAppSelector(getExpertiseRemarksIsLoad);
  const isLoadFilterRemarks = useAppSelector(getExpertiseRemarksFilterIsLoad);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
    }
  }, []);

  useEffect(() => {
    if (processId && tabShow?.expertiseRemarks.show) {
      dispatch(fetchExpertiseRemarks(processId));
    }
  }, [processId]);

  if (isLoadExpertiseRemarks) {
    return (
      <div className={cls.wrapper}>
        <Skeleton.List height={65} />
      </div>
    );
  }

  return (
    <div className={cls.wrapper}>
      <HeaderRemarks />
      {isLoadFilterRemarks && <ExpertiseFilterRemarks />}
      {!isLoadFilterRemarks &&
        expertiseRemarks?.map((remark, index) => (
          <RemarkCard key={remark.remark.id} {...remark} index={index + 1} />
        ))}
    </div>
  );
});
