import { memo, useCallback, useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  RefType,
  ThreeDotButton,
} from "../../../newUI/ThreeDotButton/ThreeDotButton";
import { fetchProjectProcessPause } from "../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessPause";
import { fetchProjectProcessViewDuplicate } from "../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import Modal from "../../../newUI/Modal/Modal";
import { ModalFeedbackAuthor } from "./ModalFeedbackAuthor/ModalFeedbackAuthor";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { getCanProcessInfo } from "../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { getCanTaskProcessInfo } from "../../../../app/feature/ProjectProcessView/Info/selectors/getCanTaskProcessInfo";
import { getFeedbackExecutorIsLoad } from "../../../../app/feature/ProjectProcessView/Info/selectors/getFeedbackExecutorIsLoad";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoProjectChatId,
  getProjectProcessInfoPublished,
  getProjectProcessIsStopped,
} from "../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { StopIcon } from "../../../newUI/SVG";
import cls from "./HeaderBtn.module.scss";
import { ModalStop } from "./ModalStop/ModalStop";
import { getProjectProcessIsLoadCreateStop } from "../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessIsLoadCreateStop";
import { ModalDeposit } from "./ModalDeposit/ModalDeposit";
import { getProjectProcessIsLoadDeposit } from "../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessIsLoadDeposit";
import { getProjectProcessRequestError } from "../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessRequest";
import { ModalPublish } from "./ModalPublish/ModalPublish";
import {
  getIsLoadPublish,
  getIsLoadUpdate,
  getPublishState,
  getUpdate,
} from "../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { fetchProjectProcessUnPublish } from "../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessUnPublish";
import { ModalUpdatePublish } from "./ModalUpdatePublish/ModalUpdatePublish";
import { ModalCreateWork } from "./ModalCreateWork/ModalCreateWork";
import { getProjectProcessIsLoadCreateWorkIsLoad } from "../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcesscreateWork";
import { getProjectProcessDocumentsTab } from "../../../../app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import { ModalPreliminaryRequest } from "./ModalPreliminaryRequest/ModalPreliminaryRequest";
import { getProjectProcessRequestPreliminaryIsLoad } from "../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessRequestPreliminary";
import { getProjectProcessTaskCompleteIsLoad } from "../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessStopped";
import { setErrorClear } from "../../../../app/feature/ProjectProcessView/HeaderBtn/slice/projectProcessHeaderBtn";
import { fetchProjectProcessApplications } from "../../../../app/feature/ProjectProcessView/Applications/services/fetchProjectProcessApplications";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../styles/restyle";
import { ModalCreateMaterial } from "./ModalCreateMaterial/ModalCreateMaterial";
import { getProjectProcessMaterialTemplateCreateListIsLoad } from "../../../../app/feature/ProjectProcessView/Material/selectors/getProjectProcessMaterialTemplateList";
import { ModalPublish as ModalTakeToWork } from "../ExpertiseTabs/ExpertiseHeaderBtn/ModalPublish/ModalPublish";
import {
  getExpertisePublishIsLoad,
  getExpertiseUpdatePublishIsLoad,
} from "../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/selectors/getExpertiseHeaderBtn";
import { ModalUpdatePublish as ModalWorkUpdate } from "../ExpertiseTabs/ExpertiseHeaderBtn/ModalUpdatePublish/ModalUpdatePublish";

const sxMenuItem = {
  color: "#9BACB9",
  "&:hover": {
    color: "#FFFFFF",
  },
};
export const HeaderBtn = memo(({ currentTab }: { currentTab: string }) => {
  const threeDotButtonRef = useRef<RefType>(null);
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const infoData = useAppSelector(getProjectProcessInfoData);

  const isLoadDeposit = useAppSelector(getProjectProcessIsLoadDeposit);
  const isLoadCreateStop = useAppSelector(getProjectProcessIsLoadCreateStop);
  const isLoadCreateMaterial = useAppSelector(
    getProjectProcessMaterialTemplateCreateListIsLoad
  );
  const isLoadTaskComplete = useAppSelector(
    getProjectProcessTaskCompleteIsLoad
  );
  const isLoadAuthorFeedback = useAppSelector(getFeedbackExecutorIsLoad);
  const isLoadPublish = useAppSelector(getIsLoadPublish);
  const isLoadTakeToWork = useAppSelector(getExpertisePublishIsLoad);
  const isLoadUpdatePublish = useAppSelector(getIsLoadUpdate);
  const isLoadUpdateWork = useAppSelector(getExpertiseUpdatePublishIsLoad);
  const published = useAppSelector(getProjectProcessInfoPublished);
  const isLoadPreliminaryRequest = useAppSelector(
    getProjectProcessRequestPreliminaryIsLoad
  );
  const isLoadCreateWork = useAppSelector(
    getProjectProcessIsLoadCreateWorkIsLoad
  );

  const can = useAppSelector(getCanProcessInfo);
  const canTask = useAppSelector(getCanTaskProcessInfo);
  const stopped = useAppSelector(getProjectProcessIsStopped);
  const projectChatId = useAppSelector(getProjectProcessInfoProjectChatId);
  const publish = useAppSelector(getPublishState);
  const updatePublish = useAppSelector(getUpdate);
  const activeDocumentationsTab = useAppSelector(getProjectProcessDocumentsTab);
  const error = useAppSelector(getProjectProcessRequestError);

  const canPreliminaryDecision =
    currentTab === "executionTask" &&
    activeDocumentationsTab === 1 &&
    can?.requestCustomerAlign;

  const [modalFeedback, setModalFeedback] = useState(false);
  const [modalStop, setModalStop] = useState(false);
  const [modalDeposit, setModalDeposit] = useState(false);
  const [modalPublish, setModalPublish] = useState(false);
  const [modalTakeToWork, setModalTakeToWork] = useState(false);
  const [updatePublishModal, setUpdatePublishModal] = useState(false);
  const [updateWorkModal, setUpdateWorkModal] = useState(false);
  const [createWorkModal, setCreateWorkModal] = useState(false);
  const [modalPreliminaryRequest, setModalPreliminaryRequest] = useState(false);
  const [modalMaterialProcessCreate, setModalMaterialProcessCreate] =
    useState(false);

  const stopModalClose = useCallback((state: boolean) => {
    setModalStop(state);
    if (params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
    }
  }, []);

  const closeModalDeposit = useCallback(() => {
    setModalDeposit(false);
  }, []);

  const closeModalUpdatePublish = useCallback(() => {
    setUpdatePublishModal(false);
  }, []);

  const closeModalUpdateWork = useCallback(() => {
    setUpdateWorkModal(false);
  }, []);

  const closeModalPublish = useCallback(() => {
    setModalPublish(false);
  }, []);

  const closeModalTakeToWork = useCallback(() => {
    setModalTakeToWork(false);
  }, []);

  const closeModalFeedback = useCallback(() => {
    setModalFeedback(false);
  }, []);

  const closeModalPreliminaryRequest = useCallback(() => {
    setModalPreliminaryRequest(false);
  }, []);

  const closeModalCreateWork = useCallback(() => {
    setCreateWorkModal(false);
  }, []);

  const closeModalCreateMaterial = useCallback(() => {
    setModalMaterialProcessCreate(false);
  }, []);
  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  useEffect(() => {
    if ((publish || updatePublish) && params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
      dispatch(fetchProjectProcessApplications(+params.id));
    }
  }, [publish, updatePublish]);

  async function pauseHandler() {
    const result = window.confirm(
      "Вы уверены, что хотите приостановить раздел?"
    );
    if (params.id && result) {
      await dispatch(fetchProjectProcessPause({ id: +params.id, pause: true }));
      await dispatch(fetchProjectProcessViewDuplicate(+params.id));
      closeThreeDot();
    }
  }

  async function unPauseHandler() {
    const result = window.confirm("Вы уверены, что хотите возобновить раздел?");
    if (params.id && result) {
      await dispatch(
        fetchProjectProcessPause({ id: +params.id, pause: false })
      );
      await dispatch(fetchProjectProcessViewDuplicate(+params.id));
      closeThreeDot();
    }
  }

  async function handleUnPublishClick() {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть раздел с публикации?"
    );
    if (result && params.id) {
      await dispatch(fetchProjectProcessUnPublish(+params.id));
      await dispatch(fetchProjectProcessViewDuplicate(+params.id));
      closeThreeDot();
    }
  }

  function feedbackHandler() {
    setModalFeedback(true);
    closeThreeDot();
  }

  function handleDepositClick() {
    setModalDeposit(true);
    closeThreeDot();
  }

  function publishClick() {
    setModalPublish(true);
  }

  function takeToWorkCLick() {
    setModalTakeToWork(true);
  }

  function updatePublishClick() {
    setUpdatePublishModal(true);
    closeThreeDot();
  }

  function updateWorkClick() {
    setUpdateWorkModal(true);
    closeThreeDot();
  }
  function createWork() {
    setCreateWorkModal(true);
    closeThreeDot();
  }

  function createMaterialProcess() {
    setModalMaterialProcessCreate(true);
    closeThreeDot();
  }

  if (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: 3000,
    });
    dispatch(setErrorClear());
  }

  return (
    <>
      {canTask?.feedbackToController && (
        <Modal
          active={modalFeedback}
          setActive={setModalFeedback}
          isLoad={isLoadAuthorFeedback}
        >
          <ModalFeedbackAuthor modalClose={closeModalFeedback} />
        </Modal>
      )}
      {can?.stop && (
        <Modal
          active={modalStop}
          setActive={stopModalClose}
          isLoad={isLoadCreateStop || isLoadTaskComplete}
        >
          <ModalStop modalClose={stopModalClose} />
        </Modal>
      )}
      {can?.freezeFunds && (
        <Modal
          active={modalDeposit}
          setActive={setModalDeposit}
          isLoad={isLoadDeposit}
        >
          <ModalDeposit
            closeModal={closeModalDeposit}
            includesNds={Boolean(infoData?.executor_nds)}
          />
        </Modal>
      )}
      {can?.publish && published && (
        <Modal
          active={modalPublish}
          setActive={setModalPublish}
          isLoad={isLoadPublish}
        >
          <ModalPublish closeModal={closeModalPublish} />
        </Modal>
      )}
      {can?.publish && !published && (
        <Modal
          active={modalTakeToWork}
          setActive={setModalTakeToWork}
          isLoad={isLoadTakeToWork}
          activeClass={cls.modal}
        >
          <ModalTakeToWork closeModal={closeModalTakeToWork} />
        </Modal>
      )}
      {can?.unpublish && published && (
        <Modal
          active={updatePublishModal}
          setActive={setUpdatePublishModal}
          isLoad={isLoadUpdatePublish}
        >
          <ModalUpdatePublish closeModal={closeModalUpdatePublish} />
        </Modal>
      )}
      {can?.update && !published && (
        <Modal
          active={updateWorkModal}
          setActive={setUpdateWorkModal}
          isLoad={isLoadUpdateWork}
          activeClass={cls.modal}
        >
          <ModalWorkUpdate closeModal={closeModalUpdateWork} />
        </Modal>
      )}
      {can?.createWork && currentTab === "work" && (
        <Modal
          active={createWorkModal}
          setActive={setCreateWorkModal}
          isLoad={isLoadCreateWork}
        >
          <ModalCreateWork closeModal={closeModalCreateWork} />
        </Modal>
      )}
      {canPreliminaryDecision && (
        <Modal
          active={modalPreliminaryRequest}
          setActive={setModalPreliminaryRequest}
          activeClass={cls.modal}
          isLoad={isLoadPreliminaryRequest}
        >
          <ModalPreliminaryRequest closeModal={closeModalPreliminaryRequest} />
        </Modal>
      )}
      {currentTab === "materials" && can?.materialProcess && (
        <Modal
          active={modalMaterialProcessCreate}
          setActive={setModalMaterialProcessCreate}
          activeClass={cls.modalMaterial}
          isLoad={isLoadCreateMaterial}
        >
          <ModalCreateMaterial closeModal={closeModalCreateMaterial} />
        </Modal>
      )}
      <div className={cls.btn}>
        {can?.publish && published && (
          <CustomButton
            onClick={publishClick}
            width={160}
            background={palette.blue}
          >
            Опубликовать
          </CustomButton>
        )}
        {can?.publish && !published && (
          <CustomButton
            onClick={takeToWorkCLick}
            width={160}
            background={palette.blue}
          >
            Взять в работу
          </CustomButton>
        )}
        {can?.createWork && currentTab === "work" && (
          <CustomButton
            onClick={createWork}
            width={150}
            background={palette.green}
          >
            Создать задачу
          </CustomButton>
        )}
        {can?.stop && (
          <IconButton
            onClick={() => setModalStop(true)}
            className={classNames(stopped ? cls.stoppedRed : cls.stoppedWhite)}
          >
            <StopIcon />
          </IconButton>
        )}
        <ThreeDotButton ref={threeDotButtonRef}>
          {can?.pause && (
            <MenuItem onClick={pauseHandler} disableRipple>
              Приостановить
            </MenuItem>
          )}
          {can?.unpause && (
            <MenuItem onClick={unPauseHandler} disableRipple>
              Возобновить
            </MenuItem>
          )}
          {canTask?.feedbackToController && (
            <MenuItem onClick={feedbackHandler} disableRipple>
              Оценить
            </MenuItem>
          )}
          <Link to={`/chat/${projectChatId}`}>
            <MenuItem sx={sxMenuItem} disableRipple>
              Чат
            </MenuItem>
          </Link>
          {can?.freezeFunds && (
            <MenuItem onClick={handleDepositClick} disableRipple>
              Пополнить
            </MenuItem>
          )}
          {can?.unpublish && (
            <MenuItem onClick={updatePublishClick} disableRipple>
              Редактировать
            </MenuItem>
          )}
          {can?.update && !published && (
            <MenuItem onClick={updateWorkClick} disableRipple>
              Редактировать
            </MenuItem>
          )}
          {can?.unpublish && (
            <MenuItem onClick={handleUnPublishClick} disableRipple>
              Отозвать
            </MenuItem>
          )}
          {currentTab === "materials" && can?.materialProcess && (
            <MenuItem onClick={createMaterialProcess} disableRipple>
              Новый запрос
            </MenuItem>
          )}
        </ThreeDotButton>
      </div>
    </>
  );
});
