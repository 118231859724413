import { useResolution } from "src/FSD/shared/lib/hooks/useResolution";
import {
  formFullUserName,
  stringDateToDDMMYYYY,
} from "src/FSD/shared/lib/helpers";
import { ApiPublishedObjectResponse } from "src/FSD/entities/publishedObject/model/responseTypes";

export const useRemoveExecutor = (
  removed: ApiPublishedObjectResponse.Removed
) => {
  const isTablet = useResolution(1200);

  const isMobile = useResolution(600);

  const fullName = formFullUserName(removed.executor);

  const jobDuration = ((removed.executor.job_duration ?? 0) / 365).toFixed(0);

  const dateStart = stringDateToDDMMYYYY(removed.date_create);

  const dateEnd = stringDateToDDMMYYYY(removed.date_complete);

  return {
    isTablet,
    isMobile,
    fullName,
    jobDuration,
    dateStart,
    dateEnd,
  } as const;
};
