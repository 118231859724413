import React, {
  Fragment,
  ChangeEventHandler,
  memo,
  useState,
  useEffect,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { TProjectProcessCheckList } from "src/app/feature/ProjectProcessView/InitialData/types/projectProcessCheckList";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Checkbox, SwitchLabel } from "src/FSD/shared/uiKit/v2";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { Tooltip } from "src/FSD/shared/uiKit/v2/Tooltip";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { TSourceDataUpdateForm } from "src/FSD/widgets/v2/sourceData/modals/SourceDataUpdateForm/model/types";
import classNames from "classnames";
import { ApiProjectChecklistResponses } from "src/FSD/entities/projectChecklist/model/responseTypes";
import { palette } from "src/styles/restyle";
import {
  ExclamationCircle,
  EyeSlashFill,
  PersonExclamation,
} from "react-bootstrap-icons";
import {
  handleFilterChange,
  getAuthorIds,
  getAuthorNames,
} from "../lib/helpers";
import { SourceDataNameEdit } from "./SourceDataNameEdit";
import { AddCheckList } from "./AddCheckList";
import styles from "./SourceDataUpdateForm.module.scss";

interface TSourceDataUpdateFormProps {
  formSubmitHandler: (data: TProjectProcessCheckList[]) => void;
  checkList: ApiProjectChecklistResponses.ListByProject[];
  canExecutorEdit: boolean;
  isUpdating: boolean;
  handleDeleteSourceDataName: (id: number) => void;
  parentId: number;
  projectId: number;
  type: number;
}

export const SourceDataUpdateForm = memo(
  ({
    formSubmitHandler,
    checkList,
    canExecutorEdit,
    isUpdating,
    handleDeleteSourceDataName,
    parentId,
    projectId,
    type,
  }: TSourceDataUpdateFormProps) => {
    const [allCheck, setAllCheck] = useState(checkList);
    const { handleClose } = useModalContext();

    const { handleSubmit, control, register } =
      useForm<TSourceDataUpdateForm.TInputs>({
        defaultValues: {
          author: getAuthorIds(allCheck, "author"),
          critical: getAuthorIds(allCheck, "critical"),
          executor: getAuthorIds(allCheck, "executor"),
          hidden: getAuthorIds(allCheck, "hidden"),
          names: getAuthorNames(allCheck),
        },
      });

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const { name, checked, value } = event.target;

      handleFilterChange(
        name,
        checked,
        canExecutorEdit,
        value,
        allCheck,
        setAllCheck
      );
    };

    useEffect(() => {
      setAllCheck(checkList);
    }, [checkList]);

    const formSubmitCallback = (data: TSourceDataUpdateForm.TInputs) => {
      const { names } = data;
      const updatedFormData = allCheck.map((check: any) =>
        names[check.id] ? { ...check, name: names[check.id] } : check
      );

      formSubmitHandler(updatedFormData);
    };

    return (
      <div>
        <ScrollWrap height={"500px"} classname={styles.scrollWrapper}>
          <div
            className={classNames(styles.modalParent, styles.modalParent_top)}
          >
            <div>
              <SwitchLabel
                onChange={(e) => handleChange(e)}
                checked={
                  allCheck.filter((item) => item?.author !== true).length < 1
                }
                name="All"
                labelPlacement="start"
                label="Выбрать все"
              />
            </div>
            <Tooltip
              title="Скрытые"
              content="(Ид, которые доступны только участникам проекта)"
            >
              <EyeSlashFill color={palette.persianGreen} />
            </Tooltip>
            <Tooltip
              title="Заказчик"
              content="(за предоставление ИД  отвечает Заказчик)"
            >
              <PersonExclamation color={palette.persianGreen} />
            </Tooltip>
            <Tooltip
              title="Критичные"
              content="(важные ИД, которые влияют на длительность выполнения работ) "
            >
              <ExclamationCircle size={20} color={palette.signalOrange} />
            </Tooltip>
          </div>
          <Modal.Form
            onSubmit={handleSubmit(formSubmitCallback)}
            className={styles.form}
            id="source-update-form"
          >
            <div className={styles.modalParent}>
              {allCheck.map((el) => (
                <Fragment key={el.id}>
                  <div className={styles.author}>
                    <Controller
                      control={control}
                      name="author"
                      render={() => (
                        <SwitchLabel
                          onChange={(e) => handleChange(e)}
                          checked={
                            el?.author || el.executor || el.critical || false
                          }
                          value={el.id}
                          name="author"
                          label={!el.delete && el.name}
                        />
                      )}
                    />
                    {el.delete && (
                      <SourceDataNameEdit
                        handleDeleteSourceDataName={handleDeleteSourceDataName}
                        register={register}
                        {...el}
                      />
                    )}
                  </div>
                  <Controller
                    control={control}
                    name="hidden"
                    render={() => (
                      <Checkbox
                        title={
                          "Файлы не публикуемые и доступные только участникам проекта"
                        }
                        onChange={(e) => handleChange(e)}
                        checked={el.hidden || false}
                        value={el.id}
                        editing
                        name="hidden"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="executor"
                    render={() => (
                      <Checkbox
                        onChange={(e) => handleChange(e)}
                        checked={el.executor || false}
                        value={el.id}
                        editing
                        name="executor"
                        disabled={false}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="critical"
                    render={() => (
                      <Checkbox
                        onChange={(e) => handleChange(e)}
                        checked={el.critical || false}
                        value={el.id}
                        editing
                        name="critical"
                      />
                    )}
                  />
                </Fragment>
              ))}
            </div>
          </Modal.Form>
          <AddCheckList parentId={parentId} projectId={projectId} type={type} />
        </ScrollWrap>
        <Modal.Controls>
          <Button onClick={handleClose} variant="outlined">
            Отменить
          </Button>
          <Button form="source-update-form" type="submit" disabled={isUpdating}>
            Сохранить
          </Button>
        </Modal.Controls>
      </div>
    );
  }
);
