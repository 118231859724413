import { rootApi } from "src/FSD/shared/api/common/rootApi";
import {
  listByProjectWithCount,
  listByProject,
  create,
  deleteProjectChecklist,
} from "./queries";

export const projectAccessApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    projectCheckListListByProjectWithCount: listByProjectWithCount(builder),
    projectCheckListListByProject: listByProject(builder),
    projectCheckListCreate: create(builder),
    projectCheckListDelete: deleteProjectChecklist(builder),
  }),
  overrideExisting: false,
});

export const {
  useProjectCheckListListByProjectWithCountQuery,
  useProjectCheckListListByProjectQuery,
  useProjectCheckListCreateMutation,
  useProjectCheckListDeleteMutation,
} = projectAccessApi;
