import { Link } from "react-router-dom";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { config } from "src/FSD/shared/lib/configs/app.config";
import styles from "./InfoObject.module.scss";
import {
  areAllStagesValuesFalse,
  formStageObject,
} from "../../lib/helpers/formStageObject";
import { TPropsObject } from "../../lib/hooks/useObjects";

export const InfoObject = ({ object }: TPropsObject) => {
  return (
    <div className={styles.infoObject}>
      <div className={styles.infoObject__name}>
        <Link
          to={`${config.localDomain}/project/test/${object.id}`}
          target="_blank"
        >
          <Paragraph
            color="light"
            isBold
            fontSize={18}
            style={{ lineHeight: "28px" }}
          >
            {object.name}
          </Paragraph>
        </Link>

        {object.gip && <Paragraph fontSize={18}>ГИП: {object.gip}</Paragraph>}
      </div>
      {!areAllStagesValuesFalse({ object }) && (
        <div className={styles.infoObject__stage}>
          <Paragraph>Стадии:</Paragraph>
          <div className={styles.infoObject__stageItem}>
            {formStageObject({ object }).map(
              (stage) =>
                stage.showStage && (
                  <Checkbox
                    key={stage.label}
                    label={stage.label}
                    checked={stage.done}
                    type="radio"
                    className={styles.infoObject__checkbox}
                    onChange={() => {}}
                    disabled={stage.enable}
                  />
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
};
