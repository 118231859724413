import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProjectCheckListCreateMutation } from "src/FSD/entities/projectChecklist/api";
import { addCheckListSchemas } from "../schemas/addCheckList";

export type TAddCheckList = {
  parentId: number;
  projectId: number;
  type: number;
};

type TFieldValues = {
  name: string;
  hidden: boolean;
  executor: boolean;
  critical: boolean;
};

export const useAddCheckList = ({
  parentId,
  projectId,
  type,
}: TAddCheckList) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<TFieldValues>({
    defaultValues: {
      name: "",
      executor: false,
      hidden: false,
      critical: false,
    },
    resolver: yupResolver(addCheckListSchemas),
  });

  const [formShow, setFormShow] = useState(false);
  const handleFormShow = () => {
    setFormShow(true);
  };

  const [checkListCreate, { isLoading }] = useProjectCheckListCreateMutation();

  const onSubmit = async (data: TFieldValues) => {
    await checkListCreate({
      parentId,
      projectId,
      data: { ProjectChecklist: { ...data, type } },
    });
    reset();
    setFormShow(false);
  };

  const handleOnSubmit = handleSubmit(onSubmit);

  return {
    register,
    control,
    setValue,
    errors,
    formShow,
    handleFormShow,
    isLoading,
    handleOnSubmit,
  };
};
