import { useMemo } from "react";
import { useProcessWorkGetDocumentationsQuery } from "src/FSD/entities/processWork/api";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import { ModalNames } from "src/FSD/shared/lib/hooks/useModal";
import { useTabsPanelContext } from "../../../../TabsPanel";

type TExecutionTask = {
  customerAlign: ApiTaskResponses.View[];
  workTask: ApiTaskResponses.View[];
};

type UseModalProps = {
  processWorkId: number;
  can: ApiProcessWorkResponses.View["can"];
};

export const useExecutionTasks = ({ processWorkId, can }: UseModalProps) => {
  const { data, isFetching } = useProcessWorkGetDocumentationsQuery({
    id: processWorkId,
  });

  const { currentChildTab } = useTabsPanelContext();

  const isCustomerAlignTab = currentChildTab === "customerAlign";

  const canCustomerAlignStart = isCustomerAlignTab && can!.customerAlign;

  const canRequestCustomerAlignStart =
    isCustomerAlignTab && can!.requestCustomerAlign;

  const isExecutionTaskLoading = isFetching || !data;

  const executionTaskData: TExecutionTask = useMemo(() => {
    if (!data) {
      return {
        customerAlign: [],
        workTask: [],
      };
    }

    return {
      customerAlign: [
        ...data.requestCustomerAlign.map((item: any) => ({
          ...item,
          isRequest: true,
        })),
        ...data.customerAlign,
      ],
      workTask: [data.task],
    };
  }, [data]);

  const executionTaskList =
    executionTaskData[currentChildTab as keyof TExecutionTask] || [];

  const modalNameCheckWork = isCustomerAlignTab
    ? ModalNames.ProcessWork.CHECK_CUSTOMER_ALIGN
    : ModalNames.ProcessWork.CHECK_EXECUTE_WORK;

  return {
    isExecutionTaskLoading,
    executionTaskList,
    currentChildTab,
    canCustomerAlignStart,
    canRequestCustomerAlignStart,
    isCustomerAlignTab,
    modalNameCheckWork,
  } as const;
};
