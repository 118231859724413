import { httpRequestGet } from "../api";
import { config } from "../../../../app.cofig";
import { BuildingFunctionTypes } from "../../../../components/pages/Project/lib/helpers/getBuildingFunctionType";

export const ApiGetBuildingFunction = (
  type: BuildingFunctionTypes
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/building-function/index?BuildingFunctionSearch[type]=${type}`
  );

export const ApiGetBuildingFields = (id: number | string): Promise<Response> =>
  httpRequestGet(`${config.localDomain}/v1/building-function/view?id=${id}`);

export const ApiGetBuildingFieldsByTwoParams = (
  bfId: number | string,
  typeId: number | string
): Promise<Response> =>
  httpRequestGet(
    `${config.localDomain}/v1/project/get-params-list?bfId=${bfId}&typeId=${typeId}`
  );
