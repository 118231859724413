import React, { ChangeEventHandler, ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import style from "./style.module.scss";

type CheckboxProps = {
  checked: boolean;
  className?: string;
  disabled?: boolean;
  editing?: boolean;
  grey?: boolean;
  id?: string;
  inactive?: boolean;
  label?: string;
  name?: string;
  onClick?: (e?: any) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  showSign?: boolean;
  sign?: boolean;
  title?: string;
  type?: "checkbox" | "radio";
  value?: any;
};

export const Checkbox = forwardRef(
  (
    {
      checked = false,
      className,
      disabled,
      editing,
      grey,
      id,
      inactive,
      label,
      name,
      onClick = () => {},
      onChange = () => {},
      showSign,
      sign,
      title,
      type = "checkbox",
      value,
    }: CheckboxProps,
    ref: ForwardedRef<any>
  ) => {
    return (
      <label
        htmlFor={id}
        onClick={onClick}
        className={classNames(
          style.checkbox__form,
          (disabled || inactive) && style.checkbox__form_disabled,
          className
        )}
      >
        <input
          checked={checked}
          className={classNames(
            style.checkbox,
            editing && style.checked,
            showSign && (sign ? style.plus : style.minus),
            grey && style.grey,
            (disabled || inactive) && style.disabled
          )}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          title={title}
          type={type}
          value={value}
          ref={ref}
        />
        {label && <span className={style.label}>{label}</span>}
      </label>
    );
  }
);
