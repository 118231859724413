import { Button, Modal } from "src/FSD/shared/uiKit/v2";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useAdditionalRequirements } from "../lib/hooks/useAdditionalRequirements";
import { AdditionalRequirements } from "../../PublishObject/ui/PublishObjectProcess/AdditionalRequirements";

const defaultValues = {
  vor: false,
  vorPnr: false,
  kac: false,
  showVor: false,
  showVorPnr: false,
  showKac: false,
  showBim: false,
};

type TProps = {
  header: string;
  id: number;
  type: PublishedObjectControllingEntities;
};

export const AdditionalRequirementsUpdateForm = ({
  id,
  type,
  header,
}: TProps) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    control,
    isSubmitting,
    valuesAdditionalRequirements,
    isAdditionalRequirementsPending,
  } = useAdditionalRequirements({ id, defaultValues, handleClose, type });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      {isAdditionalRequirementsPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <AdditionalRequirements
            control={control}
            valuesAdditionalRequirements={valuesAdditionalRequirements}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Сохранить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
