import React from "react";
import { AllToggleWidget } from "src/FSD/shared/uiKit/v2/AllToggleWidget/AllToggleWidget";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { DocumentCard } from "src/FSD/widgets/v2/project/Documents/ui/DocumentCard";
import { useProjectDocuments } from "src/FSD/widgets/v2/project/Documents/libs/hooks/useProjectDocuments";
import {
  DocumentsSkeletonBody,
  DocumentsSkeletonFullPage,
} from "src/FSD/widgets/v2/project/Documents/ui/DocumentsSkeleton";
import { Header } from "src/FSD/widgets/v2/project/Documents/ui/Header";
import { TabChildrenHeader } from "src/FSD/widgets/v2/TabsPanel";
import styles from "./Documents.module.scss";

export const Documents = () => {
  const {
    checked,
    setChecked,
    documents,
    isFetchingDocuments,
    isLoadingDocuments,
    isTablet,
    isAllToggle,
    handleClick,
  } = useProjectDocuments();

  if (isLoadingDocuments) {
    return <DocumentsSkeletonFullPage />;
  }

  return (
    <div>
      <TabChildrenHeader
        title="Договоры"
        className={styles.header}
        media={<Header checked={checked} setChecked={setChecked} />}
      />
      {isFetchingDocuments ? (
        <DocumentsSkeletonBody />
      ) : (
        <>
          <ScrollWrap height={isTablet ? "auto" : "878px"}>
            {documents.map((el, index) => (
              <DocumentCard key={index} data={el} index={index + 1} />
            ))}
          </ScrollWrap>
          <AllToggleWidget
            isAllToggle={isAllToggle}
            setIsAllToggle={handleClick}
          />
        </>
      )}
    </div>
  );
};
