import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessExpertise } from "src/app/feature/ProjectProcessView/Expertise/services/fetchProjectProcessExpertise";
import {
  getProjectProcessExpertise,
  getProjectProcessExpertiseIsLoad,
} from "src/app/feature/ProjectProcessView/Expertise/selectors/getProjectProcessExpertise";
import { ExpertiseProjectProcess } from "src/components/UI/components/ProjectProcessSkeleton/ProjectProcessSkeleton";
import cls from "./Expertise.module.scss";
import { ExpertiseCard } from "./ExpertiseCard/ExpertiseCard";

export const Expertise = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const infoId = useAppSelector(getProjectProcessInfoId);
  const isLoadExpertise = useAppSelector(getProjectProcessExpertiseIsLoad);
  const expertise = useAppSelector(getProjectProcessExpertise);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessViewDuplicate(+params.id));
    }
  }, []);

  useEffect(() => {
    if (infoId) {
      dispatch(fetchProjectProcessExpertise(infoId));
    }
  }, [infoId]);

  if (isLoadExpertise) {
    return <ExpertiseProjectProcess />;
  }

  return (
    <div className={cls.wrapper}>
      {expertise?.map((item, index) => (
        <ExpertiseCard
          key={item.processName}
          name={item.processName}
          remarks={item.remarks}
          dot={item.dot}
          index={index + 1}
        />
      ))}
    </div>
  );
});
