import { KanbanModel, KanbanSortValues } from "src/FSD/entities/kanban";
import {
  getFilterValuesFromLocalStorage,
  saveFilterValuesInLocalStorage,
} from "src/FSD/pages/v2/kanban/lib/helpers";
import { getUserId } from "../../../../../../../app/services/auth/auth";

export const getFilterValues = (userTypeId: number) => {
  const userId = getUserId();
  let actualFilterParams = getFilterValuesFromLocalStorage(userId, userTypeId);

  if (!actualFilterParams) {
    actualFilterParams = {
      ...KanbanModel.initialFilterParams,
      sort: KanbanSortValues.DATE_ASC,
      groupBy: {
        id: "",
        title: "Без группипровки",
      },
    };

    saveFilterValuesInLocalStorage(userTypeId, actualFilterParams);
  }

  return actualFilterParams;
};
