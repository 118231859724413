import { memo } from "react";
import { Column } from "src/FSD/widgets/v2/kanban";
import cn from "classnames";
import styles from "./Kanban.module.scss";
import { KanbanColumns, KanbanModel } from "../../../../entities/kanban";
import { useAppSelector } from "../../../../../app/store";
import { useScrollControls } from "../lib/hooks/useScrollControls";

export const AllTasksList = memo(() => {
  const { allTasks } = useAppSelector(KanbanModel.getTasks);

  const { followScrollTop, followScrollBottom } = useScrollControls();

  return (
    <div
      className={cn(
        styles.columns__allTasks,
        followScrollTop && styles.sticky,
        followScrollBottom && styles.sticky_bottom
      )}
    >
      <div className={styles.topHeader}>
        <h2 className={styles.topHeader__title}>Все задачи</h2>
        <Column.SortFilter />
      </div>
      <Column position={KanbanColumns.ALL_TASKS} tasks={allTasks} isAllTasks />
    </div>
  );
});
