import {
  setRequestUpdateActive,
  setRemoveRequestExecutionActive,
  setFeedbackTaskActive,
  getModalsData,
  setExecutorUpdateActive,
  setAdditionalRequirementsUpdateActive,
} from "./modalsSlice";

export const modals = {
  setRequestUpdateActive,
  setRemoveRequestExecutionActive,
  setFeedbackTaskActive,
  setExecutorUpdateActive,
  setAdditionalRequirementsUpdateActive,
  getModalsData,
};

export { publishedObjectSlice as sliceReducer } from "./slice";
