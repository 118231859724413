import React from "react";
import { Controller } from "react-hook-form";
import {
  Button,
  DateField,
  Modal,
  TextField,
  Uploader,
  Select,
} from "src/FSD/shared/uiKit/v2";
import { nameList } from "../lib/model/nameOptions";
import { useExpertiseContract } from "../lib/hooks/useExpertiseContract";
import styles from "./ExpertiseContractForm.module.scss";

type TProps = {
  processId: number;
};

export const ExpertiseContractForm = ({ processId }: TProps) => {
  const { onSubmit, control, errors, isLoading } = useExpertiseContract({
    processId,
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>Добавление документа</Modal.Header>
      <Modal.Form onSubmit={onSubmit}>
        <div>
          <div className={styles.groupName}>Документ</div>
          <div className={styles.group}>
            <div>
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, name } }) => (
                  <DateField
                    label="Дата создания"
                    changeDateHandler={onChange}
                    error={Boolean(errors?.[name])}
                    helperText={errors?.[name]?.message}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="num"
                render={({ field: { onChange, name } }) => (
                  <TextField
                    label="Номер"
                    error={Boolean(errors?.[name])}
                    helperText={errors?.[name]?.message}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange } }) => (
            <Select
              label="Вид документа"
              changeHandler={(_, value) => {
                onChange(value.id);
              }}
              options={nameList}
            />
          )}
        />

        <div>
          <div className={styles.groupName}>Срок действия документа</div>
          <div className={styles.group}>
            <div>
              <Controller
                control={control}
                name="dateStart"
                render={({ field: { onChange, name } }) => (
                  <DateField
                    label="Начальный срок"
                    changeDateHandler={onChange}
                    error={Boolean(errors?.[name])}
                    helperText={errors?.[name]?.message}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="dateEnd"
                render={({ field: { onChange, name } }) => (
                  <DateField
                    label="Конечный срок"
                    changeDateHandler={onChange}
                    error={Boolean(errors?.[name])}
                    helperText={errors?.[name]?.message}
                  />
                )}
              />
            </div>
          </div>
          <Controller
            name="fileUpload"
            control={control}
            render={({ field: { onChange, name } }) => (
              <Uploader
                setFiles={(files) => {
                  onChange(files);
                }}
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
              />
            )}
          />
        </div>
        <Modal.Controls>
          <Button disabled={isLoading} isLoading={isLoading} type="submit">
            Добавить
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
