import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../../app/store";
import { Nullable } from "../../../../../types/generics";

export type ModalState = Record<string, any> & {
  name: Nullable<string>;
};

const initialState: ModalState = {
  name: null,
};

export const modalSlice = createSlice({
  name: "rootModalSlice",
  initialState,
  reducers: {
    set: (_, action) => {
      return action.payload;
    },
  },
});

export const { set } = modalSlice.actions;

export const getModal = (state: RootState) => state.modal;
