import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getNdsText } from "src/FSD/shared/lib/helpers/ndsText";
import { TaskApi } from "src/FSD/shared/api/v1/task";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useTaskViewQuery } from "src/FSD/entities/task/api";
import { checkCustomerAlignSchema } from "../schemas";
import { useAppDispatch } from "../../../../../../../../app/store";
import { Nullable } from "../../../../../../../../types/generics";
import { pushError } from "../../../../../../../../app/feature/errorTrace";

type TFieldValues = {
  comment: string;
  paymentSum: number;
  status: Nullable<Statuses>;
  hasNds: boolean;
  processBalance: number;
};

type UseModalProps = {
  id: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
};

export const useCheckCustomerAlign = ({
  id,
  defaultValues,
  handleClose,
}: UseModalProps) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(checkCustomerAlignSchema),
  });

  const { data: taskData, isFetching } = useTaskViewQuery({
    id,
    expand: ["parent.files", "taskProcess.account"],
  });

  useEffect(() => {
    if (!taskData) {
      return;
    }

    setValue("comment", taskData.comment);
    setValue("hasNds", !!taskData!.taskProcess!.executor_nds);

    if (taskData!.taskProcess!.account) {
      setValue("processBalance", taskData!.taskProcess!.account.value);
    }
  }, [taskData]);

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      status: data.status!,
      comment: data.comment,
      paid_out: data.paymentSum,
    };

    await dispatch(
      TaskApi.complete({
        data: { id, formedData },
        events: {
          onFulfilled: () => {
            handleClose();
          },
          onRejected: (error) => {
            dispatch(pushError(error));
          },
        },
      })
    );
  };

  const status = watch("status");
  const hasNds = watch("hasNds");
  const processBalance = watch("processBalance");

  const ndsText = getNdsText(hasNds);

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    setValue,
    ndsText,
    status,
    isSubmitting,
    isTaskViewPending: isFetching || !taskData,
    taskData,
    processBalance,
  } as const;
};
