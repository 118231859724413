import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  id: number;
  data: {
    StartForm: {
      name: string;
      content: string;
      date_deadline: string;
    };
  };
}>;

export const startRequestCustomerAlign = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/project-process/start-request-customer-align`,
      params: {
        id,
      },
      data,
      method: HTTPMethods.POST,
    }),
    invalidatesTags: [
      ApiTags.ProjectProcess.VIEW,
      ApiTags.ProjectProcess.GET_DOCUMENTATIONS,
    ],
  });
