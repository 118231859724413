import { Modal } from "src/FSD/shared/uiKit/v2";
import styles from "./ViewExpertiseInfo.module.scss";

type Props = {
  header: string;
  info: string;
};

export const ViewExpertiseInfo = ({ info, header }: Props) => {
  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Form>
        <div
          className={styles.expertiseInfo}
          dangerouslySetInnerHTML={{ __html: info }}
        />
      </Modal.Form>
    </Modal.Layout>
  );
};
