import { memo } from "react";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";

const entityTypes = {
  ProjectProcess: "project-process",
  ProcessWork: "process-work",
};

type Props = {
  id: number;
  type: PublishedObjectControllingEntities;
};

export const ReasonText = memo(({ id, type }: Props) => {
  const emailLink = `/${entityTypes[type]}/get-email?id=${id}`;

  return (
    <>
      <Paragraph color="warning">Внимание!</Paragraph>
      <Paragraph color="light">
        В случае отсутствия в кошельке исполнителя необходимой суммы для
        возврата Заказчику, возврат средств через платформу не выполняется.
        Пожалуйста, подготовьте{" "}
        <TextLink redirect url={emailLink} target="_blank">
          официальное письмо
        </TextLink>{" "}
        на исполнителя раздела о возврате выплаченных средств.
      </Paragraph>
      <Paragraph color="light">
        Не забудьте подписать дополнительное соглашение или соглашение о
        расторжении, в случае снятия исполнителя со всех разделов без частичной
        оплаты за выполненные работы.
      </Paragraph>
      <TextLink redirect url={emailLink} target="_blank">
        Скачать проект оформленного текста письма с указанием банковских
        реквизитов.
      </TextLink>
    </>
  );
});
