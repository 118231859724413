import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useRequestCustomerAlignStart } from "../lib/hooks/useRequestCustomerAlignStart";
import { RequestCustomerAlignForm } from "./RequestCustomerAlignForm";

export type Props = {
  id: number;
  type: PublishedObjectControllingEntities;
};

export const RequestCustomerAlignStartForm = ({ id, type }: Props) => {
  const { form, onSubmit } = useRequestCustomerAlignStart({
    id,
    type,
  });

  return <RequestCustomerAlignForm form={form} onSubmit={onSubmit} />;
};
