import { HTTPMethods } from "src/FSD/shared/api/common";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiTaskResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
  data?: any;
}>;

export const backToWork = (builder: QueryBuilder) =>
  builder.mutation<ApiTaskResponses.View, TParams>({
    query: ({ id, data }) => ({
      url: `v1/task/back-to-work?id=${id}`,
      method: HTTPMethods.POST,
      data,
    }),
    invalidatesTags: [ApiTags.ProcessWork.GET_DOCUMENTATIONS],
  });
