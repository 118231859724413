import React, { memo, useState } from "react";
import { useParams } from "react-router";
import { IconButton } from "@mui/material";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { ApiPrimaryFileType } from "src/types/api/primaryTypes/apiPrimaryFileType";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList";
import { CardHeader } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/CardHeader/CardHeader";
import { iconBtnSx } from "src/styles/restyle";
import classNames from "classnames";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { PlusSquareFill } from "react-bootstrap-icons";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import styles from "./Processes.module.scss";

interface ProcessesProps {
  name?: string;
  files?: ApiPrimaryFileType[];
  canAdd?: boolean;
}

export const Processes = memo(({ name, files, canAdd }: ProcessesProps) => {
  const { name: nameModal, handleCloseModal, handleOpenModal } = useModal();
  const handleOpenAddSourceFiles = () => {
    handleOpenModal({
      name: ModalNames.ProjectProcess.ADD_SOURCE_FILES,
    });
  };
  const params = useParams();
  const paramsId = Number(params.id);

  const [toggleProcesses, setToggleProcesses] = useState(false);

  return (
    <>
      <CardHeader
        title={name || "Дополнительные исходные данные к разделу"}
        expanded={toggleProcesses}
        onClick={() => setToggleProcesses((prev) => !prev)}
      />
      {toggleProcesses && (
        <div className={styles.container}>
          <FilesList
            fileLinks={files}
            className={classNames(canAdd && styles.showAddFile)}
          />
          <div className={styles.footer}>
            {files && files.length > 0 && (
              <TextLink
                className={styles.downloadAll}
                title={"Скачать все"}
                redirect
                target="blank"
                url={`/project-process/download-files?id=${paramsId}`}
              >
                Скачать все
              </TextLink>
            )}
            {canAdd && (
              <>
                <Modal
                  isActive={
                    nameModal === ModalNames.ProjectProcess.ADD_SOURCE_FILES
                  }
                  handleClose={handleCloseModal}
                >
                  <ProjectProcessModals.AddSourceFiles paramsId={paramsId} />
                </Modal>
                <IconButton
                  onClick={handleOpenAddSourceFiles}
                  sx={iconBtnSx}
                  className={styles.addFile}
                >
                  <PlusSquareFill size={32} />
                  <span className={styles.addFileText}>Загрузить файлы</span>
                </IconButton>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
});
