import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { Button } from "src/FSD/shared/uiKit/v2";
import { iconBtnSx } from "src/styles/restyle";
import { Trash } from "react-bootstrap-icons";
import { useConclusionCard } from "src/FSD/widgets/v2/projectProcess/std/ExpertiseConclusion/lib/hooks/useConclusionCard";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { ApiExpertiseResponses } from "src/FSD/entities/expertise/remarkRow/model/responseTypes";
import cls from "./ConclusionCard.module.scss";

type TProps = ApiExpertiseResponses.Conclusion & {
  can: ApiProjectProcessResponses.View["can"];
};

export const ConclusionCard = memo(
  ({ can, num, date, file, fail, id }: TProps) => {
    const { failText, handleDeleteConclusion } = useConclusionCard({
      id,
      fail,
    });

    return (
      <div className={cls.card}>
        <div className={cls.card_name}>
          <p>{`Заключение №${num} от ${date}`}</p>
          <span className={fail ? cls.card_fail : cls.card_notFail}>
            ({failText})
          </span>
        </div>

        <div className={cls.card_btn}>
          <Button href={`/file/get?id=${file.id}`} target={"_blank"}>
            Скачать
          </Button>
          {can?.edit && (
            <IconButton sx={iconBtnSx} onClick={handleDeleteConclusion}>
              <Trash />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
);
