import { useState } from "react";

export const useAccordionState = <T>() => {
  const [expandedIds, setExpandedIds] = useState<T[]>([]);

  const handleExpand = (id: T) => {
    setExpandedIds([...expandedIds, id]);
  };

  const handleShrink = (id: T) => {
    setExpandedIds(expandedIds.filter((expandedId) => expandedId !== id));
  };

  return { handleExpand, handleShrink, expandedIds } as const;
};
