import { Nullable } from "../../../../../../../types/generics";
import { TSelectItem } from "../../../../../../shared/uiKit/v2/Select";
import { groupsList } from "../../constants/groupList";
import * as KanbanFilterRepository from "./filterRepository";

// КОСТЫЛИИИ 1 😭😭😭
const adaptSingleSelectToMultiple = (
  field: Nullable<TSelectItem> | TSelectItem[]
) => {
  if (field === null) {
    return [];
  }
  if (!Array.isArray(field)) {
    return [field];
  }

  return field;
};

export const getFilterValuesFromLocalStorage = (
  userId: number,
  userTypeId: number
) => {
  const filterParams = KanbanFilterRepository.getValues();

  const preliminaryValue = filterParams[userId]?.[userTypeId];

  // КОСТЫЛИИИ 2 😭😭😭
  if (preliminaryValue) {
    preliminaryValue.partGroup = adaptSingleSelectToMultiple(
      preliminaryValue.partGroup
    );
    preliminaryValue.workFlow = adaptSingleSelectToMultiple(
      preliminaryValue.workFlow
    );
    preliminaryValue.group = adaptSingleSelectToMultiple(
      preliminaryValue.group
    );
    preliminaryValue.executor = adaptSingleSelectToMultiple(
      preliminaryValue.executor
    );

    if (!preliminaryValue.groupBy) {
      const [groupBy] = groupsList;
      preliminaryValue.groupBy = groupBy;
    }
  }

  return preliminaryValue;
};
