import { Controller } from "react-hook-form";
import {
  Button,
  Modal,
  TextField,
  SelectMultiple,
  Uploader,
} from "src/FSD/shared/uiKit/v2";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { getTaskFiles } from "src/FSD/shared/lib/helpers/getTaskFiles";
import { useCustomerAlignComplete } from "../lib/hooks";

type Props = {
  id: number;
  header: string;
};

export const CustomerAlignCompleteForm = ({ id, header }: Props) => {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    control,
    isSubmitting,
    processesToCheck,
    isTaskViewPending,
  } = useCustomerAlignComplete({
    id,
    defaultValues: {
      processes: [],
      executorComment: "",
      files: [],
    },
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="files"
            control={control}
            render={({ field: { onChange } }) => (
              <Uploader
                canEdit
                autoUpload
                getDataCallback={() => getTaskFiles(id)}
                postUrl={`${config.localDomain}/v1/task/add-file?id=${id}`}
                setFiles={(files) => {
                  onChange(files);
                }}
                error={Boolean(errors.files)}
                helperText={errors.files?.message}
              />
            )}
          />
          <TextField
            {...register("executorComment")}
            label="Комментарий исполнителя"
            multiline
            minRows={3}
            error={Boolean(errors.executorComment)}
            helperText={errors.executorComment?.message}
          />
          <Controller
            name="processes"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectMultiple
                changeHandler={(newValue) => {
                  onChange(newValue);
                }}
                label="Проверяющие"
                value={value}
                options={processesToCheck.data}
                isLoading={processesToCheck.pending}
                showSelectAll
              />
            )}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Отправить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
