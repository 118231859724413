import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserId, userIsAuth } from "src/app/services/auth/auth";
import {
  Step,
  simpleProject,
  standardProject,
  projectCreation,
} from "./fragments";
import { Wrapper } from "../../UI/templates";
import style from "./style.module.scss";
import TitlePanel from "../../newUI/TitlePanel/TitlePanel";
import TabsPanel from "../../newUI/TabsPanel/TabsPanel";
import { RedirectToExternal } from "../../features/RedirectToExternal/RedirectToExternal";
import { Creation } from "./Creation/Creation";
import CustomButton from "../../newUI/CustomButton/CustomButton";
import { SimpleForm } from "./SimpleForm/SimpleForm";
import { StandardForm } from "./StandardForm/StandardForm";
import { ProjectSettings } from "./Settings/ProjectSettings";
import { FileEditing } from "../../features/ProjectEditing/FileEditing/FileEditing";
import { SectionEditing } from "../../features/ProjectEditing/SectionEditing/SectionEditing";
import { request } from "../../../app/services/api/requestHandler";
import { ApiGetProjectDataForEditing } from "../../../app/services/api/project/projectEditing";
import { useQuery } from "../../../app/services/url/getQueryParameters";
import { ApiGetUserResponsibilityTypes } from "../../../app/services/api/user/user";
import { useIsMobile } from "../../hooks/useIsMobile";
import { ApiGetProjectPartTree } from "../../../app/services/api/projectCreate/projectCreate";
import {
  setVisible,
  setRedirectVisible,
} from "../../../app/feature/modalController/modalVisibility";
import View from "./View";
import { config } from "../../../app.cofig";
import { initialUnsaved, initialInfo, simpleTypes } from "./constants";
import {
  getExpertise,
  createValues,
  createParameters,
  createDepthFirstSearch,
} from "./helpers";
import {
  TabType,
  UnsavedType,
  InfoType,
  ContractType,
  AuthorType,
  ProcessType,
} from "./types";
import { useSnackbar } from "../../hooks/useSnackbar";
import { ResponsibilityTypes } from "../../features/SpecialForm/ResponsibilityTypes";
import { palette } from "../../../styles/restyle";
import { getProjectViewData } from "../../../app/feature/ProjectView/projectView";
import { useAppSelector } from "../../../app/store";
import { getParentProjectId } from "../../../app/feature/ProjectView/ComplexReducer/projectComplex";
import { getBuildingFunctionType } from "./lib/helpers/getBuildingFunctionType";
// import useTabs from "../../hooks/useTabs";

let tabsInfo: TabType[] = [];

export const Editing = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isMobile = useIsMobile(1140);

  const isAuth = userIsAuth();
  const userTypeId = +useQuery("userTypeId")!;
  const { project } = useSelector(getProjectViewData);

  const projectId = +useQuery("id")!;
  const initialTab = projectId ? "info" : "creation";

  const [currentTab, setCurrentTab] = useState<string>(initialTab);
  const [unsaved, setUnsaved] = useState<UnsavedType>(initialUnsaved);

  const [isSimple, setSimple] = useState<boolean | undefined>();

  const [sqObject, setSqObject] = useState<boolean>(true);
  const [decree, setDecree] = useState<boolean>(true);

  const { errorSnackbar } = useSnackbar();
  const [isLoad, setLoad] = useState(true);
  const [info, setInfo] = useState<InfoType>(initialInfo);
  const [contract, setContract] = useState<ContractType>({});

  // tree states
  const [tree, setTree] = useState<any>({});
  const [ticked, setTicked] = useState<number[]>([]);
  const [openTabs, setOpenTabs] = useState<string[]>([]);
  const [disabledTicked, setDisabledTicked] = useState<number[]>([]);
  const [toTab, setToTab] = useState<string>("");

  const [hidePrices, setHidePrices] = useState<boolean>(false);
  const [secret, setSecret] = useState<boolean>(false);

  // settings
  const [dependencies, setDependencies] = useState<boolean>(false);
  const isTreeFilled = useMemo(() => ticked.length, [ticked]);

  const [hasComplexObject, setHasComplex] = useState(false);

  const [authorType, setAuthorType] = useState<AuthorType>({
    id: 0,
    title: "",
    typeName: "",
  });

  const setHasComplexMemo = useCallback(() => {
    setHasComplex(true);
  }, []);

  const parentId = useAppSelector(getParentProjectId);

  // useTabs(currentTab, setCurrentTab, "info");

  const resetProject = () => {
    setSimple(undefined);
    setCurrentTab("creation");
    setInfo(initialInfo);
    if (userTypeId) {
      navigate(`/project/create?userTypeId=${userTypeId}`);
    }
  };

  useEffect(() => {
    const saved =
      ticked.reduce(
        (isSaved, item) => disabledTicked.includes(item) && isSaved,
        true
      ) && disabledTicked.length === ticked.length;
    setUnsaved({ ...unsaved, sections: !saved });
  }, [ticked, disabledTicked]);

  useEffect(() => {
    if (!Object.keys(tree).length) {
      return;
    }

    // подсчет вложений
    const treeRaw = JSON.parse(JSON.stringify(tree));
    const getCounts = (node: string): boolean => {
      const currentNode = treeRaw[node];
      if (treeRaw[node]?.id) {
        return ticked.includes(treeRaw[node]?.id);
      }

      let count = false;
      currentNode.children.forEach((item: string) => {
        count = getCounts(item) || count;
      });

      currentNode.count = count;
      return count;
    };

    getCounts("0");
    setTree(treeRaw);
  }, [ticked]);

  useEffect(() => {
    (async () => {
      const treeRaw: any = {};
      if (projectId) {
        await request(
          ApiGetProjectPartTree(projectId),
          (data) => {
            const rootChildren: string[] = [];

            const dfs = createDepthFirstSearch(treeRaw, rootChildren);

            dfs(data, "0");

            treeRaw["0"] = {
              name: null,
              ancestor: null,
              id: null,
              children: rootChildren,
              count: false,
            };
          },
          () => (err) => {
            if (err.status >= 400) {
              resetProject();
              errorSnackbar("Произошла ошибка при выгрузке разделов");
            }
          }
        )();

        await request(
          ApiGetProjectDataForEditing(projectId),
          (data) => {
            setAuthorType({
              id: data.authorType.id,
              title: data.authorType.title,
              typeName: data.authorType.typeName,
            });

            setInfo({
              name: data.name,
              code: data.code,
              name_for_doc: data.name_for_doc,
              date_start: data.date_start,
              date_end: data.date_end,
              parentId: data.parent_id,
              design_type: {
                title: data.designType.value,
                id: `${data.designType.key}`,
              },
              bank_account_id: data.bank_account_id,
              author: { id: data.author.id },
              author_type: data.authorType.id,
              building_function_id: data.building_function_id,
              building_function_type: data.buildingFunction.type,
              expertise: data.expertise,
              expertise_type: getExpertise(data.expertise),
              can: {
                update: data.can.update,
                edit: data.can.edit,
              },
              construction_type_id: {
                id: `${data.constructionType.id}`,
                title: data.constructionType.name,
              },
              advance_pay: `${data.advance_pay}`,
              pay1: `${data.pay1}`,
              pay2: `${data.pay2}`,
              pay_limit: `${data.pay_limit}`,
              country_code: data.region.country_code,
              region_code: data.region.code,
              description: data.description,
              dependencies: data.dependencies,
              secret: data.secret,
              status: data.status,
              location: data.location,
              a: data.seismicity?.a ?? "",
              b: data.seismicity?.b ?? "",
              c: data.seismicity?.c ?? "",
              value: createValues(data.projectParameters),
              fields: createParameters(data.projectParameters),
            });
            setDependencies(Boolean(data.dependencies));
            setSecret(Boolean(data.secret));
            setHidePrices(Boolean(data.private));
            setContract({
              name: data.governmentContract ? data.governmentContract.name : "",
              date: data.governmentContract ? data.governmentContract.date : "",
              num1: data.governmentContract ? data.governmentContract.num1 : "",
              num2: data.governmentContract ? data.governmentContract.num2 : "",
              general_customer: data.governmentContract
                ? data.governmentContract.general_customer
                : "",
            });

            const activeIds: number[] = [];
            data.processes.forEach((process: ProcessType) => {
              if (process.num[0] === "7") {
                // исключаем экспертизу OMG
                return;
              }

              treeRaw[process.num].disabled = process?.disabled;
              activeIds.push(process.partId);
            });

            setTree(treeRaw);
            setTicked(activeIds);
            setDisabledTicked(activeIds);
          },
          () => (err) => {
            if (err.status === 404) {
              resetProject();

              errorSnackbar(
                "Проект не найден, сообщите администратору в чате "
              );
            }
          }
        )();
      }

      setLoad(false);
    })();
  }, [projectId]);

  useEffect(() => {
    if (parentId) {
      setAuthorType({
        id: project?.authorType.id || "",
        title: project?.authorType.title || "",
        typeName: project?.authorType.typeName,
      });

      return;
    }

    const currentUserId = getUserId();

    request(
      ApiGetUserResponsibilityTypes(currentUserId),
      (data) => {
        if (data.userTypes.length) {
          setAuthorType(data.userTypes[0]);
          if (!location.pathname.includes("update") && !parentId) {
            navigate(`/project/create?userTypeId=${data.userTypes[0].id}`);
          }
        }
      },
      () => () => {
        errorSnackbar("Ошибка запроса типов экспертизы");
      }
    )();
  }, []);

  useEffect(() => {
    const { pathname, search } = location;

    if (
      (!pathname.includes("init") && !pathname.includes("update")) ||
      (pathname.includes("update") && !search.includes("id="))
    ) {
      resetProject();
    }
  }, [location.pathname]);

  const forwardDisabled = Boolean(
    tabsInfo.find((item: any) => item.url === "info")?.incomplete
  );

  const buildingFunctionType = useMemo(() => {
    if (projectId) {
      return info.building_function_type;
    }

    const constructionType = sqObject ? "capital" : "linear";
    return getBuildingFunctionType(constructionType, Boolean(isSimple));
  }, [isSimple, sqObject, projectId, info, decree]);

  const checkTab = (tab: string) => {
    switch (tab) {
      case "info":
        return !info.name;
      case "sections":
        return !isTreeFilled;
      case "upload":
        return false;
      case "settings":
        return false;
      case "contract":
        return !contract?.name;
      default:
        return false;
    }
  };

  const filterItem = (item: TabType) => {
    const disabled = !projectId;
    const incomplete = checkTab(item.url);
    return { ...item, incomplete, disabled };
  };

  tabsInfo = useMemo(() => {
    if (hasComplexObject) {
      return simpleProject.map((item: any) => filterItem(item));
    }
    if (isSimple) {
      return simpleProject.map((item: any) => filterItem(item));
    }
    if (!isSimple) {
      return [...simpleProject, ...standardProject].map((item: any) =>
        filterItem(item)
      );
    }
    return projectCreation;
  }, [isSimple, projectId, isTreeFilled, info, contract, hasComplexObject]);

  useEffect(() => {
    if (projectId && !info.can.edit) {
      window.location.href = `${config.localDomain}/project/${projectId}`;
    }

    if (Object.keys(info).length && projectId) {
      const type = info.building_function_type;
      setSimple(simpleTypes.includes(type));
    }
  }, [info]);

  const showResponsibilityForm = currentTab === "creation";
  const redirectURL = `${config.localDomain}/project/${projectId}`;

  const handleOpenModal = () => {
    if (unsaved[currentTab]) {
      dispatch(setRedirectVisible());
    } else {
      window.location.href = redirectURL;
    }
  };

  const handleModalOnSwitch = (tab: string): boolean => {
    if (currentTab === tab) {
      return true;
    }

    if (unsaved[currentTab]) {
      setToTab(tab);
      dispatch(setVisible());
      return false;
    }

    return true;
  };

  const showTabs = Boolean(
    !isMobile || tabsInfo.filter((item: any) => !item?.disabled).length > 1
  );

  const components = (
    <>
      {isSimple === true && (
        <div className={style.formWrapper}>
          <Step
            projectId={projectId}
            isSimple={isSimple}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            forwardDisabled={forwardDisabled}
            onSwitching={handleModalOnSwitch}
            unsaved={unsaved}
          />
          {currentTab === "info" && (
            <SimpleForm
              projectId={projectId}
              isEditMode={!!projectId}
              info={info}
              infoHandler={setInfo}
              buildingFunctionType={buildingFunctionType}
              authorType={authorType}
              setUnsaved={setUnsaved}
            />
          )}
          {currentTab === "sections" && (
            <SectionEditing
              tree={tree}
              ticked={ticked}
              setTicked={setTicked}
              openTabs={openTabs}
              setOpenTabs={setOpenTabs}
              projectId={projectId}
              disabledTicked={disabledTicked}
              setDisabledTicked={setDisabledTicked}
              unsaved={unsaved}
            />
          )}
          {currentTab === "upload" && (
            <FileEditing projectId={projectId} canFinish />
          )}
        </div>
      )}
      {isSimple === false && (
        <div className={style.formWrapper}>
          <Step
            isSimple={isSimple}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            projectId={projectId}
            forwardDisabled={forwardDisabled}
            onSwitching={handleModalOnSwitch}
            unsaved={unsaved}
            hasComplex={hasComplexObject}
          />
          {currentTab === "info" && (
            <StandardForm
              projectId={projectId}
              isEditMode={!!projectId}
              // @ts-ignore
              info={info}
              infoHandler={setInfo}
              buildingFunctionType={buildingFunctionType}
              authorType={authorType}
              setUnsaved={setUnsaved}
              ticked={ticked}
              tree={tree}
              setHasComplexMemo={setHasComplexMemo}
            />
          )}
          {currentTab === "settings" && (
            <ProjectSettings
              hidePrices={hidePrices}
              setHidePrices={setHidePrices}
              projectId={projectId}
              dependencies={dependencies}
              secret={secret}
              setDependencies={setDependencies}
              setUnsaved={setUnsaved}
              authorType={authorType}
              setSecret={setSecret}
            />
          )}
          {currentTab === "sections" && (
            <SectionEditing
              tree={tree}
              setTree={setTree}
              ticked={ticked}
              setTicked={setTicked}
              openTabs={openTabs}
              setOpenTabs={setOpenTabs}
              projectId={projectId}
              disabledTicked={disabledTicked}
              setDisabledTicked={setDisabledTicked}
              unsaved={unsaved}
            />
          )}
          {currentTab === "upload" && <FileEditing projectId={projectId} />}
        </div>
      )}
      {isSimple === undefined && (
        <Creation
          decree={decree}
          setDecree={setDecree}
          sqObject={sqObject}
          setSqObject={setSqObject}
          setSimple={setSimple}
          setCurrentTab={setCurrentTab}
          authorType={authorType}
        />
      )}
    </>
  );

  if (!isAuth) return <RedirectToExternal to={"/site/login"} />;

  return (
    <div className={style.wrapper}>
      <Wrapper>
        <TitlePanel
          isContentLoad={isLoad}
          setEditing={() => {}}
          primaryText={authorType.title}
          secondaryText={authorType.typeName}
          photo={""}
        >
          {showResponsibilityForm && !parentId && (
            <ResponsibilityTypes
              id="author_type"
              name="author_type"
              label="Форма собственности заказчика"
              userID={info ? info?.author?.id : null}
              changeHandler={(data) => {
                navigate(`/project/create?userTypeId=${data.id}`);
                setAuthorType(data);
              }}
              value={authorType.id}
              newDesign
            />
          )}
          {!!projectId && (
            <div className={style.textLink}>
              <CustomButton background={palette.blue} onClick={handleOpenModal}>
                Просмотр проекта
              </CustomButton>
            </div>
          )}
        </TitlePanel>
        <div className={style.contents}>
          <TabsPanel
            isContentLoad={isLoad}
            setEditing={() => {}}
            tabsInfo={tabsInfo}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            components={components}
            stepByStep
            onSwitching={handleModalOnSwitch}
            showTabs={showTabs}
          />
        </div>
      </Wrapper>
      <View
        toTab={toTab}
        setCurrentTab={setCurrentTab}
        redirectURL={redirectURL}
      />
    </div>
  );
};
