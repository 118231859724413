import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { removeFileById } from "../helpers/removeFileById";
import { TFile, TFileData, TProgresses } from "../../model/types";
import {
  generateFilesListFromClient,
  generateFilesListFromServer,
} from "../helpers/generateFilesList";
import { uploadFile } from "../helpers/uploadFile";
import { request } from "../../../../../../../app/services/api/requestHandler";
import {
  ApiChangeFileVisibility,
  ApiDeleteFile,
} from "../../../../../../../app/services/api/file/file";
import { Nullable } from "../../../../../../../types/generics";

export const UploaderErrorMessages = {
  COULD_NOT_UPLOAD_A_FILE: "Возникла ошибка при загрузке файла",
  COULD_NOT_CHANGE_A_FILE: "Возникла ошибка при изменении параметров файла",
  COULD_NOT_DELETE_A_FILE: "Возникла ошибка при удалении файла",
  COULD_NOT_FETCH_FILES: "Возникла ошибка при получении файлов",
  FILES_REQUIRED: "Выберите нужные файлы. Дождитесь окончания загрузки!",
};

export const useUploader = ({
  postUrl,
  initialFiles = [],
  autoUpload = false,
  getDataCallback,
  uploadAsActive,
  setFilesCallback,
  handleUpdate,
}: {
  postUrl?: string;
  initialFiles?: TFileData[];
  autoUpload?: boolean;
  uploadAsActive?: boolean;
  getDataCallback: (() => Promise<TFileData[]>) | (() => boolean) | undefined;
  setFilesCallback?: (files: TFile[], isLoading?: boolean) => void;
  handleUpdate?: () => void;
}) => {
  const [files, setFiles] = useState<TFile[]>([]);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [progresses, setProgresses] = useState<TProgresses>({});
  const [requests, setRequests] = useState<Record<string, XMLHttpRequest>>({});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (setFilesCallback) {
      setFilesCallback(files, isLoading);
    }
  }, [files, isLoading]);

  useEffect(() => {
    const initialFilesFormed = generateFilesListFromServer(initialFiles);
    setFiles(initialFilesFormed);

    if (getDataCallback) {
      (async () => {
        try {
          const responseFiles = await getDataCallback();

          const initialFilesFormed = generateFilesListFromServer(
            responseFiles as TFileData[]
          );

          setFiles(initialFilesFormed);
          setLoading(false);
        } catch (error) {
          enqueueSnackbar(UploaderErrorMessages.COULD_NOT_FETCH_FILES, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      })();

      return;
    }

    setLoading(false);
  }, []);

  const handleChangeActive = async (
    id: string,
    serverId: number | null,
    newVisibility: boolean
  ) => {
    setFiles(
      files.map((file: TFile) =>
        file.id === id ? { ...file, visibility: newVisibility } : file
      )
    );

    if (autoUpload && serverId) {
      try {
        await request(ApiChangeFileVisibility(serverId, newVisibility))();
      } catch (error) {
        enqueueSnackbar(UploaderErrorMessages.COULD_NOT_CHANGE_A_FILE, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    }
  };

  const handleUpload = (uploadedfiles: Nullable<FileList>) => {
    if (!uploadedfiles || !uploadedfiles.length) {
      return;
    }

    const newFiles = generateFilesListFromClient(
      Array.from(uploadedfiles),
      uploadAsActive
    );

    if (autoUpload && postUrl) {
      const postUrlExpanded = `${postUrl}&expand=svgIcon`;

      newFiles.forEach(({ id, file }: TFile) => {
        const formData = new FormData();

        formData.append("file", file!);
        formData.append("visibility", `${Number(uploadAsActive)}`);

        try {
          const xhr = uploadFile(
            postUrlExpanded,
            id,
            formData,
            setProgresses,
            setFiles,
            setRequests
          );
          setRequests({ ...requests, [id]: xhr });
        } catch (error) {
          enqueueSnackbar(UploaderErrorMessages.COULD_NOT_UPLOAD_A_FILE, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    } else {
      const newFilesFulfilled = newFiles.reduce((result, file) => {
        return { ...result, [file.id]: 100 };
      }, {});

      setProgresses((progresses) => ({
        ...progresses,
        ...newFilesFulfilled,
      }));
    }

    setFiles([...files, ...newFiles]);
  };

  const handleDelete = async (id: string, serverId: number | null) => {
    if (autoUpload) {
      if (requests[id]) {
        requests[id].abort();

        setRequests((requests: Record<string, XMLHttpRequest>) => {
          delete requests[id];
          return requests;
        });
      }

      if (serverId) {
        try {
          await request(ApiDeleteFile(serverId))();
        } catch (error) {
          enqueueSnackbar(UploaderErrorMessages.COULD_NOT_DELETE_A_FILE, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      }
    }

    setFiles(removeFileById(files, id));
  };

  useEffect(() => {
    if (handleUpdate) {
      handleUpdate();
    }
  }, [files]);

  return {
    files,
    isLoading,
    progresses,
    handleUpload,
    handleDelete,
    handleChangeActive,
  } as const;
};
