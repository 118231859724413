import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { useThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton/useThreeDotButton";

export const useRequirements = (processWork: ApiProcessWorkResponses.View) => {
  const { threeDotButtonRef, closeThreeDot } = useThreeDotButton();

  const { kac, vor, vor_pnr, published } = processWork.projectPart;

  const isAdditionalRequirements = kac || vor || vor_pnr;

  const showOptions =
    processWork.can!.update && published && isAdditionalRequirements;

  return {
    threeDotButtonRef,
    showOptions,
    closeThreeDot,
  } as const;
};
