import { memo } from "react";
import cn from "classnames";
import styles from "./CheckTasks.module.scss";

type Props = {
  count: number;
  totalCount: number;
};

export const TaskCounter = memo(({ count, totalCount }: Props) => {
  const isComplete = count === totalCount;

  return (
    <span
      className={cn(
        styles.taskCounter,
        isComplete && styles.taskCounter_complete
      )}
    >
      ({count}/{totalCount})
    </span>
  );
});
