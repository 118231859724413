import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import {
  useProjectAccessDataRemoveExecutorQuery,
  useProjectAccessRemoveExecutorMutation,
} from "src/FSD/entities/projectAccess/api";
import { useProcessWorkDataRemoveExecutorQuery } from "src/FSD/entities/processWork/api";
import {
  RemovalBy715Ids,
  Steps,
  reasonsOfExecutorRemoval,
} from "../../model/constants";
import { validationSchema } from "../schemas/validationSchema";
import { TFieldValues } from "../../model/types";
import { formPricesData } from "../helpers/formPricesData";
import { Props as UseModalProps } from "../../ui/RequestRemoveExecutorForms";

const defaultValues = {
  currentStep: Steps.REASON,
  content: "",
  reason: reasonsOfExecutorRemoval[0],
  reason2: RemovalBy715Ids.HAS_NOT_STARTED_YET,
  reason3: false,
  refund: false,
  advancesPaidByProcesses: false,
  atLeastOneProcessRequired: false,
  processes: {
    checked: {},
    price: {},
  },
};

export const useRequestRemoveExecutorStart = ({ id, type }: UseModalProps) => {
  const { handleClose } = useModalContext();

  const dataRemoveExecutorQueries = {
    ProjectProcess: useProjectAccessDataRemoveExecutorQuery,
    ProcessWork: useProcessWorkDataRemoveExecutorQuery,
  };

  const { data: dataRemoveExecutor, isFetching } = dataRemoveExecutorQueries[
    type
  ]({
    id,
  });

  const removeExecutorForm = useForm<TFieldValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const [removeExecutor, { isLoading: isRemovingExecutor }] =
    useProjectAccessRemoveExecutorMutation();

  const currentStep = removeExecutorForm.watch("currentStep");

  const onSubmit = async (dataValues: TFieldValues) => {
    const data = formPricesData(dataValues);

    const formedData = {
      class: type,
      content: dataValues.content,
      refund: dataValues.refund,
      reason: String(dataValues.reason.id),
      reason2: dataValues.reason2,
      reason3: dataValues.reason3,
      data,
    };

    formedData.data.push({
      id,
      price: 0,
    });

    const result = await removeExecutor({ objectId: id, data: formedData });
    // @ts-ignore
    if (!result?.error) {
      handleClose();
    }
  };

  const isDataRemoveExecutorLoading = !dataRemoveExecutor || isFetching;

  return {
    onSubmit,
    currentStep,
    isDataRemoveExecutorLoading,
    removeExecutorForm,
    dataRemoveExecutor,
    isRemovingExecutor,
  } as const;
};
