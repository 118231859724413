import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProjectProcessResponses } from "../../model/responseTypes";
import { ApiTags } from "../../../../shared/api/tags";

type TParams = RequestParams<{
  id: number;
}>;

export const getSubCheckTasks = (builder: QueryBuilder) =>
  builder.query<ApiProjectProcessResponses.GetSubCheckTasks, TParams>({
    query: (params) => ({
      url: `v1/project-process/get-sub-check-tasks`,
      params: {
        id: params.id,
      },
    }),
    providesTags: [ApiTags.ProjectProcess.GET_SUB_CHECK_TASKS],
  });
