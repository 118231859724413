import { ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { clearTaskCompletion } from "src/FSD/entities/kanban/model";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { pushSuccessMessage } from "src/app/feature/errorTrace";
import {
  TWorkFlowClasses,
  WorkFlowClasses,
} from "src/FSD/shared/lib/constants/workFlowClasses";
import ProcessWorkModals from "src/FSD/widgets/v2/processWork/modals";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { KanbanModel } from "src/FSD/entities/kanban";
import { CheckOutputTask } from "./CheckOutputTask";
import { CompleteTaskWithoutModal } from "./CompleteTaskWithoutModal";
import useNonInitialEffect from "../../../../../../components/hooks/useNonInitialEffect";
import KanbanModals from "../../modals";
import TaskModals from "../../../task/modals";
import PublishedObjectModals from "../../../publishedObject/modals";

/*
  "app\\workflow\\verification\\Workflow": <></>, // пока пусто
*/

export const TaskCompletionModals = () => {
  const dispatch = useAppDispatch();

  const taskCompletion = useAppSelector(KanbanModel.getTaskCompletion);
  const cachedTasks = useAppSelector(KanbanModel.getCache);

  const handleCloseModal = () => {
    dispatch(clearTaskCompletion());
  };

  useNonInitialEffect(() => {
    if (!cachedTasks) {
      dispatch(pushSuccessMessage());
    }
  }, [cachedTasks]);

  if (!taskCompletion || !cachedTasks) {
    return null;
  }

  const { id, header, workFlowClass, process } = taskCompletion;

  const isTaskCustomerAlign =
    workFlowClass === WorkFlowClasses.CUSTOMER_ALIGN_WORK ||
    workFlowClass === WorkFlowClasses.CUSTOMER_ALIGN_PROCESS ||
    workFlowClass === WorkFlowClasses.EXECUTE_WORK ||
    workFlowClass === WorkFlowClasses.EXECUTE_PROCESS;

  const classesWithoutModals: Partial<Record<TWorkFlowClasses, ReactNode>> = {
    [WorkFlowClasses.CHECK_OUTPUT_TASK]: (
      <CheckOutputTask taskId={id} handleCloseModal={handleCloseModal} />
    ),
  };

  // const workFlowClass = WorkFlowClasses.EXECUTE_WORK;
  // отдельно можно указать класс для дебага

  const classesWithModals: Partial<Record<TWorkFlowClasses, ReactNode>> = {
    [WorkFlowClasses.CHECK_EXPERTISE_REMARK_ROW]: (
      <ProcessWorkModals.CheckExpertiseRemarkRowCompleteForm
        id={id}
        header={header}
      />
    ),
    [WorkFlowClasses.EXPERTISE_REMARK_ROW]: (
      <ProcessWorkModals.ExpertiseRemarkRowCompleteForm
        id={id}
        header={header}
      />
    ),
    [WorkFlowClasses.CUSTOMER_ALIGN_WORK]: (
      <ProcessWorkModals.CustomerAlignCompleteForm id={id} header={header} />
    ),
    [WorkFlowClasses.REQUEST_WORK_CUSTOMER_ALIGN]: (
      <ProcessWorkModals.RequestCustomerAlignCompleteForm
        id={id}
        header={header}
      />
    ),

    [WorkFlowClasses.CHECK_WORK_CUSTOMER_ALIGN]: (
      <ProcessWorkModals.CheckCustomerAlignForm id={id} header={header} />
    ),
    [WorkFlowClasses.CHECK_EXECUTE_WORK]: (
      <ProcessWorkModals.CheckExecuteCompleteForm id={id} header={header} />
    ),
    [WorkFlowClasses.EXECUTE_WORK]: (
      <PublishedObjectModals.ExecuteCompleteForm
        id={id}
        header={header}
        isCustomerAlign={isTaskCustomerAlign}
      />
    ),
    [WorkFlowClasses.REQUEST_PROCESS_CUSTOMER_ALIGN]: (
      <ProjectProcessModals.RequestCustomerAlignCompleteForm
        id={id}
        header={header}
      />
    ),
    [WorkFlowClasses.CUSTOMER_ALIGN_PROCESS]: (
      <ProjectProcessModals.CustomerAlignCompleteForm id={id} header={header} />
    ),
    [WorkFlowClasses.CHECK_PROCESS_CUSTOMER_ALIGN]: (
      <ProjectProcessModals.CheckCustomerAlignForm id={id} header={header} />
    ),
    [WorkFlowClasses.TECHNICAL_SPECIFICATIONS]: (
      <TaskModals.TechnicalSpecificationsForm header={header} taskId={id} />
    ),
    [WorkFlowClasses.CHECK_FREE_TASK]: (
      <KanbanModals.CheckFreeTaskForm taskId={id} />
    ),
    [WorkFlowClasses.SUB_TASK]: (
      <ProjectProcessModals.SubTaskForm taskId={id} header={header} />
    ),
    [WorkFlowClasses.CHECK_SUB_TASK]: (
      <ProjectProcessModals.CheckSubTaskForm taskId={id} header={header} />
    ),
    [WorkFlowClasses.CHECK_SUB_CHECK]: (
      <ProjectProcessModals.CheckSubCheckForm taskId={id} />
    ),
    [WorkFlowClasses.EXECUTE_PROCESS]: (
      <ProjectProcessModals.ExecuteProcessForm taskId={id} header={header} />
    ),
    [WorkFlowClasses.OUTPUT_TASK]: (
      <ProjectProcessModals.OutputTaskForm taskId={id} header={header} />
    ),
    [WorkFlowClasses.CHECK_SUB_CHECK_PROCESS]: (
      <ProjectProcessModals.CheckSubCheckProcessForm taskId={id} />
    ),
    [WorkFlowClasses.CHECK_EXPERTISE_REMARK]: (
      <ProjectProcessModals.CheckExpertiseRemarkForm
        taskId={id}
        header={header}
      />
    ),
    [WorkFlowClasses.EXPERTISE_REMARK]: (
      <ProjectProcessModals.ExpertiseRemarkForm taskId={id} header={header} />
    ),
    [WorkFlowClasses.CHECK_EXECUTE_PROCESS]: (
      <ProjectProcessModals.CheckExecuteProcess
        taskId={id}
        processId={process.id}
        header={header}
      />
    ),
  };

  // завершение задачи без модалки (появляются различные сообщения для конферма)
  if (classesWithoutModals[workFlowClass]) {
    return <>{classesWithoutModals[workFlowClass]}</>;
  }

  // завершение задачи с помощью модалки
  if (classesWithModals[workFlowClass]) {
    return (
      <Modal isActive handleClose={handleCloseModal}>
        {classesWithModals[workFlowClass]}
      </Modal>
    );
  }

  // завершение задачи без модалок и конфермов
  return <CompleteTaskWithoutModal id={id} workFlowClass={workFlowClass} />;
};

// <ModalExpertiseRowComplete />
