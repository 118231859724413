import { RequestCustomerAlignCompleteForm } from "./RequestCustomerAlignComplete";
import { CustomerAlignCompleteForm } from "./CustomerAlignComplete";
import { ExpertiseRemarkRowCompleteForm } from "./ExpertiseRemarkRowComplete";
import { CheckExpertiseRemarkRowCompleteForm } from "./CheckExpertiseRemarkRowComplete";
import { CheckExecuteCompleteForm } from "./CheckExecuteComplete";
import { ProcessWorkCreate } from "./ProcessWorkCreate";
import { Delegate } from "./Delegate";
import { CheckCustomerAlignForm } from "./CheckCustomerAlign";

const ProcessWorkModals = {
  RequestCustomerAlignCompleteForm,
  CustomerAlignCompleteForm,
  CheckCustomerAlignForm,
  ExpertiseRemarkRowCompleteForm,
  CheckExpertiseRemarkRowCompleteForm,
  CheckExecuteCompleteForm,
  ProcessWorkCreate,
  Delegate,
};

export default ProcessWorkModals;
