import React, { useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import classNames from "classnames";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { trimNumber } from "src/utils/trimNumber";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  RefType,
  ThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { useProcessWorkUnPublishMutation } from "src/FSD/entities/processWork/api";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { palette } from "src/styles/restyle";
import { ShieldCheck } from "react-bootstrap-icons";
import styles from "./WorksAccordion.module.scss";

type TProps = ApiProjectProcessResponses.TWorks & {
  userFio: string;
};

export const AccordionWorks = ({ work, appCount, userFio }: TProps) => {
  const {
    title,
    id,
    activeAccess,
    executorType,
    status,
    date_start,
    date_limit,
    secure,
    price,
  } = work;

  const threeDotButtonRef = useRef<RefType>(null);
  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };
  const [unPublish] = useProcessWorkUnPublishMutation();
  const unPublishWorkClick = async () => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть задачу с публикации?"
    );
    if (result) {
      await unPublish({ id });
      closeThreeDot();
    }
  };

  const isRuble = price === null;
  const priceIsNotNumber = price === "Договорная";
  const dateHasExpired = moment().isAfter(
    moment(date_limit, "DD.MM.YYYY").toDate()
  );
  const dateHasExpiredAndWork = status.key === Statuses.WORK && dateHasExpired;

  const { handleOpenModal } = useModal();

  return (
    <div className={classNames(styles.parent)}>
      <div className={classNames(styles.title)}>
        <TextLink
          variant="white"
          redirect
          url={`/process-work/${id}`}
          title={title}
          className={styles.title__link}
          target="_blank"
        >
          {title}
        </TextLink>
        <div
          className={classNames(styles.subtitle, {
            [styles.parent_date_old]: dateHasExpiredAndWork,
          })}
        >
          {status.value}
        </div>
      </div>
      <div className={classNames(styles.parent_flex)}>
        {!!activeAccess && (
          <>
            <div className={styles.title}>{userFio}</div>
            <div className={styles.parent_type}>
              {activeAccess.userType?.listName}
            </div>
          </>
        )}
        {!activeAccess && status.key === 2 && (
          <>
            <div className={styles.title}>Форма собственности</div>
            <div
              className={classNames(
                styles.parent_type,
                styles.parent_type_title
              )}
            >
              {executorType.map((type) => (
                <span key={type.value}>{type.value}</span>
              ))}
            </div>
          </>
        )}
      </div>
      <div className={styles.title}>
        {!!appCount && (
          <div className={styles.count}>
            <p>Заявки:</p>
            <TextLink
              url={`/process-work/${id}?tab=application`}
              className={styles.title}
              redirect
            >
              {appCount}
            </TextLink>
          </div>
        )}
      </div>
      <div className={classNames(styles.parent_flex)}>
        {!priceIsNotNumber && (
          <div className={styles.parent_price}>
            <span>{trimNumber(price)}</span>
            {!isRuble && <span className={styles.parent_price_ruble}>₽</span>}
            {secure && <ShieldCheck color={palette.silverGrey} />}
          </div>
        )}
        {date_start && (
          <div
            className={classNames(styles.parent_date, {
              [styles.parent_date_old]: dateHasExpiredAndWork,
            })}
          >
            {date_start} - {date_limit}
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <Button component={Link} to={`/process-work/${id}`} target="_blank">
          Открыть
        </Button>
        <ThreeDotButton ref={threeDotButtonRef}>
          <MenuItem component={Link} to={`/process-work/${id}`} target="_blank">
            Перейти к задаче
          </MenuItem>
          {work.can.unpublish && (
            <MenuItem
              onClick={() => {
                closeThreeDot();
                handleOpenModal({
                  id,
                  name: ModalNames.PublishedObject.UPDATE_PUBLISHED_OBJECT,
                  header: `Редактирование задачи ${work.title}`,
                  type: PublishedObjectControllingEntities.PROCESS_WORK,
                });
              }}
            >
              Редактировать
            </MenuItem>
          )}
          {work.can.unpublish && (
            <MenuItem onClick={unPublishWorkClick}>
              Вернуть с публикации
            </MenuItem>
          )}
          {work.can.publish && (
            <MenuItem
              onClick={() => {
                closeThreeDot();
                handleOpenModal({
                  id,
                  name: ModalNames.PublishedObject.PUBLISH_OBJECT,
                  header: `Публикация задачи ${work.title}`,
                  type: PublishedObjectControllingEntities.PROCESS_WORK,
                });
              }}
            >
              Опубликовать
            </MenuItem>
          )}
        </ThreeDotButton>
      </div>
    </div>
  );
};
