import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button, TextField } from "src/FSD/shared/uiKit/v2";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useFreezeFunds } from "../lib/hooks/useFreezeFunds";

type Props = {
  id: number;
  header: string;
  type: PublishedObjectControllingEntities;
};

export const FreezeFundsForm = ({ id, header, type }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    isSubmitting,
    isPublishedObjectPending,
    label,
  } = useFreezeFunds({
    id,
    defaultValues: { price: 0, maxDeposit: 0 },
    handleClose,
    type,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      {isPublishedObjectPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("price")}
            label={label}
            error={!!errors.price}
            helperText={errors?.price?.message}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Пополнить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
