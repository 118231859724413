import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";

export const groupByColor = (
  arr: ApiProjectProcessResponses.TabChecksItem[]
) => {
  return arr.reduce(
    (acc: Record<string, ApiProjectProcessResponses.TabChecksItem[]>, item) => {
      const { color } = item.status;
      if (!acc[color]) {
        acc[color] = [];
      }
      acc[color].push(item);
      return acc;
    },
    {}
  );
};
