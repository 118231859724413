import { memo } from "react";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { List } from "src/FSD/shared/uiKit/v2/List";
import styles from "./CommentsForm.module.scss";
import { useComments } from "../lib/hooks/useComments";

type Props = {
  taskId: number;
};

export const CommentsForm = memo(({ taskId }: Props) => {
  const { isCommentsLoading, comments } = useComments(taskId);

  return (
    <Modal.Layout>
      <Modal.Header>Комментарии</Modal.Header>
      {isCommentsLoading ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form>
          <List>
            {comments.length ? (
              comments.map((comment: any) => (
                <div key={comment.id} className={styles.comment}>
                  <h2>{comment.author}</h2>
                  <h2>{comment.status.value}</h2>
                  <p>{comment.text}</p>
                  <p>{comment.date_create}</p>
                </div>
              ))
            ) : (
              <p className={styles.notFound}>Комментарии не найдены</p>
            )}
          </List>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
});
