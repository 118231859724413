import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiProjectPartResponses } from "../../model/responseTypes";

export const getTypes = (builder: QueryBuilder) =>
  builder.query<ApiProjectPartResponses.GetTypes, void>({
    query: () => ({
      url: `v1/project-part/get-types`,
    }),
    providesTags: [ApiTags.ProjectPart.LIST],
  });
