import React from "react";
import classNames from "classnames";
import style from "../../pages/Project/NewView/Sections/Sections.module.scss";
import { TextLink, ThemeTextLink } from "../../UI/components/TextLink/TextLink";
import { trimNumber } from "../../../utils/trimNumber";
import { SecureIcon } from "../SVG";
import { ThreeDotButtons } from "../../pages/Project/NewView/Sections/SectionsTree/ThreeDotButtons";
import { useIsMobile } from "../../hooks/useIsMobile";
import { TValuesData } from "../../../app/feature/ProjectView/SectionsReducer/thunks";
import { selectLvlClass } from "../../../utils/helpersOpenNode";
import { TooltipWrapper } from "../TooltipWrapper/TooltipWrapper";

interface NewTaskCardProps {
  data: TValuesData;
  authorFI: string;
  patronymic: string;
  fetchGetProjectProcessTree: () => Promise<void>;
}

export const SectionCard = (props: NewTaskCardProps) => {
  const isMobile = useIsMobile(680);
  const {
    id,
    title,
    status,
    projectPart,
    activeAccess,
    executorType,
    countAccesses,
    countProcessWorks,
    price,
    secure,
    date_start,
    date_limit,
    lvl,
  } = props.data;

  const isRuble = price === "Договорная";
  const lvlClass = selectLvlClass(lvl);
  return (
    <div className={classNames(style.parent, style[lvlClass])}>
      <div className={classNames(style.parent_flex, isMobile && style.title)}>
        <TextLink
          customTheme={ThemeTextLink.WHITE}
          redirect
          url={`/project-process/${id}`}
          target="_blank"
        >
          <TooltipWrapper content={title}>
            <p>{projectPart?.code}</p>
          </TooltipWrapper>
        </TextLink>
        <div className={style.subtitle}>{status.value}</div>
      </div>
      <div className={classNames(style.parent_flex, isMobile && style.author)}>
        {!!activeAccess && (
          <>
            <div className={style.title}>
              <p>{props.authorFI}</p>
              <p>{props?.patronymic}</p>
            </div>
            <div className={style.subtitle}>
              {activeAccess?.userType?.listName}
            </div>
          </>
        )}
        {!activeAccess && status.key === 2 && (
          <>
            <div className={style.parent_type}>Форма собственности</div>
            <div
              className={classNames(
                style.parent_type_executor,
                style.parent_type
              )}
            >
              <div
                className={classNames(style.parent_type_title, style.subtitle)}
              >
                {executorType.map((type) => (
                  <span key={type.value}>{type.value}</span>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={style.title}>
        {countAccesses > 0 && (
          <div className={style.count}>
            <p>Заявки:</p>
            <TextLink url={`/project-process/${id}?tab=application`} redirect>
              <span className={style.title}>{countAccesses}</span>
            </TextLink>
          </div>
        )}
        <div className={style.count}>
          <p>Задачи:</p>
          <span>{countProcessWorks > 0 ? countProcessWorks : "-"}</span>
        </div>
      </div>
      <div className={classNames(style.parent_flex, isMobile && style.price)}>
        {price && (
          <div className={style.parent_price}>
            {trimNumber(price)}
            <span className={style.parent_price_ruble}>{!isRuble && "₽"}</span>
            {secure && <SecureIcon width="16" heigth="18" fill="#D5D6DE" />}
          </div>
        )}
        {date_start && (
          <div className={style.parent_date}>
            {date_start} - {date_limit}
          </div>
        )}
      </div>
      <ThreeDotButtons
        node={props.data}
        fetchGetProjectProcessTree={props.fetchGetProjectProcessTree}
      />
    </div>
  );
};
