import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";
import {
  useTaskUpdateMutation,
  useTaskViewQuery,
} from "src/FSD/entities/task/api";
import { Nullable } from "../../../../../../../../types/generics";
import { requestCustomerAlignSchema } from "../schemas/requestCustomerAlignSchema";

type UseModalProps = {
  taskId: number;
};

const defaultValues = {
  dateDeadline: null,
  content: "",
  name: "",
};

export type TFieldValues = {
  dateDeadline: Nullable<Date>;
  content: string;
  name: string;
};

export const useEditRequestCustomerAlign = ({ taskId }: UseModalProps) => {
  const { handleClose } = useModalContext();

  const form = useForm<TFieldValues>({
    defaultValues,
    resolver: yupResolver(requestCustomerAlignSchema),
  });

  const { data: taskData, isFetching } = useTaskViewQuery({
    id: taskId,
  });

  useEffect(() => {
    if (!taskData) {
      return;
    }

    form.setValue("content", taskData.content);
    form.setValue("dateDeadline", timestampToDate(taskData.date_deadline));
    form.setValue("name", taskData.name);
  }, [taskData]);

  const [updateTask] = useTaskUpdateMutation();

  const onSubmit = async (dataValues: TFieldValues) => {
    const data = {
      StartForm: {
        content: dataValues.content,
        name: dataValues.name,
        date_deadline: toServerDate(dataValues.dateDeadline)!,
      },
    };

    const result = await updateTask({ id: taskId!, task: data });
    // @ts-ignore
    if (!result?.error) {
      handleClose();
    }
  };

  return {
    form,
    onSubmit,
    isFetching,
  } as const;
};
