import { useNavigate } from "react-router-dom";
import { useProjectGetChildProjectsQuery } from "src/FSD/entities/project/api";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";
import { setParentProjectId } from "../../../../../../../app/feature/ProjectView/ComplexReducer/projectComplex";
import { useAppDispatch } from "../../../../../../../app/store";

export type TPropsObject = {
  object: ApiProjectResponses.ChildProject;
};

export const useObjects = (projectId: number) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: objectsRaw, isFetching } = useProjectGetChildProjectsQuery({
    id: projectId,
  });

  const objects = useDataMapper(objectsRaw, Object.values);

  const handleCreateSubProject = () => {
    dispatch(setParentProjectId(projectId));
    navigate("/project/create");
  };

  return {
    isObjectsLoading: !objects || isFetching,
    objects,
    handleCreateSubProject,
  } as const;
};
