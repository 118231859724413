import React, { memo } from "react";
import classNames from "classnames";
import styles from "./Statistics.module.scss";

type TProps = {
  allSourceData: number;
  overdueSourceData: number;
  receivedSourceData: number;
};

export const Statistics = memo(
  ({ allSourceData, overdueSourceData, receivedSourceData }: TProps) => {
    return (
      <div className={classNames(styles.container)}>
        <div className={styles.item}>
          <span className={styles.title}>Всего</span>
          <span className={styles.count}>{allSourceData}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Просрочено</span>
          <span className={styles.count}>{overdueSourceData}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.title}>Получено</span>
          <span className={styles.count}>{receivedSourceData}</span>
        </div>
      </div>
    );
  }
);
