import { useConclusionIndexQuery } from "src/FSD/entities/expertise/conclusion/api";

type TPops = {
  processId: number;
};

export const useExpertiseConclusion = ({ processId }: TPops) => {
  const { data = [], isFetching } = useConclusionIndexQuery(
    {
      id: processId,
      expand: ["file"],
    },
    { skip: !processId }
  );

  const isLoading = isFetching || !data;

  return { processId, data, isLoading } as const;
};
