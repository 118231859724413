import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { ApiPublishedObjectRequests } from "src/FSD/entities/publishedObject/model/requestsTypes";
import { useQueries } from "./useQueries";

type TFieldValues = {
  vor: boolean;
  vorPnr: boolean;
  kac: boolean;
  showVor: boolean;
  showVorPnr: boolean;
  showKac: boolean;
  showBim: boolean;
};

type UseModalProps = {
  id: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
  type: PublishedObjectControllingEntities;
};

export const useAdditionalRequirements = ({
  id,
  defaultValues,
  handleClose,
  type,
}: UseModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });

  const isProcessWork =
    type === PublishedObjectControllingEntities.PROCESS_WORK;

  const { processData, isFetching, updateAddition } = useQueries(
    isProcessWork,
    id
  );

  useEffect(() => {
    if (!processData) {
      return;
    }

    setValue("vor", processData.vor);
    setValue("vorPnr", processData.vor_pnr);
    setValue("kac", processData.kac);

    setValue("showVor", processData.projectPart.vor);
    setValue("showVorPnr", processData.projectPart.vor_pnr);
    setValue("showKac", processData.projectPart.kac);
  }, [processData]);

  const valuesAdditionalRequirements = {
    vor: watch("showVor"),
    vorPnr: watch("showVorPnr"),
    kac: watch("showKac"),
    bim: watch("showBim"),
  };

  const onSubmit = async (data: TFieldValues) => {
    const formedData: ApiPublishedObjectRequests.UpdateAdditionObject = {
      vor: data.vor,
      vor_pnr: data.vorPnr,
      kac: data.kac,
    };

    await updateAddition({ id, data: formedData });

    handleClose();
  };

  return {
    register,
    handleSubmit,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    valuesAdditionalRequirements,
    isAdditionalRequirementsPending: isFetching || !processData,
    isProcessWork,
  } as const;
};
