import {
  useProjectProcessPublishInfoQuery,
  useProjectProcessViewQuery,
} from "src/FSD/entities/projectProcess/api";
import {
  useProcessWorkPublishInfoQuery,
  useProcessWorkViewQuery,
} from "src/FSD/entities/processWork/api";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";

type TUseQueriesProps = {
  id: number;
  type: PublishedObjectControllingEntities;
};

export const useQueries = ({ id, type }: TUseQueriesProps) => {
  const usePublishInfoQuery = {
    ProcessWork: useProcessWorkPublishInfoQuery,
    ProjectProcess: useProjectProcessPublishInfoQuery,
  };

  const useViewQuery = {
    ProcessWork: useProcessWorkViewQuery,
    ProjectProcess: useProjectProcessViewQuery,
  };

  const { data: publishInfo, isFetching: isFetchingPublishInfo } =
    usePublishInfoQuery[type]({
      id,
    });

  const { data: processData, isFetching } = useViewQuery[type]({
    id,
  });

  return {
    publishInfo,
    processData,
    isFetching,
    isFetchingPublishInfo,
  };
};
