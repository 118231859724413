import { SignStopFill } from "react-bootstrap-icons";
import { MenuItem } from "@mui/material";
import cn from "classnames";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph";
import { useModalExecutorUpdate } from "src/FSD/entities/publishedObject/lib/hooks/useModalExecutorUpdate";
import { ThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { useModalsRequestRemoveGip } from "src/FSD/entities/project/lib/hooks/useModalRequestRemoveGip";
import styles from "./Object.module.scss";
import { InfoObject } from "./InfoObject/InfoObject";
import { StatusObject } from "./StatusObject/StatusObject";
import { Progress } from "./ProgressObject/Progress";
import { useObject } from "../lib/hooks/useObject";
import { TPropsObject } from "../lib/hooks/useObjects";

export const Object = ({ object }: TPropsObject) => {
  const {
    handleDeleteProject,
    showThreeDotButton,
    can,
    threeDotButtonRef,
    closeThreeDot,
  } = useObject({ object });

  const { handleOpenRequestRemoveGip } = useModalsRequestRemoveGip();

  const { handleOpenExecutorUpdate } = useModalExecutorUpdate();

  const header = object.stopped ? (
    <Paragraph color="light" className={styles.objectBlock__header}>
      <SignStopFill width={20} height={20} color="#CE0000" />
      <span className={styles.objectBlock__span}>Внимание!</span> Приостановлено
      выполнение разделов {object.stopProcesses.join(", ")}
    </Paragraph>
  ) : (
    <div></div>
  );

  return (
    <Card
      header={header}
      className={styles.objectBlock}
      contentClassName={styles.objectBlock__content}
      controls={
        <Card.Controls
          location="top"
          className={cn(
            styles.objectBlock__controls,
            object.stopped && styles.objectBlock__controls__top
          )}
        >
          {showThreeDotButton && (
            <ThreeDotButton ref={threeDotButtonRef}>
              {can.delete && (
                <MenuItem onClick={() => handleDeleteProject(object.id)}>
                  Удалить объект
                </MenuItem>
              )}
              {can.requestRemoveGip && (
                <MenuItem
                  onClick={() => {
                    closeThreeDot();
                    handleOpenRequestRemoveGip(object.id);
                  }}
                >
                  Снять ГИПа
                </MenuItem>
              )}
              {can.updateGip && (
                <MenuItem
                  onClick={() => {
                    closeThreeDot();
                    handleOpenExecutorUpdate(object.id);
                  }}
                >
                  Сменить ГИПа
                </MenuItem>
              )}
            </ThreeDotButton>
          )}
        </Card.Controls>
      }
    >
      <InfoObject object={object} />
      <StatusObject object={object} />
      <Progress object={object} />
    </Card>
  );
};
