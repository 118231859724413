import React from "react";
import { ChildAccordion } from "src/FSD/shared/uiKit/v2/Accordion";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { ExecutionChildrenItem } from "./ExecutionChildrenItem";
import styles from "./ExecutionChildren.module.scss";

type TProps = {
  children?: ApiProjectProcessResponses.Children;
};

export const ExecutionChildren = ({
  children = { objects: [], dot: false },
}: TProps) => {
  if (!children?.objects.length) {
    return null;
  }

  const { dot, objects } = children;
  const data = Object.values(objects);
  const completeCount = data.reduce(
    (count: number, item: any) => count + Number(item?.status.dot === "ok"),
    0
  );
  const title = `Подзадачи(${completeCount}/${data.length})`;

  return (
    <div className={styles.accordion}>
      <ChildAccordion title={title} dot={dot}>
        <div className={styles.item}>
          {data.map((task) => (
            <ExecutionChildrenItem key={task.id} task={task} />
          ))}
        </div>
      </ChildAccordion>
    </div>
  );
};
