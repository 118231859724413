import { ProgressBar } from "src/FSD/shared/uiKit/v2/ProgressBar";
import styles from "./Progress.module.scss";
import { TPropsObject } from "../../lib/hooks/useObjects";
import { useProgressDates } from "../../lib/hooks/useProgressDates";
import { useProgressPrice } from "../../lib/hooks/useProgressPrice";

export const Progress = ({ object }: TPropsObject) => {
  const {
    percentDates,
    titleAdditionDates,
    backgroundProgressDate,
    lowerLabelDates,
  } = useProgressDates({ object });

  const {
    percentPrice,
    titleAdditionPrice,
    backgroundProgressPrice,
    priceEnd,
    priceStart,
  } = useProgressPrice({ object });

  return (
    <div className={styles.processBlock}>
      <ProgressBar
        title="Сроки"
        titleAddition={titleAdditionDates}
        upperLeftLabel={object.dates.start}
        upperRightLabel={object.dates.end ?? ""}
        lowerLabel={lowerLabelDates}
        valueProgress={percentDates}
        colorScheme={backgroundProgressDate}
      />
      {object.price && (
        <ProgressBar
          title="Бюджет"
          titleAddition={titleAdditionPrice}
          upperRightLabel={priceStart}
          lowerLabel={priceEnd}
          valueProgress={percentPrice}
          colorScheme={backgroundProgressPrice}
        />
      )}
    </div>
  );
};
