import React, { memo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment/moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import cls from "./ModalPublish.module.scss";
import CustomButton from "../../../../../newUI/CustomButton/CustomButton";
import { palette } from "../../../../../../styles/restyle";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  getProjectProcessInfoDateLimit,
  getProjectProcessInfoDateStart,
  getProjectProcessInfoId,
  getProjectProcessInfoName,
  getProjectProcessInfoProjectName,
} from "../../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchExpertisePublish } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/services/fetchExpertisePublish";
import { parseUnprocessableFields } from "../../../../../../app/services/api/requestHandler";
import {
  getExpertisePublishError422,
  getExpertisePublishSuccess,
} from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/selectors/getExpertiseHeaderBtn";
import { setClearDocumentSuccess } from "../../../../../../app/feature/ProjectProcessView/ExpertisePage/ExpertiseHeaderBtn/slice/expertiseHeaderBtn";
import { DateField } from "../../../../../features/SpecialForm/DateField/DateField";

interface ModalPublishProps {
  closeModal: () => void;
}

const schema = Yup.object().shape({
  date_start: Yup.mixed().required("Необходимо заполнить «Дата начала»"),
  date_limit: Yup.mixed().required("Необходимо заполнить «Дата окончания»"),
});

export const ModalPublish = memo(({ closeModal }: ModalPublishProps) => {
  const dispatch = useAppDispatch();
  const processId = useAppSelector(getProjectProcessInfoId);
  const date_start = useAppSelector(getProjectProcessInfoDateStart);
  const date_limit = useAppSelector(getProjectProcessInfoDateLimit);
  const projectName = useAppSelector(getProjectProcessInfoProjectName);
  const processName = useAppSelector(getProjectProcessInfoName);
  const error422 = useAppSelector(getExpertisePublishError422);
  const publishSuccess = useAppSelector(getExpertisePublishSuccess);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      date_start: date_start ? moment(date_start, "DD.MM.YYYY").toDate() : null,
      date_limit: date_limit ? moment(date_limit, "DD.MM.YYYY").toDate() : null,
    },
  });

  const dateStartValue = watch("date_start");
  const dateLimitValue = watch("date_limit");

  const formSubmitHandler = async (dataValues: any) => {
    const data = {
      date_start: moment(dataValues.date_start, "DD.MM.YYYY").format(
        "DD.MM.YYYY"
      ),
      date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
        "DD.MM.YYYY"
      ),
      save: dataValues.save,
    };
    if (processId) {
      await dispatch(fetchExpertisePublish({ processId, data }));
    }
  };

  useEffect(() => {
    if (error422) {
      parseUnprocessableFields(error422, setError);
    }
  }, [error422]);

  useEffect(() => {
    if (publishSuccess) {
      clearErrors();
      dispatch(setClearDocumentSuccess());
      closeModal();
    }
  }, [publishSuccess]);

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
      <h1>
        Публикация раздела «{processName}» по проекту «{projectName}»
      </h1>
      <div className={cls.form_date}>
        <div className={cls.form_date_start}>
          <Controller
            control={control}
            name="date_start"
            render={({ field: { value } }) => (
              <DateField
                variant={cls.formElement}
                label="Начальный срок"
                changeDateHandler={(date) => {
                  setValue("date_start", date);
                }}
                startDateProp={value}
                error={!!errors.date_start}
              />
            )}
          />
          {errors.date_start && (
            <p className={cls.error}>{errors.date_start.message}</p>
          )}
        </div>
        <div className={cls.form_date_limit}>
          <Controller
            control={control}
            name="date_limit"
            render={({ field: { value } }) => (
              <DateField
                variant={cls.formElement}
                label="Конечный срок"
                changeDateHandler={(date) => {
                  setValue("date_limit", date);
                }}
                startDateProp={value}
                error={!!errors.date_limit}
              />
            )}
          />
          {errors.date_limit && (
            <p className={cls.error}>{errors.date_limit.message}</p>
          )}
        </div>
      </div>
      <div className={cls.form_submit}>
        <CustomButton
          background={palette.grey}
          width={200}
          type="submit"
          onClick={() => setValue("save", 1)}
        >
          Сохранить в черновике
        </CustomButton>
        <CustomButton
          background={palette.green}
          width={160}
          type="submit"
          onClick={() => setValue("save", 0)}
        >
          Взять в работу
        </CustomButton>
      </div>
    </form>
  );
});
