import { useProjectProcessCanCheckQuery } from "src/FSD/entities/projectProcess/api";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useProcessWorkCanCheckQuery } from "src/FSD/entities/processWork/api";

type UseModalProps = {
  id: number;
  type: PublishedObjectControllingEntities;
};

export const useCanCheckObject = ({ id, type }: UseModalProps) => {
  const useCanCheck = {
    ProcessWork: useProcessWorkCanCheckQuery,
    ProjectProcess: useProjectProcessCanCheckQuery,
  };

  const { data: canCheckData, isFetching } = useCanCheck[type]({
    id,
  });

  return {
    isCanCheckPending: isFetching || !canCheckData,
    canCheckData,
  } as const;
};
