import React, { memo } from "react";
import moment from "moment/moment";
import { IconButton } from "@mui/material";
import { RemarkRowsValue } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/types/expertiseRemarksTypes";
import { selectColor } from "src/utils/selectColor";
import { CommentIcon } from "src/components/newUI/SVG";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { iconBtnSx } from "src/styles/restyle";
import { FileArrowDownFill } from "react-bootstrap-icons";
import cls from "../TableCard.module.scss";
import TableCardBodyPencilBtn from "./TableCardBodyPencilBtn/TableCardBodyPencilBtn";
import { BackToWorkBtn } from "./BackToWorkBtn/BackToWorkBtn";
import { CompleteBtn } from "./CompleteBtn/CompleteBtn";

interface TableCardBodyProps {
  data: RemarkRowsValue;
}
export const TableCardBody = memo(({ data }: TableCardBodyProps) => {
  const { row, count, groups, commentCount } = data;
  const groupsArray = Object.values(groups);
  const { name, handleCloseModal, handleOpenModal } = useModal();

  const handleCommentRemarkRow = () => {
    handleOpenModal({
      name: ModalNames.ProjectProcess.COMMENT_REMARK_ROW,
    });
  };

  return (
    <>
      <tr>
        <td rowSpan={count}>{row.num}</td>
        <td rowSpan={count}>{row.text}</td>
        <td rowSpan={count}>{row.comment}</td>
      </tr>
      {groupsArray.map((group) => (
        <React.Fragment key={group.partGroup.id}>
          <tr>
            <td colSpan={3}>
              <div className={cls.flex}>
                <p>{group?.partGroup?.name}</p>
                <p style={{ color: selectColor(group.status.color) }}>
                  {group.status.value}
                </p>
                <p>
                  {moment(row.date_limit, "DD.MM.YYYY HH:mm:ss Z").format(
                    "DD.MM.YYYY"
                  )}
                </p>
              </div>
            </td>
          </tr>
          {group.tasks?.map((task) => (
            <tr key={task.id}>
              <td>
                <div className={cls.flex}>
                  <div className={cls.flexCol}>
                    <p>{`${task.taskToProcess?.projectPart?.code} (${task.executor?.surname})`}</p>
                    <span style={{ color: selectColor(task.status.color) }}>
                      {task.status.value}
                    </span>
                    <p
                      style={{ color: selectColor(task.workflow_data?.color) }}
                    >
                      {task.workflow_data.reason}
                    </p>
                    <p>{task.comment}</p>
                  </div>
                  <Modal
                    isActive={
                      name === ModalNames.ProjectProcess.COMMENT_REMARK_ROW
                    }
                    handleClose={handleCloseModal}
                  >
                    <ProjectProcessModals.CommentRemarkRowForm rowId={row.id} />
                  </Modal>
                  <div>
                    <IconButton onClick={handleCommentRemarkRow}>
                      {commentCount === 0 ? (
                        <CommentIcon />
                      ) : (
                        <CommentIcon
                          fonsSize={"10"}
                          color={"#fa990e"}
                          count={String(commentCount)}
                        />
                      )}
                    </IconButton>
                  </div>
                </div>
              </td>
              <td>
                <div className={cls.changes}>
                  {task.workflow_data.changes}
                  {task.files.length === 0 && (
                    <p>(Документация не приложена)</p>
                  )}
                </div>
              </td>
              <td>
                <div>
                  {task.can.edit && (
                    <TableCardBodyPencilBtn task={task} row={row} />
                  )}
                  {task.can.backToWork && (
                    <BackToWorkBtn taskId={task.children[0].id} />
                  )}
                  {task.can.check && (
                    <CompleteBtn taskId={task.children[0].id} />
                  )}
                  {task.can.view && task.files?.length > 0 && (
                    <TextLink
                      url={`/task/download-files?id=${task.parent_id}`}
                      redirect
                      target={"_blank"}
                    >
                      <IconButton sx={iconBtnSx}>
                        <FileArrowDownFill />
                      </IconButton>
                    </TextLink>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </React.Fragment>
      ))}
    </>
  );
});
