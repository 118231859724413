import { ChangeEvent } from "react";
import { Folder2 } from "react-bootstrap-icons";
import styles from "./styles.module.scss";
import { Nullable } from "../../../../../../types/generics";

type DropzoneProps = {
  handleUpload: (files: Nullable<FileList>) => void;
};

export const Dropzone = ({ handleUpload }: DropzoneProps) => {
  return (
    <label
      className={styles.dropzone}
      onDragOver={(event) => {
        event.preventDefault();
      }}
      onDrop={(event) => {
        event.preventDefault();
        handleUpload(event.dataTransfer?.files);
      }}
    >
      <Folder2 className={styles.inputIcon} />
      <p className={styles.description}>
        <span>Загрузите</span>&nbsp;
        <span>или</span>&nbsp;
        <span>перетащите</span>&nbsp;
        <span>файлы</span>
      </p>
      <input
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleUpload(event.currentTarget.files);
        }}
        type="file"
        hidden
        multiple
      />
    </label>
  );
};
