import React, { memo } from "react";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { iconBtnSx } from "src/styles/restyle";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { fetchExpertiseRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchExpertiseRemarkDelete } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkDelete";
import { fetchExpertiseRemarkStart } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkStart";
import { getCanProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { EyeFill, GearFill } from "react-bootstrap-icons";
import { Modal } from "src/FSD/shared/uiKit/v2";
import {
  ThreeDotButton,
  useThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { UpdateRemarkRowForm } from "src/FSD/widgets/v2/projectProcess/modals/UpdateRemarkRow";
import cls from "../RemarkCard.module.scss";

interface RemarkCardHeaderProps {
  editMode: boolean;
  setEditMode: (mode: boolean) => void;
  remarkId: number;
}

export const RemarkCardHeader = memo(
  ({ editMode, setEditMode, remarkId }: RemarkCardHeaderProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);
    const canProcess = useAppSelector(getCanProcessInfo);

    const { closeThreeDot, threeDotButtonRef } = useThreeDotButton();
    const { name, handleCloseModal, handleOpenModal } = useModal();

    const handleCreateRemarkRow = () => {
      closeThreeDot();
      handleOpenModal({
        name: ModalNames.ProjectProcess.UPDATE_REMARK_ROW,
      });
    };

    async function deleteRemarkClick() {
      const result = window.confirm(
        "Вы уверены, что хотите удалить замечание ?"
      );
      if (result) {
        await dispatch(fetchExpertiseRemarkDelete(remarkId));
        closeThreeDot();
        if (processId) {
          dispatch(fetchExpertiseRemarks(processId));
        }
      }
    }

    async function startRemarkClick() {
      const result = window.confirm(
        "Вы уверены, что хотите направить все в работу ?"
      );
      if (result) {
        await dispatch(fetchExpertiseRemarkStart(remarkId));
        closeThreeDot();
        if (processId) {
          dispatch(fetchExpertiseRemarks(processId));
        }
      }
    }

    return (
      <div className={cls.header}>
        {!editMode ? (
          <h1>Чек-лист снятия замечаний</h1>
        ) : (
          <h1>Таблица замечаний</h1>
        )}

        <div className={cls.header_btn}>
          {canProcess?.edit && (
            <>
              {!editMode ? (
                <IconButton sx={iconBtnSx} onClick={() => setEditMode(true)}>
                  <GearFill />
                </IconButton>
              ) : (
                <IconButton sx={iconBtnSx} onClick={() => setEditMode(false)}>
                  <EyeFill />
                </IconButton>
              )}
            </>
          )}
          {editMode ? (
            <>
              <Modal
                isActive={name === ModalNames.ProjectProcess.UPDATE_REMARK_ROW}
                handleClose={handleCloseModal}
              >
                <ProjectProcessModals.UpdateRemarkRowForm remarkId={remarkId} />
              </Modal>
              <ThreeDotButton ref={threeDotButtonRef}>
                <MenuItem onClick={handleCreateRemarkRow}>
                  Добавить замечание
                </MenuItem>
                <MenuItem onClick={startRemarkClick}>Направить все</MenuItem>
                <MenuItem onClick={deleteRemarkClick}>Удалить</MenuItem>
              </ThreeDotButton>
            </>
          ) : (
            <ThreeDotButton ref={threeDotButtonRef}>
              <MenuItem
                className={cls.link}
                component={"a"}
                href={`/expertise-remark/pdf?id=${remarkId}`}
              >
                Экспорт в PDF
              </MenuItem>
              <MenuItem
                className={cls.link}
                component={"a"}
                href={`/expertise-remark/xlsx?id=${remarkId}`}
              >
                Экспорт в Excel
              </MenuItem>
            </ThreeDotButton>
          )}
        </div>
      </div>
    );
  }
);
