import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProjectChecklistResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
  type: number;
  searchValue: string;
}>;

export const listByProjectWithCount = (builder: QueryBuilder) =>
  builder.query<ApiProjectChecklistResponses.InitialData, TParams>({
    query: ({ id, searchValue, type }) => ({
      url: `v1/project-checklist/list-by-project-with-count`,
      params: {
        projectId: id,
        "ProjectChecklistSearch[name]": searchValue,
        "ProjectChecklistSearch[type]": type ?? "",
      },
    }),
    providesTags: [],
  });
