import { List } from "src/FSD/shared/uiKit/v2/List";
import { WarningMessages } from "src/FSD/shared/lib/constants/warnings";
import { ProjectProcessTabs } from "src/FSD/entities/projectProcess/model/tabs";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useApplications } from "../lib/hooks/useApplications";
import { TabChildrenHeader, TabWarning } from "../../../../TabsPanel";
import { Application } from "./Application";
import styles from "./Applications.module.scss";
import PublishedObjectModals from "../../../../publishedObject/modals";

type Props = {
  projectProcessId: number;
};

export const Applications = ({ projectProcessId }: Props) => {
  const { applicationsList, currentChildTab, isApplicationsLoading } =
    useApplications(projectProcessId);

  const media = currentChildTab === ProjectProcessTabs.applications.bad && (
    <TabWarning>{WarningMessages.BAD_APPLICATIONS}</TabWarning>
  );

  const { name, handleCloseModal, restData } = useModal();

  return (
    <>
      <Modal
        isActive={name === ModalNames.PublishedObject.REMOVE_REQUEST_EXECUTION}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.RemoveRequestExecutionForm
          taskId={restData.taskId}
          header={restData.header}
          type={PublishedObjectControllingEntities.PROCESS_WORK}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.UPDATE_REQUEST}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.UpdateRequestForm
          id={projectProcessId}
          taskId={restData.taskId}
          header={restData.header}
          type={PublishedObjectControllingEntities.PROCESS_WORK}
        />
      </Modal>
      <TabChildrenHeader
        title="Заявки"
        media={media}
        mediaClassName={styles.media}
      />
      <List maxHeight="865px">
        {isApplicationsLoading ? (
          <Skeleton.List />
        ) : (
          applicationsList.map((application: any) => (
            <Application key={application.id} application={application} />
          ))
        )}
      </List>
    </>
  );
};
