import { memo } from "react";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";

import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { useRequestRemoveGip } from "../lib/hooks/useRequestRemoveGip";

export type Props = {
  projectId: number;
  header: string;
};

export const RequestRemoveGipForm = memo(({ projectId, header }: Props) => {
  const { handleSubmit, onSubmit, errors, isSubmitting, register } =
    useRequestRemoveGip({
      projectId,
      defaultValues: {
        comment: "",
      },
    });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("comment")}
          label="Комментарий к заявке"
          multiline
          minRows={3}
          error={Boolean(errors.comment)}
          helperText={errors.comment?.message}
        />
        <Modal.Controls>
          <Button
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Снять
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
});
