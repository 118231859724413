import { memo } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import moment from "moment/moment";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import cls from "./ModalPreliminaryRequest.module.scss";
import { palette, textFieldSX } from "../../../../../styles/restyle";
import CustomButton from "../../../../newUI/CustomButton/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { getProjectProcessInfoId } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchProjectProcessDocumentations } from "../../../../../app/feature/ProjectProcessView/Documentations/services/fetchProjectProcessDocumentations";
import { DateField } from "../../../../features/SpecialForm/DateField/DateField";
import { fetchProjectProcessViewDuplicate } from "../../../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { fetchProjectProcessRequestPreliminary } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessRequestPreliminary";
import { updateTaskById } from "../../../../../app/feature/ProjectProcessView/Tasks/services/updateTaskById";

type TInputs = {
  content: string;
  date_deadline: any;
};

const schema = Yup.object().shape({
  date_deadline: Yup.date().required("Необходимо заполнить «Конечный срок»"),
});

interface ModalPreliminaryRequestProps {
  closeModal: () => void;
  header?: string;
  defaultValues?: Record<string, Date | string>;
  taskId?: number | null;
  processIdDerrived?: number;
}

/**
 * Todo: перенесен в src/FSD/widgets/v2/projectProcess/modals/PreliminaryRequest
 */
export const ModalPreliminaryRequest = memo(
  ({
    closeModal,
    header = "",
    defaultValues,
    taskId = null,
    processIdDerrived,
  }: ModalPreliminaryRequestProps) => {
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);

    const actualProcessId = processId ?? processIdDerrived;

    const {
      handleSubmit,
      setValue,
      register,
      clearErrors,
      formState: { errors },
    } = useForm<TInputs>({
      defaultValues,
      mode: "onSubmit",
      resolver: yupResolver(schema),
    });

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        StartForm: {
          content: dataValues.content,
          date_deadline: dataValues.date_deadline
            ? moment(dataValues.date_deadline, "DD.MM.YYYY").format(
                "DD.MM.YYYY"
              )
            : null,
        },
      };

      if (actualProcessId) {
        await dispatch(
          taskId
            ? updateTaskById({ taskId, data })
            : fetchProjectProcessRequestPreliminary({
                id: actualProcessId,
                data,
              })
        );

        dispatch(fetchProjectProcessDocumentations(actualProcessId));
        dispatch(fetchProjectProcessViewDuplicate(actualProcessId));
      }
      closeModal();
    };

    return (
      <form onSubmit={handleSubmit(formSubmitHandler)} className={cls.form}>
        <h1>{header}</h1>
        <div className={cls.dateWrapper}>
          <DateField
            startDateProp={defaultValues?.date_deadline as Date}
            label="Конечный срок"
            changeDateHandler={(date) => {
              setValue("date_deadline", date);
              clearErrors("date_deadline");
            }}
            error={Boolean(errors.date_deadline)}
            helperText={String(errors.date_deadline?.message || "")}
          />
        </div>
        <TextField
          {...textFieldSX}
          multiline
          minRows={4}
          placeholder="Введите комментарий"
          label="Комментарий"
          {...register("content")}
        />
        {Boolean(errors.content) && (
          <p className={cls.error}>{errors.content?.message}</p>
        )}
        <CustomButton
          type="submit"
          width={160}
          background={palette.blue}
          className={cls.form_submit}
        >
          Отправить
        </CustomButton>
      </form>
    );
  }
);
