import React from "react";
import { CircularProgress, ThemeProvider, ListItemText } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { palette } from "src/styles/restyle";
import { TAnyObject } from "src/components/pages/Organisation/tabs/Info/types";
import { Autocomplete } from "src/FSD/shared/uiKit/v2/Autocomplete";
import { AutocompleteProps } from "@mui/material/Autocomplete";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import style from "./style.module.scss";

export type TSelectItem = {
  level?: number;
  title: string;
  name?: string;
  id: number | string;
};

const theme = createTheme({
  components: {
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
  },
});

export type SelectProps = {
  options?: TAnyObject[];
  label?: string;
  id?: string;
  isLoading?: boolean;
  textClassName?: string;
  defaultValue?: TAnyObject | null;
  changeHandler?: (event: any, value: any, reason: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  name?: string;
  searchHandler?: (val: string) => void;
  value?: TSelectItem | null;
  disableClear?: boolean;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  editing?: boolean;
  placeholder?: string;
  variant?: string;
  optionLabel?: "name" | "title";
} & Omit<AutocompleteProps<any, any, any, any>, "renderInput" | "options">;

export const Select = ({
  options = [],
  label,
  id = "",
  className = "",
  textClassName = "",
  isLoading = false,
  changeHandler = () => {},
  error = false,
  helperText,
  name = "",
  searchHandler = () => {},
  value = null,
  disableClear = false,
  size,
  disabled,
  placeholder,
  optionLabel = "title",
  ...props
}: SelectProps) => {
  const optionTitle = options[0]?.[optionLabel] ? optionLabel : "title";

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        id={id}
        onChange={changeHandler}
        size={size ?? "small"}
        options={options}
        className={className}
        getOptionLabel={(option: any) => option?.[optionTitle] || ""}
        closeText={"Закрыть"}
        clearText={"Очистить"}
        defaultValue={undefined}
        openText={"Открыть"}
        loading={isLoading}
        loadingText={"Загрузка"}
        value={value}
        ListboxProps={{
          style: {
            maxHeight: "195px",
          },
        }}
        disabled={disabled || isLoading}
        disableClearable={disableClear}
        disablePortal
        renderOption={(props, item) => {
          const marginLeft = item?.level ? item.level * 25 : 0;

          return (
            <li {...props} style={{ marginLeft }} key={item.id}>
              <ListItemText>{item?.[optionTitle]}</ListItemText>
            </li>
          );
        }}
        {...props}
        renderInput={(params: any) => (
          <div className={style.selectField}>
            {disabled || isLoading ? (
              <TextField
                error={error}
                helperText={helperText}
                name={name}
                className={textClassName}
                {...params}
                value={(value && value?.[optionTitle]) || undefined}
                onChange={(e) => {
                  searchHandler(e.currentTarget.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isLoading && (
                        <CircularProgress
                          size={24}
                          sx={{ color: palette.persianGreen }}
                          className={style.loading}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                label={label}
                placeholder={placeholder}
              />
            ) : (
              <TextField
                error={error}
                helperText={helperText}
                name={name}
                {...params}
                className={textClassName}
                InputLabelProps={{ shrink: true }}
                value={(value && value?.[optionTitle]) || undefined}
                onChange={(e) => {
                  searchHandler(e.currentTarget.value);
                }}
                label={label}
                placeholder={placeholder}
              />
            )}
          </div>
        )}
      />
    </ThemeProvider>
  );
};
