import React, { ChangeEvent, KeyboardEvent, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { inputBaseClasses } from "@mui/material/InputBase";
import { palette } from "src/styles/restyle";
import { Search, XLg } from "react-bootstrap-icons";

interface SearchBarProps {
  value?: string;
  onChange: (searchValue: string) => void;
  className?: string;
  placeholder?: string;
}

const sx = {
  color: "#D5D6DE",
  cursor: "pointer",
};

const textFieldStyles = {
  width: "285px",
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  [`& .${inputBaseClasses.root}`]: {
    fontWeight: "400",
    paddingLeft: 0,
    transition: "all 0.2s",
  },
  [`& .${inputBaseClasses.focused}`]: {
    borderBottom: `1px solid ${palette.persianGreen}`,
  },
  "& .MuiInputBase-input::placeholder": {
    color: palette.grey,
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
};

export const SearchBar = ({
  onChange,
  value: insertValue = "",
  className,
  placeholder = "Введите название документа",
}: SearchBarProps) => {
  const [state, setState] = useState({ value: insertValue, isSearched: false });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ value: event.target.value, isSearched: false });
  };

  const handleSearch = () => {
    onChange(state.value);
    setState({ ...state, isSearched: true });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleReset = () => {
    onChange("");
    setState({ value: "", isSearched: false });
  };

  const AdornmentComponent = state.isSearched ? XLg : Search;
  const handleClick = state.isSearched ? handleReset : handleSearch;

  return (
    <TextField
      variant="standard"
      className={className}
      sx={{
        ...textFieldStyles,
        [`& .${inputBaseClasses.root}`]: {
          borderBottom: `1px solid ${state.isSearched ? palette.persianGreen : palette.silverGrey}`,
          color: state.isSearched ? palette.persianGreen : palette.silverGrey,
        },
      }}
      inputProps={{
        placeholder,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={sx} onClick={handleClick}>
            <AdornmentComponent width={"14"} height={"14"} />
          </InputAdornment>
        ),
      }}
      onKeyDown={handleKeyDown}
      value={state.value}
      onChange={handleChange}
    />
  );
};
