import { Controller } from "react-hook-form";
import { Button, Modal, TextField, Uploader } from "src/FSD/shared/uiKit/v2";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { SelectReviewProcesses } from "src/FSD/features/v2/SelectReviewProcesses";
import { useCustomerAlignStart } from "../lib/hooks/useCustomerAlignStart";

export type Props = {
  id: number;
  type: PublishedObjectControllingEntities;
};

export const CustomerAlignStartForm = ({ id, type }: Props) => {
  const { onSubmit, handleSubmit, register, errors, control, isSubmitting } =
    useCustomerAlignStart({ id, type });

  return (
    <Modal.Layout>
      <Modal.Header>Выдать предварительное решение</Modal.Header>
      <Modal.Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("name")}
          label="Наименование"
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
        />
        <Controller
          name="uploadFiles"
          control={control}
          render={({ field: { onChange } }) => (
            <Uploader
              setFiles={(files) => {
                onChange(files);
              }}
              error={Boolean(errors.uploadFiles)}
              helperText={errors.uploadFiles?.message}
            />
          )}
        />
        <TextField
          {...register("comment")}
          label="Комментарий исполнителя"
          multiline
          minRows={3}
          error={Boolean(errors.comment)}
          helperText={errors.comment?.message}
        />
        {type === "ProjectProcess" && (
          <Controller
            name="reviewProcessIds"
            control={control}
            render={({ field: { onChange } }) => (
              <SelectReviewProcesses
                processId={id}
                onProcessesChange={(newValue) => {
                  onChange(newValue);
                }}
              />
            )}
          />
        )}
        <Modal.Controls>
          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Отправить
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
