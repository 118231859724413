import React from "react";
import { TabsSourceData } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/TabsSourceData/TabsSourceData";
import { TabDataByProcess } from "src/FSD/widgets/v2/projectProcess/std/SourceData/ui/TabDataByProcess";
import { ApiPrimaryProjectType } from "src/types/api/primaryTypes/apiPrimaryProjectType";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import formSourceData from "src/app/feature/ProjectProcessView/InitialData/utils/formSourceData";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";

type TProps = {
  activeTab: number;
  data: ApiProjectProcessResponses.GetFilesDto;
  can: ApiPrimaryProjectType["can"];
};

export const SourceDataContent = ({ activeTab, data, can }: TProps) => {
  if (activeTab === 5) {
    return <TabDataByProcess data={data} />;
  }

  const sourceData = formSourceData(data?.newSourceData ?? {});
  const newSourceData = sourceData?.[activeTab] || [];

  return (
    <ListMapper>
      {newSourceData.map((el, index) => (
        <TabsSourceData
          key={el.name}
          {...el}
          index={index}
          type={activeTab}
          can={can}
        />
      ))}
    </ListMapper>
  );
};
