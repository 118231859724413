import React, { memo, useEffect, useMemo, useState } from "react";
import { TextField, Skeleton } from "@mui/material";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import style from "./style.module.scss";
import { Regions } from "../../../features/SpecialForm/Country/RegionsOfTheCountry";
import { ConstructionType } from "../../../features/SpecialForm/ConstructionType";
import { BuildingFunctionTypes } from "../../../features/SpecialForm/BuildingFunctionTypes";
import { ErrorMessage } from "../../../features/Forms/ErrorMessage/ErrorMessage";
import CustomButton from "../../../newUI/CustomButton/CustomButton";
import {
  parseUnprocessableFields,
  request,
} from "../../../../app/services/api/requestHandler";
import {
  ApiPostCreateProject,
  ApiPutEditProject,
} from "../../../../app/services/api/projectCreate/projectCreate";
import { CountriesType } from "../../../features/SpecialForm/Country/Country";
import { SetState } from "../../../features/ProjectEditing/SectionEditing/SectionEditing";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { simpleSchema } from "../schemas";
import { Values } from "../StandardForm/StandardForm";
import { UnsavedType } from "../types";
import { palette, textFieldSX } from "../../../../styles/restyle";
import {
  TFieldValues as TExpertiseFieldValues,
  useExpertise,
} from "../lib/hooks/useExpertise";
import { Expertise } from "../ui/Expertise";
import { BuildingFunctionTypes as BuildingFunctionTypesEnum } from "../lib/helpers/getBuildingFunctionType";

const paperOpt = {
  position: "absolute",
  left: "0px",
  width: "100%",
  marginTop: 4,
  color: "#26313a",
};

let sumError = false;

type SimpleProjectFormProps = {
  isEditMode: boolean;
  projectId: number;
  info: any;
  infoHandler: SetState<any>;
  buildingFunctionType: BuildingFunctionTypesEnum;
  authorType: any;
  setUnsaved: SetState<UnsavedType>;
};

export const SimpleForm: React.FC<SimpleProjectFormProps> = memo(
  ({
    isEditMode = false,
    projectId,
    buildingFunctionType,
    info,
    infoHandler,
    authorType,
    setUnsaved,
  }) => {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const isMobile = useIsMobile();

    let construction: any = {};

    const {
      projectPartList,
      projectPartTypes,
      isExpertiseLoading,
      expertiseForm,
      onExperiseSubmit,
      projectData,
    } = useExpertise({ projectId, buildingFunctionType });

    const initialFormValues =
      isEditMode && info.name
        ? {
            name: info.name,
            region_code: info.region_code,
            country_code: info.country_code,
            building_function_id: info.building_function_id,
            author_type: info.author_type,
            advance_pay: info.advance_pay, // Условия оплаты -> Аванс
            pay1: info.pay1, // Условия оплаты -> Оплата после приёмки документации
            pay2: info.pay2, // Условия оплаты -> Оплата после получения средств от заказчика
            pay_limit: info.pay_limit, // Условия оплаты -> Период окончательной оплаты
            construction_type_id: {
              id: info.construction_type_id.id,
              title: info.construction_type_id.title,
            },
            can: {
              update: info?.can?.update,
              edit: info?.can?.edit,
            },
            description: info.description,
          }
        : {
            advance_pay: "", // Условия оплаты -> Аванс
            pay1: "", // Условия оплаты -> Оплата после приёмки документации
            pay2: "", // Условия оплаты -> Оплата после получения средств от заказчика
            pay_limit: "", // Условия оплаты -> Период окончательной оплаты
            name: "",
            region_code: "",
            country_code: "RU",
            building_function_id: 0,
            author_type: "",
            construction_type_id: {
              id: "1",
              title: "Новое строительство",
            },
            can: {
              update: true,
              edit: true,
            },
            description: "",
          };

    const {
      register,
      trigger,
      watch,
      reset,
      setValue,
      formState: { dirtyFields, errors },
      handleSubmit,
      getValues,
      setError,
    } = useForm({
      // @ts-ignore
      resolver: yupResolver(simpleSchema),
      mode: "onBlur",
      defaultValues: {
        ...initialFormValues,
      },
    });

    const watchCountryCode = watch("country_code");
    const watchRegionCode = watch("region_code");
    const watchConstructionType = watch("construction_type_id");
    const watchBuildingFunctionId = watch("building_function_id");
    const watchAdvancePay = watch("advance_pay");
    const watchPay1 = watch("pay1");
    const watchPay2 = watch("pay2");

    const dirtyForm = Object.keys(dirtyFields).length;

    sumError = useMemo(
      () =>
        Number(watchAdvancePay) + Number(watchPay1) + Number(watchPay2) !==
          100 &&
        watchAdvancePay &&
        watchPay1 &&
        watchPay2,
      [watchAdvancePay, watchPay1, watchPay2]
    );

    useEffect(() => {
      setUnsaved((prev) => ({
        ...prev,
        info: Boolean(dirtyForm),
      }));
    }, [dirtyForm]);

    const isDescendant = Boolean(projectData?.parent_id);

    const submitForm = async (data: Values) => {
      if (sumError) {
        return;
      }

      const values = getValues();

      reset(values, {
        // @ts-ignore
        dirtyFields: false,
      });

      construction = data.construction_type_id;

      setLoading(true);

      let recievedProjectId: number;

      if (!isEditMode) {
        await request(
          ApiPostCreateProject({
            Project: {
              name: data.name,
              building_function_id: data.building_function_id, // Назначение строительства
              construction_type_id: data.construction_type_id.id, // Тип строительства
              country_code: data.country_code,
              region_code: data.region_code,
              author_type: authorType.id,
              advance_pay: Number(data.advance_pay), // Аванс
              pay1: Number(data.pay1), // Оплата после приёмки документации
              pay2: Number(data.pay2), // Оплата после получения средств от заказчика
              pay_limit: Number(data.pay_limit), // Период окончательной оплаты
              description: data.description,
            },
          }),
          (id, headersData, headers, status) => {
            recievedProjectId = id;

            if (status === 200) {
              enqueueSnackbar("Данные о проекте успешно сохранены", {
                variant: "success",
                autoHideDuration: 5000,
              });

              navigate(`/project/update?userTypeId=${authorType.id}&id=${id}`);
            }
          },
          () => (data) => {
            const message = JSON.parse(data.message);
            if (message.hasOwnProperty("name")) {
              enqueueSnackbar(message.name, {
                variant: "error",
                autoHideDuration: 5000,
              });
            }
            parseUnprocessableFields(data.message, setError);
          }
        )();
      }

      if (isEditMode) {
        await request(
          ApiPutEditProject(projectId, {
            Project: {
              name: data.name,
              author_type: authorType.id,
              building_function_id: data.building_function_id, // Назначение строительства
              construction_type_id: data.construction_type_id.id, // Тип строительства
              advance_pay: Number(data.advance_pay), // Аванс
              pay1: Number(data.pay1), // Оплата после приёмки документации
              pay2: Number(data.pay2), // Оплата после получения средств от заказчика
              pay_limit: Number(data.pay_limit), // Период окончательной оплаты
              country_code: data.country_code,
              region_code: data.region_code,
              description: data.description,
            },
          }),
          (result, headersData, headers, status) => {
            recievedProjectId = result.id;

            if (status === 200) {
              enqueueSnackbar("Данные редактирования успешно сохранены", {
                variant: "success",
                autoHideDuration: 5000,
              });
            }

            if (Number(status) >= 400) {
              enqueueSnackbar("При сохранении возникла ошибка", {
                variant: "error",
                autoHideDuration: 5000,
              });
            }

            const newInfo = {
              ...info,
              name: result.name,
              author_type: result.authorType,
              building_function_id: result.building_function_id,
              construction_type_id: {
                id: `${construction.id}`,
                title: construction.title,
              },
              advance_pay: `${result.advance_pay}`,
              pay1: `${result.pay1}`,
              pay2: `${result.pay2}`,
              pay_limit: `${result.pay_limit}`,
              country_code: data.country_code, // исключение
              region_code: result.region_code,
              description: result.description,
              can: {
                update: info.can.update,
                edit: info.can.edit,
              },
            };

            infoHandler(newInfo);
          },
          () => (data) => {
            const message = JSON.parse(data.message);
            if (message.hasOwnProperty("name")) {
              enqueueSnackbar(message.name, {
                variant: "error",
                autoHideDuration: 5000,
              });
            }
            parseUnprocessableFields(data.message, setError);
          }
        )();
      }

      /* ******************* */
      // в конце сабмитим экспертизу с актуальным выданным projectId
      if (!isDescendant) {
        expertiseForm.handleSubmit((data: TExpertiseFieldValues) =>
          onExperiseSubmit(data, recievedProjectId)
        )();
      }
      /* ******************* */

      setLoading(false);
    };

    const error = (error: string | undefined) => {
      if (!error) {
        return null;
      }
      return <ErrorMessage variant={style.postInputError} message={error} />;
    };

    if (loading || isExpertiseLoading) {
      return (
        <div className={classNames(style.grid, style.skeleton)}>
          <Skeleton
            variant="rectangular"
            className={classNames(style.wrapper, style.box1)}
          />
          <Skeleton
            variant="rectangular"
            className={classNames(style.wrapper, style.box2)}
          />
          <Skeleton
            variant="rectangular"
            className={classNames(style.wrapper, style.box3)}
          />
          <Skeleton
            variant="rectangular"
            className={classNames(style.wrapper, style.box4)}
          />
        </div>
      );
    }

    return (
      <form
        className={classNames(style.grid, isDescendant && style.noExpertise)}
        onSubmit={handleSubmit(submitForm)}
      >
        <div className={classNames(style.wrapper, style.box1)}>
          <div className={style.toSave}>
            <h2>Название проекта</h2>
            {!isMobile && (
              <CustomButton background={palette.green} type="submit">
                Сохранить
              </CustomButton>
            )}
          </div>
          <TextField
            label={"Наименование"}
            error={Boolean(errors.name?.message)}
            helperText={errors.name?.message}
            {...register("name", {
              value: initialFormValues.name,
            })}
            {...textFieldSX}
          />
        </div>
        {!isDescendant && (
          <div className={classNames(style.wrapper, style.box2)}>
            <h2>Экспертиза</h2>
            <Expertise
              projectPartList={projectPartList!}
              projectPartTypes={projectPartTypes!}
              form={expertiseForm}
            />
          </div>
        )}
        <div className={classNames(style.wrapper, style.box3)}>
          <h2>Параметры объекта</h2>
          <CountriesType
            variant={style.countries}
            changeHandler={async (data) => {
              setValue("country_code", data.id);
              setValue("region_code", 0);

              await trigger("country_code");
              await trigger("region_code");
            }}
            value={watchCountryCode}
            label="Страна"
            paperOptions={paperOpt}
          />
          <Regions
            id="region_code"
            name="region_code"
            label="Регион"
            value={watchRegionCode}
            countryCode={watchCountryCode}
            blurHandler={async () => {
              await trigger("region_code");
            }}
            changeHandler={async (data) => {
              setValue("region_code", data.id);
              await trigger("region_code");
            }}
            error={Boolean(errors.region_code?.message)}
            paperOptions={paperOpt}
          />
          {Boolean(errors.region_code?.message) && (
            <ErrorMessage
              variant={style.postInputError}
              message={String(errors.region_code?.message)}
            />
          )}
          <ConstructionType
            variant={style.constructionType}
            changeHandler={(data) => {
              setValue("construction_type_id", data);
            }}
            value={watchConstructionType.id}
            isClearable={true}
            paperOptions={paperOpt}
          />
          <BuildingFunctionTypes
            variant={style.constructionFiled}
            changeHandler={async (data) => {
              setValue("building_function_id", data.id);
              await trigger("building_function_id");
            }}
            value={watchBuildingFunctionId}
            isClearable={true}
            paperOptions={paperOpt}
            buildingFunctionType={buildingFunctionType}
            disabled={!info.can.edit}
            isRequired
          />
        </div>

        <div className={classNames(style.wrapper, style.box4)}>
          <h2>Условия оплаты</h2>
          <div className={style.conditions}>
            <div>
              <p>Аванс</p>
              <TextField
                label={"%"}
                disabled={!initialFormValues?.can.edit}
                InputLabelProps={{
                  shrink: true,
                }}
                {...textFieldSX}
                {...register("advance_pay", {
                  value: initialFormValues.advance_pay,
                })}
                error={Boolean(sumError || errors.advance_pay?.message)}
              />
            </div>
            {/* @ts-ignore */}
            <p>{error(errors.advance_pay?.message as string)}</p>
            <div>
              <p>После приемки документации</p>
              <TextField
                label={"%"}
                disabled={!initialFormValues?.can.edit}
                InputLabelProps={{
                  shrink: true,
                }}
                {...textFieldSX}
                {...register("pay1", {
                  value: initialFormValues.advance_pay,
                })}
                error={Boolean(sumError || errors.pay1?.message)}
              />
            </div>
            {/* @ts-ignore */}
            <p>{error(errors.pay1?.message as string)}</p>
            <div>
              <p>После экспертизы или оплаты Заказчиком</p>
              <TextField
                label={"%"}
                disabled={!initialFormValues?.can.edit}
                InputLabelProps={{
                  shrink: true,
                }}
                {...textFieldSX}
                {...register("pay2", {
                  value: initialFormValues.advance_pay,
                })}
                error={Boolean(sumError || errors.pay2?.message)}
              />
            </div>
            {/* @ts-ignore */}
            <p>{error(errors.pay2?.message as string)}</p>
            {sumError && (
              <p className={style.sumOfPayError}>
                Общая сумма оплаты должна быть равна 100%
              </p>
            )}
            <div>
              <p>Период окончательной оплаты</p>
              <TextField
                label={"мес"}
                disabled={!initialFormValues?.can.edit}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                {...register("pay_limit", {
                  value: initialFormValues.pay_limit,
                })}
                {...textFieldSX}
                error={Boolean(sumError || errors.pay_limit?.message)}
              />
            </div>
            {/* @ts-ignore */}
            <p>{error(errors.pay_limit?.message as string)}</p>
          </div>
        </div>
        <div className={classNames(style.wrapper, style.box5)}>
          <h2>Описание</h2>
          <TextField {...register("description")} multiline minRows={3} />
          {isMobile && (
            <CustomButton background={palette.green} type="submit">
              Сохранить
            </CustomButton>
          )}
        </div>
      </form>
    );
  }
);
