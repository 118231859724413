import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { concatenateParams } from "src/FSD/shared/lib/helpers/concatenateParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiProjectProcessResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: string | undefined;
}>;

const expands = ["activeAccess.userType", "can"];

export const getWorks = (builder: QueryBuilder) =>
  builder.query<ApiProjectProcessResponses.GetWorks, TParams>({
    query: (params) => ({
      url: `v1/project-process/get-works`,
      params: {
        id: params.id,
        expand: concatenateParams(expands),
      },
    }),
    providesTags: [ApiTags.ProjectProcess.GET_WORKS],
  });
