import React from "react";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { useAppSelector } from "src/app/store";
import { getExecutorProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getExecutorProcessInfo";
import { TextLink } from "src/components/UI/components/TextLink/TextLink";
import { NewAvatar } from "src/components/newUI/NewAvatar/NewAvatar";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoProjectChatId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  ThreeDotButton,
  useThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import { ProjectAccessControllingEntities } from "src/FSD/entities/projectAccess/model/controllingEntities";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { ChatLeftText } from "react-bootstrap-icons";
import { newDesignButtonSx, palette } from "src/styles/restyle";
import styles from "./Executor.module.scss";
import ProjectAccessModals from "../../../../../projectAccess/modals";
import PublishedObjectModals from "../../../../../publishedObject/modals";

export const ExecutorHave = () => {
  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;
  const executor = useAppSelector(getExecutorProcessInfo);
  const chatId = useAppSelector(getProjectProcessInfoProjectChatId);

  const canThreeDotBtn =
    infoData.can.requestRemoveExecutor ||
    infoData.can.updateExecutor ||
    infoData.activeAccess?.taskRemove?.can?.complete ||
    infoData.task?.can?.feedbackToExecutor ||
    infoData.task?.can?.feedbackToExecutor;

  const { name, handleOpenModal, handleCloseModal } = useModal();

  const { closeThreeDot, threeDotButtonRef } = useThreeDotButton();

  return (
    <div className={styles.executor}>
      <div className={styles.executor_header}>
        <h2>Исполнитель</h2>
        {executor && (
          <>
            <Modal
              isActive={
                name ===
                ModalNames.PublishedObject.REQUEST_REMOVE_EXECUTOR_START
              }
              handleClose={handleCloseModal}
            >
              <PublishedObjectModals.RequestRemoveExecutorStartForms
                id={infoData.id}
                type={PublishedObjectControllingEntities.PROJECT_PROCESS}
              />
            </Modal>
            <Modal
              isActive={name === ModalNames.ProjectAccess.UPDATE_ACCESS}
              handleClose={handleCloseModal}
            >
              <ProjectAccessModals.UpdateAccessForm
                id={infoData.id}
                header="Изменить доступ к проекту"
                type={ProjectAccessControllingEntities.PROJECT_PROCESS}
              />
            </Modal>
            <Modal
              isActive={
                name ===
                ModalNames.PublishedObject.REQUEST_REMOVE_EXECUTOR_COMPLETE
              }
              handleClose={handleCloseModal}
            >
              <PublishedObjectModals.RequestRemoveExecutorCompleteForm
                id={infoData.id}
                type={PublishedObjectControllingEntities.PROJECT_PROCESS}
              />
            </Modal>
            <div className={styles.executor_header_btn}>
              {infoData.can.chat && (
                <Link to={`/chat/${chatId}`} target="_blank">
                  <IconButton title="Чат" sx={newDesignButtonSx}>
                    <ChatLeftText color={palette.silverGrey} />
                  </IconButton>
                </Link>
              )}
              <div className={styles.buttons}>
                {canThreeDotBtn && (
                  <ThreeDotButton ref={threeDotButtonRef} color="success">
                    {infoData.can.requestRemoveExecutor && (
                      <MenuItem
                        onClick={() => {
                          handleOpenModal({
                            name: ModalNames.PublishedObject
                              .REQUEST_REMOVE_EXECUTOR_START,
                          });
                          closeThreeDot();
                        }}
                        disableRipple
                      >
                        Снять исполнителя
                      </MenuItem>
                    )}
                    {infoData.task?.can?.feedbackToExecutor && (
                      <MenuItem
                        onClick={() => {
                          handleOpenModal({
                            name: ModalNames.Task.FEEDBACK,
                            taskId: infoData.task.id,
                            header: "Оценить исполнителя",
                            isExecutor: true,
                          });
                          closeThreeDot();
                        }}
                        disableRipple
                      >
                        Оценить исполнителя
                      </MenuItem>
                    )}
                    {infoData.can.updateExecutor && (
                      <MenuItem
                        onClick={() => {
                          handleOpenModal({
                            name: ModalNames.ProjectAccess.UPDATE_ACCESS,
                          });
                          closeThreeDot();
                        }}
                        disableRipple
                      >
                        Сменить исполнителя
                      </MenuItem>
                    )}
                    {infoData?.activeAccess?.taskRemove?.can?.complete && (
                      <MenuItem
                        onClick={() => {
                          handleOpenModal({
                            name: ModalNames.PublishedObject
                              .REQUEST_REMOVE_EXECUTOR_COMPLETE,
                          });
                          closeThreeDot();
                        }}
                        disableRipple
                      >
                        Рассмотреть конфликт
                      </MenuItem>
                    )}
                  </ThreeDotButton>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {executor && (
        <div className={styles.executor_info}>
          <div className={styles.executor_text}>
            <TextLink url={executor.link}>
              <p className={styles.executor_name}>{executor.name}</p>
            </TextLink>
            <span>{executor.firm}</span>
          </div>
          <div className={styles.executor_avatar_container}>
            <hr className={styles.yellowLine} />
            <NewAvatar
              photoUrl={executor.avatar}
              rating={executor.rating}
              jobDuration={executor.jobDuration}
              borderColor={"#F5A623"}
              classname={styles.executor_avatar}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExecutorHave;
