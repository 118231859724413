import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import moment from "moment";
import { SearchBar } from "src/FSD/shared/uiKit/v2/SearchBar/SearchBar";
import { useAppDispatch } from "src/app/store";
import { newIconBtnSx } from "src/styles/restyle";
import {
  applyFilter,
  getProjectHistory,
  setDataReducer,
} from "src/app/feature/ProjectView/HistoryReducer/projectHistory";
import styles from "./Header.module.scss";
import { FilterHistoryIcon } from "../HistorySvg";
import { NewModal } from "../../../../../newUI/NewModal/NewModal";
import { FilterModal } from "./FilterModal/FilterModal";
import { HeaderCount } from "../../../../../newUI/HeaderCount/HeaderCount";

export const Header = () => {
  const dispatch = useAppDispatch();
  const { personValue, countFilter } = useSelector(getProjectHistory);
  const [modalFilter, setModalFilter] = useState(false);
  const sessionValue = sessionStorage.getItem("recent");
  const buferFilter = sessionValue && JSON.parse(sessionValue);
  function closeModal() {
    setModalFilter(false);
  }

  const onChangeSearch = (value: string) => {
    dispatch(setDataReducer(value));
  };

  function changeFilter(name: string, data: any) {
    if (name === "type") {
      const filterData = { ...personValue, type: data };
      dispatch(applyFilter(filterData));
    }
    if (name === "date1") {
      const filterData = { ...personValue, date1: data };
      dispatch(applyFilter(filterData));
    }
    if (name === "date2") {
      const filterData = { ...personValue, date2: data };
      dispatch(applyFilter(filterData));
    }
    if (name === "userFio") {
      const filterData = { ...personValue, userFio: data };
      dispatch(applyFilter(filterData));
    }
  }

  function changeActiveFilter(name: string) {
    if (name === "type") {
      const data = { ...personValue, type: { id: "", title: "" } };
      dispatch(applyFilter(data));
    }
    if (name === "date1") {
      const data = { ...personValue, date1: "" };
      dispatch(applyFilter(data));
    }
    if (name === "date2") {
      const data = { ...personValue, date2: "" };
      dispatch(applyFilter(data));
    }
    if (name === "userFio") {
      const data = { ...personValue, userFio: "" };
      dispatch(applyFilter(data));
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.header_top}>
        <h1>История</h1>
        <SearchBar
          onChange={onChangeSearch}
          placeholder={"Введите данные для поиска"}
        />
      </div>
      <div className={styles.header_bottom}>
        <div className={styles.activeFilter}>
          {personValue.userFio && (
            <HeaderCount
              status={!!personValue.userFio}
              setStatus={() => changeActiveFilter("userFio")}
              text={personValue.userFio}
            />
          )}
          {personValue.date1 && (
            <HeaderCount
              status={!!personValue.date1}
              setStatus={() => changeActiveFilter("date1")}
              text={`c ${moment(personValue.date1).format("DD.MM.YYYY")}`}
            />
          )}
          {personValue.date2 && (
            <HeaderCount
              status={!!personValue.date2}
              setStatus={() => changeActiveFilter("date2")}
              text={`по ${moment(personValue.date2).format("DD.MM.YYYY")}`}
            />
          )}
          {personValue?.type?.title && (
            <HeaderCount
              status={!!personValue.type.title}
              setStatus={() => changeActiveFilter("type")}
              text={personValue.type.title}
            />
          )}
          {buferFilter?.map(([key, value]: any) => {
            if (key === "type") {
              const data = { id: value.id, title: value.title };
              return (
                <HeaderCount
                  status={false}
                  setStatus={() => changeFilter("type", data)}
                  text={value.title}
                />
              );
            }
            if (key === "date1") {
              return (
                <HeaderCount
                  status={false}
                  setStatus={() => changeFilter("date1", value)}
                  text={`c ${moment(value).format("DD.MM.YYYY")}`}
                />
              );
            }
            if (key === "date2") {
              return (
                <HeaderCount
                  status={false}
                  setStatus={() => changeFilter("date2", value)}
                  text={`по ${moment(value).format("DD.MM.YYYY")}`}
                />
              );
            }
            if (key === "userFio") {
              return (
                <HeaderCount
                  status={false}
                  setStatus={() => changeFilter("userFio", value)}
                  text={value}
                />
              );
            }
            return null;
          })}
        </div>
        <div className={styles.filter}>
          <NewModal
            active={modalFilter}
            setActive={setModalFilter}
            title={"Фильтры по истории проекта"}
          >
            <FilterModal closeModal={closeModal} />
          </NewModal>
          <p>Фильтры</p>
          <div className={styles.filter_icon}>
            <IconButton sx={newIconBtnSx} onClick={() => setModalFilter(true)}>
              <FilterHistoryIcon />
            </IconButton>
            <div className={styles.filter_circle}>{countFilter}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
