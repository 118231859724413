import { TDot } from "src/FSD/shared/lib/types/dot";
import { dotColors } from "src/FSD/shared/lib/helpers/dotColor";
import classNames from "classnames";
import styles from "./Dot.module.scss";

type TProps = {
  dot: TDot;
  className?: string;
};

export const Dot = ({ dot, className }: TProps) => {
  const color = dotColors(dot);
  if (color) {
    return (
      <div
        className={classNames(styles.dot, className)}
        style={{ backgroundColor: color }}
      />
    );
  }
  return null;
};
