import { Controller } from "react-hook-form";
import cn from "classnames";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { SelectUserTypeToAssign } from "src/FSD/features/v2/SelectUserTypeToAssign";
import { ProjectAccessControllingEntities } from "src/FSD/entities/projectAccess/model/controllingEntities";
import { SelectExecutor } from "src/FSD/features/v2/SelectExecutor";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { useExecutorUpdate } from "../lib/hooks/useExecutorUpdate";
import styles from "./UpdateAccessForm.module.scss";

const defaultValues = {
  userTypeToAssign: null,
  userToAssign: null,
};

type TProps = {
  id: number;
  header: string;
  type: ProjectAccessControllingEntities;
};

export const UpdateAccessForm = ({ id, header, type }: TProps) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    errors,
    userType,
    executor,
    isViewPending,
    userTypeId,
    userId,
    label,
    isProcessWork,
  } = useExecutorUpdate({
    id,
    defaultValues,
    handleClose,
    type,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      {isViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={cn(
              styles.blockExecutor,
              !isProcessWork && styles.blockExecutorReverse
            )}
          >
            <Controller
              name="userTypeToAssign"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectUserTypeToAssign
                  publishedObjectId={id}
                  value={value}
                  changeHandler={(_, newValue) => {
                    onChange(newValue);
                  }}
                  executor={executor}
                  error={!!errors.userTypeToAssign}
                  helperText={errors?.userTypeToAssign?.message}
                  userTypeId={userTypeId}
                  setValue={setValue}
                  type={type}
                />
              )}
            />

            <Controller
              name="userToAssign"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectExecutor
                  publishedObjectId={id}
                  userType={userType}
                  value={value}
                  changeHandler={(_, newValue) => {
                    onChange(newValue);
                  }}
                  error={!!errors.userToAssign}
                  helperText={errors?.userToAssign?.message}
                  userId={userId}
                  setValue={setValue}
                  type={type}
                  label={label}
                />
              )}
            />
          </div>

          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Сохранить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
