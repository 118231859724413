import { memo, useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import { useAppSelector } from "../../../../../app/store";
import {
  getProjectProcessDocumentsCustomerAlign,
  getProjectProcessDocumentsRequestCustomerAlign,
  getProjectProcessDocumentsTab,
} from "../../../../../app/feature/ProjectProcessView/Documentations/selectors/getProjectProcessDocumentations";
import { CustomerAlignCard } from "./CustomerAlignCard/CustomerAlignCard";
import cls from "./CustomerAlign.module.scss";
import { iconBtnSx } from "../../../../../styles/restyle";
import { AddFile } from "../../../../newUI/SVG";
import { getCanProcessInfo } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import Modal from "../../../../newUI/Modal/Modal";
import { ModalPreliminaryDone } from "./ModalPreliminaryDone/ModalPreliminaryDone";
import { getProjectProcessDonePreliminaryIsLoad } from "../../../../../app/feature/ProjectProcessView/HeaderBtn/selectors/getProjectProcessDonePreliminary";
import { ModalPreliminaryRequest } from "../../HeaderBtn/ModalPreliminaryRequest/ModalPreliminaryRequest";
import { RequestCustomerAlignCard } from "./CustomerAlignCard/RequestCustomerAlignCard";
import { ModalWrapper } from "../../../User/Edit/PersonalDataChange/PersonalDataChange";
import { getProjectProcessInfoName } from "../../../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";

export const CustomerAlign = memo(({ currentTab, setModalData }: any) => {
  const customerAlign = useAppSelector(getProjectProcessDocumentsCustomerAlign);
  const can = useAppSelector(getCanProcessInfo);
  const activeDocumentationsTab = useAppSelector(getProjectProcessDocumentsTab);
  const nameProcess = useAppSelector(getProjectProcessInfoName);

  const isLoadDonePreliminary = useAppSelector(
    getProjectProcessDonePreliminaryIsLoad
  );
  const requestCustomerAlign = useAppSelector(
    getProjectProcessDocumentsRequestCustomerAlign
  );

  const [modalPreliminaryDone, setModalPreliminaryDone] = useState(false);
  const [modalPreliminaryRequest, setModalPreliminaryRequest] = useState(false);

  const closeModalPreliminaryDone = useCallback(() => {
    setModalPreliminaryDone(false);
  }, []);

  const closeModalPreliminaryRequest = useCallback(() => {
    setModalPreliminaryRequest(false);
  }, []);

  const canPreliminaryDecision =
    currentTab === "executionTask" &&
    activeDocumentationsTab === 1 &&
    can?.requestCustomerAlign;

  const canCustomerAlign =
    can?.customerAlign &&
    currentTab === "executionTask" &&
    activeDocumentationsTab === 1;

  return (
    <>
      {canPreliminaryDecision && (
        <ModalWrapper
          show={modalPreliminaryRequest}
          handleCloseModal={closeModalPreliminaryRequest}
        >
          <ModalPreliminaryRequest
            header={`Запрос предварительного решения по разделу «${nameProcess}»`}
            closeModal={closeModalPreliminaryRequest}
          />
        </ModalWrapper>
      )}
      {canCustomerAlign && (
        <Modal
          active={modalPreliminaryDone}
          setActive={setModalPreliminaryDone}
          isLoad={isLoadDonePreliminary}
        >
          <ModalPreliminaryDone closeModal={closeModalPreliminaryDone} />
        </Modal>
      )}
      <div className={cls.controls}>
        {canPreliminaryDecision && (
          <div className={cls.btn}>
            <span>Запросить</span>
            <IconButton
              sx={iconBtnSx}
              title="Выдать"
              onClick={() => setModalPreliminaryRequest(true)}
            >
              <AddFile width="24" height="24" />
            </IconButton>
          </div>
        )}
        {canCustomerAlign && (
          <div className={cls.btn}>
            <span>Выдать предварительное решение</span>
            <IconButton
              sx={iconBtnSx}
              title="Выдать"
              onClick={() => setModalPreliminaryDone(true)}
            >
              <AddFile width="24" height="24" />
            </IconButton>
          </div>
        )}
      </div>
      <div className={cls.cardWrapper}>
        {requestCustomerAlign &&
          requestCustomerAlign.map((customer) => (
            <RequestCustomerAlignCard
              key={customer.id}
              {...customer}
              setModalData={setModalData}
            />
          ))}
        {customerAlign &&
          customerAlign.map((customer) => (
            <CustomerAlignCard
              key={customer.id}
              {...customer}
              setModalData={setModalData}
            />
          ))}
      </div>
    </>
  );
});
