import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { countJobDuration } from "src/FSD/shared/lib/helpers/countJobDuration";
import { formFullUserName } from "../../../../../../shared/lib/helpers";

export const useExecutorHave = (processWork: ApiProcessWorkResponses.View) => {
  const executor = processWork.activeAccess.user!;
  const { chatId } = processWork.project!;

  const showOptions =
    processWork.can!.requestRemoveExecutor ||
    processWork.can!.updateExecutor ||
    processWork.activeAccess.taskRemove?.can?.complete ||
    processWork.task.can!.feedbackToExecutor;

  const fullName = formFullUserName(executor);

  const jobDuration = countJobDuration(executor.job_duration);

  return {
    fullName,
    executor,
    chatId,
    showOptions,
    jobDuration,
  } as const;
};
