import * as KanbanModel from "../../model";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";

export const useModalViewKanbanTask = () => {
  const dispatch = useAppDispatch();

  const viewKanbanTask = useAppSelector(KanbanModel.getViewTask);

  const handleOpenKanbanViewTask = (taskId: number, header: string) => {
    dispatch(
      KanbanModel.setViewTask({
        isOpen: true,
        taskId,
        header,
      })
    );
  };

  const handleCloseKanbanViewTask = () => {
    dispatch(
      KanbanModel.setViewTask({
        isOpen: false,
        taskId: null,
        header: "",
      })
    );
  };

  return {
    viewKanbanTask,
    handleOpenKanbanViewTask,
    handleCloseKanbanViewTask,
  } as const;
};
