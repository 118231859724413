import { IconButton } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { config } from "src/app.cofig";
import { useIsMobile } from "src/components/hooks/useIsMobile";
import { NewDownloadArrow } from "src/components/newUI/SVG";
import { CommentIconType } from "src/FSD/widgets/v2/project/SourceData/ui/SourceDataView/CommentIconType";
import style from "src/FSD/widgets/v2/project/SourceData/ui/SourceDataView/SourceDataView.module.scss";
import { iconBtnSx } from "src/styles/restyle";
import { PencilSquare, Trash } from "react-bootstrap-icons";

type TProps = {
  setCommentId: Dispatch<SetStateAction<number | null>>;
  handleDeleteComment: (id: number) => void;
  id: number;
  date_limit: string;
  status: number;
  comment: string;
  can: Record<string, boolean>;
};

const statusesList: Record<string, string> = {
  2: "inprocess",
  16: "undefined",
  3: "success",
};

export const SourceCommentItem = ({
  setCommentId,
  handleDeleteComment,
  id,
  date_limit,
  status,
  comment,
  can: { update, download },
}: TProps) => {
  const isTablet = useIsMobile(1600);
  const handleDownloadAllFiles = () => {
    window.open(
      `${config.localDomain}/comment-source-data/download-files?id=${id}&all=true`,
      "_blank"
    );
  };

  return (
    <>
      <CommentIconType status={statusesList[status]} />
      <p className={style.comment_text}>{comment}</p>
      <p className={style.comment_date}>{date_limit}</p>
      <div className={style.commentControls}>
        {update && (
          <IconButton
            className={style.edit}
            sx={{ ...iconBtnSx, padding: 0 }}
            onClick={() => setCommentId(id)}
          >
            <PencilSquare size={isTablet ? "22" : "26"} />
          </IconButton>
        )}
        {download && (
          <IconButton
            className={style.edit}
            sx={{ ...iconBtnSx, padding: 0 }}
            onClick={handleDownloadAllFiles}
          >
            <NewDownloadArrow
              width={isTablet ? "22" : "26"}
              height={isTablet ? "22" : "26"}
            />
          </IconButton>
        )}
        {update && (
          <IconButton
            className={style.edit}
            sx={{ ...iconBtnSx, padding: 0 }}
            onClick={() => handleDeleteComment(id)}
          >
            <Trash size={isTablet ? 22 : 26} />
          </IconButton>
        )}
      </div>
    </>
  );
};
