import { useState, useEffect } from "react";

export const useResolution = (size = 500) => {
  const [isResolution, setResolution] = useState<boolean>(
    window.innerWidth < size + 1
  );

  useEffect(() => {
    const handleResize = () => setResolution(window.innerWidth < size + 1);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size]);

  return isResolution;
};
