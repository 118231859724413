import { SourceDataUpdateForm } from "src/FSD/widgets/v2/sourceData/modals/SourceDataUpdateForm";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { useSourceDataUpdate } from "src/FSD/widgets/v2/project/modals/SourceDataUpdate/libs/hooks/useSourceDataUpdate";

type TProps = {
  title: string;
  parentId: number;
  searchValue: string;
  type: number;
  projectId: number;
  handleDeleteSourceDataName: (id: number) => void;
};

export const SourceDataUpdateModal = ({
  title,
  parentId,
  searchValue,
  projectId,
  handleDeleteSourceDataName,
  type,
}: TProps) => {
  const { isLoading, formSubmitHandler, data, isUpdating, canExecutorEdit } =
    useSourceDataUpdate({
      parentId,
      searchValue,
      projectId,
      type,
    });

  return (
    <Modal.Layout modalSize="xl">
      <Modal.Header>{title}</Modal.Header>
      {isLoading ? (
        <Modal.Preloader />
      ) : (
        <SourceDataUpdateForm
          formSubmitHandler={formSubmitHandler}
          checkList={data}
          canExecutorEdit={canExecutorEdit}
          handleDeleteSourceDataName={handleDeleteSourceDataName}
          parentId={parentId}
          projectId={projectId}
          isUpdating={isUpdating}
          type={type}
        />
      )}
    </Modal.Layout>
  );
};
