import moment from "moment";
import { daysBetweenDates } from "src/FSD/shared/lib/helpers/daysBetweenDates";
import { Nullable } from "../../../../../../../types/generics";

const daysFromToday = (date: string, format: string): number => {
  return daysBetweenDates(moment.utc().format(format), date, format);
};

export const calculateDays = (
  startDate: string,
  endFactDate: Nullable<string>,
  formatStart: string
) => {
  const nowToStartDays = endFactDate
    ? daysBetweenDates(startDate, endFactDate, formatStart)
    : daysFromToday(startDate, formatStart);

  return nowToStartDays;
};
