import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { indexQuery, getTypes } from "./queries";

export const projectPartApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    projectPartIndex: indexQuery(builder),
    projectPartGetTypes: getTypes(builder),
  }),
  overrideExisting: false,
});

export const { useProjectPartIndexQuery, useProjectPartGetTypesQuery } =
  projectPartApi;
