import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  projectProcessId: number;
  checklistName?: string;
}>;

export const getFilesDto = (builder: QueryBuilder) =>
  builder.query<ApiProjectProcessResponses.GetFilesDto, TParams>({
    query: ({ projectProcessId, checklistName }) => ({
      url: `v1/project-process/get-files-dto`,
      params: {
        id: projectProcessId,
        checklistName,
      },
    }),
    providesTags: [ApiTags.ProjectProcess.GET_FILES_DTO],
  });
