import React, { memo, useCallback, useState } from "react";
import classNames from "classnames";
import { RemarksType } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/types/expertiseRemarksTypes";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getExpertiseOpenRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarks";
import { setOpenRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/slice/expertiseRemarks";
import { ToggleAccordion } from "src/components/newUI/ToggleAccordion/ToggleAccordion";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { Dot } from "src/components/newUI/Dot/Dot";
import cls from "./RemarkCard.module.scss";
import { TableCard } from "./TableCard/TableCard";
import { ViewGridCard } from "./ViewGridCard/ViewGridCard";
import { RemarkCardHeader } from "./RemarkCardHeader/RemarkCardHeader";

export const RemarkCard = memo(
  ({ data, remark, index }: RemarksType & { index: number }) => {
    const openRemarks = useAppSelector(getExpertiseOpenRemarks);
    const { num_by_project, date_create, id } = remark;
    const dispatch = useAppDispatch();
    const dataArray = Object.entries(data);
    const [editMode, setEditMode] = useState(false);
    const handleRemarkClick = (index: number) => {
      dispatch(setOpenRemarks(index));
    };

    const setEditModeMemo = useCallback((mode: boolean) => {
      setEditMode(mode);
    }, []);

    return (
      <div>
        <div onClick={() => handleRemarkClick(index)} className={cls.remark}>
          <div className={cls.remark_num}>
            <Dot status={remark.dot ? "warning" : ""} />
            <span
              className={cls.remark__title}
            >{`Изм. №${num_by_project}`}</span>
            <span>{`от ${date_create}`}</span>
            <TextLink
              url={`/expertise-remark/download-files?id=${remark.id}`}
              redirect
            >
              <span className={cls.link}>Скачать замечания</span>
            </TextLink>
          </div>
          <div className={cls.remark_count}>
            <div className={cls.remark_count_flex}>
              <p>Всего</p>
              <span>{remark.count.total}</span>
            </div>
            <div className={cls.remark_count_flex}>
              <p>Выполнено</p>
              <span>{remark.count.done}</span>
            </div>
          </div>
          <ToggleAccordion toggle={openRemarks[index]} root />
        </div>
        {!!openRemarks[index] && (
          <div className={cls.wrapper}>
            <RemarkCardHeader
              editMode={editMode}
              setEditMode={setEditModeMemo}
              remarkId={id}
            />
            {!editMode && (
              <table className={cls.table}>
                <thead className={cls.table_head}>
                  <tr>
                    <th className={classNames(cls.table_head_num, cls.headTd)}>
                      № п/п
                    </th>
                    <th
                      className={classNames(cls.table_head_remark, cls.headTd)}
                    >
                      Замечание
                    </th>
                    <th
                      className={classNames(cls.table_head_comment, cls.headTd)}
                    >
                      Комментарий
                    </th>
                    <th
                      className={classNames(cls.table_head_answer, cls.headTd)}
                    >
                      Ответы на замечание
                    </th>
                    <th
                      colSpan={2}
                      className={classNames(cls.table_head_doc, cls.headTd)}
                    >
                      Документация, в которую внесены изменения
                    </th>
                  </tr>
                </thead>
                <tbody className={cls.tbody}>
                  {dataArray.map(([id, data]) => (
                    <TableCard
                      key={data.header}
                      header={data.header}
                      rows={data.remarkRows}
                    />
                  ))}
                </tbody>
              </table>
            )}
            {editMode && (
              <div className={cls.grid}>
                <div className={cls.grid_item}>№ п/п</div>
                <div className={cls.grid_item}>Замечание</div>
                <div className={cls.grid_item}>Комментарий</div>
                <div className={cls.grid_item}>Группы разделов</div>
                <div className={cls.grid_item}>Контрольный срок</div>
                <div className={cls.grid_item}>Статус</div>
                <div className={cls.grid_item}></div>
                {dataArray.map(([id, data]) => (
                  <ViewGridCard
                    key={data.header}
                    header={data.header}
                    rows={data.remarkRows}
                    projectId={+id}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);
