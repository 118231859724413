import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Sections.module.scss";
import { TValuesData } from "../../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import {
  getProjectSections,
  setOpenNode,
} from "../../../../../../app/feature/ProjectView/SectionsReducer/projectSections";
import { Accordion } from "../../../../../newUI/Accordion/Accordion";
import { SectionCard } from "../../../../../newUI/SectionCard/SectionCard";

type TreeProps = {
  data: TValuesData[];
  fetchGetProjectProcessTree: () => Promise<void>;
};
type TreeNodeProps = {
  node: TValuesData;
  index: number;
  fetchGetProjectProcessTree: () => Promise<void>;
};

export function selectMargin(lvl: number) {
  switch (lvl) {
    case 1:
      return "15px";
    case 2:
      return "6px";
    case 3:
      return "4px";
    case 4:
      return "0px";
    default:
      return "0px";
  }
}
const SectionsTree = ({ data = [], fetchGetProjectProcessTree }: TreeProps) => {
  return (
    <div className={styles.sections_container}>
      {data.map((tree, index) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <TreeNode
          key={`${tree.id}${tree.name}${tree.date_start}`}
          node={tree}
          index={index}
          fetchGetProjectProcessTree={fetchGetProjectProcessTree}
        />
      ))}
    </div>
  );
};

const TreeNode = ({
  node,
  index,
  fetchGetProjectProcessTree,
}: TreeNodeProps) => {
  const dispatch = useDispatch();
  const { openNode } = useSelector(getProjectSections);
  const hasChild = !!node.data;
  const authorFI = `${node.activeAccess?.user?.surname} ${node.activeAccess?.user?.name}`;
  const marginAccordion = selectMargin(node.lvl);
  const firstChild = node.lvl === 1 && index === 0;
  return (
    <>
      {node?.name && (
        <Accordion
          text={node?.name}
          toogle={openNode[node?.num]}
          onToggle={() => dispatch(setOpenNode(node?.num))}
          numNode={node?.num}
          classname={styles.accordion}
          marginTop={firstChild ? "0px" : marginAccordion}
        />
      )}
      {!hasChild && (
        <SectionCard
          authorFI={authorFI}
          data={node}
          patronymic={node.activeAccess?.user?.patronymic}
          fetchGetProjectProcessTree={fetchGetProjectProcessTree}
        />
      )}
      {hasChild && openNode[node?.num] && node.data && (
        <div>
          <SectionsTree
            data={Object.values(node?.data)}
            fetchGetProjectProcessTree={fetchGetProjectProcessTree}
          />
        </div>
      )}
    </>
  );
};

export default SectionsTree;
