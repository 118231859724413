import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  id: number;
  data: any;
}>;

export const create = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/process-work/create`,
      params: {
        processId: id,
      },
      method: HTTPMethods.POST,
      data,
      isMultipart: true,
    }),
    invalidatesTags: [
      ApiTags.ProcessWork.VIEW,
      ApiTags.ProjectProcess.GET_WORKS,
    ],
  });
