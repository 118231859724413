import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { useAppSelector } from "src/app/store";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import { fetchUnPublishProcess } from "src/app/feature/ProjectView/SectionsReducer/thunks";
import { fetchProjectProcessViewDuplicate } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import {
  getPublishState,
  getUpdate,
} from "src/app/feature/ProjectView/SectionsReducer/projectSections";
import { fetchProjectProcessPause } from "src/app/feature/ProjectProcessView/HeaderBtn/services/fetchProjectProcessPause";
import {
  RefType,
  ThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import PublishedObjectModals from "../publishedObject/modals";

const TitleTabsHamburger = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const threeDotButtonRef = useRef<RefType>(null);
  const publish = useAppSelector(getPublishState);
  const updatePublish = useAppSelector(getUpdate);

  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;

  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  const unPublishClick = async () => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть раздел с публикации?"
    );
    if (result) {
      await dispatch(fetchUnPublishProcess(infoData.id));
      await dispatch(fetchProjectProcessViewDuplicate(+infoData.id));
    }
  };

  async function unPauseHandler() {
    const result = window.confirm("Вы уверены, что хотите возобновить раздел?");
    if (params.id && result) {
      await dispatch(
        fetchProjectProcessPause({ id: +params.id, pause: false })
      );
      await dispatch(fetchProjectProcessViewDuplicate(+params.id));
      closeThreeDot();
    }
  }

  useEffect(() => {
    if (publish || updatePublish) {
      dispatch(fetchProjectProcessViewDuplicate(+infoData.id));
    }
  }, [publish, updatePublish]);

  const { name, handleOpenModal, handleCloseModal, restData } = useModal();

  const nameProjectProcess = `«${infoData.projectPart.name}» по проекту «${infoData.project.name}»`;

  const showThreeDot =
    (infoData.can.publish && infoData.projectPart.published) ||
    infoData.can.update ||
    infoData.can.unpublish ||
    infoData.can.freezeFunds ||
    infoData.can.pause ||
    infoData.can.unpause ||
    infoData.task?.can?.feedbackToController ||
    infoData.can.chat;

  return (
    <div>
      <Modal
        isActive={name === ModalNames.PublishedObject.PUBLISH_OBJECT}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.PublishObjectForm
          id={restData.id}
          header={restData.header}
          type={restData.type}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.UPDATE_PUBLISHED_OBJECT}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.UpdatePublishedObjectForm
          id={restData.id}
          header={restData.header}
          type={restData.type}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.FREEZE_FUNDS}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.FreezeFundsForm
          id={infoData.id}
          header="Пополнение баланса раздела"
          type={PublishedObjectControllingEntities.PROJECT_PROCESS}
        />
      </Modal>
      {showThreeDot && (
        <ThreeDotButton ref={threeDotButtonRef}>
          {infoData.can.publish && infoData.projectPart.published && (
            <MenuItem
              onClick={() => {
                closeThreeDot();
                handleOpenModal({
                  name: ModalNames.PublishedObject.PUBLISH_OBJECT,
                  header: `Публикация раздела «${nameProjectProcess}»`,
                  id: infoData.id,
                  type: PublishedObjectControllingEntities.PROJECT_PROCESS,
                });
              }}
              disableRipple
            >
              Опубликовать
            </MenuItem>
          )}
          {(infoData.can.update || infoData.can.unpublish) && (
            <MenuItem
              onClick={() => {
                closeThreeDot();
                handleOpenModal({
                  name: ModalNames.PublishedObject.UPDATE_PUBLISHED_OBJECT,
                  header: `Редактирование раздела «${nameProjectProcess}»`,
                  id: infoData.id,
                  type: PublishedObjectControllingEntities.PROJECT_PROCESS,
                });
              }}
              disableRipple
            >
              Редактировать
            </MenuItem>
          )}
          {infoData.can.unpublish && (
            <MenuItem
              onClick={() => {
                closeThreeDot();
                unPublishClick();
              }}
              disableRipple
            >
              Вернуть с публикации
            </MenuItem>
          )}
          {infoData.can.freezeFunds && (
            <MenuItem
              onClick={() => {
                closeThreeDot();
                handleOpenModal({
                  name: ModalNames.PublishedObject.FREEZE_FUNDS,
                });
              }}
              disableRipple
            >
              Пополнить баланс
            </MenuItem>
          )}
          {infoData.can.unpause && (
            <MenuItem onClick={unPauseHandler} disableRipple>
              Возобновить
            </MenuItem>
          )}
          {infoData.task?.can?.feedbackToController && (
            <MenuItem
              onClick={() => {
                closeThreeDot();
                handleOpenModal({
                  name: ModalNames.Task.FEEDBACK,
                  taskId: infoData.task.id,
                  header: "Оценить заказчика",
                  isExecutor: false,
                });
              }}
              disableRipple
            >
              Оценить заказчика
            </MenuItem>
          )}
        </ThreeDotButton>
      )}
    </div>
  );
};

export default TitleTabsHamburger;
