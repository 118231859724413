import { CreateRequestForm, UpdateRequestForm } from "./Request";
import { RemoveRequestExecutionForm } from "./RemoveRequestExecution";
import { AdditionalRequirementsUpdateForm } from "./AdditionalRequirementsUpdate";
import { CustomerAlignStartForm } from "./CustomerAlignStart";
import {
  RequestCustomerAlignStartForm,
  EditRequestCustomerAlignForm,
} from "./RequestCustomerAlign";
import { ExecuteCompleteForm } from "./ExecuteComplete";
import { RequestRemoveExecutorStartForms } from "./RequestRemoveExecutorStart";
import { RequestRemoveExecutorCompleteForm } from "./RequestRemoveExecutorComplete";
import { FreezeFundsForm } from "./FreezeFunds";
import { PublishObjectForm, UpdatePublishedObjectForm } from "./PublishObject";
import { CheckExecuteCompleteObject } from "./CheckExecuteComplete";

const PublishedObjectModals = {
  CreateRequestForm,
  UpdateRequestForm,
  RemoveRequestExecutionForm,
  AdditionalRequirementsUpdateForm,
  CustomerAlignStartForm,
  RequestCustomerAlignStartForm,
  RequestRemoveExecutorStartForms,
  RequestRemoveExecutorCompleteForm,
  EditRequestCustomerAlignForm,
  ExecuteCompleteForm,
  FreezeFundsForm,
  PublishObjectForm,
  UpdatePublishedObjectForm,
  CheckExecuteCompleteObject,
};

export default PublishedObjectModals;
