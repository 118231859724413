import React from "react";
import { useExchangeTasks } from "src/FSD/widgets/v2/projectProcess/std/ExchangeTasks/lib/hooks/useExchangeTasks";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { Accordion, Modal } from "src/FSD/shared/uiKit/v2";
import { ExchangeTasksItems } from "src/FSD/widgets/v2/projectProcess/std/ExchangeTasks/ui/ExchangeTasksItems";
import {
  TabChildrenHeader,
  useTabsPanelContext,
} from "src/FSD/widgets/v2/TabsPanel";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { ExchangeTasksHeader } from "./ExchangeTasksHeader";
import styles from "./ExchangeTasks.module.scss";
import TaskModals from "../../../../task/modals";

export const ExchangeTasks = () => {
  const { paramsId, tasks } = useExchangeTasks();
  const { currentChildTab } = useTabsPanelContext();
  const { name, handleCloseModal, restData } = useModal();

  return (
    <>
      <TabChildrenHeader
        title="Задания"
        className={styles.header}
        media={<ExchangeTasksHeader paramsId={paramsId} />}
      />
      <Modal
        isActive={name === ModalNames.Task.COMMENTS}
        handleClose={handleCloseModal}
      >
        <TaskModals.CommentsForm taskId={restData.taskId} />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProjectProcess.OUTPUT_TASK}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.OutputTaskForm
          taskId={restData.taskId}
          header={restData.header}
        />
      </Modal>

      <ScrollWrap height={"876px"} classname={styles.list}>
        {tasks.map(({ partName, dot, tasks }, index) => (
          <Accordion
            title={partName}
            classNameTitle={styles.accordionTitle}
            dot={dot}
            key={`${currentChildTab}-${index}`}
          >
            <ExchangeTasksItems tasks={tasks} />
          </Accordion>
        ))}
      </ScrollWrap>
    </>
  );
};
