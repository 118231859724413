import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import { FolderIcon, FolderWarningIcon } from "src/components/newUI/SVG";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { messages } from "src/components/pages/Project/View/InitialData/InitialDataCard";
import { CircularIndeterminate } from "src/components/UI/components/Project/Skeleton";
import { useIsMobile } from "src/components/hooks/useIsMobile";
import { KeyboardArrowIcon } from "src/components/newUI/KeyboardArrowIcon/KeyboardArrowIcon";
import { newIconBtnSx } from "src/styles/restyle";
import { useSourceDataCard } from "src/FSD/widgets/v2/project/SourceData/lib/hooks/useSourceDataCard";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { useProjectCheckListDeleteMutation } from "src/FSD/entities/projectChecklist/api";
import { GearFill } from "react-bootstrap-icons";
import { SourceDataContent } from "./SourceDataContent";
import styles from "./SourceData.module.scss";

type InitialCardProps = {
  name: string;
  parentId: number;
  type: number;
  index: number;
  all: number;
  inWork: number;
  dot: boolean;
  searchValue: string;
};

const sxBtn = { color: "white", padding: 0 };

export const SourceDataCard = ({
  name,
  parentId,
  index,
  all,
  inWork,
  dot,
  type,
  searchValue,
}: InitialCardProps) => {
  const { project } = useSelector(getProjectViewData);
  const params = useParams<{ id: string }>();
  const paramsId = Number(params.id);
  const isMobile = useIsMobile(681);
  const isTablet = useIsMobile(1600);
  const canGearBtn = project?.can.view && !project?.parentId;

  const { handleOpenModal } = useModal();
  const { data, isLoading, showSourceData, handleClick } = useSourceDataCard({
    id: paramsId,
    parentId,
    type,
    searchValue,
  });
  const arrowIcon = (
    <KeyboardArrowIcon isOpen={showSourceData} color={"#D5D6DE"} />
  );
  const [deleteProjectCheckList] = useProjectCheckListDeleteMutation();

  const handleDeleteSourceDataName = async (sourceDataId: number) => {
    if (!window.confirm(messages.CONFIRM_DELETE_SOURCE_ITEM)) {
      return;
    }

    await deleteProjectCheckList({ id: sourceDataId });
  };

  const handleOpenSourceDataUpdate = () => {
    handleOpenModal({
      name: ModalNames.SourceData.SOURCE_DATA_UPDATE,
      title: name,
      parentId,
      handleDeleteSourceDataName,
    });
  };

  return (
    <div>
      <div
        className={classNames(
          styles.card,
          showSourceData && styles.card_notMargin
        )}
        onClick={handleClick}
      >
        <div className={styles.card_left}>
          {!dot && <FolderIcon width={"24"} height={"24"} />}
          {dot && <FolderWarningIcon />}
          <div className={styles.card_left_text}>
            <p>{name}</p>
            <span
              className={classNames(
                inWork === all && inWork !== 0 && styles.card_left_complete
              )}
            >{`(${inWork}/${all})`}</span>
          </div>
        </div>
        <div className={styles.card_toggle}>{arrowIcon}</div>
        {isMobile && canGearBtn && showSourceData && (
          <IconButton
            sx={sxBtn}
            onClick={handleOpenSourceDataUpdate}
            className={styles.card_gearBtn}
          >
            <GearFill size={22} />
          </IconButton>
        )}
      </div>
      {showSourceData && (
        <>
          <div className={styles.container}>
            {!isMobile && (
              <div className={styles.parent}>
                <div className={styles.parent_status}>
                  {isTablet ? "Стат." : "Статус"}
                </div>
                <div>Наименование</div>
                <div>Дата запроса</div>
                <div>Контр. срок</div>
                <div>{isTablet ? "Согл." : "Согласовано"}</div>
                <div className={styles.parent_icon}>
                  {canGearBtn && (
                    <IconButton
                      sx={{ ...newIconBtnSx, padding: 0 }}
                      onClick={handleOpenSourceDataUpdate}
                    >
                      <GearFill size={22} />
                    </IconButton>
                  )}
                </div>
                {data!.map((el) => (
                  <SourceDataContent
                    key={el.id}
                    el={el}
                    parentId={parentId}
                    index={index}
                  />
                ))}
              </div>
            )}
            {isMobile &&
              data!.map((el) => (
                <SourceDataContent
                  key={el.id}
                  el={el}
                  parentId={parentId}
                  index={index}
                  isMobile={isMobile}
                />
              ))}
            {isLoading && <CircularIndeterminate />}
          </div>
        </>
      )}
    </div>
  );
};
