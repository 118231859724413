import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { UseFormReturn } from "react-hook-form";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import styles from "./Form.module.scss";
import {
  TFieldValues,
  TPublicationValues,
} from "../lib/hooks/usePublishContent";
import { TermsPayment } from "./TermsPayment/TermsPayment";
import { PublishObjectProcess } from "./PublishObjectProcess/PublishObjectProcess";

type Props = {
  id: number;
  header: string;
  type: PublishedObjectControllingEntities;
  form: UseFormReturn<any>;
  onSubmit: (dataValues: TFieldValues) => void;
  publicationValues: TPublicationValues;
  isSubmittingSave: boolean;
  isSubmittingPublish: boolean;
  isProjectProcessViewPending: boolean;
  processData: any;
  showErrorFiles: boolean;
  publishInfo: any;
  textProcess: string;
  isProcessWork: boolean;
  isEdit?: boolean;
};

export const ObjectForm = ({
  id,
  header,
  type,
  form,
  onSubmit,
  publicationValues,
  isSubmittingSave,
  isSubmittingPublish,
  isProjectProcessViewPending,
  processData,
  showErrorFiles,
  publishInfo,
  textProcess,
  isProcessWork,
  isEdit = false,
}: Props) => {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { isSubmitting, errors },
  } = form;

  return (
    <Modal.Layout modalSize="xl">
      <Modal.Header>{header}</Modal.Header>
      {isProjectProcessViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.modalForm}
        >
          <PublishObjectProcess
            control={control}
            register={register}
            publicationValues={publicationValues}
            id={id}
            setValue={setValue}
            errors={errors}
            processData={processData!}
            showErrorFiles={showErrorFiles}
            secureMissingAttributes={publishInfo!.secureMissingAttributes}
            isProcessWork={isProcessWork}
            textProcess={textProcess}
            type={type}
            isEdit={isEdit}
          />
          <TermsPayment
            register={register}
            publicationValues={publicationValues}
            setValue={setValue}
            isSubmittingSave={isSubmittingSave}
            isSubmittingPublish={isSubmittingPublish}
            isSubmitting={isSubmitting}
            errors={errors}
            isEdit={isEdit}
          />
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
