import {
  Accordion,
  SubTitleCount,
  TitleButton,
} from "src/FSD/shared/uiKit/v2/Accordion";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { useState } from "react";
import ProcessWorkModals from "src/FSD/widgets/v2/processWork/modals";
import { AccordionWorks } from "./AccordionWorks";

type TProps = ApiProjectProcessResponses.TGroups & {
  userId: number;
};

export function GroupAccordion({
  userFio,
  userId,
  doneCount,
  inWorkCount,
  outDateCount,
  delegate,
  canDelegate,
  dot,
  works,
}: TProps) {
  const [showDelegate, setShowDelegate] = useState(false);
  const arrayWorks = Object.values(works);
  const pencilBtnClick = () => {
    setShowDelegate(true);
  };

  const hideDelegate = () => {
    setShowDelegate(false);
  };

  return (
    <>
      {canDelegate && (
        <Modal isActive={showDelegate} handleClose={hideDelegate}>
          <ProcessWorkModals.Delegate delegate={delegate} userId={userId} />
        </Modal>
      )}
      <Accordion
        title={userFio}
        dot={dot}
        titleAfter={
          <span>
            {delegate && (
              <span data-delegate={true}>(Делегирование прав по разделу)</span>
            )}
            {canDelegate && <TitleButton onClick={pencilBtnClick as any} />}
          </span>
        }
        subTitle={
          <SubTitleCount
            inWorkCount={inWorkCount}
            outDateCount={outDateCount}
            doneCount={doneCount}
          />
        }
      >
        {arrayWorks.map((item) => (
          <AccordionWorks key={item.work.id} {...item} userFio={userFio} />
        ))}
      </Accordion>
    </>
  );
}
