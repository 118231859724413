import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { Button } from "src/FSD/shared/uiKit/v2";
import { Trash } from "react-bootstrap-icons";
import { iconBtnSx } from "src/styles/restyle";
import { ApiExpertiseResponses } from "src/FSD/entities/expertise/remarkRow/model/responseTypes";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { useDocumentDeleteMutation } from "src/FSD/entities/expertise/document/api";
import styles from "./DocumentCard.module.scss";

type TProps = ApiExpertiseResponses.Document & {
  can: ApiProjectProcessResponses.View["can"];
};

export const DocumentCard = memo(
  ({ can, date, name, num, id, file }: TProps) => {
    const [deleteDocument] = useDocumentDeleteMutation();

    async function handleDeleteDocument() {
      const result = window.confirm("Вы уверены, что хотите удалить договор?");
      if (result) {
        await deleteDocument({ id });
      }
    }

    return (
      <div className={styles.card}>
        <p>{`${name} №${num} от ${date}`}</p>
        <div className={styles.card_btn}>
          <Button href={`/file/get?id=${file.id}`} target={"_blank"}>
            Скачать
          </Button>
          {can?.edit && (
            <IconButton sx={iconBtnSx} onClick={handleDeleteDocument}>
              <Trash />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
);
