import React, { memo, useCallback, useState } from "react";
import classNames from "classnames";
import { IconButton } from "@mui/material";
import {
  PencilSquare,
  BoxArrowInLeft,
  Trash,
  BoxArrowInRight,
} from "react-bootstrap-icons";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { RemarkRowsValue } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/types/expertiseRemarksTypes";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getCanProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getCanProcessInfo";
import { fetchExpertiseRemarkRowStop } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkRowStop";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchExpertiseRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { fetchExpertiseRemarkRowStart } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkRowStart";
import { fetchExpertiseRemarkRowDelete } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkRowDelete";
import { iconBtnSx } from "src/styles/restyle";
import { EditRemarkRowForm } from "src/FSD/widgets/v2/expertise/remarkRow/modals/EditRemarkRow/ui/EditRemarkRowForm";
import cls from "../../RemarkCard.module.scss";

interface GridCardBodyProps {
  data: RemarkRowsValue;
  projectId: number;
}
export const GridCardBody = memo(({ data, projectId }: GridCardBodyProps) => {
  const dispatch = useAppDispatch();
  const { row, partCodes } = data;
  const can = useAppSelector(getCanProcessInfo);
  const processId = useAppSelector(getProjectProcessInfoId);

  const [modalUpdateRemarkRow, setUpdateRemarkRow] = useState(false);

  const handleCloseModal = useCallback(() => {
    setUpdateRemarkRow(false);
  }, []);

  async function remarkRowStopHandler() {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть замечание в черновик?"
    );
    if (result) {
      await dispatch(fetchExpertiseRemarkRowStop(row.id));
      if (processId) {
        dispatch(fetchExpertiseRemarks(processId));
      }
    }
  }

  async function remarkRowStartHandler() {
    const result = window.confirm(
      "Вы уверены, что хотите направить замечание в работу?"
    );
    if (result) {
      await dispatch(fetchExpertiseRemarkRowStart(row.id));
      if (processId) {
        dispatch(fetchExpertiseRemarks(processId));
      }
    }
  }

  async function remarkRowDeleteHandler() {
    const result = window.confirm("Вы уверены, что хотите удалить замечание ?");
    if (result) {
      await dispatch(fetchExpertiseRemarkRowDelete(row.id));
      if (processId) {
        dispatch(fetchExpertiseRemarks(processId));
      }
    }
  }

  return (
    <>
      <div className={cls.grid_item}>{row.num}</div>
      <div className={cls.grid_item}>{row.text}</div>
      <div className={cls.grid_item}>{row.comment}</div>
      <div className={classNames(cls.grid_item, cls.grid_codes)}>
        {partCodes.map((code) => (
          <div key={code}>{code}</div>
        ))}
      </div>
      <div className={cls.grid_item}>{row.date_limit}</div>
      <div className={cls.grid_item}>{row.status.value}</div>
      <div className={cls.grid_item}>
        {can?.edit && (
          <div>
            {
              <Modal
                isActive={modalUpdateRemarkRow}
                handleClose={handleCloseModal}
              >
                <EditRemarkRowForm
                  processId={processId!}
                  remarkRowId={row.id}
                />
              </Modal>
            }
            <IconButton
              sx={iconBtnSx}
              title={"Изменить"}
              onClick={() => setUpdateRemarkRow(true)}
            >
              <PencilSquare />
            </IconButton>
            {row.status.key !== 1 && (
              <IconButton
                sx={iconBtnSx}
                title={"Вернуть в черновик"}
                onClick={remarkRowStopHandler}
              >
                <BoxArrowInRight />
              </IconButton>
            )}
            {row.status.key === 1 && (
              <IconButton
                sx={iconBtnSx}
                title={"Отправить в работу"}
                onClick={remarkRowStartHandler}
              >
                <BoxArrowInLeft />
              </IconButton>
            )}
            {row.status.key === 1 && (
              <IconButton
                sx={iconBtnSx}
                title={"Удалить"}
                onClick={remarkRowDeleteHandler}
              >
                <Trash />
              </IconButton>
            )}
          </div>
        )}
      </div>
    </>
  );
});
