import React from "react";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { ViewList } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { setRedirectVisible } from "../../../app/feature/modalController/modalVisibility";
import { useIsMobile } from "../../hooks/useIsMobile";
import CustomButton from "../../newUI/CustomButton/CustomButton";
import {
  InfoSquareSVG,
  ArrowDownSquare,
  GearFillSVG,
  ArrowLeftSquare,
  ArrowRightSquare,
} from "../../newUI/SVG";
import { simpleList, standardList } from "./constants";
import {
  TabListItemType,
  StyledSelectProps,
  StyledSelectOptionType,
  StepProps,
} from "./types";
import style from "./style.module.scss";
import { palette } from "../../../styles/restyle";

export const projectCreation = [
  {
    title: "Создание",
    url: "creation",
    icon: <InfoSquareSVG />,
    incomplete: true,
    disabled: false,
  },
];

export const simpleProject = [
  {
    title: "Информация",
    url: "info",
    icon: <InfoSquareSVG />,
    incomplete: true,
    disabled: false,
  },
  {
    title: "Разделы",
    url: "sections",
    icon: <ViewList />,
    incomplete: true,
    disabled: true,
  },
  {
    title: "Файлы",
    url: "upload",
    icon: <ArrowDownSquare />,
    incomplete: false,
    disabled: true,
  },
];

export const standardProject = [
  {
    title: "Настройки",
    url: "settings",
    icon: <GearFillSVG />,
    incomplete: false,
    disabled: true,
  },
  /*
    {
      title: "Гос. контракт",
      url: "contract",
      icon: <GovContractSVG />,
      incomplete: true,
      disabled: true,
    },
    
       */
];

export const Step: React.FC<StepProps> = ({
  projectId,
  isSimple,
  currentTab,
  setCurrentTab,
  forwardDisabled,
  onSwitching,
  unsaved,
  hasComplex,
}) => {
  const naviagate = useNavigate();

  const isEditingMode = Boolean(projectId);

  const dispatch = useDispatch();
  const tabs = hasComplex || isSimple ? simpleList : standardList;
  const index = tabs.findIndex(
    (item: TabListItemType) => currentTab === item.name
  );

  const isMobile = useIsMobile(800);

  const handleBackwards = () => {
    const tab = tabs[index - 1].name;
    if (onSwitching) {
      const canSwitch = onSwitching(tab);
      if (!canSwitch) {
        return;
      }
    }

    setCurrentTab(tab);
  };

  const handleForwards = () => {
    const tab = tabs[index + 1].name;

    if (onSwitching) {
      const canSwitch = onSwitching(tab);
      if (!canSwitch) {
        return;
      }
    }

    setCurrentTab(tab);
  };

  const handleFinish = () => {
    if (unsaved.settings && currentTab === "settings") {
      dispatch(setRedirectVisible());
    } else {
      naviagate(`/project/${projectId}`);
    }
  };

  const mode = isEditingMode ? "Редактирование" : "Создание";

  const showForward =
    !forwardDisabled &&
    index < tabs.length - 1 &&
    !tabs[index].canFinish &&
    isEditingMode;

  const showBackward = !!index;

  return (
    <div className={style.stepWrapper}>
      <h1>{mode} проекта</h1>
      {isMobile ? (
        <>
          {showBackward && (
            <IconButton onClick={handleBackwards} className={style.iconButton}>
              <ArrowLeftSquare />
            </IconButton>
          )}
          {showForward && (
            <IconButton
              onClick={handleForwards}
              className={classNames(style.iconButton, style.forward)}
            >
              <ArrowRightSquare />
            </IconButton>
          )}
        </>
      ) : (
        <>
          {showBackward && (
            <CustomButton onClick={handleBackwards} background={palette.blue}>
              Назад
            </CustomButton>
          )}
          {!tabs[index]?.canFinish && !index && <div />}
          {showForward && (
            <CustomButton onClick={handleForwards} background={palette.blue}>
              Далее
            </CustomButton>
          )}
        </>
      )}
      {!isMobile && tabs[index]?.canFinish && (
        <CustomButton
          onClick={handleFinish}
          width={160}
          background={palette.green}
          className={style.finish}
        >
          Завершить
        </CustomButton>
      )}
    </div>
  );
};

export const StyledSelect: React.FC<StyledSelectProps> = ({
  value,
  onChange,
  options,
}) => (
  <select className={style.styledSelect} value={value} onChange={onChange}>
    {options.map((item: StyledSelectOptionType) => (
      <option value={item.id}>{item.title}</option>
    ))}
  </select>
);
