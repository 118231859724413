import { memo, ReactNode } from "react";
import { ListItemText } from "@mui/material";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { Autocomplete } from "src/FSD/shared/uiKit/v2/Autocomplete";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { getCompleteOptions, getNewValue } from "./utils";

type IsOptionEqualToValue = (
  option: TSelectItem,
  value: TSelectItem
) => boolean;

type Props = {
  value: TSelectItem[];
  label: string;
  placeholder?: string;
  changeHandler: (newValue: TSelectItem[]) => void;
  options: TSelectItem[];
  showSelectAll?: boolean;
  isLoading?: boolean;
  isOptionEqualToValue?: IsOptionEqualToValue;
  helperText?: ReactNode;
  error?: boolean;
  disabled?: boolean;
  disablePortal?: boolean;
  optionLabel?: "name" | "title";
};

/**
 * @param {TSelectItem[]} options.id уникальный `id` элемента `options`
 * @param {TSelectItem[]} options.title Отображаемый текст элемента `options`
 * @example
 * <Controller
 *   name="fieldName"
 *   control={control}
 *   render={({ field: { value, onChange } }) => (
 *     <SelectMultiple
 *       label="Label"
 *       value={value}
 *       options={[
 *          { id: 1, title: "Заголовок 1" },
 *          { id: 2, title: "Заголовок 2" },
 *        ]}
 *       changeHandler={(selectItem) => {
 *         onChange(selectItem);
 *       }}
 *       showSelectAll
 *       error={Boolean(errors.fieldName)}
 *       helperText={errors.fieldName?.message}
 *     />
 *   )}
 * />
 */
export const SelectMultiple = ({
  value = [],
  label,
  placeholder,
  changeHandler,
  options = [],
  showSelectAll = false,
  helperText,
  error = false,
  disablePortal = true,
  isLoading = false,
  disabled = false,
  optionLabel = "title",
  ...other
}: Props) => {
  const completeOptions = getCompleteOptions(value, options, showSelectAll);
  const optionTitle = options[0]?.[optionLabel] ? optionLabel : "title";

  const handleChange = (_: unknown, newValue: TSelectItem[]) => {
    const newOptions = getNewValue(options, newValue);
    changeHandler(newOptions);
  };

  return (
    <Autocomplete
      {...other}
      multiple
      disableCloseOnSelect
      onChange={handleChange}
      options={completeOptions}
      getOptionLabel={(option: any) => option?.[optionTitle]}
      value={value}
      loading={isLoading}
      filterSelectedOptions
      disabled={disabled}
      disablePortal={disablePortal}
      renderOption={(props, item) => {
        return (
          <li {...props} key={item.id}>
            <ListItemText>{item?.[optionTitle]}</ListItemText>
          </li>
        );
      }}
      // isOptionEqualToValue={() => true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputLabelProps={{ shrink: true }}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default memo(SelectMultiple);
