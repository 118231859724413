import { createSlice } from "@reduxjs/toolkit";
import { modalsInitialState } from "./modelState";
import { RootState } from "../../../../app/store";

const modalsSlice = createSlice({
  name: "projectModalsSlice",
  initialState: modalsInitialState,
  reducers: {
    setRequestRemoveGipActive(state, action) {
      state.requestRemoveGip = action.payload;
    },
  },
});

export const { setRequestRemoveGipActive } = modalsSlice.actions;

export const getModalsData = (state: RootState) => state.projectNew.modals;

export default modalsSlice.reducer;
