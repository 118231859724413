import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiProjectChecklistResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  projectId: number;
  parentId: number;
  type: number;
  searchValue?: string;
}>;

export const listByProject = (builder: QueryBuilder) =>
  builder.query<ApiProjectChecklistResponses.ListByProject[], TParams>({
    query: ({ projectId, parentId, searchValue, type }) => ({
      url: `v1/project-checklist/list-by-project`,
      params: {
        projectId,
        parentId,
        ...(type && {
          type,
        }),
        ...(searchValue && {
          searchStr: searchValue,
        }),
      },
    }),
    providesTags: [ApiTags.ProjectChecklist.LIST_BY_PROJECT],
  });
