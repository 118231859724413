import React, { memo } from "react";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { config } from "src/app.cofig";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { getFiles } from "src/FSD/shared/lib/helpers/getFiles";
import { ApiTags } from "src/FSD/shared/api/tags";
import { useAppDispatch } from "src/app/store";
import { projectProcessApi } from "src/FSD/entities/projectProcess/api";

interface ModalFilesProps {
  paramsId: number;
}

export const AddSourceFiles = memo(({ paramsId }: ModalFilesProps) => {
  const { handleClose } = useModalContext();
  const dispatch = useAppDispatch();

  const handleUpdate = () => {
    dispatch(
      projectProcessApi.util.invalidateTags([
        ApiTags.ProjectProcess.GET_FILES_DTO,
      ])
    );
    handleClose();
  };

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>Загрузить файлы</Modal.Header>
      <Modal.Form>
        <Uploader
          autoUpload
          canEdit
          getDataCallback={() => getFiles("project-process", paramsId, false)}
          postUrl={`${config.localDomain}/v1/project-process/add-file?id=${paramsId}`}
        />
        <Modal.Controls>
          <Button onClick={handleUpdate}>Закрыть</Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
});
