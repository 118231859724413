import React, { useState, Fragment } from "react";
import { IconButton } from "@mui/material";
import { getProjectSections } from "src/app/feature/ProjectView/SectionsReducer/projectSections";
import { useAppSelector } from "src/app/store";
import { CaretArrowDown } from "src/components/newUI/ToggleAccordion/ToggleAccordionSvg/ToggleAccordionSvg";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { ArrowDownSquareFill } from "react-bootstrap-icons";
import style from "./SourceDataView.module.scss";
import { iconBtnSx } from "../../../../../../../styles/restyle";
import { config } from "../../../../../../../app.cofig";
import { SourceDataIconType } from "./SourceDataIconType";
import { DatePopper } from "./DatePopper";
import { AddFilesButton } from "./AddFilesBtn";
import { ToggleAccordion } from "../../../../../../../components/newUI/ToggleAccordion/ToggleAccordion";
import Comment from "./Comment";
import TransitionShowContent from "../../../../../../../components/newUI/TransitionShowContent/TransitionShowContent";

type SourceDataViewProps = {
  isMobile: boolean | undefined;
  canUpdateSourceData: boolean;
  canAddFile: boolean;
  onChangeDate: (data: any) => void;
  isLoadDateUpdate: boolean;
  index?: number;
  parentId: number;
  checklist: any;
  status: any;
  critical: boolean;
  can: any;
  id: number;
  date_complete: string;
  date_create: string;
  date_deadline: string;
  task_id: number;
  projectId: number;
  projectParentId?: number;
  type: "process" | "project";
};

export const SourceDataView = ({
  isMobile,
  checklist,
  status,
  critical,
  can,
  id,
  date_complete,
  date_create,
  date_deadline,
  canUpdateSourceData,
  canAddFile,
  onChangeDate,
  isLoadDateUpdate,
  task_id,
  parentId,
  projectId,
  index,
  projectParentId,
  type = "project",
}: SourceDataViewProps) => {
  const [isCommentOpen, setCommentOpen] = useState<boolean>(false);

  const canEditDate = canUpdateSourceData && status !== "success";
  const handleNavigate = (id: number) => {
    window.location.href = `${config.localDomain}/project-source-data/download-files?id=${id}`;
  };

  const { sourceComments } = useAppSelector(getProjectSections);
  const commentCount = ` (${sourceComments[id]?.length > 0 ? sourceComments[id].length : 0})`;

  const downloadTaskBtn = can?.download && (
    <IconButton
      onClick={() => handleNavigate(id)}
      sx={{ ...iconBtnSx, padding: 0 }}
    >
      <ArrowDownSquareFill size={32} />
    </IconButton>
  );

  if (isMobile) {
    return (
      <div className={style.mobileCard}>
        <div className={style.mobileCard_title}>
          <div className={style.text}>{checklist.name}</div>
          <SourceDataIconType status={status} critical={critical} />
        </div>
        <div className={style.mobileCard_date}>
          <p>Дата запроса</p>
          <DatePopper
            date_create={date_create}
            date_deadline={date_deadline}
            canEditDate={canEditDate}
            onChangeDate={onChangeDate}
            isLoadDateUpdate={isLoadDateUpdate}
            dateType="start"
          />
        </div>
        <div className={style.mobileCard_date}>
          <p>Контр.срок</p>
          <DatePopper
            date_create={date_create}
            date_deadline={date_deadline}
            canEditDate={canEditDate}
            onChangeDate={onChangeDate}
            isLoadDateUpdate={isLoadDateUpdate}
            dateType="end"
          />
        </div>
        <div className={style.mobileCard_date}>
          <p>Согласовано</p>
          {date_complete ? (
            <div className={style.text}>{date_complete}</div>
          ) : (
            <div>-</div>
          )}
        </div>
        <div className={style.mobileCard_btn}>
          <div className={style.icon}>
            {downloadTaskBtn}
            {canAddFile && !projectParentId && (
              <AddFilesButton
                parentId={parentId}
                projectId={projectId}
                index={index}
                task_id={task_id}
                type={type}
              />
            )}
            <IconButton
              className={style.toggleCommentsList}
              onClick={() => setCommentOpen(!isCommentOpen)}
            >
              <ToggleAccordion toggle={isCommentOpen} />
            </IconButton>
          </div>
        </div>
        {isCommentOpen && (
          <Comment can={can} sourceDataId={id} name={checklist.name} />
        )}
      </div>
    );
  }

  return (
    <Fragment key={checklist.name}>
      <SourceDataIconType
        status={status}
        critical={critical}
        className={style.iconType}
      />
      <div className={style.text}>{checklist.name}</div>
      <DatePopper
        date_create={date_create}
        date_deadline={date_deadline}
        canEditDate={canEditDate}
        onChangeDate={onChangeDate}
        isLoadDateUpdate={isLoadDateUpdate}
        dateType="start"
      />
      <DatePopper
        date_create={date_create}
        date_deadline={date_deadline}
        date_complete={!!date_complete}
        canEditDate={canEditDate}
        onChangeDate={onChangeDate}
        isLoadDateUpdate={isLoadDateUpdate}
        dateType="end"
      />
      {date_complete ? (
        <div className={style.text}>{date_complete}</div>
      ) : (
        <div className={style.text}>-</div>
      )}

      <div className={style.icon}>
        {downloadTaskBtn}
        {canAddFile && !projectParentId && (
          <AddFilesButton
            parentId={parentId}
            projectId={projectId}
            index={index}
            task_id={task_id}
            type={type}
          />
        )}
        <Button
          onClick={() => setCommentOpen(!isCommentOpen)}
          endIcon={<CaretArrowDown isOpen={isCommentOpen} />}
          color="info"
        >
          Коммент.
          <span className={style.commentCount}>{commentCount}</span>
        </Button>
      </div>
      <TransitionShowContent open={isCommentOpen} classname={style.comment}>
        <Comment can={can} sourceDataId={id} name={checklist.name} />
      </TransitionShowContent>
    </Fragment>
  );
};
