import React, { memo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getExpertiseProjectList,
  getExpertiseProjectListIsLoad,
  getExpertiseProjectPartList,
} from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseProjectList";
import { fetchExpertiseProjectList } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseProjectList";
import { fetchExpertiseProjectPartList } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseProcesses";
import { fetchExpertiseRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import {
  Button,
  DateField,
  Modal,
  TextField,
  Select,
  SelectMultiple,
} from "src/FSD/shared/uiKit/v2";
import { fetchExpertiseRemarkRowCreate } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkRowCreate";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import {
  objectToSelect,
  toSelect,
} from "src/app/feature/project/projectProcessPublication";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { createOutTaskSchemas } from "src/FSD/widgets/v2/projectProcess/modals/UpdateRemarkRow/lib/schemas/createRemarkRow";

type TInputs = {
  text: string;
  comment: string;
  date_limit: any;
  project_id: TSelectItem;
  project_part_ids: TSelectItem[];
};

interface ModalCreateRemarkRowProps {
  remarkId: number;
}

export const UpdateRemarkRowForm = memo(
  ({ remarkId }: ModalCreateRemarkRowProps) => {
    const { handleClose } = useModalContext();
    const dispatch = useAppDispatch();
    const processId = useAppSelector(getProjectProcessInfoId);

    const projectList = useAppSelector(getExpertiseProjectList);
    const projectPartList = useAppSelector(getExpertiseProjectPartList) || [];
    const isLoadProjectList = useAppSelector(getExpertiseProjectListIsLoad);

    useEffect(() => {
      if (processId) {
        dispatch(fetchExpertiseProjectList(processId));
      }
    }, [processId]);

    const {
      handleSubmit,
      control,
      watch,
      formState: { errors },
    } = useForm<TInputs>({
      resolver: yupResolver(createOutTaskSchemas),
    });

    const projectIdValue = watch("project_id");
    useEffect(() => {
      if (processId && projectIdValue) {
        dispatch(
          fetchExpertiseProjectPartList({
            projectId: Number(projectIdValue.id),
            processId,
          })
        );
      }
    }, [projectIdValue]);

    const projectOptions = useDataMapper(projectList, objectToSelect);
    const projectPartOptions = useDataMapper(projectPartList, toSelect);

    const formSubmitHandler = async (dataValues: TInputs) => {
      const data = {
        text: dataValues.text,
        comment: dataValues.comment,
        date_limit: moment(dataValues.date_limit, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        ),
        project_id: Number(dataValues.project_id.id),
        project_part_ids: dataValues.project_part_ids?.map(
          ({ id }) => id as number
        ),
      };

      await dispatch(fetchExpertiseRemarkRowCreate({ remarkId, data }));
      if (processId) {
        dispatch(fetchExpertiseRemarks(processId));
      }
      handleClose();
    };

    return (
      <Modal.Layout>
        <Modal.Header>Добавление строки замечания</Modal.Header>
        {isLoadProjectList ? (
          <Modal.Preloader />
        ) : (
          <Modal.Form onSubmit={handleSubmit(formSubmitHandler)}>
            <Controller
              control={control}
              name="text"
              render={({ field: { onChange, name } }) => (
                <TextField
                  label="Текст замечания"
                  placeholder="Введите текст"
                  multiline
                  minRows={2}
                  error={Boolean(errors?.[name])}
                  helperText={errors?.[name]?.message}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="comment"
              render={({ field: { onChange, name } }) => (
                <TextField
                  label="Комментарий"
                  placeholder="Введите текст"
                  multiline
                  minRows={2}
                  error={Boolean(errors?.[name])}
                  helperText={errors?.[name]?.message}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="date_limit"
              render={({ field: { onChange, name } }) => (
                <DateField
                  label="Контрольный срок"
                  changeDateHandler={onChange}
                  error={Boolean(errors?.[name])}
                  helperText={errors?.[name]?.message}
                />
              )}
            />
            <Controller
              name="project_id"
              control={control}
              render={({ field: { value, onChange, name } }) => (
                <Select
                  label="Объект"
                  value={value}
                  options={projectOptions}
                  changeHandler={(_, newValue) => {
                    onChange(newValue);
                  }}
                  error={Boolean(errors?.[name])}
                  helperText={errors?.[name]?.message}
                />
              )}
            />
            <Controller
              name="project_part_ids"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectMultiple
                  label="Разделы"
                  value={value}
                  options={projectPartOptions}
                  changeHandler={(newValue) => {
                    onChange(newValue);
                  }}
                  showSelectAll
                />
              )}
            />
            <Modal.Controls>
              <Button type="submit">Сохранить</Button>
            </Modal.Controls>
          </Modal.Form>
        )}
      </Modal.Layout>
    );
  }
);
