import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { TabsPanel } from "src/FSD/widgets/v2/TabsPanel";
import { Breadcrumbs } from "src/FSD/shared/uiKit/v2/Breadcrumbs";
import { Info } from "src/FSD/widgets/v2/processWork/Info";
import { Removed } from "src/FSD/widgets/v2/processWork/Removed";
import { SourceFiles } from "src/FSD/widgets/v2/processWork/SourceFiles";
import { PageWrapper } from "src/FSD/shared/uiKit/v2/PageWrapper/PageWrapper";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Applications } from "src/FSD/widgets/v2/processWork/Applications";
import {
  ThreeDotButton,
  useThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import TaskModals from "src/FSD/widgets/v2/task/modals";
import { Documents } from "src/FSD/widgets/v2/processWork/Documents";
import { ExecutionTasks } from "src/FSD/widgets/v2/processWork/ExecutionTask";
import { useResolution } from "src/FSD/shared/lib/hooks/useResolution";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import PublishedObjectModals from "src/FSD/widgets/v2/publishedObject/modals";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useProcessWorkViewPage } from "../lib/hooks/useProcessWorkViewPage";
import { SkeletonPage } from "./SkeletonPage";
import { ScreenResultions } from "../lib/constants/screenResolutions";

export const ProcessWorkViewPage = () => {
  const isMobile = useResolution(ScreenResultions.MOBILE);
  const isTablet = useResolution(ScreenResultions.TABLET);

  const { id } = useParams();

  const {
    processWork,
    device,
    breadCrumbs,
    isProcessWorkLoading,
    showThreeDot,
    publishObjectHeader,
    handleUnpublishWork,
    headerUpdate,
  } = useProcessWorkViewPage({
    processWorkId: Number(id),
  });

  const { closeThreeDot, threeDotButtonRef } = useThreeDotButton();

  const { name, handleOpenModal, handleCloseModal, restData } = useModal();

  const componentsList = useMemo(() => {
    if (!processWork) {
      return {};
    }

    return {
      info: <Info processWork={processWork} />,
      sourceFiles: <SourceFiles processWork={processWork} />,
      applications: <Applications processWorkId={processWork.id} />,
      removed: <Removed processWorkId={processWork.id} />,
      executionTask: (
        <ExecutionTasks processWorkId={processWork.id} can={processWork.can} />
      ),
      documents: <Documents activeContract={processWork.activeContract} />,
    };
  }, [processWork]);

  return (
    <PageWrapper>
      {isProcessWorkLoading ? (
        <SkeletonPage isMobile={isMobile} isTablet={isTablet} />
      ) : (
        <>
          <Modal
            isActive={name === ModalNames.PublishedObject.PUBLISH_OBJECT}
            handleClose={handleCloseModal}
          >
            <PublishedObjectModals.PublishObjectForm
              id={processWork.id}
              header={publishObjectHeader}
              type={PublishedObjectControllingEntities.PROCESS_WORK}
            />
          </Modal>

          <Modal
            isActive={
              name === ModalNames.PublishedObject.UPDATE_PUBLISHED_OBJECT
            }
            handleClose={handleCloseModal}
          >
            <PublishedObjectModals.UpdatePublishedObjectForm
              id={processWork.id}
              header={restData.header}
              type={PublishedObjectControllingEntities.PROCESS_WORK}
            />
          </Modal>

          <Modal
            isActive={name === ModalNames.Task.FEEDBACK}
            handleClose={handleCloseModal}
          >
            <TaskModals.FeedbackForm
              taskId={restData.taskId}
              header={restData.header}
              isExecutor={restData.isExecutor}
            />
          </Modal>
          <Modal
            isActive={name === ModalNames.PublishedObject.FREEZE_FUNDS}
            handleClose={handleCloseModal}
          >
            <PublishedObjectModals.FreezeFundsForm
              id={processWork.id}
              header="Пополнение баланса задачи"
              type={PublishedObjectControllingEntities.PROCESS_WORK}
            />
          </Modal>
          <Breadcrumbs items={breadCrumbs} />
          <TabsPanel
            title={processWork.title}
            subTitle={processWork.status.value}
            tabsList={processWork.tabs}
            componentsList={componentsList}
            device={device}
            actions={
              showThreeDot && (
                <ThreeDotButton ref={threeDotButtonRef}>
                  {processWork.can.publish && (
                    <MenuItem
                      onClick={() => {
                        closeThreeDot();
                        handleOpenModal({
                          name: ModalNames.PublishedObject.PUBLISH_OBJECT,
                        });
                      }}
                    >
                      Опубликовать
                    </MenuItem>
                  )}
                  {processWork.can.unpublish && (
                    <MenuItem
                      onClick={() => {
                        closeThreeDot();
                        handleOpenModal({
                          name: ModalNames.PublishedObject
                            .UPDATE_PUBLISHED_OBJECT,
                          header: headerUpdate,
                        });
                      }}
                    >
                      Редактировать
                    </MenuItem>
                  )}
                  {processWork.can.unpublish && (
                    <MenuItem
                      onClick={() => {
                        closeThreeDot();
                        handleUnpublishWork();
                      }}
                    >
                      Вернуть с публикации
                    </MenuItem>
                  )}
                  {processWork.can.freezeFunds && (
                    <MenuItem
                      onClick={() => {
                        closeThreeDot();
                        handleOpenModal({
                          name: ModalNames.PublishedObject.FREEZE_FUNDS,
                        });
                      }}
                    >
                      Пополнить баланс
                    </MenuItem>
                  )}
                  {processWork.task?.can?.feedbackToController && (
                    <MenuItem
                      onClick={() => {
                        closeThreeDot();
                        handleOpenModal({
                          name: ModalNames.Task.FEEDBACK,
                          taskId: processWork.task.id,
                          header: "Оценить заказчика",
                          isExecutor: false,
                        });
                      }}
                    >
                      Оценить заказчика
                    </MenuItem>
                  )}
                  {processWork.can.chat && (
                    <MenuItem
                      to={`/chat/${processWork.project.chatId}`}
                      target="_blank"
                      component={Link}
                    >
                      Чат
                    </MenuItem>
                  )}
                </ThreeDotButton>
              )
            }
          />
        </>
      )}
    </PageWrapper>
  );
};
