import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { finalStatuses } from "src/FSD/shared/lib/constants/finalStatuses";
import { daysFromToday } from "src/FSD/shared/lib/helpers/daysFromToday";
import styles from "../../ui/CheckTasks.module.scss";

const classesByStatus: Partial<Record<Statuses, string>> = {
  [Statuses.WORK]: styles.card_normal,
  [Statuses.IS_DONE]: styles.card_normal,
  [Statuses.IS_REJECT]: styles.card_normal,
  [Statuses.DONE]: styles.card_positive,
  [Statuses.REJECT]: styles.card_positive,
  [Statuses.CANCELLED]: styles.card_positive,
  [Statuses.ACCEPT]: styles.card_positive,
};

export const getClassByStatus = (
  taskStatus: Statuses,
  dateDeadline: string
) => {
  const classByStatus = classesByStatus[taskStatus] || styles.card_normal;

  const daysDiffernce = daysFromToday(dateDeadline);

  return daysDiffernce < 0 && !finalStatuses.includes(taskStatus)
    ? styles.card_negative
    : classByStatus;
};
