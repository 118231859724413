import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  id: number;
}>;

export const unPublish = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id }) => ({
      url: `v1/process-work/unpublish`,
      params: {
        id,
      },
      method: HTTPMethods.POST,
    }),
    invalidatesTags: [
      ApiTags.ProcessWork.VIEW,
      ApiTags.ProjectProcess.GET_WORKS,
    ],
  });
