import React, { memo } from "react";
import classNames from "classnames";
import { trimPrice } from "src/utils/trimNumber";
import { TooltipWrapper } from "src/components/newUI/TooltipWrapper/TooltipWrapper";
import { QuestionCircle } from "react-bootstrap-icons";
import styles from "./GraphRender.module.scss";

export const GraphRender = memo(({ budget, name, today, last }: any) => {
  const isWork = name === "work";
  const isExpertise = name === "expertise";
  const isProject = name === "project";
  const isResearch = name === "research";

  const filtredArrayPd =
    Array.isArray(budget) && isProject
      ? budget.filter((item: any) => item.width < 5)
      : [];
  const filteredArrayWork =
    Array.isArray(budget) && isWork
      ? budget.filter((item: any) => item.width < 5)
      : [];
  const isCirclePd = filtredArrayPd.length > 0;
  const isCircleWork = filteredArrayWork.length > 0;

  const projectGraphRender = isProject && (
    <div className={styles.flex}>
      <div className={styles.flex_question}>
        <p>ПД</p>
        {isCirclePd && (
          <TooltipWrapper
            content={
              <div className={styles.tooltip}>
                <p>{`Затраты по ПД на текущий день`}</p>
                <div className={styles.tooltip_text}>
                  <p>План:</p>
                  <span>{budget[0].priceCurrent}</span>
                </div>
                <div className={styles.tooltip_text}>
                  <p>Факт:</p>
                  <span>{budget[0].priceFact}</span>
                </div>
              </div>
            }
            classname={styles.tooltip_size}
          >
            <span className={styles.tooltip_circle}>
              <QuestionCircle size={20} />
            </span>
          </TooltipWrapper>
        )}
      </div>
      <div className={styles.flexCol}>
        {budget.map((value: any, index: number) => (
          <>
            {index === 0 && (
              <div className={styles.parent}>
                <p>План</p>
                <div className={styles.container}>
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "0",
                    }}
                    className={styles.todayPlan}
                  />
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "-25px",
                    }}
                    className={styles.shadow}
                  />
                  <div
                    style={{
                      marginLeft: `${value?.marginLeft}%`,
                      width: `${value?.width}%`,
                    }}
                    className={classNames(styles.plan, styles.pdPlan)}
                  >
                    <p>{trimPrice(value?.priceCurrent) || "0"}</p>
                    <p className={styles.price}>
                      {trimPrice(value?.price) || "0"}₽
                    </p>
                    <div className={styles.date}>
                      <p>{`c ${value.dateStart}`}</p>
                      <p>{`по ${value.dateEnd}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {index === 1 && (
              <div className={styles.parent}>
                <p>Факт</p>
                <div className={styles.container}>
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "0",
                    }}
                    className={classNames(
                      styles.todayFact,
                      last && styles.todayLast
                    )}
                  />
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "0",
                    }}
                    className={styles.shadowFact}
                  />
                  <div
                    style={{
                      marginLeft: `${value?.marginLeft}%`,
                      width: `${value?.width}%`,
                      background: `${value?.color}`,
                    }}
                    className={classNames(
                      styles.fact,
                      value.width !== budget[0].width && styles.factEnd
                    )}
                  >
                    <p>{trimPrice(value?.priceFact) || "0"}</p>
                  </div>
                </div>
                <div className={styles.date} />
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );

  const workGraphRender = isWork && (
    <div className={styles.flex}>
      <div className={styles.flex_question}>
        <p>РД</p>
        {isCircleWork && (
          <TooltipWrapper
            content={
              <div className={styles.tooltip}>
                <p>{`Затраты по РД на текущий день`}</p>
                <div className={styles.tooltip_text}>
                  <p>План:</p>
                  <span>{budget[0].priceCurrent}</span>
                </div>
                <div className={styles.tooltip_text}>
                  <p>Факт:</p>
                  <span>{budget[0].priceFact}</span>
                </div>
              </div>
            }
            classname={styles.tooltip_size}
          >
            <span className={styles.tooltip_circle}>
              <QuestionCircle width={"20"} height={"20"} />
            </span>
          </TooltipWrapper>
        )}
      </div>
      <div className={styles.flexCol}>
        {budget.map((value: any, index: number) => (
          <>
            {index === 0 && (
              <div className={styles.parent}>
                <p>План</p>
                <div className={styles.container}>
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "0",
                    }}
                    className={styles.todayPlan}
                  />
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "-25px",
                    }}
                    className={styles.shadow}
                  />
                  <div
                    style={{
                      marginLeft: `${value?.marginLeft}%`,
                      width: `${value?.width}%`,
                    }}
                    className={classNames(styles.plan, styles.workPlan)}
                  >
                    <p>{trimPrice(value?.priceCurrent) || "0"}</p>
                    <p className={styles.price}>
                      {trimPrice(value?.price) || "0"}₽
                    </p>
                    <div className={styles.date}>
                      <p>{`c ${value.dateStart}`}</p>
                      <p>{`по ${value.dateEnd}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {index === 1 && (
              <div className={styles.parent}>
                <p>Факт</p>
                <div className={styles.container}>
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "0",
                    }}
                    className={classNames(
                      styles.todayFact,
                      last && styles.todayLast
                    )}
                  />
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "0",
                    }}
                    className={styles.shadowFact}
                  />
                  <div
                    style={{
                      marginLeft: `${value?.marginLeft}%`,
                      width: `${value?.width}%`,
                      background: `${value?.color}`,
                    }}
                    className={classNames(
                      styles.fact,
                      value.width !== budget[0].width && styles.factEnd
                    )}
                  >
                    <p>{trimPrice(value?.priceFact) || "0"}</p>
                  </div>
                </div>
                <div className={styles.date} />
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );

  const researchGraphRender = isResearch && (
    <div className={styles.flex}>
      <p>ИИ</p>
      <div className={styles.flexCol}>
        {budget.map((value: any, index: number) => (
          <>
            {index === 0 && (
              <div className={styles.parent}>
                <p>План</p>
                <div className={styles.container}>
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "0",
                    }}
                    className={styles.todayPlan}
                  />
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "-25px",
                    }}
                    className={styles.shadow}
                  />
                  <div
                    style={{
                      marginLeft: `${value?.marginLeft}%`,
                      width: `${value?.width}%`,
                    }}
                    className={classNames(styles.plan, styles.researchPlan)}
                  >
                    <p>{trimPrice(value?.priceCurrent) || "0"}</p>
                    <p className={styles.price}>
                      {trimPrice(value?.price) || "0"}₽
                    </p>
                    <div className={styles.date}>
                      <p>{`c ${value.dateStart}`}</p>
                      <p>{`по ${value.dateEnd}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {index === 1 && (
              <div className={styles.parent}>
                <p>Факт</p>
                <div className={styles.container}>
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "0",
                    }}
                    className={classNames(
                      styles.todayFact,
                      last && styles.todayLast
                    )}
                  />
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "0",
                    }}
                    className={styles.shadowFact}
                  />
                  <div
                    style={{
                      marginLeft: `${value?.marginLeft}%`,
                      width: `${value?.width}%`,
                      background: `${value?.color}`,
                    }}
                    className={classNames(
                      styles.fact,
                      value.width !== budget[0].width && styles.factEnd
                    )}
                  >
                    <p>{trimPrice(value?.priceFact) || "0"}</p>
                  </div>
                </div>
                <div className={styles.date} />
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );

  const expertiseGraphRender = isExpertise && (
    <div className={styles.flex}>
      <p>Экспертиза</p>
      <div className={styles.parent}>
        <p>План</p>
        <div className={classNames(styles.container, styles.expertise)}>
          {budget.map((value: any, index: any) => (
            <>
              {index === 0 && (
                <>
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "0",
                    }}
                    className={classNames(
                      styles.todayFact,
                      last && styles.todayLast
                    )}
                  />
                  <div
                    style={{
                      marginLeft: `${today?.div1?.marginLeft}%`,
                      top: "-25px",
                    }}
                    className={styles.shadow}
                  />
                  <div
                    style={{
                      marginLeft: `${value?.marginLeft}%`,
                      width: `${value?.width}%`,
                    }}
                    className={classNames(
                      styles.plan,
                      budget.length > 1 && styles.expertisePlan,
                      budget.length === 1 && styles.expertisePlan2
                    )}
                  >
                    {budget.length > 1 && (
                      <div className={styles.expertiseCircle} />
                    )}
                  </div>
                </>
              )}
              {index === 1 && (
                <div
                  style={{
                    width: `${value?.width}%`,
                  }}
                  className={styles.reserve}
                >
                  <p>Резерв</p>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {projectGraphRender}
      {workGraphRender}
      {researchGraphRender}
      {expertiseGraphRender}
    </>
  );
});
