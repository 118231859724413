import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useProcessWorkViewQuery } from "src/FSD/entities/processWork/api";
import { useProjectProcessViewQuery } from "src/FSD/entities/projectProcess/api";
import { useLazyUserTypeSecureMissingAttributesQuery } from "src/FSD/entities/userType/api";
import { ApiProjectAccessRequests } from "src/FSD/entities/projectAccess/model/requestsTypes";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { WarningMessages } from "src/FSD/shared/lib/constants/warnings";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { requestSchema } from "../schemas/request";
import { Nullable } from "../../../../../../../../types/generics";
import { pushSuccessMessage } from "../../../../../../../../app/feature/errorTrace";
import { useAppDispatch } from "../../../../../../../../app/store";

const entityTitles = {
  ProcessWork: "задачи",
  ProjectProcess: "раздела",
};

const entityQueries = {
  ProcessWork: useProcessWorkViewQuery,
  ProjectProcess: useProjectProcessViewQuery,
};

const defaultValues = {
  dateStart: null,
  dateLimit: null,
  price: 0,
  userType: null,
  comment: "",
  bankAccount: null,
  isBimUser: false,
};

export type TFieldValues = {
  dateStart: Nullable<Date>;
  dateLimit: Nullable<Date>;
  price: number;
  userType: Nullable<TSelectItem & { shortTypeName: string }>;
  comment: string;
  bankAccount: Nullable<TSelectItem>;
  isBimUser: boolean;
};

type Props = {
  id: number;
  type: PublishedObjectControllingEntities;
  submitQuery: any;
  taskId: Nullable<number>;
};

export const useRequest = ({ id, type, submitQuery, taskId }: Props) => {
  const { handleClose } = useModalContext();

  const dispatch = useAppDispatch();

  const { data: publishedObjectData, isFetching: isEntityLoading } =
    entityQueries[type]({
      id,
    });

  const [getTypeMissingAttributes, { data: typeMissingAttributes = [] }] =
    useLazyUserTypeSecureMissingAttributesQuery();

  const isFetching = isEntityLoading || !publishedObjectData;

  const form = useForm<TFieldValues>({
    defaultValues,
    resolver: yupResolver(requestSchema),
    mode: "onChange",
  });

  const onSubmit = async (data: TFieldValues) => {
    if (typeMissingAttributes.length) {
      return;
    }

    const formedData: ApiProjectAccessRequests.Request = {
      content: data.comment,
      date_start: toServerDate(data.dateStart)!,
      date_limit: toServerDate(data.dateLimit)!,
      price: data.price,
      executor_type: Number(data.userType!.id),
    };

    if (data.bankAccount) {
      formedData.bank_account_id = data.bankAccount.id as number;
    }

    const id = taskId ?? publishedObjectData!.id;

    // taskId при редактировании
    const result = await submitQuery({
      id,
      formedData,
    });
    // @ts-ignore
    if (!result?.error) {
      handleClose();
      dispatch(pushSuccessMessage());
    }
  };

  const warningMessages = publishedObjectData?.with_docs
    ? WarningMessages.MANDATORY_BANK_DETAILS
    : "";

  const userTypeValue = form.watch("userType");

  useEffect(() => {
    form.setValue("bankAccount", null);

    if (!userTypeValue?.id) {
      return;
    }

    getTypeMissingAttributes({
      id: userTypeValue!.id as number,
      secure: publishedObjectData!.secure,
    });
  }, [userTypeValue]);

  const showBimWarning =
    !form.watch("isBimUser") && Boolean(publishedObjectData?.bim);

  const showUserTypeWarning = !publishedObjectData?.executorType.find(
    (type) => type.value === userTypeValue?.shortTypeName
  );

  const showBankAccountField = Boolean(publishedObjectData?.with_docs);

  return {
    form,
    onSubmit,
    entityTitle: entityTitles[type],
    isFetching,
    warningMessages,
    typeMissingAttributes,
    showBimWarning,
    showUserTypeWarning,
    showBankAccountField,
    publishedObjectData,
  } as const;
};
