import React from "react";
import classNames from "classnames";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ApiDocumentsResponses } from "src/FSD/entities/documents/model/responseTypes";
import { DocumentProcess } from "src/FSD/widgets/v2/project/Documents/ui/DocumentProcess";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { config } from "src/app.cofig";
import { ToggleAccordion } from "src/components/newUI/ToggleAccordion/ToggleAccordion";
import { trimPrice, trimRubPennies } from "src/utils/trimNumber";
import { palette } from "src/styles/restyle";
import { ShieldCheck } from "react-bootstrap-icons";
import { useCardNode } from "../libs/hooks/useCardNode";
import styles from "./CardNode.module.scss";

interface CardNodeProps {
  doc: ApiDocumentsResponses.Contract;
  index: number;
}

export const CardNode = ({ doc, index }: CardNodeProps) => {
  const {
    isMobile,
    handleToggleDocs,
    docs,
    sxTrue,
    sxFalse,
    dotType,
    iconType,
  } = useCardNode();

  const mapDocs = doc.docs.map((el) => (
    <div className={styles.borderDocs} key={el.id}>
      <div className={styles.docs}>
        <div className={styles.card_signDocs}>
          <span>{dotType(el.sign.author)}</span>
          <span>{dotType(el.sign.signer)}</span>
        </div>
        <div>{`${el.name} № ${el.num || ""} от ${el.date_create}`}</div>
      </div>
    </div>
  ));

  const projectProcessesMap = Object.values(doc.projects).map((el, i) => (
    <DocumentProcess
      key={i}
      name={el.name}
      processes={el.processes}
      index={index + i}
    />
  ));

  const verifiedUserIcon = doc.sums.secure > 0 && !isMobile && (
    <span className={styles.card_secure}>
      {" "}
      <ShieldCheck size={20} color={palette.grey} />
    </span>
  );

  return (
    <>
      <div className={styles.card_name} onClick={() => handleToggleDocs(index)}>
        <div className={styles.card_name_content}>
          <div className={styles.card_name_sign}>
            <span>{iconType(doc.sign.author)}</span>
            <span>{iconType(doc.sign.signer)}</span>
          </div>
          <TextLink
            redirect
            url={`${config.localDomain}/document/view?id=${doc.id}`}
            target={"_blank"}
            variant="white"
          >
            {doc.name}
          </TextLink>
          <span>({doc.status.title})</span>
          {verifiedUserIcon}
        </div>
        <ToggleAccordion secondRoot toggle={docs[index]} />
      </div>
      <div className={styles.border}>
        <div className={styles.parent}>
          <div style={{ paddingLeft: "20px" }}>Наименование документа</div>
          <div>Ожидают подписи</div>
          <div className={styles.hidden}>Всего</div>
          <div className={styles.hidden}>Внесено</div>
          <div className={styles.hidden}>1-я выплата</div>
          <div className={styles.hidden}>2-я выплата</div>
          <div className={classNames(styles.hidden)}>3-я выплата</div>
          <div className={classNames(styles.line)} />
          <div style={{ paddingLeft: "20px" }} className={styles.colorTitle}>
            {doc.sums.count}
          </div>
          <div className={styles.parent_document}>
            {doc.sums.pairSignCountList.PAIR_GREEN > 0 && (
              <div>
                <FiberManualRecordIcon sx={sxTrue} />
                <span>{doc.sums.pairSignCountList.PAIR_GREEN}</span>
              </div>
            )}
            <div>
              <FiberManualRecordIcon sx={sxFalse} />
              {doc.sums.pairSignCountList.PAIR_ORANGE > 0 ? (
                <span>{doc.sums.pairSignCountList.PAIR_ORANGE}</span>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
          <div
            className={classNames(
              doc.sums.danger && styles.danger,
              styles.colorTitle
            )}
          >
            {trimRubPennies(doc.sums.allValue)}
          </div>
          <div
            className={classNames(
              doc.sums.danger && styles.danger,
              styles.colorTitle
            )}
          >
            {trimRubPennies(doc.sums.inputValue)}
          </div>
          <div className={styles.colorTitle}>{trimPrice(doc.sums.pay1)}</div>
          <div className={styles.colorTitle}>{trimPrice(doc.sums.pay2)}</div>
          <div className={styles.colorTitle}>{trimPrice(doc.sums.pay3)}</div>
        </div>
      </div>
      {docs[index] && mapDocs}
      {docs[index] && projectProcessesMap}
    </>
  );
};
