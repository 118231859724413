import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiProjectPartResponses } from "../../model/responseTypes";
import { Nullable } from "../../../../../types/generics";

type TParams = RequestParams<{
  type?: number;
  buildingFunctionType?: Nullable<number>;
}>;

export const indexQuery = (builder: QueryBuilder) =>
  builder.query<ApiProjectPartResponses.View[], TParams>({
    query: (params) => ({
      url: `v1/project-part/index`,
      params: {
        "ProjectPartSearch[type]": params.type,
        "ProjectPartSearch[building_function_type]": String(
          params.buildingFunctionType
        ),
      },
    }),
    providesTags: [ApiTags.ProjectPart.LIST],
  });
