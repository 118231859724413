import { useProjectViewQuery } from "src/FSD/entities/project/api";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { useDevice } from "src/FSD/shared/lib/hooks/useDevice";
import { mapProject } from "../mappers/mapProject";
import { getBreadcrumbs } from "../helpers/getBreadcrumbs";

const expand = [
  "processPublishedCost",
  "industry",
  "accountId",
  "files",
  "expertise",
  "buildingFunction",
  "fundType",
  "requestRemoveGipTask",
  "projectParameters",
  "gipProcess",
  "designType",
  "can",
  "constructionType",
  "files",
  "gipHelpers",
  "gipAccess",
  "gipAccessActive",
  "gipAccessActive.userType",
  "gipAccess.userType",
  "chatId",
  "authorAccess",
  "tabs",
  "gipHelpersApplication",
  "expertise",
  "seismicity",
  "resultFilesSize",
  "authorPremium",
];

type TUsePageProps = {
  id: number;
};

const MOBILE_SIZE = 1280;
const TABLET_SIZE = 1600;

export const useProjectViewPage = ({ id }: TUsePageProps) => {
  const {
    data: projectRaw,
    isFetching,
    isLoading,
  } = useProjectViewQuery({ id, expand }, { skip: !id });

  const project = useDataMapper(projectRaw, mapProject);

  const device = useDevice(MOBILE_SIZE, TABLET_SIZE);

  const breadcrumbs = getBreadcrumbs(project);

  const isProjectFetching = !project || isFetching;

  return {
    project,
    device,
    isProjectFetching,
    breadcrumbs,
    isLoading,
  } as const;
};
