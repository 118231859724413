import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { concatenateParams } from "src/FSD/shared/lib/helpers/concatenateParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiProjectProcessResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
}>;

const expands = [
  "activeAccess.userType",
  "activeAccess.user",
  "can",
  "files.file",
  "project",
  "warning",
  "task.can",
  "tabs",
  "stopped",
  "project.chatId",
  "project.can",
  "haveExpertise",
  "account.value",
  "bim",
  "activeAccess.taskRemove.data",
  "activeAccess.taskRemove.can",
  "project.files",
];

export const view = (builder: QueryBuilder) =>
  builder.query<ApiProjectProcessResponses.View, TParams>({
    query: (params) => ({
      url: `v1/project-process/view`,
      params: {
        id: params.id,
        expand: concatenateParams(expands),
      },
    }),
    providesTags: [ApiTags.ProjectProcess.VIEW],
  });
