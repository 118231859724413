import {
  useProjectProcessGetInTasksQuery,
  useProjectProcessGetOutTasksQuery,
} from "src/FSD/entities/projectProcess/api";
import { useParams } from "react-router";
import { useTabsPanelContext } from "src/FSD/widgets/v2/TabsPanel";

export const useExchangeTasks = () => {
  const params = useParams<{ id: string }>();
  const paramsId = Number(params.id);
  const { currentChildTab } = useTabsPanelContext();

  const { data: inTaskData } = useProjectProcessGetInTasksQuery(
    { id: paramsId! },
    { skip: !paramsId }
  );

  const { data: outTaskData } = useProjectProcessGetOutTasksQuery(
    { id: paramsId! },
    { skip: !paramsId }
  );

  const inTask = inTaskData ? Object.values(inTaskData) : [];
  const outTask = outTaskData ? Object.values(outTaskData) : [];

  const tasks = currentChildTab === "inputTask" ? inTask : outTask;

  return {
    paramsId,
    tasks,
  };
};
