import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiProjectResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
}>;

export const getChildProjects = (builder: QueryBuilder) =>
  builder.query<ApiProjectResponses.TGetChildProjects, TParams>({
    query: (params) => ({
      url: `v1/project/get-child-projects`,
      params: {
        id: params.id,
      },
    }),
    providesTags: [ApiTags.Project.GET_CHILD_PROJECTS],
  });
