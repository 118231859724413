import cn from "classnames";
import { RequiredTypes } from "src/components/newUI/RequiredTypes/RequiredTypes";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import styles from "./Executor.module.scss";
import { useExecutorSearch } from "../../lib/hooks/useExecutorSearch";
import PublishedObjectModals from "../../../../publishedObject/modals";
import { Skeleton } from "../../../../../../shared/uiKit/v2/Skeleton";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const ExecutorSearch = ({ processWork }: Props) => {
  const {
    countApplications,
    handleNavigation,
    requestExecutionHeader,
    isFetchingApplications,
  } = useExecutorSearch({ processWork });

  const { name, handleOpenModal, handleCloseModal } = useModal();

  return (
    <Card
      header={<Card.Header>Исполнитель</Card.Header>}
      className={cn(styles.executor, styles.executorCard)}
      controls={
        <Card.Controls location="top">
          {processWork.can!.requestExecution && (
            <Button
              onClick={() => {
                handleOpenModal({
                  name: ModalNames.PublishedObject.CREATE_REQUEST,
                });
              }}
              color="primary"
            >
              Заявиться
            </Button>
          )}
        </Card.Controls>
      }
    >
      <Modal
        isActive={name === ModalNames.PublishedObject.CREATE_REQUEST}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.CreateRequestForm
          id={processWork.id}
          header={requestExecutionHeader}
          type={PublishedObjectControllingEntities.PROCESS_WORK}
        />
      </Modal>
      <div className={styles.userInfo}>
        <Paragraph>Требуемая форма собственности</Paragraph>
        <RequiredTypes
          types={processWork.executorType}
          classname={styles.executor_type}
        />
      </div>
      <div className={styles.applications}>
        {isFetchingApplications ? (
          <Skeleton.List width={"90px"} height={50} count={1} />
        ) : (
          countApplications > 0 && (
            <>
              <div
                className={styles.applications__count}
                onClick={handleNavigation}
              >
                {countApplications}
              </div>
              <p>Кол-во заявок</p>
            </>
          )
        )}
      </div>

      <hr className={styles.yellowLine} />
    </Card>
  );
};
