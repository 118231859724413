import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Link, useParams } from "react-router-dom";
import { config } from "src/app.cofig";
import { ApiProjectResponses } from "src/FSD/entities/project/model/responseTypes";
import {
  useProjectRecalculationsRelationsMutation,
  useProjectDeleteMutation,
  useProjectPauseMutation,
  useProjectUnPauseMutation,
} from "src/FSD/entities/project/api";
import {
  ThreeDotButton,
  useThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";

type TProps = {
  project: ApiProjectResponses.View;
};

export const ProjectHeader = ({ project }: TProps) => {
  const params = useParams<{ id: string }>();
  const id = Number(params.id);

  const [recalculationsRelations] = useProjectRecalculationsRelationsMutation();
  const [deleteProject] = useProjectDeleteMutation();
  const [pause] = useProjectPauseMutation();
  const [unPause] = useProjectUnPauseMutation();
  const { closeThreeDot, threeDotButtonRef } = useThreeDotButton();

  const handleNavigate = () => {
    window.open(
      `${config.localDomain}/account/view?id=${project?.accountId}`,
      "_blank"
    );
  };

  const handleDelete = () => {
    const result = window.confirm("Вы уверены, что хотите удалить проект?");
    if (id && result) {
      deleteProject({ id });
    }
  };

  const pauseProject = () => {
    const result = window.confirm(
      "Вы уверены, что хотите приостановить проект?"
    );
    if (id && result) {
      pause({ id });
    }
  };

  const unPauseProject = () => {
    const result = window.confirm("Вы уверены, что хотите продолжить проект?");
    if (id && result) {
      unPause({ id });
    }
  };

  const reCalculationRelations = () => {
    const result = window.confirm("Вы уверены, что хотите обновить связи?");
    if (id && result) {
      recalculationsRelations({ id });
    }
  };

  return (
    <div>
      <ThreeDotButton ref={threeDotButtonRef}>
        {project?.accountId && (
          <MenuItem
            onClick={() => {
              handleNavigate();
              closeThreeDot();
            }}
            disableRipple
          >
            Кошелек
          </MenuItem>
        )}
        {project?.can?.edit && (
          <MenuItem
            onClick={() => {
              closeThreeDot();
            }}
            disableRipple
          >
            <Link
              style={{
                color: "#D5D6DE",
              }}
              to={`/project/update?id=${id}&tab=project`}
            >
              Редактировать
            </Link>
          </MenuItem>
        )}
        {project?.can?.isAdmin && (
          <MenuItem
            onClick={() => {
              reCalculationRelations();
              closeThreeDot();
            }}
            disableRipple
          >
            Обновить связи
          </MenuItem>
        )}
        {project?.can?.edit && project?.status.key !== 15 && (
          <MenuItem
            onClick={() => {
              pauseProject();
              closeThreeDot();
            }}
            disableRipple
          >
            Приостановить
          </MenuItem>
        )}
        {project?.can?.edit && project?.status.key === 15 && (
          <MenuItem
            onClick={() => {
              unPauseProject();
              closeThreeDot();
            }}
            disableRipple
          >
            Продолжить
          </MenuItem>
        )}
        {project?.can?.delete && (
          <MenuItem
            onClick={() => {
              handleDelete();
              closeThreeDot();
            }}
            disableRipple
          >
            Удалить
          </MenuItem>
        )}
      </ThreeDotButton>
    </div>
  );
};
