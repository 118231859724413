import { memo } from "react";
import { KanbanModel } from "src/FSD/entities/kanban";
import { Column } from "src/FSD/widgets/v2/kanban";
import { sortedColumns } from "../lib/constants/sortedColumns";
import { useAppSelector } from "../../../../../app/store";
import styles from "./Kanban.module.scss";

export const RowTasksList = memo(() => {
  const { rowTasks } = useAppSelector(KanbanModel.getTasks);

  const groupBy = useAppSelector(KanbanModel.getGroupByValue);

  const isGrouppedByWeeks = groupBy.id === "";

  if (!Object.keys(rowTasks).length) {
    return (
      <div className={styles.columns__rows__group}>
        <h2 className={styles.columns__rows__group__header}>
          Ничего не найдено
        </h2>
      </div>
    );
  }

  return (
    <>
      {Object.entries(rowTasks).map(([rowId, { header, columns }], index) => (
        <div key={header} className={styles.columns__rows__group}>
          <h2 className={styles.columns__rows__group__header}>{header}</h2>
          {sortedColumns.map((columnId) => {
            const position = `${columnId}.${rowId}`;
            const isStatic = isGrouppedByWeeks && index > 0;

            return (
              <Column
                key={position}
                position={position}
                tasks={columns[columnId]}
                isStatic={isStatic}
              />
            );
          })}
        </div>
      ))}
    </>
  );
});
