import { KanbanColumns, KanbanStatuses } from "src/FSD/entities/kanban";
import { WorkFlowClasses } from "src/FSD/shared/lib/constants/workFlowClasses";
import { getProjectCode } from "src/FSD/shared/lib/helpers/getProjectCode";
import { ColumnTaskProps as UseColumnTaskProps } from "../../ui/ColumnTask";
import { getMarkBackgroundColor } from "../helpers/getBackgroundColor";
import { getTaskPosition } from "../helpers/getTaskPosition";
import { CheckValues } from "../constants/checkValues";

export const useColumnTask = ({
  isCheck,
  project,
  isImportant,
  workFlowClass,
  position,
}: UseColumnTaskProps) => {
  const { columnId } = getTaskPosition(position);

  const showRedCross = isCheck === CheckValues.RED_CROSS;
  const showGreenCheck = isCheck === CheckValues.GREEN_CHECK;

  const isFreeTask =
    workFlowClass === WorkFlowClasses.FREE_TASK ||
    workFlowClass === WorkFlowClasses.CHECK_FREE_TASK;

  const isDoneColumn = columnId === KanbanStatuses[KanbanColumns.DONE];

  const code = getProjectCode(project.code);

  const backgroundColor = getMarkBackgroundColor(isImportant);

  const showCheckIcon =
    columnId === KanbanStatuses[KanbanColumns.IS_DONE] && isCheck !== null;

  return {
    backgroundColor,
    isFreeTask,
    isDoneColumn,
    showCheckIcon,
    showGreenCheck,
    showRedCross,
    code,
  } as const;
};
