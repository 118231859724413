import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./baseQuery";
import { ApiTags } from "../tags";

const tagTypes = Object.values(ApiTags).map((tag) => Object.keys(tag)[0]);

export const rootApi = createApi({
  keepUnusedDataFor: 0.001,
  reducerPath: "rootApi",
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes,
});
