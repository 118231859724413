import { PublishedObjectModel } from "src/FSD/entities/publishedObject";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";

export const useModalAdditionalRequirementsUpdate = () => {
  const dispatch = useAppDispatch();

  const { additionalRequirementsUpdate } = useAppSelector(
    PublishedObjectModel.modals.getModalsData
  );

  const handleOpenAdditionalRequirementsUpdate = () => {
    dispatch(
      PublishedObjectModel.modals.setAdditionalRequirementsUpdateActive({
        isOpen: true,
      })
    );
  };

  const handleCloseAdditionalRequirementsUpdate = () => {
    dispatch(
      PublishedObjectModel.modals.setAdditionalRequirementsUpdateActive({
        isOpen: false,
      })
    );
  };

  return {
    additionalRequirementsUpdate,
    handleOpenAdditionalRequirementsUpdate,
    handleCloseAdditionalRequirementsUpdate,
  } as const;
};
