import React from "react";
import { useParams } from "react-router-dom";
import { TabChildrenHeader } from "src/FSD/widgets/v2/TabsPanel";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { List } from "src/FSD/shared/uiKit/v2/List";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import { useSourceData } from "src/FSD/widgets/v2/project/SourceData/lib/hooks/useSourceData";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import ProjectModals from "src/FSD/widgets/v2/project/modals";
import { HeaderActions } from "src/FSD/widgets/v2/project/SourceData/ui/HeaderActions/HeaderActions";
import { Statistics } from "src/FSD/widgets/v2/project/SourceData/ui/Statistics/Statistics";
import { SourceDataCard } from "./SourceDataCard";
import styles from "./SourceData.module.scss";

export const SourceData = () => {
  const params = useParams<{ id: string }>();
  const projectId = Number(params.id);
  const { name: nameModal, handleCloseModal, restData } = useModal();
  const { searchValue, setSearchValue, isLoading, data, type } = useSourceData({
    id: Number(params.id),
  });

  return (
    <div className={styles.wrapper}>
      <TabChildrenHeader
        title="Исходные данные по проекту"
        media={<HeaderActions setSearchValue={setSearchValue} />}
      />
      <List maxHeight="865px">
        {isLoading ? (
          <Skeleton.List height={65} />
        ) : (
          <>
            <Statistics
              allSourceData={data!.allSourceData}
              overdueSourceData={data!.overdueSourceData}
              receivedSourceData={data!.receivedSourceData}
            />
            <ListMapper>
              {data!.data.map((el, index) => (
                <SourceDataCard
                  key={el.name}
                  name={el.name}
                  all={el.all}
                  inWork={el.inWork}
                  dot={el.dot}
                  parentId={el.id}
                  index={index}
                  type={type}
                  searchValue={searchValue}
                />
              ))}
            </ListMapper>
            <Modal
              isActive={nameModal === ModalNames.SourceData.SOURCE_DATA_UPDATE}
              handleClose={handleCloseModal}
            >
              <ProjectModals.SourceDataUpdateModal
                title={restData.title}
                type={type}
                parentId={restData.parentId}
                searchValue={searchValue}
                projectId={projectId}
                handleDeleteSourceDataName={restData.handleDeleteSourceDataName}
              />
            </Modal>
          </>
        )}
      </List>
    </div>
  );
};
