import { ForwardedRef, ReactNode, forwardRef, memo } from "react";
import cn from "classnames";
import styles from "./Paragraph.module.scss";

type TColor = "default" | "light" | "warning" | "error" | "minor";

type Props = {
  children: ReactNode;
  fontSize?: number;
  className?: string;
  style?: Record<string, string | number>;
  color?: TColor;
  isBold?: boolean;
};

export const Paragraph = memo(
  forwardRef(
    (
      {
        children,
        fontSize = 16,
        className = "",
        isBold = false,
        style = {},
        color = "default",
      }: Props,
      ref: ForwardedRef<any>
    ) => {
      return (
        <p
          ref={ref}
          className={cn(
            styles.paragraph,
            className,
            color === "light" && styles.paragraph_light,
            color === "warning" && styles.paragraph_warning,
            color === "error" && styles.paragraph_error,
            color === "minor" && styles.paragraph_minor,
            isBold && styles.paragraph_bold
          )}
          style={{ fontSize: `${fontSize}px`, ...style }}
        >
          {children}
        </p>
      );
    }
  )
);
