import React from "react";
import BreadcrumbsMui from "@mui/material/Breadcrumbs";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import BreadcrumbsSeparator from "./BreadcrumbsSeparator";
import styles from "./Breadcrumbs.module.scss";

export type TBreadcrumbItem = {
  isActive: boolean;
  title: string | React.ReactNode;
  url?: string;
};

const sx = {
  marginBottom: "10px",
};

type TBreadcrumbsProps = {
  items: TBreadcrumbItem[];
  className?: string;
  separator?: string | React.ReactNode;
};

export const Breadcrumbs = React.memo(
  ({
    className,
    items = [],
    separator = <BreadcrumbsSeparator className={styles.separatorIcon} />,
  }: TBreadcrumbsProps) => {
    return (
      <BreadcrumbsMui
        sx={sx}
        aria-label="breadcrumb"
        separator={separator}
        className={className}
      >
        {items.map((item) =>
          item?.url && item.isActive ? (
            <TextLink
              key={item.url}
              className={styles.link}
              url={item.url}
              redirect
              target="_blank"
            >
              {item.title}
            </TextLink>
          ) : (
            <span key={item.url} className={styles.text}>
              {item.title}
            </span>
          )
        )}
      </BreadcrumbsMui>
    );
  }
);
