import React, {
  forwardRef,
  useImperativeHandle,
  Ref,
  ReactNode,
  useState,
} from "react";
import classNames from "classnames";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Menu } from "src/FSD/shared/uiKit/v2/Menu";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import style from "./style.module.scss";

interface ThreeDotButtonProps {
  children: ReactNode;
  className?: string;
  color?: "success" | "white";
  disabled?: boolean;
}

// Todo Желательно это все вынести в общие файлы
declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
  }

  interface PaletteOptions {
    white?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}

const theme = createTheme({
  palette: {
    white: {
      main: "#FFFFFF",
    },
    success: {
      main: "#0AA699",
    },
  },
});

export interface RefType {
  handleClose: () => void;
}

export const ThreeDotButton = forwardRef(
  (
    { children, color = "white", className, disabled }: ThreeDotButtonProps,
    ref: Ref<RefType>
  ) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({ handleClose }));

    if (!children) {
      return null;
    }

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    return (
      <ThemeProvider theme={theme}>
        <div className={classNames(style.root, className)}>
          <IconButton
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            disabled={disabled}
            color={color}
            className={style.iconButton}
          >
            <ThreeDotsVertical />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
          >
            {children}
          </Menu>
        </div>
      </ThemeProvider>
    );
  }
);
