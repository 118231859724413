import { ProjectModel } from "../..";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";

export const useModalsRequestRemoveGip = () => {
  const dispatch = useAppDispatch();

  const { requestRemoveGip } = useAppSelector(
    ProjectModel.modals.getModalsData
  );

  const handleOpenRequestRemoveGip = (projectId?: number) => {
    dispatch(
      ProjectModel.modals.setRequestRemoveGipActive({
        isOpen: true,
        projectId: projectId ?? null,
      })
    );
  };

  const handleCloseRequestRemoveGip = () => {
    dispatch(
      ProjectModel.modals.setRequestRemoveGipActive({
        isOpen: false,
        projectId: null,
      })
    );
  };

  return {
    requestRemoveGip,
    handleOpenRequestRemoveGip,
    handleCloseRequestRemoveGip,
  } as const;
};
