import React, { memo } from "react";
import { IconButton } from "@mui/material";
import { iconBtnSx } from "src/styles/restyle";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { fetchExpertiseRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { taskCompleteThunk } from "src/app/feature/taskComplete/services/taskCompleteThunk";
import { XSquareFill } from "react-bootstrap-icons";

export const BackToWorkBtn = memo(({ taskId }: any) => {
  const dispatch = useAppDispatch();
  const processId = useAppSelector(getProjectProcessInfoId);

  async function backToWorkHandle(id: number) {
    if (!window.confirm("Вы уверены, что хотите вернуть на доработку?")) {
      return;
    }
    const data = {
      CompleteForm: {
        status: 5,
      },
    };
    await dispatch(taskCompleteThunk({ taskId: id, data }));
    if (processId) {
      await dispatch(fetchExpertiseRemarks(processId));
    }
  }

  return (
    <>
      <IconButton
        sx={iconBtnSx}
        title={"Вернуть на доработку"}
        onClick={() => {
          backToWorkHandle(taskId);
        }}
      >
        <XSquareFill size={24} />
      </IconButton>
    </>
  );
});
