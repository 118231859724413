import { responseAgreement } from "src/FSD/features/v2/SelectAgreement/model/options";
import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";
import { messages } from "../../../../../../../../utils/SimplifiedYup";
import { ApiPublishedObjectResponse } from "../../../../../../../entities/publishedObject/model/responseTypes";

export const setDateValues = (
  processData: any,
  publishInfo: ApiPublishedObjectResponse.PublishInfo,
  setValue: any,
  setError: any,
  isEdit: boolean
) => {
  const missingAttributesLength = publishInfo.secureMissingAttributes.length;

  if (processData.date_start && processData.date_limit) {
    setValue("dateStart", timestampToDate(processData.date_start));
    setValue("dateLimit", timestampToDate(processData.date_limit));

    if (timestampToDate(processData.date_start)! < new Date()) {
      setError("dateStart", {
        message: messages.futureDate("Начальный срок"),
      });
    }
  }

  if (processData!.price) {
    if (Number(processData.price)) {
      setValue("price", Number(processData.price));
    } else {
      setValue("checkNegotiatedPrice", true);
    }
  }

  if (processData.pay1 || processData.pay2) {
    setValue("pay1", processData.pay1);
    setValue("pay2", processData.pay2);
  }

  if (processData.with_docs && !missingAttributesLength) {
    setValue("agreementsActs", responseAgreement[1]);
  }

  if (!publishInfo.pay3 && processData.pay3 > 0 && processData.pay2 >= 0) {
    setValue("pay2", processData.pay2 + processData.pay3);
  }

  if (publishInfo.pay3 && processData.pay3) {
    setValue("pay3", processData.pay3);
  }

  if (processData.secure && !missingAttributesLength) {
    setValue("safeTransaction", responseAgreement[1]);
  }

  if (processData.bim) {
    setValue("bim", processData.bim);
  }

  if (processData.kac) {
    setValue("kac", processData.kac);
  }

  if (processData.vor) {
    setValue("vor", processData.vor);
  }

  if (processData.vor_pnr) {
    setValue("vorPnr", processData.vor_pnr);
  }

  setValue("showVor", processData.projectPart.vor);
  setValue("showVorPnr", processData.projectPart.vor_pnr);
  setValue("showKac", processData.projectPart.kac);
  setValue("showPay3", publishInfo.pay3);
  setValue("canNoSecure", publishInfo.canNoSecure);

  if (!isEdit) {
    setValue("showBim", processData.projectPart.bim);
  }

  if (!publishInfo.canNoSecure) {
    setValue("agreementsActs", responseAgreement[1]);
    setValue("safeTransaction", responseAgreement[1]);
  }
};
