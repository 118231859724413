import React from "react";
import { Controller } from "react-hook-form";
import { Button, Modal, TextField, Uploader } from "src/FSD/shared/uiKit/v2";
import { SelectReviewProcesses } from "src/FSD/features/v2/SelectReviewProcesses";
import { useCreateOutTask } from "../lib/hooks/useCreateOutTask";

type TProps = {
  processId: number;
};

export const CreateOutTaskForm = ({ processId }: TProps) => {
  const { onSubmit, form, isLoading } = useCreateOutTask({ id: processId });

  const {
    control,
    setValue,
    formState: { errors },
  } = form;

  return (
    <Modal.Layout>
      <Modal.Header>Выдать задание</Modal.Header>
      <Modal.Form onSubmit={onSubmit}>
        <Controller
          name="toProcessesIds"
          control={control}
          render={({ field: { onChange, name } }) => (
            <SelectReviewProcesses
              processId={processId}
              projectFieldName="toProjectId"
              processesFieldName={name}
              onProjectChange={(newValue) => {
                setValue("toProjectId", newValue);
              }}
              onProcessesChange={(newValue) => {
                onChange(newValue);
              }}
              errors={errors}
            />
          )}
        />
        <Controller
          name="uploadFiles"
          control={control}
          render={({ field: { onChange } }) => (
            <Uploader
              setFiles={(files) => {
                onChange(files);
              }}
              error={Boolean(errors.uploadFiles)}
              helperText={errors.uploadFiles?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="content"
          render={({ field: { onChange } }) => (
            <TextField
              multiline
              minRows={2}
              label="Комментарий"
              placeholder="Введите текст"
              error={Boolean(errors.content)}
              helperText={errors.content?.message}
              onChange={(value) => {
                onChange(value);
              }}
            />
          )}
        />
        <Modal.Controls>
          <Button disabled={isLoading} isLoading={isLoading} type="submit">
            Отправить
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
