import { memo } from "react";
import { KanbanColumns, KanbanModel } from "src/FSD/entities/kanban";
import { Column } from "src/FSD/widgets/v2/kanban";
import { Select, TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { groupsList } from "../lib/constants/groupList";
import styles from "./Kanban.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";

export const RowTasksHeader = memo(() => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(KanbanModel.getGroupByValue);

  const handleChangeGroupByValue = (_: Event, groupByValue: TSelectItem) => {
    if (groupByValue.id === groupBy.id) {
      return;
    }

    dispatch(KanbanModel.setGroupByValue(groupByValue));
  };

  return (
    <>
      <div className={styles.topHeader}>
        <h2 className={styles.topHeader__title}>Задачи отчётного периода</h2>
        <Select
          value={groupBy}
          className={styles.select}
          changeHandler={handleChangeGroupByValue}
          textClassName={styles.select__text}
          options={groupsList}
          disableClear
        />
      </div>
      <div className={styles.columns__rows__columnHeaders}>
        <Column
          isEmpty
          header={
            <Column.Header>
              <Column.Title>{KanbanColumns.IN_WORK}</Column.Title>
              <Column.AddTask />
            </Column.Header>
          }
        />
        <Column
          isEmpty
          header={
            <Column.Header>
              <Column.Title>{KanbanColumns.IS_DONE}</Column.Title>
            </Column.Header>
          }
        />
        <Column
          isEmpty
          header={
            <Column.Header>
              <Column.Title>{KanbanColumns.DONE}</Column.Title>
            </Column.Header>
          }
        />
      </div>
    </>
  );
});
