import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { concatenateParams } from "src/FSD/shared/lib/helpers/concatenateParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiExpertiseResponses } from "../../../remarkRow/model/responseTypes";

type TParams = RequestParams<{
  id: number;
}>;

export const indexDocument = (builder: QueryBuilder) =>
  builder.query<ApiExpertiseResponses.Document[], TParams>({
    query: (params) => ({
      url: `v1/expertise/document/index`,
      params: {
        "DocumentSearch[process_id]": params.id,
        expand: concatenateParams(params.expand),
      },
    }),
    providesTags: [ApiTags.Expertise.DOCUMENTS_INDEX],
  });
