import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { ApiTags } from "src/FSD/shared/api/tags";

export type TParams = {
  id: number;
};

export const getOutTasks = (builder: QueryBuilder) =>
  builder.query<ApiProjectProcessResponses.GetTasks, TParams>({
    query: (params) => ({
      url: `v2/project-process/get-out-tasks`,
      params: {
        id: params.id,
      },
    }),
    providesTags: [ApiTags.ProjectProcess.GET_OUT_TASKS],
  });
