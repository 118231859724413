import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiPublishedObjectRequests } from "../../../publishedObject/model/requestsTypes";

type TParams = RequestParams<{
  id: number;
  data: ApiPublishedObjectRequests.UpdatePublishedObject;
}>;

export const updatePublished = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/process-work/update-published`,
      params: {
        id,
      },
      method: HTTPMethods.PUT,
      data,
    }),
    invalidatesTags: [
      ApiTags.ProcessWork.VIEW,
      ApiTags.ProjectProcess.GET_WORKS,
    ],
  });
