import { Controller, UseFormReturn } from "react-hook-form";
import { Button, DateField, Modal, TextField } from "src/FSD/shared/uiKit/v2";
import { TFieldValues } from "../lib/hooks/useRequestCustomerAlignStart";

type Props = {
  form: UseFormReturn<TFieldValues, object>;
  onSubmit: (dataValues: TFieldValues) => void;
  isFetching?: boolean;
};

export const RequestCustomerAlignForm = ({
  form,
  onSubmit,
  isFetching,
}: Props) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;

  return (
    <Modal.Layout>
      <Modal.Header>Запрос предварительного решения</Modal.Header>
      {isFetching ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("name")}
            label="Наименование"
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
          <Controller
            control={control}
            name="dateDeadline"
            render={({ field: { value, onChange } }) => (
              <DateField
                label="Конечный срок"
                startDateProp={value}
                changeDateHandler={(date) => {
                  onChange(date);
                }}
                error={Boolean(errors.dateDeadline)}
                helperText={errors?.dateDeadline?.message}
              />
            )}
          />
          <TextField
            {...register("content")}
            multiline
            minRows={3}
            placeholder="Введите комментарий"
            label="Комментарий"
            error={Boolean(errors.content)}
            helperText={errors.content?.message}
          />
          <Modal.Controls>
            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Отправить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
