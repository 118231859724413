import {
  useProjectProcessFreezeFundsMutation,
  useProjectProcessViewQuery,
} from "src/FSD/entities/projectProcess/api";
import {
  useProcessWorkFreezeFundsMutation,
  useProcessWorkViewQuery,
} from "src/FSD/entities/processWork/api";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";

type TUseQueriesProps = {
  id: number;
  type: PublishedObjectControllingEntities;
};
export const useQueries = ({ id, type }: TUseQueriesProps) => {
  const useViewQuery = {
    ProcessWork: useProcessWorkViewQuery,
    ProjectProcess: useProjectProcessViewQuery,
  };

  const useFreezeFunds = {
    ProcessWork: useProcessWorkFreezeFundsMutation,
    ProjectProcess: useProjectProcessFreezeFundsMutation,
  };

  const { data: processData, isFetching } = useViewQuery[type]({
    id,
    expand: ["account"],
  });

  const [freezeFunds] = useFreezeFunds[type]();

  return {
    processData,
    isFetching,
    freezeFunds,
  };
};
