import { memo, useEffect, useRef, useState } from "react";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import cn from "classnames";
import styles from "./CheckTasks.module.scss";
import { Details } from "./Details";
import { Processes } from "./Processes";
import { getClassByStatus } from "../lib/helpers/getClassByStatus";

type Props = ApiProjectProcessResponses.GetSubCheckTasks[0]["tasks"][0];

export const SubCheckTask = memo((task: Props) => {
  const someRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  const { status, date_deadline, date_complete } = task.task;

  const taskStatus: Statuses = status.key;

  const classByStatus = getClassByStatus(
    taskStatus,
    date_complete || date_deadline
  );

  useEffect(() => {
    if (!someRef.current) {
      return;
    }

    setHeight(someRef.current.clientHeight - 50);
  }, []);

  return (
    <Card className={cn(styles.card, classByStatus)}>
      <div className={styles.subCheckTask} ref={someRef}>
        <Details {...task} />
        <div className={styles.line} />
        <Processes height={height} {...task} />
      </div>
    </Card>
  );
});
