import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { concatenateParams } from "src/FSD/shared/lib/helpers/concatenateParams";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  id: number;
  expand: string[];
}>;

export const getDocumentations = (builder: QueryBuilder) =>
  builder.query<ApiProjectProcessResponses.GetDocumentations, TParams>({
    query: (params) => ({
      url: `v2/project-process/get-documentations`,
      params: {
        id: params.id,
        expand: concatenateParams(params.expand),
      },
    }),
    providesTags: [ApiTags.ProjectProcess.GET_DOCUMENTATIONS],
  });
