import React, { useMemo } from "react";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { CheckCircleFill, ClockFill, XCircleFill } from "react-bootstrap-icons";
import { palette } from "src/styles/restyle";
import { taskStatusIcon } from "src/FSD/widgets/v2/projectProcess/std/ExecutionTasks/lib/helpers/taskStatusIcon";
import styles from "./Checks.module.scss";
import { groupByColor } from "../lib/helpers/groupByColor";

type TProps = {
  header: string;
  checks: ApiProjectProcessResponses.TabChecksItem[];
};

export const Checks = ({ header, checks }: TProps) => {
  const groupedByColor = useMemo(() => groupByColor(checks), [checks]);

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      <div className={styles.list}>
        {Object.keys(groupedByColor).map((color) => (
          <div className={styles.group} key={color}>
            {groupedByColor[color].map(({ header, status }) => (
              <div className={styles.item} key={header}>
                <div className={styles.item__icon}>
                  {taskStatusIcon(status.key)}
                </div>
                <div className={styles.item__title}>{header}</div>
                <div className={styles.item__desc}>{status.value}</div>
              </div>
            ))}
          </div>
        ))}

        <div className={styles.help}>
          <div className={styles.help__item}>
            <CheckCircleFill color={palette.persianGreen} /> принято
          </div>
          <div className={styles.help__item}>
            <XCircleFill color={palette.bordeaux} /> отказано
          </div>
          <div className={styles.help__item}>
            <ClockFill color={palette.silverGrey} /> на проверке
          </div>
          <div className={styles.help__item}>
            <ClockFill color={palette.bordeaux} /> на проверке(просрочено)
          </div>
        </div>
      </div>
    </Modal.Layout>
  );
};
