import moment from "moment";

export const daysBetweenDates = (
  date1: Date | number | string,
  date2: Date | number | string,
  format: string
): number => {
  const a = moment(date1, format);
  const b = moment(date2, format);

  return b.diff(a, "days");
};
