import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiProjectAccessRequests } from "../../model/requestsTypes";

type TParams = RequestParams<{
  id: number;
  formedData: ApiProjectAccessRequests.Update;
}>;

export const update = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, formedData }) => ({
      url: `v1/project-access/update`,
      params: {
        id,
      },
      data: { ProjectAccess: formedData },
      method: HTTPMethods.PUT,
    }),
    invalidatesTags: [
      ApiTags.ProjectProcess.VIEW,
      ApiTags.ProcessWork.VIEW,
      ApiTags.Project.GET_CHILD_PROJECTS,
    ],
  });
