import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { concatenateParams } from "src/FSD/shared/lib/helpers/concatenateParams";
import { ApiTags } from "src/FSD/shared/api/tags";
import { ApiPublishedObjectResponse } from "../../../publishedObject/model/responseTypes";

type TParams = RequestParams<{
  id: number;
}>;

export const removed = (builder: QueryBuilder) =>
  builder.query<ApiPublishedObjectResponse.Removed[], TParams>({
    query: (params) => ({
      url: `v1/project-process/removed`,
      params: {
        id: params.id,
        expand: concatenateParams(params.expand),
      },
    }),
    providesTags: [ApiTags.ProjectProcess.REMOVED],
  });
