import * as Yup from "yup";
import {
  dateFutureRequired,
  stringRequired,
} from "../../../../../../../../utils/SimplifiedYup";

export const requestCustomerAlignSchema = Yup.object({
  dateDeadline: dateFutureRequired("Конечный срок"),
  name: stringRequired("Наименование"),
});
