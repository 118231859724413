import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { setColorConfigTheme } from "./app/services/user/userConfig";
import { DevHeader } from "./components/features/DevHeader/DevHeader";
import { EventEmitterLayer } from "./components/events/EventEmitterLayer";
import { isDevelopment } from "./app/services/app/app";
import { isNotRootPath } from "./app/services/app/routing";
import { useAuth } from "./components/hooks/useAuth";
import { showLogUserInfo } from "./app/services/log/log";
import { useSetFontApp } from "./components/hooks/useSetFontApp";
import { getThemeProviderConfig } from "./components/UI/GetThemeProviderConfig";
import {
  selectorAppTheme,
  selectorAttemptedToken,
  setAttemptedToken,
} from "./app/feature/app/app";
import { useTheme } from "./components/hooks/useTheme";
import { ErrorHandler } from "./components/features/ErrorHandler";
import { ErrorCreator } from "./components/features/ErrorCreator";
import { getCookie } from "./app/services/app/storage/cookies";
import { removeToken } from "./app/services/auth/auth";
import { useGlobalCounts } from "./components/hooks/useGlobalCounts";
import { useConstantValues } from "./components/hooks/useConstantValues";
import { useJivo } from "./components/hooks/useJivo";
import { AppRoutes } from "./routes/Routes";
import ErrorTrace from "./components/newUI/ErrorTrace";

export const App: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const attemptedToken = useSelector(selectorAttemptedToken);
  const theme = useSelector(selectorAppTheme);

  useAuth();
  useSetFontApp();
  useTheme();
  useGlobalCounts();
  useJivo();
  useConstantValues();

  useEffect(() => {
    if (!isDevelopment()) {
      if (!getCookie("access_token") || getCookie("access_token") === "") {
        removeToken();
      }
    }
  });

  useEffect(() => {
    if (isDevelopment()) dispatch(setAttemptedToken());
    setColorConfigTheme("dark");
  }, []);

  useEffect(() => {
    if (isNotRootPath(location.pathname)) {
      navigate(location.pathname);
    }
  }, [navigate]);

  useEffect(() => {
    if (attemptedToken && isDevelopment()) showLogUserInfo();
  }, [attemptedToken]);

  if (!attemptedToken) {
    return null;
  }

  return (
    <ErrorHandler>
      <ErrorCreator>
        <DevHeader />
        <ThemeProvider theme={getThemeProviderConfig(theme)!}>
          <SnackbarProvider maxSnack={3}>
            <EventEmitterLayer>
              <AppRoutes />
              <ErrorTrace />
            </EventEmitterLayer>
          </SnackbarProvider>
        </ThemeProvider>
      </ErrorCreator>
    </ErrorHandler>
  );
};
