import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = {
  id: number;
  data: FormData;
};

export const createOutTask = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/project-process/create-out-task`,
      params: {
        id,
      },
      method: HTTPMethods.POST,
      data,
      isMultipart: true,
    }),
    invalidatesTags: [ApiTags.ProjectProcess.GET_OUT_TASKS],
  });
