import { useEffect } from "react";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import {
  useProjectAccessRequestMutation,
  useProjectAccessRequestWorkMutation,
} from "src/FSD/entities/projectAccess/api";
import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";
import { RequestForm } from "./Form";
import { useRequest } from "../lib/hooks/useRequest";

const submitQueries = {
  ProcessWork: useProjectAccessRequestWorkMutation,
  ProjectProcess: useProjectAccessRequestMutation,
};

export type Props = {
  id: number;
  header: string;
  type: PublishedObjectControllingEntities;
};

export const CreateRequestForm = ({ id, type, header }: Props) => {
  const [submitQuery] = submitQueries[type]();

  const {
    form,
    onSubmit,
    entityTitle,
    warningMessages,
    isFetching,
    typeMissingAttributes,
    showBimWarning,
    showUserTypeWarning,
    showBankAccountField,
    publishedObjectData,
  } = useRequest({
    id,
    type,
    submitQuery,
    taskId: null,
  });

  useEffect(() => {
    if (!publishedObjectData) {
      return;
    }

    if (publishedObjectData.date_start) {
      form.setValue(
        "dateStart",
        timestampToDate(publishedObjectData.date_start)
      );
    }
    if (publishedObjectData.date_limit) {
      form.setValue(
        "dateLimit",
        timestampToDate(publishedObjectData.date_limit)
      );
    }
    if (publishedObjectData.price) {
      form.setValue("price", Number(publishedObjectData.price));
    }
  }, [publishedObjectData]);

  return (
    <RequestForm
      header={header}
      form={form}
      onSubmit={onSubmit}
      isFetching={isFetching}
      entityTitle={entityTitle}
      warningMessages={warningMessages}
      typeMissingAttributes={typeMissingAttributes}
      showBimWarning={showBimWarning}
      showUserTypeWarning={showUserTypeWarning}
      showBankAccountField={showBankAccountField}
    />
  );
};
