import { trimPrice } from "../../../../../../../utils/trimNumber";
import { TPropsObject } from "./useObjects";

export const useProgressPrice = ({ object }: TPropsObject) => {
  const { percent, end, start } = object.price || {};

  const percentPrice = Math.min(100, percent);

  const excessCost = end - start;

  const titleAdditionPrice =
    percent > 100 ? `(превышен на ${excessCost} ₽))` : "(в пределах плана)";

  const backgroundProgressPrice = percent > 100 ? "red" : "green";

  const priceStart = `${trimPrice(start)} ₽`;
  const priceEnd = `${trimPrice(end)} ₽`;

  return {
    percentPrice,
    titleAdditionPrice,
    backgroundProgressPrice,
    priceEnd,
    priceStart,
  } as const;
};
