import { createSlice } from "@reduxjs/toolkit";
import { modalsInitialState } from "./modalsState";
import { RootState } from "../../../../app/store";

const modalsSlice = createSlice({
  name: "publishedObjectModalsSlice",
  initialState: modalsInitialState,
  reducers: {
    setRequestUpdateActive(state, action) {
      state.requestUpdate = action.payload;
    },
    setRemoveRequestExecutionActive(state, action) {
      state.removeRequestExecution = action.payload;
    },
    setFeedbackTaskActive(state, action) {
      state.feedbackTask = action.payload;
    },
    setExecutorUpdateActive(state, action) {
      state.executorUpdate = action.payload;
    },
    setAdditionalRequirementsUpdateActive(state, action) {
      state.additionalRequirementsUpdate = action.payload;
    },
  },
});

export const {
  setRequestUpdateActive,
  setRemoveRequestExecutionActive,
  setFeedbackTaskActive,
  setExecutorUpdateActive,
  setAdditionalRequirementsUpdateActive,
} = modalsSlice.actions;

export const getModalsData = (state: RootState) => state.publishedObject.modals;

export default modalsSlice.reducer;
