import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph";
import styles from "./StatusObject.module.scss";
import { TPropsObject } from "../../lib/hooks/useObjects";

export const StatusObject = ({ object }: TPropsObject) => {
  const statusWork = object.status.key === 2;
  const statusDone = object.status.key === 3;
  const color = statusDone ? "minor" : "default";
  const beforeText = statusWork ? "до" : "";
  const datesEnd = object.dates.end ? `${beforeText} ${object.dates.end}` : "";

  return (
    <div className={styles.statusObject}>
      <Paragraph
        color="light"
        isBold
        fontSize={18}
        className={styles.statusObject__value}
      >
        {object.status.value}
      </Paragraph>
      <Paragraph fontSize={18} color={color} isBold>
        {datesEnd}
      </Paragraph>
    </div>
  );
};
