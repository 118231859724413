import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  id: number;
  userId: number;
  data: {
    delegate: boolean;
  };
}>;

export const delegate = (builder: QueryBuilder) =>
  builder.mutation<boolean, TParams>({
    query: ({ id, userId, data }) => ({
      url: `v1/project-process/delegate`,
      params: {
        id,
        userId,
      },
      method: HTTPMethods.POST,
      data,
    }),
    invalidatesTags: [
      ApiTags.ProcessWork.VIEW,
      ApiTags.ProjectProcess.GET_WORKS,
    ],
  });
