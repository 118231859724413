import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { saveFilterValuesInLocalStorage } from "src/FSD/pages/v2/kanban/lib/helpers";
import { initialFilterParams } from "src/FSD/entities/kanban/model";
import { KanbanType, KanbanModel, KanbanApi } from "src/FSD/entities/kanban";
import { isDeepEqual } from "src/FSD/shared/lib/helpers";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";
import { filterSchema } from "../schemas";
import { getDashboardDestinations } from "../../../../../../../app/feature/dashboard/destinations";
import {
  TUserType,
  getDashboardHeader,
} from "../../../../../../../app/feature/dashboard/header";
import { getFilterValues } from "../helpers";
import { Nullable } from "../../../../../../../types/generics";
import { getParsedUserToken } from "../../../../../../../app/services/auth/auth";
import { useClearUpFields } from "./useClearUpFields";

export const useSearchFilter = () => {
  const dispatch = useAppDispatch();
  const selectOptions = useAppSelector(KanbanModel.getSelectOptions);
  const filterPending = useAppSelector(KanbanModel.getFilterPending);

  const groupBy = useAppSelector(KanbanModel.getGroupByValue);
  const sort = useAppSelector(KanbanModel.getSortValue);

  const datePlaceholders = useAppSelector(KanbanModel.getSetting);

  const isFirstlyOpen = useRef<boolean>(true);
  const userTypeIdRef = useRef<Nullable<number>>(null);

  // отрефакторить при переносе dashboard на fsd
  // -------
  const { userType: userTypeId, projectId } = useAppSelector(
    getDashboardDestinations
  );
  const { data: userTypes } = useAppSelector(getDashboardHeader);

  const firmId =
    userTypes.find((userType: TUserType) => userType.id === userTypeId)
      ?.firmId ?? null;
  // -------

  const {
    control,
    watch,
    trigger,
    setValue,
    formState: { errors, isValidating, isValid },
  } = useForm<KanbanType.FilterValues>({
    defaultValues: useMemo(
      () => getFilterValues(userTypeId),
      [userTypeId, datePlaceholders]
    ),
    resolver: yupResolver(filterSchema),
  });

  const projectValue = watch("project");
  const partGroupValue = watch("partGroup");
  const groupValue = watch("group");

  useClearUpFields(groupValue, setValue);

  const onSubmit = (filterValues: KanbanType.FilterValues) => {
    saveFilterValuesInLocalStorage(userTypeId, filterValues);

    dispatch(KanbanModel.setGroupByValue(filterValues.groupBy));
    dispatch(KanbanModel.setSortValue(filterValues.sort));

    dispatch(
      KanbanApi.fetchViewByUserTypeId({
        userTypeId,
        filterValues,
        showSpinner: true,
      })
    );
  };

  const filterValues = watch();

  const filterValuesExtendedRef = useRef<Nullable<any>>(null);

  const submitKanbanFilter = () => {
    const filterValuesExtended = { ...filterValues, groupBy, sort };

    if (
      !isDeepEqual(filterValuesExtended, filterValuesExtendedRef.current) &&
      !isValidating &&
      isValid
    ) {
      onSubmit(filterValuesExtended);
      filterValuesExtendedRef.current = filterValuesExtended;
    }
  };

  // для первой загрузки канбана
  useEffect(() => {
    if (!userTypeId) {
      return;
    }

    const filterValuesExtended = { ...filterValues, sort, groupBy };

    if (userTypeId !== userTypeIdRef.current) {
      const cachedFilterValues = getFilterValues(userTypeId);

      Object.entries(cachedFilterValues).forEach(([field, value]) => {
        setValue(
          field as keyof KanbanType.FilterValues,
          value as KanbanType.FilterValues[keyof KanbanType.FilterValues]
        );
      });

      onSubmit(cachedFilterValues);
      filterValuesExtendedRef.current = filterValuesExtended;
      userTypeIdRef.current = userTypeId;

      return;
    }

    const currentStaticFields = {
      project: filterValuesExtended.project?.id,
      dateStart: toServerDate(filterValuesExtended.dateStart),
      dateLimit: toServerDate(filterValuesExtended.dateLimit),
      groupBy: filterValuesExtended.groupBy.id,
      sort: filterValuesExtended.sort,
    };

    const previousStaticFields = {
      project: filterValuesExtendedRef.current.project?.id,
      dateStart: toServerDate(filterValuesExtendedRef.current.dateStart),
      dateLimit: toServerDate(filterValuesExtendedRef.current.dateLimit),
      groupBy: filterValuesExtendedRef.current.groupBy.id,
      sort: filterValuesExtendedRef.current.sort,
    };

    const selectsChangesCheck =
      (filterValuesExtended.workFlow.length === 0 &&
        filterValuesExtendedRef.current.workFlow.length) ||
      (filterValuesExtended.partGroup.length === 0 &&
        filterValuesExtendedRef.current.partGroup.length) ||
      (filterValuesExtended.group.length === 0 &&
        filterValuesExtendedRef.current.group.length) ||
      (filterValuesExtended.executor.length === 0 &&
        filterValuesExtendedRef.current.executor.length);

    const areSameValues = isDeepEqual(
      currentStaticFields,
      previousStaticFields
    );

    if ((!areSameValues || selectsChangesCheck) && !isValidating && isValid) {
      onSubmit(filterValuesExtended);
      filterValuesExtendedRef.current = filterValuesExtended;
    }
  }, [filterValues, userTypeId, sort, groupBy.id, isValidating, isValid]);

  // определние проекта на канбане по выбранному проекту на дашборде
  useEffect(() => {
    if (
      !selectOptions.projects.length ||
      filterPending.getProjects ||
      !projectId ||
      !isFirstlyOpen.current
    ) {
      return;
    }

    const project = selectOptions.projects.find(
      (project) => project.id === projectId
    );

    setValue("project", project ?? null);

    const filterValues = getFilterValues(userTypeId);
    saveFilterValuesInLocalStorage(userTypeId, { ...filterValues, project });

    isFirstlyOpen.current = false;
  }, [projectId, selectOptions, userTypeId, filterPending]);

  const handleClear = () => {
    // Для каждого поля кроме sort и groupBy сбрасываем значение
    Object.entries(initialFilterParams).forEach(([field, value]) => {
      setValue(field as keyof KanbanType.FilterValues, value);
    });
  };

  // Выгрузка списка проектов,
  // исполнителей и типов задач
  useEffect(() => {
    if (!userTypeId) {
      return;
    }

    dispatch(KanbanApi.fetchWorkFlowList());
    dispatch(KanbanApi.fetchProjectsByUserTypeId(userTypeId));

    return () => {
      dispatch(KanbanModel.clearFilterData());
    };
  }, [userTypeId]);

  // Выгрузка списка групп по специализации
  useEffect(() => {
    if (!firmId) {
      dispatch(KanbanModel.setFilterGroupsList([]));
      return;
    }

    dispatch(
      KanbanApi.fetchGroupsByFirmIdAndPartGroupId({
        firmId,
        partGroups: partGroupValue,
      })
    );
  }, [partGroupValue.length, firmId]);

  // выгрузка списка исполнителей
  useEffect(() => {
    if (!userTypeId) {
      return;
    }

    if (!projectValue?.id && !partGroupValue.length && !groupValue.length) {
      const {
        data: { surname, name, id },
      } = getParsedUserToken();

      const currentUser = {
        id,
        title: `${surname} ${name}`,
      };

      dispatch(KanbanModel.setFilterUsersList([currentUser]));
      return;
    }

    dispatch(
      KanbanApi.fetchUsersListByUserTypeId({
        userTypeId,
        projectId: projectValue?.id as number,
        partGroups: partGroupValue,
        groups: groupValue,
      })
    );
  }, [userTypeId, projectValue?.id, partGroupValue.length, groupValue.length]);

  return {
    control,
    setValue,
    filterPending,
    selectOptions,
    trigger,
    errors,
    handleClear,
    hasFirm: Boolean(firmId),
    datePlaceholders,
    submitKanbanFilter,
  } as const;
};
