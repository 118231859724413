import { createContext, useContext } from "react";
import { getCompoundComponentsError } from "../../../lib/helpers/getCompoundComponentsError";

type TModal = any; // типизировать

const CardContext = createContext<TModal | null>(null);

export function useCardContext() {
  const context = useContext(CardContext);
  if (!context) {
    const errorMessage = getCompoundComponentsError("Card");
    throw new Error(errorMessage);
  }
  return context;
}

export default CardContext;
