const PublishedObject = {
  CUSTOMER_ALIGN_START: "PublishedObject.CustomerAlignStart",
  EDIT_REQUEST_CUSTOMER_ALIGN: "PublishedObject.EditRequestCustomerAlign",
  REQUEST_CUSTOMER_ALIGN_START: "PublishedObject.RequestCustomerAlignStart",
  EXECUTE_COMPLETE: "PublishedObject.ExecuteComplete",
  REQUEST_REMOVE_EXECUTOR_COMPLETE:
    "PublishedObject.RequestRemoveExecutorComplete",
  REQUEST_REMOVE_EXECUTOR_START: "PublishedObject.RequestRemoveExecutorStart",
  CREATE_REQUEST: "PublishedObject.CreateRequest",
  UPDATE_REQUEST: "PublishedObject.UpdateRequest",
  REMOVE_REQUEST_EXECUTION: "PublishedObject.RemoveRequestExecution",
  FREEZE_FUNDS: "PublishedObject.FreezeFunds",
  UPDATE_PUBLISHED_OBJECT: "PublishedObject.UpdatePublishedObject",
  PUBLISH_OBJECT: "PublishedObject.PublishObject",
};

const ProjectProcess = {
  CREATE_OUT_TASK: "ProjectProcess.CreateOutTask",
  REQUEST_OUT_TASK: "ProjectProcess.RequestOutTask",
  OUTPUT_TASK: "ProjectProcess.OutputTask",
  CONCLUSION_CREATE: "ProjectProcess.ConclusionCreate",
  EXPERTISE_CONTRACT: "ProjectProcess.ExpertiseContract",
  CHECK_CUSTOMER_ALIGN: "ProjectProcess.CheckCustomerAlign",
  CHECK_EXECUTE_PROCESS: "ProjectAccess.CheckExecuteProcess",
  CHECK_SUB_CHECK_PROCESS: "ProjectProcess.CheckSubCheckProcess",
  CHECK_SUB_CHECK: "ProjectProcess.CheckSubCheck",
  CHECK_SUB_TASK: "ProjectProcess.CheckSubTask",
  SUB_TASK: "ProjectProcess.SubTask",
  EXPERTISE_REMARK: "ProjectProcess.ExpertiseRemark",
  COMMENT_REMARK_ROW: "ProjectProcess.CommentRemarkRow",
  REMARK_CREATE: "ProjectProcess.RemarkCreate",
  UPDATE_REMARK_ROW: "ProjectProcess.UpdateRemarkRow",
  ADD_SOURCE_FILES: "ProjectAccess.AddSourceFiles",
};

const ProcessWork = {
  CHECK_CUSTOMER_ALIGN: "ProcessWork.CheckCustomerAlign",
  CHECK_EXECUTE_WORK: "ProcessWork.CheckExecuteComplete",
};

const Task = {
  COMMENTS: "Task.Comments",
  FEEDBACK: "Task.Feedback",
};

const ProjectAccess = {
  UPDATE_ACCESS: "ProjectAccess.UpdateAccess",
};

const Project = {
  VIEW_EXPERTISE_INFO: "Project.ViewExpertiseInfo",
};

const SourceData = {
  SOURCE_DATA_UPDATE: "SourceData.SourceDataUpdate",
};

export const ModalNames = {
  PublishedObject,
  ProjectAccess,
  ProjectProcess,
  ProcessWork,
  Project,
  Task,
  SourceData,
};
