import {
  Card,
  List,
  Accordion,
  Modal,
  useAccordionState,
} from "src/FSD/shared/uiKit/v2";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { TaskCounter } from "./TaskCounter";
import { SubCheckTask } from "./SubCheckTask";
import { useCheckTasks } from "../lib/hooks/useCheckTasks";
import ProjectProcessModals from "../../../modals";
import TaskModals from "../../../../task/modals";

type Props = {
  projectProcessId: number;
};

export const CheckTasks = ({ projectProcessId }: Props) => {
  const { subCheckTasks, isTasksLoading } = useCheckTasks(projectProcessId);

  const { restData, name, handleCloseModal } = useModal();

  const { handleExpand, handleShrink, expandedIds } = useAccordionState();

  return (
    <>
      <Modal
        isActive={name === ModalNames.ProjectProcess.CHECK_SUB_CHECK}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.CheckSubCheckForm taskId={restData.taskId} />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProjectProcess.CHECK_SUB_CHECK_PROCESS}
        handleClose={handleCloseModal}
      >
        <ProjectProcessModals.CheckSubCheckProcessForm
          taskId={restData.taskId}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.Task.COMMENTS}
        handleClose={handleCloseModal}
      >
        <TaskModals.CommentsForm taskId={restData.taskId} />
      </Modal>
      <Card header={<Card.Header>Проверки</Card.Header>}></Card>
      {isTasksLoading ? (
        <List gap={15} margin={15}>
          <Skeleton.List />;
        </List>
      ) : (
        <List gap={0} margin={15} maxHeight="885px">
          <ListMapper>
            {subCheckTasks.map((group) => {
              const {
                dot,
                title,
                tasks,
                counts: { done, total },
              } = group;

              const dotValue = dot ? "warning" : null;

              return (
                <Accordion
                  dot={dotValue}
                  title={title}
                  titleAfter={<TaskCounter count={done} totalCount={total} />}
                >
                  {tasks.map((task) => (
                    <SubCheckTask key={task.id} {...task} />
                  ))}
                </Accordion>
              );
            })}
          </ListMapper>
        </List>
      )}
    </>
  );
};
