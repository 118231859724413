export enum BuildingFunctionTypes {
  SIMPLE_CAPITAL_CONSTRUCTION = 4,
  SIMPLE_LINEAR = 5,
  CAPITAL_CONSTRUCTION_963 = 6,
  LINEAR_963 = 7,
}

export const getBuildingFunctionType = (
  constructionType: "capital" | "linear",
  isSimple: boolean
) => {
  if (isSimple) {
    if (constructionType === "capital") {
      return BuildingFunctionTypes.SIMPLE_CAPITAL_CONSTRUCTION;
    }
    return BuildingFunctionTypes.SIMPLE_LINEAR;
  }

  if (constructionType === "capital") {
    return BuildingFunctionTypes.CAPITAL_CONSTRUCTION_963;
  }
  return BuildingFunctionTypes.LINEAR_963;
};
