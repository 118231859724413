import MenuItem from "@mui/material/MenuItem";
import { Card } from "src/FSD/shared/uiKit/v2/Card/Card";
import { ThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import cn from "classnames";
import { CheckLabel } from "src/FSD/shared/uiKit/v2/CheckIcon";
import { useModalAdditionalRequirementsUpdate } from "src/FSD/entities/publishedObject/lib/hooks/useModalAdditionalRequirements";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import styles from "./Requirements.module.scss";
import { useRequirements } from "../../lib/hooks/useRequirements";
import PublishedObjectModals from "../../../../publishedObject/modals";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const Requirements = ({ processWork }: Props) => {
  const { threeDotButtonRef, showOptions, closeThreeDot } =
    useRequirements(processWork);

  const {
    additionalRequirementsUpdate,
    handleOpenAdditionalRequirementsUpdate,
    handleCloseAdditionalRequirementsUpdate,
  } = useModalAdditionalRequirementsUpdate();

  // + будет модалка ProcessWork.AdditionalRequirements
  // создана модалка PublishedObjectModals.AdditionalRequirementsUpdateForm для задачи/раздела
  return (
    <>
      <Modal
        isActive={additionalRequirementsUpdate.isOpen}
        handleClose={handleCloseAdditionalRequirementsUpdate}
      >
        <PublishedObjectModals.AdditionalRequirementsUpdateForm
          id={processWork.id}
          header="Дополнительные требования к задаче"
          type={PublishedObjectControllingEntities.PROCESS_WORK}
        />
      </Modal>
      <Card
        header={<Card.Header>Требования к задаче</Card.Header>}
        controls={
          <Card.Controls location="top">
            {showOptions && (
              <ThreeDotButton ref={threeDotButtonRef} color="success">
                <MenuItem
                  onClick={() => {
                    closeThreeDot();
                    handleOpenAdditionalRequirementsUpdate();
                  }}
                  disableRipple
                >
                  Редактировать
                </MenuItem>
              </ThreeDotButton>
            )}
          </Card.Controls>
        }
      >
        {processWork.projectPart.published && (
          <div className={styles.checksList}>
            <div
              className={cn(
                styles.checksList__column,
                styles.checksList__column_short
              )}
            >
              <h3>Договор</h3>
              <CheckLabel
                isCheck={processWork.secure}
                text="Безопасная сделка"
                className={styles.checksList__check}
                fontSize={20}
              />
              <CheckLabel
                isCheck={processWork.with_docs}
                text="Заключение договора"
                className={styles.checksList__check}
                fontSize={20}
              />
            </div>
            <div className={styles.checksList__column}>
              <h3>Разработка</h3>
              <h3 />
              <CheckLabel
                isCheck={processWork.bim}
                text="BIM"
                className={styles.checksList__check}
                fontSize={20}
              />
              <CheckLabel
                isCheck={processWork.vor}
                text="Ведомость объемов работ"
                className={styles.checksList__check}
                fontSize={20}
              />
              <CheckLabel
                isCheck={processWork.kac}
                text="Конъюнктурный анализ цен"
                className={styles.checksList__check}
                fontSize={20}
              />
              <CheckLabel
                isCheck={processWork.vor_pnr}
                text="ВОР на пусконаладочные работы"
                className={styles.checksList__check}
                fontSize={20}
              />
            </div>
          </div>
        )}
        <hr className={styles.yellowLine} />
      </Card>
    </>
  );
};
