import { DragDropContext } from "react-beautiful-dnd";
import { KanbanModel } from "src/FSD/entities/kanban";
import styles from "./Kanban.module.scss";
import { useAppSelector } from "../../../../../app/store";
import { KanbanLoading } from "./KanbanLoading";
import { useKanbanDnD } from "../lib/hooks/useKanbanDnD/useKanbanDnD";
import { RowTasksList } from "./RowTasksList";
import { RowTasksHeader } from "./RowTasksHeader";
import { AllTasksList } from "./AllTasks";

const KanbanDesktop = () => {
  const isLoading = useAppSelector(KanbanModel.getIsViewPending);

  const { handleOnDragEnd } = useKanbanDnD();

  if (isLoading) {
    return <KanbanLoading />;
  }

  return (
    <div className={styles.columns}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <AllTasksList />
        <div className={styles.columns__rows}>
          <RowTasksHeader />
          <RowTasksList />
        </div>
      </DragDropContext>
    </div>
  );
};

export default KanbanDesktop;
