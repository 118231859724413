import { ReactNode, memo } from "react";

type Props = {
  children: ReactNode;
};

const ColumnTitle = memo(({ children }: Props) => {
  return <h2>{children}</h2>;
});

export default ColumnTitle;
