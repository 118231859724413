import React, { ReactNode } from "react";
import AccordionMui from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ChevronDown, CircleFill } from "react-bootstrap-icons";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import styles from "./ChildrenAccordion.module.scss";

type TProps = {
  title: string;
  className?: string;
  children: ReactNode;
  expanded?: boolean;
  dot?: boolean;
};

export const ChildAccordion = ({
  title,
  className,
  children,
  dot,
  expanded,
}: TProps) => {
  return (
    <AccordionMui
      expanded={expanded}
      classes={{
        root: classNames(styles.accordion, className),
        expanded: styles.accordion__expanded,
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton>
            <ChevronDown className={styles.iconCaret} />
          </IconButton>
        }
        classes={{
          root: styles.summaryRoot,
          content: styles.summaryContent,
          expanded: styles.summaryExpanded,
          expandIconWrapper: styles.summaryExpandIconWrapper,
        }}
      >
        {dot && <CircleFill className={styles.dot} />}
        <div className={styles.title}>{title}</div>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: styles.detailsRoot,
        }}
      >
        {children}
      </AccordionDetails>
    </AccordionMui>
  );
};
