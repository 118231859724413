import { Controller, UseFormReturn } from "react-hook-form";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { ReasonText } from "./ReasonText";
import { TFieldValues } from "../model/types";
import { useRefundForm } from "../lib/hooks/useRefundForm";

type Props = {
  id: number;
  type: PublishedObjectControllingEntities;
  form: UseFormReturn<TFieldValues, object>;
  onSubmit: (data: TFieldValues) => void;
  isRemovingExecutor: boolean;
};

export const RefundForm = ({
  id,
  form,
  type,
  onSubmit,
  isRemovingExecutor,
}: Props) => {
  const { refundValue, control, onPreSubmit, handleSubmit, handleGoBack } =
    useRefundForm({
      form,
      onSubmit,
    });

  return (
    <Modal.Form onSubmit={handleSubmit(onPreSubmit)}>
      <Paragraph>Необходим ли возврат выплаченного аванса?</Paragraph>
      <Controller
        name="refund"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            checked={value}
            editing
            onChange={() => {
              onChange(!value);
            }}
            label={"Да"}
          />
        )}
      />
      {refundValue && <ReasonText id={id} type={type} />}
      <Modal.Controls>
        <Button color="primary" variant="outlined" onClick={handleGoBack}>
          Назад
        </Button>
        <Button color="primary" type="submit" isLoading={isRemovingExecutor}>
          {refundValue ? <>Далее</> : <>Снять</>}
        </Button>
      </Modal.Controls>
    </Modal.Form>
  );
};
