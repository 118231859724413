import * as Yup from "yup";
import {
  filesAreRequired,
  stringRequired,
} from "../../../../../../../../utils/SimplifiedYup";

export const customerAlignStartSchema = Yup.object({
  name: stringRequired("Наименование"),
  uploadFiles: filesAreRequired(),
});
