import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import { config } from "src/app.cofig";
import CustomButton from "src/components/newUI/CustomButton/CustomButton";
import { IForSelect } from "src/app/feature/profileReducer/types";
import { Uploader } from "src/components/features/Uploader/Uploader";
import infoStyle from "src/components/pages/User/Edit/Info/style.module.scss";
import { Select } from "src/components/UI/components/FormComponentns/Select";
import { SetState } from "src/components/features/ProjectEditing/SectionEditing/SectionEditing";
import { UnsavedType } from "src/components/pages/Project/types";
import useNonInitialEffect from "src/components/hooks/useNonInitialEffect";
import { stringMaxLengthRequired } from "src/utils/SimplifiedYup";
import { palette, textFieldSX } from "src/styles/restyle";
import style from "./style.module.scss";
import { SubmitDataProps } from "../Portfolio";

type ComponentProps = {
  setUnsaved: SetState<UnsavedType>;
  buildingOptions: IForSelect[];
  portfolio?: any;
  onCreate?: (
    data: SubmitDataProps,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onUpdate?: (
    data: SubmitDataProps,
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  onDelete?: (
    id: number,
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
};

const schema = yup.object().shape({
  name: stringMaxLengthRequired("Название", 300),
});

const PortfolioItem: React.FC<ComponentProps> = ({
  setUnsaved,
  buildingOptions,
  portfolio,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    watch,
    formState: { errors, dirtyFields },
  } = useForm<SubmitDataProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      buildingFunction: portfolio
        ? {
            // @ts-ignore
            title: portfolio.buildingFunction.name,
            id: portfolio.buildingFunction.id,
          }
        : {
            title: "Общественные и административные здания",
            id: 1,
          },
    },
    mode: "all",
    reValidateMode: "onChange",
  });

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const imagesArray = watch("photo_upload");
  const buildingFunction = watch("buildingFunction");

  const dirtyForm = Object.keys(dirtyFields).length;

  useEffect(() => {
    setUnsaved((prev) => ({ ...prev, portfolio: Boolean(dirtyForm) }));
  }, [dirtyForm, setUnsaved]);

  useNonInitialEffect(() => {
    setUnsaved((prev) => ({
      ...prev,
      portfolio: portfolio?.photo_upload
        ? portfolio.photo_upload.length !== imagesArray.length ||
          portfolio.buildingFunction.id !== buildingFunction.id
        : Boolean(imagesArray.length),
    }));
  }, [imagesArray, buildingFunction, setUnsaved]);

  return (
    <div className={style.container}>
      <form
        onSubmit={handleSubmit(
          portfolio
            ? (data = getValues()) =>
                onUpdate!(data, portfolio.id, setIsFetching)
            : (data = getValues()) => onCreate!(data, setIsFetching)
        )}
      >
        <div className={style.formContent}>
          <div className={style.formChunk}>
            <div
              className={cn(style.imageContainer, {
                [style.noInput]: false,
              })}
            >
              <img
                className={style.image}
                src={
                  imagesArray?.length
                    ? URL.createObjectURL(imagesArray[0])
                    : portfolio?.photo
                      ? `${config.localDomain}${portfolio.photo}`
                      : "https://dev.tizh.ru/images/news_plug_main.png"
                }
                alt="Изображение"
              />
              <input
                {...register("photo_upload")}
                type="file"
                accept="image/*"
                id={`imageInput_${portfolio?.id}`}
                className={style.imageInput}
              />
              <label
                htmlFor={`imageInput_${portfolio?.id}`}
                className={style.imageLabel}
              >
                <span>{portfolio?.photo ? "Заменить" : "Выбрать"}</span>
              </label>
              {!!errors.photo_upload && (
                <span className={style.imageError}>
                  {errors.photo_upload.message}
                </span>
              )}
            </div>
          </div>
          <div className={cn(style.formChunk, style.long)}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Название"
              defaultValue={portfolio ? portfolio.name : ""}
              autoComplete="off"
              {...register("name")}
              {...textFieldSX}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : null}
            />
            <Select
              value={buildingFunction}
              changeHandler={(_, value) => {
                setValue("buildingFunction", value);
              }}
              options={buildingOptions}
              disableClear
              label={"Назначение"}
              size={"small"}
            />
          </div>
        </div>
        <div className={infoStyle.multiScroll}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            multiline
            minRows={6}
            maxRows={6}
            label="Описание"
            defaultValue={portfolio ? portfolio.description : ""}
            {...register("description")}
            {...textFieldSX}
          />
        </div>
        <div className={style.margin} />
        <Uploader
          defaultValue={portfolio ? portfolio.files : null}
          autoUpload={!!portfolio}
          identifier={portfolio ? portfolio.id : null}
          canBeEdited={true}
          postUrlString={(id) =>
            `${config.localDomain}/v1/portfolio/add-file?id=${id}`
          }
          fileHandler={(data: any) => {
            setValue("upload_files", data);
          }}
        />
        <div className={style.bottomContainer}>
          {portfolio ? (
            <CustomButton
              width={160}
              type="button"
              style={{ marginRight: "16px" }}
              background={palette.red}
              onClick={() => onDelete!(portfolio.id, setIsFetching)}
              disabled={isFetching}
            >
              Удалить
            </CustomButton>
          ) : null}
          <CustomButton
            width={160}
            type="submit"
            background={palette.blue}
            disabled={isFetching}
          >
            Сохранить
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default PortfolioItem;
