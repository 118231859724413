import React from "react";
import { Controller } from "react-hook-form";
import { Button, Modal, TextField, DateField } from "src/FSD/shared/uiKit/v2";
import { SelectReviewProcesses } from "src/FSD/features/v2/SelectReviewProcesses";
import { useRequestOutTask } from "../lib/hooks/useRequestOutTask";

type TProps = {
  processId: number;
};

export const RequestOutTaskForm = ({ processId }: TProps) => {
  const { onSubmit, control, setValue, errors, isLoading, currentDate } =
    useRequestOutTask({
      id: processId,
    });

  return (
    <Modal.Layout>
      <Modal.Header>Запрос задания</Modal.Header>
      <Modal.Form onSubmit={onSubmit}>
        <Controller
          name="processesIds"
          control={control}
          render={({ field: { onChange, name } }) => (
            <SelectReviewProcesses
              processId={processId}
              projectFieldName="projectId"
              processesFieldName={name}
              onProjectChange={(newValue) => {
                setValue("projectId", newValue);
              }}
              onProcessesChange={(newValue) => {
                onChange(newValue);
              }}
              errors={errors}
            />
          )}
        />
        <Controller
          control={control}
          name="dateLimit"
          render={({ field: { onChange } }) => (
            <DateField
              label="Контрольный срок"
              changeDateHandler={(date) => {
                onChange(date);
              }}
              minDate={currentDate}
              error={Boolean(errors.dateLimit)}
              helperText={errors.dateLimit?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="content"
          render={({ field: { onChange } }) => (
            <TextField
              multiline
              minRows={2}
              label="Комментарий"
              placeholder="Введите текст"
              error={Boolean(errors.content)}
              helperText={errors.content?.message}
              onChange={(value) => {
                onChange(value);
              }}
            />
          )}
        />
        <Modal.Controls>
          <Button disabled={isLoading} isLoading={isLoading} type="submit">
            Отправить
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
