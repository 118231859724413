type TUser = {
  surname: string;
  name: string;
  patronymic: string;
};

export const formInitialsUserName = (user: TUser) => {
  return [user?.surname, `${user?.name?.[0]}.`, `${user?.patronymic?.[0]}.`]
    .filter(Boolean)
    .join(" ");
};
