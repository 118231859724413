import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { ExecutorApplied } from "./ExecutorApplied";
import { ExecutorSearch } from "./ExecutorSearch";
import { ExecutorEmpty } from "./ExecutorEmpty";
import { ApiProcessWorkResponses } from "../../../../../../entities/processWork/model/responseTypes";

type Props = {
  processWork: ApiProcessWorkResponses.View;
};

export const Executor = ({ processWork }: Props) => {
  if (processWork.activeAccess?.user) {
    return <ExecutorApplied processWork={processWork} />;
  }

  if (processWork.status.key === Statuses.WORK) {
    return <ExecutorSearch processWork={processWork} />;
  }

  return <ExecutorEmpty />;
};
