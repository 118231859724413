import { Draft, PayloadAction } from "@reduxjs/toolkit";
import * as KanbanType from "../../types";
import { KanbanColumns, KanbanStatuses } from "../../model/constants";
import { IsDoneStatuses, WorkStatuses } from "../../model/columnStatuses";

const DONE = KanbanStatuses[KanbanColumns.DONE];

export const dropCheckedTask = (
  state: Draft<KanbanType.State>,
  action: PayloadAction<any>
) => {
  const { tasks, destinationColumnId, destinationRowId } = state.tasks.cache;

  const { id, isCheck, status } = action.payload;

  const taskStatus = status?.key || status;

  const destinationRowTasks = state.tasks.rowTasks[destinationRowId!];

  destinationRowTasks.columns = tasks;

  // добавляем актуальный isCheck
  destinationRowTasks.columns[destinationColumnId!] =
    destinationRowTasks.columns[destinationColumnId!].map((task) =>
      task.id === id ? { ...task, isCheck } : task
    );

  // если статусы не подходят для колонки "Выполнено"
  if (
    WorkStatuses.includes(taskStatus) ||
    IsDoneStatuses.includes(taskStatus)
  ) {
    // убираем таск из колонки "Выполнено"
    const taskIndex = destinationRowTasks.columns[DONE].findIndex(
      (task) => task.id !== id
    );

    const [task] = destinationRowTasks.columns[DONE].splice(taskIndex, 1);

    // и перемещаем в колонку согласно статусу задачи
    destinationRowTasks.columns[taskStatus].unshift(task!);
  }
};
