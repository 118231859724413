/**
 * Функция для склонения слова "день" в зависимости от числительного.
 * @param number - Число.
 * @returns Число и правильная форма слова "день".
 */
export function declensionOfDay(number: number) {
  const titles = ["день", "дня", "дней"];
  const cases = [2, 0, 1, 1, 1, 2];
  return `${
    titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ]
  }`;
}
