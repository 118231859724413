import { useState } from "react";
import { useSelector } from "react-redux";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { useTabsPanelContext } from "../../../../TabsPanel/ui/TabsPanel/TabsPanelContext";
import {
  getProjectProcessBadApplications,
  getProjectProcessGoodApplications,
} from "../../../../../../../app/feature/ProjectProcessView/Applications/selectors/getProjectProcessApplications";
import { useProcessWorkApplicationQuery } from "../../../../../../entities/processWork/api";

type TUseProps = {
  processWork: ApiProcessWorkResponses.View;
};

export const useExecutorSearch = ({ processWork }: TUseProps) => {
  const { setCurrentTab } = useTabsPanelContext();

  const { data: applications, isFetching: isFetchingApplications } =
    useProcessWorkApplicationQuery({
      id: processWork.id,
    });

  const [modalRequest, setModalRequest] = useState(false);

  const countApplications =
    (applications?.good?.length || 0) + (applications?.bad?.length || 0);

  const handleNavigation = () => {
    setCurrentTab("applications");
  };

  const executorTypes = processWork.executorType.map((item) => item.value);
  const nameProjectProcess = `«${processWork.projectPart.name}${processWork.projectPart.code ? ` (${processWork.projectPart.code})` : ""}»`;

  const requestExecutionHeader = `Заявка на исполнение задачи «${processWork.title}» по разделу ${nameProjectProcess}`;

  return {
    modalRequest,
    setModalRequest,
    countApplications,
    handleNavigation,
    executorTypes,
    requestExecutionHeader,
    isFetchingApplications,
  } as const;
};
