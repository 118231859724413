import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { ComponentPropsType } from "../../../types/other/components/componentPropsType";
import { request } from "../../../app/services/api/requestHandler";
import { SelectGrouped } from "../../UI/components/FormComponentns/SelectGrouped/SelectGrouped";
import { ApiGetBuildingFunction } from "../../../app/services/api/buildingFunction/buildingFunction";
import style from "./style.module.scss";
import { BuildingFunctionTypes as BuildingFunctionTypesEnum } from "../../pages/Project/lib/helpers/getBuildingFunctionType";

export type BuildingFunctionTypeItem = {
  title: string;
  id: string;
  type: number;
};

export type BuildingFunctionTypeProps = ComponentPropsType<{
  changeHandler?: (type: BuildingFunctionTypeItem) => void;
  value?: number | string;
  isClearable?: boolean;
  paperOptions?: { [key: string]: any };
  disabled?: boolean;
  buildingFunctionType: BuildingFunctionTypesEnum;
  isRequired?: boolean;
  error?: boolean;
}>;

export const BuildingFunctionTypes: React.FC<BuildingFunctionTypeProps> = ({
  variant = "",
  changeHandler = () => {},
  value,
  paperOptions,
  disabled,
  buildingFunctionType,
  isRequired,
  error,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [allTypes, setAllTypes] = useState<BuildingFunctionTypeItem[]>([]);
  const [selectedValue, setSelectValue] = useState<
    BuildingFunctionTypeItem | undefined
  >(undefined);

  // console.log("BuildingFunctionTypes", allTypes);
  // console.log("selectedValue", selectedValue);

  const onLoadTypes = () => {
    if (!buildingFunctionType) {
      return;
    }

    request(
      ApiGetBuildingFunction(buildingFunctionType),
      (data) => {
        const types: any = data.map((item: { id: number; name: string }) => ({
          title: item.name,
          id: item.id,
        }));

        setAllTypes(types);
        if (types.length) {
          const selectedValue =
            types.find((type: BuildingFunctionTypeItem) => type.id === value) ||
            types[0];

          setSelectValue(selectedValue);
          changeHandler(selectedValue);
        }
      },
      () => () => {
        enqueueSnackbar("Ошибка запроса типов строительства", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    )();
  };

  const onSearch = (data: string) => {
    const result = allTypes.find((item) => item.title === data);
    setSelectValue(result || undefined);
  };

  useEffect(() => {
    onLoadTypes();
  }, []);

  useEffect(() => {
    const result = allTypes.find((item) => item.id === value);
    setSelectValue(result || undefined);
  }, [value, allTypes]);

  return (
    <>
      <SelectGrouped
        changeHandler={(event, value) => {
          changeHandler(value);
        }}
        key={selectedValue ? selectedValue.title : "noneSpec"}
        value={selectedValue}
        searchHandler={onSearch}
        options={allTypes}
        label={"Назначение объекта"}
        variant={classNames(variant)}
        disableClear={true}
        fullWidth={true}
        paperOptions={paperOptions}
        disabled={disabled}
        error={error}
      />
      {isRequired && error && (
        <p className={style.error}>Необходимо заполнить "Назначение объекта"</p>
      )}
    </>
  );
};
