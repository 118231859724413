import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  Button,
  Checkbox,
  DateField,
  Modal,
  Uploader,
  Select,
} from "src/FSD/shared/uiKit/v2";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getExpertiseProjectList,
  getExpertiseProjectListIsLoad,
} from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseProjectList";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoProjectId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { fetchExpertiseProjectList } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseProjectList";
import { fetchExpertiseRemarkCreate } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarkCreate";
import { fetchExpertiseRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { remarkCreateSchemas } from "src/FSD/widgets/v2/projectProcess/modals/RemarkCreateForm/lib/schemas/remarkCreate";
import { getExpertiseRemarkCreateIsLoad } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarks";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { objectToSelect } from "src/app/feature/project/projectProcessPublication";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { Nullable } from "src/types/generics";
import { TFile } from "src/FSD/shared/uiKit/v2/Uploader";
import cls from "./RemarkCreateForm.module.scss";

type TInputs = {
  project_id: TSelectItem;
  date_create: Nullable<Date>;
  parseSpreadsheet: boolean;
  files: TFile[];
};

export const RemarkCreateForm = () => {
  const dispatch = useAppDispatch();
  const isLoadCreateRemark = useAppSelector(getExpertiseRemarkCreateIsLoad);
  const projectList = useAppSelector(getExpertiseProjectList);
  const isLoadProjectList = useAppSelector(getExpertiseProjectListIsLoad);
  const projectId = useAppSelector(getProjectProcessInfoProjectId);
  const processId = useAppSelector(getProjectProcessInfoId);

  const { handleClose } = useModalContext();

  useEffect(() => {
    if (processId) {
      dispatch(fetchExpertiseProjectList(processId));
    }
  }, []);

  const projectOptions = useDataMapper(projectList, objectToSelect);
  const targetObject = projectOptions?.find(
    (item: TSelectItem) => item.id === projectId
  );

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TInputs>({
    resolver: yupResolver(remarkCreateSchemas),
  });

  const checkImport = watch("parseSpreadsheet");
  useEffect(() => {
    if (processId && checkImport) {
      dispatch(fetchExpertiseProjectList(processId));
      setValue("project_id", targetObject);
    }
  }, [processId, checkImport]);

  const formSubmitHandler = async (dataValues: TInputs) => {
    console.log("_dataValues", dataValues);
    const formData = new FormData();
    formData.append("projectId", String(dataValues.project_id?.id));
    formData.append(
      "date_create",
      String(toServerDate(dataValues.date_create))
    );
    formData.append(
      "parseSpreadsheet",
      dataValues.parseSpreadsheet ? "1" : "0"
    );
    dataValues.files.forEach(({ file }) => {
      formData.append("upload_files[]", file!);
    });

    if (processId) {
      await dispatch(fetchExpertiseRemarkCreate({ processId, formData }));
      dispatch(fetchExpertiseRemarks(processId));
    }
    handleClose();
  };

  return (
    <Modal.Layout>
      <Modal.Header>Замечания</Modal.Header>
      {isLoadCreateRemark ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(formSubmitHandler)}>
          <Controller
            control={control}
            name="parseSpreadsheet"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label="Импорт вопросов из таблицы (.xlsx, .xls, .ods, .csv)"
                onChange={onChange}
                checked={value}
              />
            )}
          />
          {checkImport && (
            <div className={cls.form_alert}>
              <p>
                Импортируемый документ должен иметь следующий вид: в первой
                колонке нумерация строк должна быть в числовом формате, во
                второй колонке - текст в текстовом формате, в третьей колонке
                дата должна иметь вид ДД.MM.ГГ или ДД.ММ.ГГГГ и иметь формат
                текстовый или даты.
              </p>
              <div className={cls.form_alert_grid}>
                <div className={cls.form_alert_grid_item}>№ п/п</div>
                <div className={cls.form_alert_grid_item}>Замечание</div>
                <div className={cls.form_alert_grid_item}>Срок</div>
              </div>
            </div>
          )}
          <Controller
            name="date_create"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateField
                label="Контрольный срок"
                changeDateHandler={onChange}
                startDateProp={value}
                error={!!errors.date_create}
              />
            )}
          />
          {checkImport && (
            <Controller
              name="project_id"
              control={control}
              render={({ field: { value, onChange, name } }) => (
                <Select
                  label="Объект"
                  value={value}
                  disabled={isLoadProjectList}
                  options={projectOptions}
                  changeHandler={(_, newValue) => {
                    console.log("_value", value);
                    onChange(newValue);
                  }}
                  error={Boolean(errors?.[name])}
                  helperText={errors?.[name]?.message}
                />
              )}
            />
          )}
          <Controller
            name="files"
            control={control}
            render={({ field: { onChange, name } }) => (
              <Uploader
                setFiles={(files) => {
                  onChange(files);
                }}
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
              />
            )}
          />
          <Modal.Controls>
            <Button type="submit">Создать</Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
