import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = RequestParams<{
  projectId: number;
  parentId: number;
  type: number;
  data: {
    critical: boolean;
    executor: boolean;
    hidden: boolean;
    id: number;
    select: boolean;
  }[];
}>;

export const update = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ projectId, parentId, type, data }) => ({
      url: `v1/project-source-data/update`,
      params: {
        projectId,
        parentChecklistId: parentId,
        type,
      },
      method: HTTPMethods.PUT,
      data,
    }),
    invalidatesTags: [ApiTags.ProjectSourceData.INDEX_QUERY],
  });
