import * as Yup from "yup";
import {
  dateRequired,
  filesAreRequired,
  stringRequired,
} from "src/utils/SimplifiedYup";

export const conclusionCreateSchemas = Yup.object().shape({
  num: stringRequired("Номер"),
  name: stringRequired("Наименование"),
  date: dateRequired("Дата"),
  dateStart: dateRequired("Дата начала"),
  dateEnd: dateRequired("Дата окончания"),
  fileUpload: filesAreRequired(),
});
