import { useEffect } from "react";
import { useProjectAccessRequestUpdateMutation } from "src/FSD/entities/projectAccess/api";
import { useTaskViewQuery } from "src/FSD/entities/task/api";
import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";
import { RequestForm } from "./Form";
import { useRequest } from "../lib/hooks/useRequest";
import { Props as CreateRequestFormProps } from "./CreateRequestForm";

type Props = CreateRequestFormProps & {
  taskId: number;
};

export const UpdateRequestForm = ({ id, type, header, taskId }: Props) => {
  const [submitQuery] = useProjectAccessRequestUpdateMutation();

  const {
    form,
    onSubmit,
    entityTitle,
    warningMessages,
    isFetching: isPublishedObjectFetching,
    typeMissingAttributes,
    showBimWarning,
    showUserTypeWarning,
    showBankAccountField,
  } = useRequest({
    id,
    type,
    taskId,
    submitQuery,
  });

  const { data: taskData, isFetching: isTaskFetching } = useTaskViewQuery({
    id: taskId,
  });

  useEffect(() => {
    if (!taskData) {
      return;
    }

    form.setValue("comment", taskData.content);

    if (taskData.workflow_data?.date_start) {
      form.setValue(
        "dateStart",
        timestampToDate(taskData.workflow_data.date_start)
      );
    }

    if (taskData.workflow_data.date_limit) {
      form.setValue(
        "dateLimit",
        timestampToDate(taskData.workflow_data?.date_limit)
      );
    }

    if (taskData.workflow_data.price) {
      form.setValue("price", Number(taskData.workflow_data.price));
    }
  }, [taskData]);

  const isTaskViewFetching = isTaskFetching || !taskData;

  return (
    <RequestForm
      taskData={taskData}
      header={header}
      form={form}
      onSubmit={onSubmit}
      isFetching={isPublishedObjectFetching || isTaskViewFetching}
      entityTitle={entityTitle}
      warningMessages={warningMessages}
      typeMissingAttributes={typeMissingAttributes}
      showBimWarning={showBimWarning}
      showUserTypeWarning={showUserTypeWarning}
      showBankAccountField={showBankAccountField}
    />
  );
};
