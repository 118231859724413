import { daysBetweenDates } from "src/FSD/shared/lib/helpers/daysBetweenDates";
import { calculateOverdue } from "src/FSD/shared/lib/helpers/calculateOverdue";
import { declensionOfDay } from "src/FSD/shared/lib/helpers";
import { calculateDays } from "../helpers/calculateDays";
import { TPropsObject } from "./useObjects";

export const useProgressDates = ({ object }: TPropsObject) => {
  const { start, end, endFact, percent } = object.dates || {};

  const percentDates = Math.min(100, percent);

  const overdueDays = calculateOverdue(end);
  const textDays = declensionOfDay(overdueDays);

  const titleAdditionDates =
    percent > 100
      ? `(просрочено на ${overdueDays} ${textDays}!)`
      : "(в пределах плана)";

  const backgroundProgressDate = percent > 100 ? "red" : "green";

  const formatStart = "DD.MM.YYYY";

  const nowToStartDays = calculateDays(start, endFact, formatStart);

  const diffEndToStartDays = daysBetweenDates(start, end, formatStart);
  const sumOverDays = diffEndToStartDays + overdueDays;

  const resultNowToStartDays = nowToStartDays >= 0 ? nowToStartDays : "-";
  const resultDiffEndToStartDays = end ? diffEndToStartDays : "-";

  const lowerLabelDates = `${percent > 100 ? sumOverDays : resultNowToStartDays}/${resultDiffEndToStartDays} дней`;

  return {
    percentDates,
    titleAdditionDates,
    backgroundProgressDate,
    lowerLabelDates,
  } as const;
};
