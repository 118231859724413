import {
  KanbanApi,
  KanbanColumns,
  KanbanModel,
  KanbanStatuses,
} from "../../../../../../entities/kanban";
import { getTaskRowId } from "../../helpers/getTaskRowId";
import { ColumnShift } from "./useKanbanDnD";

export const allTasksToInWork = ({
  dispatch,
  sourceColumnId,
  destinationColumnId,
  destinationRowId,
  allTasks,
  rowTasks,
  result,
  groupBy,
}: ColumnShift) => {
  // из "Все задачи" в "В работе"
  if (
    sourceColumnId === KanbanStatuses[KanbanColumns.ALL_TASKS] &&
    destinationColumnId === KanbanStatuses[KanbanColumns.IN_WORK]
  ) {
    // получаем задачи из "Все задачи" и "В работе"
    const sourceColumnTasks = [...allTasks];
    const destinationColumnTasks = [
      ...rowTasks[destinationRowId].columns[destinationColumnId],
    ];

    // удаляем задачу из колонки-истока "Все задачи", добавляем в колонку-сток "В работе"
    const [reorderedTask] = sourceColumnTasks.splice(result.source.index, 1);
    destinationColumnTasks.splice(result.destination!.index, 0, reorderedTask);

    // обновляем колонки для группы
    const updatedTasks = {
      ...rowTasks[destinationRowId].columns,
      [destinationColumnId]: destinationColumnTasks,
    };

    // записываем обновленную колонку "Все задачи" в state
    dispatch(KanbanModel.setAllTasks(sourceColumnTasks));

    const taskRowId = getTaskRowId(reorderedTask, destinationRowId, groupBy);

    // отправляем запрос на обновление задачи
    dispatch(
      KanbanApi.updateTask({
        taskId: reorderedTask.id,
        status: destinationColumnId as number, // id конечной колонки - это новый статус задачи
      })
    );

    // если закинул не в ту группу, то перекидываем в нужную
    if (taskRowId !== destinationRowId) {
      // тут есть доп. проверка в случае если данной группы еще нет
      dispatch(
        KanbanModel.dropToRowTasks({
          rowId: taskRowId,
          columnId: destinationColumnId,
          task: reorderedTask,
        })
      );

      // скроллим до нужной группы
      setTimeout(() => {
        document
          .querySelector(`#task-anchor-${reorderedTask.id}`)!
          .scrollIntoView({
            behavior: "smooth",
          });
      });

      return;
    }

    dispatch(
      KanbanModel.setRowTasks({ rowId: destinationRowId, updatedTasks })
    );
  }
};
