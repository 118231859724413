import {
  projectProcessApi,
  useProjectProcessDepositFundsMutation,
} from "src/FSD/entities/projectProcess/api";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import {
  processWorkApi,
  useProcessWorkDepositFundsMutation,
} from "src/FSD/entities/processWork/api";

type UseModalProps = {
  id: number;
  type: PublishedObjectControllingEntities;
};

export const useDepositFunds = ({ id, type }: UseModalProps) => {
  const useDepositFunds = {
    ProcessWork: useProcessWorkDepositFundsMutation,
    ProjectProcess: useProjectProcessDepositFundsMutation,
  };

  const useLazyCanCheck = {
    ProcessWork: processWorkApi.useLazyProcessWorkCanCheckQuery,
    ProjectProcess: projectProcessApi.useLazyProjectProcessCanCheckQuery,
  };

  const [depositFunds] = useDepositFunds[type]();
  const [canCheck] = useLazyCanCheck[type]();

  const transferDepositFunds = async () => {
    await depositFunds({ id });
    await canCheck({ id });
  };

  return {
    transferDepositFunds,
  } as const;
};
