import * as Yup from "yup";
import {
  stringRequired,
  dateRequired,
  notNullRequired,
} from "src/utils/SimplifiedYup";

export const createOutTaskSchemas = Yup.object().shape({
  text: stringRequired("Текст замечания"),
  date_limit: dateRequired("Контрольный срок"),
  project_id: notNullRequired("Объект"),
});
