import React from "react";
import classNames from "classnames";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import classes from "./InfoDesign.module.scss";
import { ProjectViewData } from "../../../../../../app/feature/ProjectView/createData/createData";

type InfoDesignProps = {
  project: ProjectViewData | null;
};

export const InfoDesign: React.FC<InfoDesignProps> = ({ project }) => {
  if (project) {
    const onPause = project.statusKey === Statuses.PAUSED;

    return (
      <div
        className={classNames(
          classes.project,
          classes.basic,
          onPause && classes.pause
        )}
      >
        <h1 className={classes.project_title}>Проектирование</h1>
        <div className={classes.project_divP}>
          <p className={classes.subtitle}>Сроки</p>
          {project.dateStart && (
            <h3>{`${project.dateStart} - ${
              project.dateEnd || "Не указано"
            }`}</h3>
          )}
        </div>
        <p className={classes.subtitle}>Экспертиза</p>
        <div className={classes.expertiseList}>
          {project.expertiseType.length ? (
            project.expertiseType.map((expertise) => <h3>{expertise.title}</h3>)
          ) : (
            <h3>Не требуется</h3>
          )}
        </div>
        <div className={classes.design_type}>{project.designType}</div>
      </div>
    );
  }
  return <div></div>;
};
