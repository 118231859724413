import { useState } from "react";

export const useSourceDataNameEdit = () => {
  const [edit, setEdit] = useState(false);

  const toggleEdit = () => {
    setEdit((prevState) => !prevState);
  };

  return { edit, toggleEdit } as const;
};
