import { Nullable } from "../../../../types/generics";

export type TModalsState = {
  requestUpdate: {
    isOpen: boolean;
    id: Nullable<number>;
    header: string;
    executorTypes: string[];
    bim: boolean;
    withDocs: boolean;
    dateStartCustomer: Nullable<string>;
    dateLimitCustomer: Nullable<string>;
    priceCustomer: Nullable<number>;
  };
  removeRequestExecution: {
    isOpen: boolean;
    taskId: Nullable<number>;
    header: string;
  };
  feedbackTask: {
    isOpen: boolean;
    taskId: Nullable<number>;
    header: string;
    isExecutor: boolean;
  };
  executorUpdate: {
    isOpen: boolean;
    projectId: Nullable<number>;
  };
  additionalRequirementsUpdate: {
    isOpen: boolean;
  };
};

export const modalsInitialState: TModalsState = {
  requestUpdate: {
    isOpen: false,
    id: null,
    header: "",
    executorTypes: [],
    bim: false,
    withDocs: false,
    dateStartCustomer: null,
    dateLimitCustomer: null,
    priceCustomer: null,
  },
  removeRequestExecution: {
    isOpen: false,
    taskId: null,
    header: "",
  },
  feedbackTask: {
    isOpen: false,
    header: "",
    taskId: null,
    isExecutor: false,
  },
  executorUpdate: {
    isOpen: false,
    projectId: null,
  },
  additionalRequirementsUpdate: {
    isOpen: false,
  },
};
