import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TaskApi } from "src/FSD/shared/api/v1/task";
import {
  useTaskCompleteMutation,
  useTaskViewQuery,
} from "src/FSD/entities/task/api";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { useAppDispatch } from "../../../../../../../../app/store";
import {
  pushError,
  pushSuccessMessage,
} from "../../../../../../../../app/feature/errorTrace";
import { Nullable } from "../../../../../../../../types/generics";
import { checkExecuteCompleteSchema } from "../schemas/checkExecuteComplete";

type TFieldValues = {
  comment: string;
  status: Nullable<Statuses>;
};

type UseModalProps = {
  taskId: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
};

export const useCheckExecuteCompleteObjectForm = ({
  taskId,
  defaultValues,
  handleClose,
}: UseModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(checkExecuteCompleteSchema),
  });

  const dispatch = useAppDispatch();

  const { data: taskData, isFetching } = useTaskViewQuery({
    id: taskId,
    expand: ["taskProcess.task", "taskWork.task"],
  });

  const [taskComplete] = useTaskCompleteMutation();

  const status = watch("status");

  useEffect(() => {
    if (!taskData) {
      return;
    }

    setValue("comment", taskData!.taskProcess!.task!.content);
  }, [taskData]);

  const taskDataObject = {
    ProcessWork: taskData?.taskWork,
    ProjectProcess: taskData?.taskProcess,
  };

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      CompleteForm: {
        comment: data.comment,
        status: data.status!,
      },
    };

    const result = await taskComplete({ id: taskId, task: formedData });

    // @ts-ignore
    if (!result?.error) {
      handleClose();
      dispatch(pushSuccessMessage());
    }
  };

  const isRejecting = isSubmitting && status === Statuses.REJECT;
  const isAccepting = isSubmitting && status === Statuses.ACCEPT;

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    isRejecting,
    isAccepting,
    isTaskViewPending: isFetching || !taskData,
    taskDataObject,
  } as const;
};
