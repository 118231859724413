import React from "react";
import { Controller } from "react-hook-form";
import {
  Button,
  DateField,
  Modal,
  TextField,
  Uploader,
} from "src/FSD/shared/uiKit/v2";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";

import { useConclusionCreate } from "../lib/hooks/useConclusionCreate";
import styles from "./ConclusionCreateForm.module.scss";

type TProps = {
  processId: number;
};

export const ConclusionCreateForm = ({ processId }: TProps) => {
  const { onSubmit, control, errors, isLoading } = useConclusionCreate({
    processId,
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>Заключение</Modal.Header>
      <Modal.Form onSubmit={onSubmit}>
        <div className={styles.field}>
          <Controller
            control={control}
            name="num"
            render={({ field: { onChange, name } }) => (
              <TextField
                label="Номер"
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
                onChange={(value) => {
                  onChange(value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, name } }) => (
              <DateField
                label="Дата"
                changeDateHandler={(date) => {
                  onChange(date);
                }}
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
              />
            )}
          />
          <div className={styles.checkbox}>
            <Controller
              control={control}
              name="fail"
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  editing
                  checked={!!value}
                  label="Получено отрицательное заключение экспертизы"
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <Controller
          name="fileUpload"
          control={control}
          render={({ field: { onChange, name } }) => (
            <Uploader
              setFiles={(files) => {
                onChange(files);
              }}
              error={Boolean(errors?.[name])}
              helperText={errors?.[name]?.message}
            />
          )}
        />

        <Modal.Controls>
          <Button disabled={isLoading} isLoading={isLoading} type="submit">
            Сохранить
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
