import { helpersYears } from "src/utils/helpersYears";
import { StarFill } from "react-bootstrap-icons";
import styles from "./Rating.module.scss";

type Props = {
  rating: number;
  jobDuration?: number;
};

export const RatingStarExperience = ({ rating, jobDuration }: Props) => {
  const formattedRating = rating ? Number(rating).toFixed(1) : "0";

  const formattedJobDuration = helpersYears(jobDuration);

  return (
    <div className={styles.skills}>
      <div className={styles.star}>
        <StarFill />
        <p>{formattedRating}</p>
      </div>
      {jobDuration !== undefined && <p>Опыт {formattedJobDuration}</p>}
    </div>
  );
};
