import { WarningMessages } from "../constants/warnings";

export namespace publishedObjectMessages {
  export const getWarningMessageApplication = (
    showBimWarning: boolean,
    showUserTypeWarning: boolean
  ) => {
    let message;
    if (showBimWarning && !showUserTypeWarning) {
      message = "работы с BIM";
    } else if (showUserTypeWarning && !showBimWarning) {
      message = "формы собственности";
    } else {
      message = "формы собственности и работы с BIM";
    }

    const textWarning = `Ваша заявка не соответствует условиям ${message}. ${WarningMessages.MODAL_REQUEST_APPLICATION}`;

    return textWarning;
  };
}
