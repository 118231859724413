import { request } from "src/app/services/api/requestHandler";
import { config } from "../configs/app.config";
import { httpRequestGet } from "../services/api/httpRequests";

const ApiGetFiles = (
  entity: string,
  id: number | string,
  includeCan = true
): Promise<Response> => {
  const expandParams = `files.svgIcon${includeCan ? ",can" : ""}`;
  return httpRequestGet(
    `${config.localDomain}/v1/${entity}/view?id=${id}&fields=files&expand=${expandParams}`
  );
};

export const getFiles = async (
  entity: string,
  id: number | string,
  includeCan?: boolean
) => {
  let response: any = [];

  await request(ApiGetFiles(entity, id, includeCan), (data) => {
    response = data;
  })();

  return response.files;
};
