import { CheckCircleFill, ClockFill, XCircleFill } from "react-bootstrap-icons";
import { palette } from "../../../../../styles/restyle";

const icons = {
  work: <ClockFill color={palette.white} />,
  cross: <XCircleFill color={palette.bordeaux} />,
  done: <CheckCircleFill color={palette.persianGreen} />,
  expired: <ClockFill color={palette.bordeaux} />,
};

export type TaskIconStatus = keyof typeof icons;

type Props = {
  status: TaskIconStatus;
};

export const TaskStatus = ({ status }: Props) => {
  return icons[status] || null;
};
