import { TDot } from "src/FSD/shared/lib/types/dot";

export const dotColors = (dot?: TDot) => {
  switch (dot) {
    case true:
    case "warning":
      return "#EEA527";
    case "error":
      return "#CE0000";
    default:
      return "";
  }
};
