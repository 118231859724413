import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { useCanCheckObject } from "../lib/hooks/useCanCheckObject";
import { CheckExecuteCompleteObjectForm } from "./CheckExecuteComleteObjectForm/CheckExecuteCompleteObjectForm";
import { DepositFunds } from "./DepositFunds/DepositFunds";

type TProps = {
  taskId: number;
  id: number;
  header: string;
  type: PublishedObjectControllingEntities;
};

export const CheckExecuteCompleteObject = ({
  taskId,
  id,
  header,
  type,
}: TProps) => {
  const { isCanCheckPending, canCheckData } = useCanCheckObject({
    id,
    type,
  });

  const contentModal =
    canCheckData === true ? (
      <CheckExecuteCompleteObjectForm taskId={taskId} type={type} />
    ) : (
      <DepositFunds canCheckData={canCheckData!} id={id} type={type} />
    );

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      {isCanCheckPending ? <Modal.Preloader /> : contentModal}
    </Modal.Layout>
  );
};
