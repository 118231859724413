import * as Yup from "yup";
import { stringRequired, dateFutureRequired } from "src/utils/SimplifiedYup";

export const requestOutTaskSchemas = Yup.object().shape({
  projectId: stringRequired("Проект"),
  processesIds: Yup.array().required(
    "Необходимо заполнить «Проверяющие разделы»"
  ),
  dateLimit: dateFutureRequired("Контрольный срок"),
});
