import * as Yup from "yup";
import {
  dateCurrentOrFutureRequired,
  notNullRequired,
  stringMaxLengthRequired,
} from "src/utils/SimplifiedYup";

export const createFreeTaskSchema = Yup.object().shape({
  content: stringMaxLengthRequired("Название задачи", 500),
  project: notNullRequired("Проект"),
  partGroup: notNullRequired("Специализация"),
  executor: notNullRequired("Исполнитель"),
  dateDeadline: dateCurrentOrFutureRequired("Контрольный срок"),
});
