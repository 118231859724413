import { ReactNode } from "react";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph/Paragraph";
import { ApiCommonResponses } from "src/FSD/entities/apiCommonResponses";
import styles from "./RequestForm.module.scss";
import { formMissingAttributes } from "../lib/helpers/formMissingAttributes";

type TProps = {
  missingAttributes: ApiCommonResponses.SecureMissingAttributes[];
  children: ReactNode;
};

export const MissingAttributesError = ({
  missingAttributes = [],
  children,
}: TProps) => {
  return (
    <div className={styles.missingAttributes}>
      <Paragraph color="error">{children}</Paragraph>
      <ul className={styles.missingAttributes__ul}>
        {missingAttributes.map((item, index) => {
          const attributesBlock = formMissingAttributes(item);

          return (
            <li key={index} className={styles.missingAttributes__li}>
              {attributesBlock}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
