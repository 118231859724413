import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import {
  RefType,
  ThreeDotButton,
} from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import PublishedObjectModals from "src/FSD/widgets/v2/publishedObject/modals";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { Modal as NewModal } from "src/FSD/shared/uiKit/v2";
import styles from "../Sections.module.scss";
import { config } from "../../../../../../app.cofig";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import Modal from "../../../../../newUI/Modal/Modal";
import {
  fetchUnPublishProcess,
  TValuesData,
} from "../../../../../../app/feature/ProjectView/SectionsReducer/thunks";
import { ModalBackToWork } from "../ModalBackToWork/ModalBackToWork";
import {
  setTouched,
  setOpen as setOpenGroupProcessPublishing,
} from "../../../../../../app/feature/project/projectProcessPublication";
import { setOpen as setOpenGroupProcessRequest } from "../../../../../../app/feature/project/projectProcessRequest";
import { getProjectViewData } from "../../../../../../app/feature/ProjectView/projectView";
import { pushSuccessMessage } from "../../../../../../app/feature/errorTrace";

interface ThreeDotButtonsProps {
  node: TValuesData;
  fetchGetProjectProcessTree: () => Promise<void>;
}

export const ThreeDotButtons = ({
  node,
  fetchGetProjectProcessTree,
}: ThreeDotButtonsProps) => {
  const dispatch = useDispatch();
  const isTablet = useIsMobile(1600);
  const { project } = useSelector(getProjectViewData);
  const threeDotButtonRef = useRef<RefType>(null);
  const [publishModal, setPublishModal] = useState(false);
  const [backToWorkModal, setBackToWorkModal] = useState(false);
  const [updatePublishModal, setUpdatePublishModal] = useState(false);

  const closeThreeDot = () => {
    if (threeDotButtonRef.current) {
      threeDotButtonRef.current.handleClose();
    }
  };

  const memoCloseModal = useCallback(() => {
    setPublishModal(false);
    setUpdatePublishModal(false);
    setBackToWorkModal(false);
    if (project) {
      // dispatch(fetchProjectSectionsTreeDuplicate(+project.id));
      fetchGetProjectProcessTree();
    }
  }, []);

  const handleOpenPublishProcess = () => {
    dispatch(setOpenGroupProcessPublishing(node));
    // fetchGetProjectProcessTree();
  };

  const handleOpenRequestProcess = () => {
    dispatch(setOpenGroupProcessRequest(node));
    fetchGetProjectProcessTree();
  };

  const handleClose = () => {
    closeThreeDot();
    dispatch(setTouched(false)); // для групповой публикации
  };

  const handleNavigate = () => {
    window.open(`${config.localDomain}/project-process/${node.id}`, "_blank");
  };

  const handleBackToWorkModal = () => {
    setBackToWorkModal(true);
    fetchGetProjectProcessTree();
  };

  const unPublishClick = async () => {
    if (
      !window.confirm("Вы уверены, что хотите вернуть раздел с публикации?")
    ) {
      return;
    }

    const result = await dispatch(fetchUnPublishProcess(node.id));

    // @ts-ignore
    if (!result?.error) {
      dispatch(pushSuccessMessage());
      fetchGetProjectProcessTree();
    }
  };

  const canPublishGroup = Boolean(node.partGroup);

  const canRequestGroup = Boolean(node.partGroup) && node.can?.requestExecution;

  const canPublishProjectPublished =
    node.can?.publish && node.projectPart.published;

  const { name, handleOpenModal, handleCloseModal, restData } = useModal();

  const headerPublishObject = `Публикация раздела «${node.projectPart.name}» по проекту «${project?.name}»`;
  const headerUpdatePublishedObject = `Редактирование раздела «${node.projectPart.name}» по проекту «${project?.name}»`;

  return (
    <div
      className={classNames(styles.btnFlex, isTablet && styles.btnFlex_tablet)}
    >
      {project && canPublishProjectPublished && (
        // <NewModal
        //   active={publishModal}
        //   setActive={setPublishModal}
        //   isLoad={isLoadPublish}
        //   title={`Публикация раздела «${node.projectPart.name}» по проекту «${project?.name}»`}
        //   activeClass={styles.modal}
        // >
        //   <PublishModalContent
        //     node={node}
        //     project={project}
        //     closeModal={memoCloseModal}
        //   />
        // </NewModal>
        <NewModal
          isActive={name === ModalNames.PublishedObject.PUBLISH_OBJECT}
          handleClose={handleCloseModal}
        >
          <PublishedObjectModals.PublishObjectForm
            id={restData.id}
            header={restData.header}
            type={PublishedObjectControllingEntities.PROJECT_PROCESS}
          />
        </NewModal>
      )}
      {project && node.can.publish && !node.projectPart.published && (
        <Modal
          active={backToWorkModal}
          setActive={setBackToWorkModal}
          activeClass={styles.overflowModal}
        >
          <ModalBackToWork
            node={node}
            project={project}
            closeModal={memoCloseModal}
          />
        </Modal>
      )}
      {project && node.can.unpublish && (
        // <NewModal
        //   active={updatePublishModal}
        //   setActive={setUpdatePublishModal}
        //   isLoad={isLoadUpdatePublish}
        //   title={`Редактирование раздела «${node.projectPart.name}» по проекту «${project?.name}»`}
        //   activeClass={styles.modal}
        // >
        //   <UpdatePublishModalContent
        //     node={node}
        //     project={project}
        //     closeModal={memoCloseModal}
        //   />
        // </NewModal>
        <NewModal
          isActive={name === ModalNames.PublishedObject.UPDATE_PUBLISHED_OBJECT}
          handleClose={handleCloseModal}
        >
          <PublishedObjectModals.UpdatePublishedObjectForm
            id={restData.id}
            header={restData.header}
            type={PublishedObjectControllingEntities.PROJECT_PROCESS}
          />
        </NewModal>
      )}
      <Button
        className={classNames(isTablet && styles.btn)}
        onClick={handleNavigate}
      >
        Открыть
      </Button>
      <ThreeDotButton ref={threeDotButtonRef}>
        <MenuItem
          onClick={() => {
            handleNavigate();
            handleClose();
          }}
          disableRipple
        >
          Перейти к разделу
        </MenuItem>
        {node.can?.unpublish && (
          <MenuItem
            onClick={() => {
              handleOpenModal({
                id: node.id,
                name: ModalNames.PublishedObject.UPDATE_PUBLISHED_OBJECT,
                header: headerUpdatePublishedObject,
              });
              handleClose();
            }}
            disableRipple
          >
            Редактировать
          </MenuItem>
        )}
        {node.can?.unpublish && (
          <MenuItem
            onClick={() => {
              unPublishClick();
              handleClose();
            }}
            disableRipple
          >
            Вернуть с публикации
          </MenuItem>
        )}
        {canPublishProjectPublished && (
          <MenuItem
            onClick={() => {
              handleOpenModal({
                id: node.id,
                name: ModalNames.PublishedObject.PUBLISH_OBJECT,
                header: headerPublishObject,
              });
              handleClose();
            }}
            disableRipple
          >
            Опубликовать
          </MenuItem>
        )}
        {canPublishProjectPublished && canPublishGroup && (
          <MenuItem
            onClick={() => {
              handleOpenPublishProcess();
              handleClose();
            }}
            disableRipple
          >
            Опубликовать группу
          </MenuItem>
        )}
        {canRequestGroup && (
          <MenuItem
            onClick={() => {
              handleOpenRequestProcess();
              handleClose();
            }}
            disableRipple
          >
            Заявиться на группу
          </MenuItem>
        )}
        {node.can?.publish &&
          !node.projectPart.published &&
          node.can?.expertiseTakeToWork && (
            <MenuItem
              onClick={() => {
                handleBackToWorkModal();
                handleClose();
              }}
              disableRipple
            >
              Взять в работу
            </MenuItem>
          )}
      </ThreeDotButton>
    </div>
  );
};
