import React, { memo } from "react";
import {
  Control,
  Controller,
  DeepRequired,
  FieldErrorsImpl,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { DateField } from "src/FSD/shared/uiKit/v2/DateField";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Checkbox } from "src/FSD/shared/uiKit/v2/Checkbox";
import { SelectMultiple } from "src/FSD/shared/uiKit/v2/SelectMultiple";
import { TSelectItem } from "src/components/features/Forms/SetGipForm/types";
import { Nullable } from "src/types/generics";
import { IPublishInfoReturn } from "src/app/feature/ProjectView/SectionsReducer/thunks";
import styles from "./UpdatePublishProcess.module.scss";

const types = [
  { id: 1, title: "Физическое лицо" },
  { id: 2, title: "Юридическое лицо" },
  { id: 3, title: "Индивидуальный предприниматель" },
  { id: 4, title: "Самозанятый" },
];

export type TFieldValues = {
  price: number;
  executorTypes: TSelectItem[];
  dateStart: Nullable<Date>;
  dateLimit: Nullable<Date>;
  daysInterval?: 1 | 8;
  vor: boolean;
  vorPnr: boolean;
  kac: boolean;
  pay1: number;
  pay2: number;
  pay3: number;
  paymentSum: number;
  checkNegotiatedPrice: boolean;
  hasExpertise: boolean;
};

type TProps = {
  additional: boolean;
  checkNegotiatedPrice: boolean;
  control: Control<TFieldValues, object>;
  errors: FieldErrorsImpl<DeepRequired<TFieldValues>>;
  formSubmitHandler: any;
  handleSubmit: UseFormHandleSubmit<TFieldValues>;
  hasExpertise: boolean;
  isLoadPublishInfo: boolean;
  isWork?: boolean;
  loading: boolean;
  pay1: number;
  pay2: number;
  pay3: number;
  price: string;
  projectPart: {
    published: boolean;
  };
  publishInfo: IPublishInfoReturn;
  register: UseFormRegister<TFieldValues>;
  title: string;
};

export const UpdatePublishProcess = memo((props: TProps) => {
  const {
    additional,
    checkNegotiatedPrice,
    control,
    errors,
    formSubmitHandler,
    handleSubmit,
    hasExpertise,
    isLoadPublishInfo,
    isWork,
    loading,
    pay1,
    pay2,
    pay3,
    price,
    projectPart,
    publishInfo,
    register,
    title,
  } = props;

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{title}</Modal.Header>
      {isLoadPublishInfo ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(formSubmitHandler)}>
          <h3>Стоимость, сроки и условия оплаты</h3>
          <div className={styles.form_flex_start}>
            <TextField
              {...register("price")}
              label="Стоимость"
              defaultValue={price}
              error={!!errors.price}
              helperText={checkNegotiatedPrice ? "" : errors?.price?.message}
              disabled={checkNegotiatedPrice}
              className={styles.form__price}
            />
            <Controller
              control={control}
              name="checkNegotiatedPrice"
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label="Договорная стоимость"
                  onChange={onChange}
                  value={value}
                  checked={!!value}
                  className={styles.form__price__checkbox}
                  editing
                />
              )}
            />
          </div>
          <div className={styles.form_pay}>
            <TextField
              {...register("pay1")}
              label="Аванс (%)"
              defaultValue={pay1}
              error={!!errors.pay1}
              helperText={errors?.pay1?.message}
            />
            <TextField
              {...register("pay2")}
              label="Оплата после приемки (%)"
              defaultValue={!hasExpertise ? pay3 + pay2 : pay2}
              error={!!errors.pay2}
              helperText={errors?.pay2?.message}
            />
            {hasExpertise && projectPart.published && (
              <TextField
                {...register("pay3")}
                label="Оплата после экспертизы (%)"
                defaultValue={pay3}
                error={!!errors.pay3}
                helperText={errors?.pay3?.message}
              />
            )}
            <input type="hidden" {...register("paymentSum")} />
          </div>
          {errors.paymentSum && (
            <p className={styles.form_pay_paymentSum}>
              {errors.paymentSum.message}
            </p>
          )}
          <div className={styles.form_date}>
            <div className={styles.form_date_start}>
              <Controller
                control={control}
                name="dateStart"
                render={({ field: { value, onChange } }) => (
                  <DateField
                    label="Начальный срок"
                    startDateProp={value}
                    changeDateHandler={(date) => {
                      onChange(date);
                    }}
                    error={!!errors.dateStart}
                    helperText={errors?.dateStart?.message}
                  />
                )}
              />
            </div>
            <div className={styles.form_date_end}>
              <Controller
                control={control}
                name="dateLimit"
                render={({ field: { value, onChange } }) => (
                  <DateField
                    label="Конечный срок"
                    changeDateHandler={(date) => {
                      onChange(date);
                    }}
                    startDateProp={value}
                    error={!!errors.dateLimit}
                    helperText={errors.dateLimit?.message}
                  />
                )}
              />
            </div>
          </div>
          <h3>Требования к исполнителю</h3>
          <Controller
            name="executorTypes"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectMultiple
                label="Форма собственности"
                value={value}
                options={types}
                showSelectAll
                changeHandler={(selectItem) => {
                  onChange(selectItem);
                }}
                error={!!errors.executorTypes}
                helperText={errors?.executorTypes?.message}
              />
            )}
          />
          {additional && !isWork && (
            <h3>Дополнительные требования к разработке раздела</h3>
          )}
          <div className={styles.form_flexColumn}>
            {(publishInfo.vor || publishInfo.vor_pnr) && (
              <div className={styles.form_flex}>
                {publishInfo.vor && (
                  <Controller
                    control={control}
                    name="vor"
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        label="Ведомость объемов работ"
                        onChange={onChange}
                        value={value}
                        checked={value}
                        editing
                      />
                    )}
                  />
                )}
                {errors.vor && (
                  <p className={styles.error}>{errors.vor.message}</p>
                )}
                {publishInfo.vor_pnr && (
                  <Controller
                    control={control}
                    name="vorPnr"
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        label="Ведомость объемов работ на пусконаладочные работы"
                        onChange={onChange}
                        value={value}
                        checked={value}
                        editing
                      />
                    )}
                  />
                )}
                {errors.vorPnr && (
                  <p className={styles.error}>{errors.vorPnr.message}</p>
                )}
              </div>
            )}
            <div className={styles.form_flex}>
              {publishInfo.kac && (
                <Controller
                  control={control}
                  name="kac"
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      label="Конъюнктурный анализ цен"
                      onChange={onChange}
                      value={value}
                      checked={value}
                      editing
                    />
                  )}
                />
              )}
            </div>
          </div>
          <Modal.Controls>
            <Button type="submit" disabled={loading} isLoading={loading}>
              Изменить
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
});
