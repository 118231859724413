import { ArrowDownCircle, ArrowRightCircle } from "react-bootstrap-icons";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { List } from "src/FSD/shared/uiKit/v2/List";
import { Heading, HeadingAccordion } from "src/FSD/shared/uiKit/v2/Heading";
import { Paragraph } from "src/FSD/shared/uiKit/v2/Paragraph";
import { TextLink } from "src/FSD/shared/uiKit/v2/TextLink";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import { useViewKanbanTask } from "../lib/hooks/useViewKanbanTask";
import styles from "./ViewKanbanTask.module.scss";
import { TaskCheck } from "./TaskCheck";
import { TaskComment } from "./TaskComment";

type Props = {
  taskId: number;
  header: string;
  isCompletion?: boolean;
};

export const ViewKanbanTask = ({ taskId, header, isCompletion }: Props) => {
  const {
    taskData,
    isTaskLoading,
    register,
    handleSubmit,
    onSubmit,
    errors,
    isSubmitting,
    isRejecting,
    isAccepting,
    setValue,
    areCommentsOpen,
    setCommentsOpen,
    areChecksOpen,
    setChecksOpen,
  } = useViewKanbanTask({ taskId });

  return (
    <Modal.Layout position="right">
      <Modal.Header>{header}</Modal.Header>
      {isTaskLoading ? (
        <Modal.Preloader />
      ) : (
        <>
          <Modal.Form onSubmit={handleSubmit(onSubmit)}>
            <Heading>Проект</Heading>
            <Paragraph>{taskData!.projectName}</Paragraph>
            {Boolean(taskData!.objectName) && (
              <>
                <Heading>Объект</Heading>
                <Paragraph>{taskData.objectName}</Paragraph>
              </>
            )}
            <Heading>Вложения</Heading>
            {taskData.files.length ? (
              <TextLink
                url={`${config.localDomain}/task/download-files?id=${taskId}`}
                target="_blank"
                rightIcon={<ArrowDownCircle />}
              >
                Скачать материалы к задаче ({taskData.files.length})
              </TextLink>
            ) : (
              <Paragraph>Вложения отсутствуют</Paragraph>
            )}
            {Boolean(taskData.checks.length) && (
              <>
                <HeadingAccordion
                  id="checksControl"
                  isOpen={areChecksOpen}
                  setOpen={() => setChecksOpen(!areChecksOpen)}
                  label="Согласующие разделы"
                />
                {areChecksOpen && (
                  <List margin={0} gap={5}>
                    {taskData.checks.map(
                      (check: ApiTaskResponses.Check, index: number) => (
                        <TaskCheck key={index} {...check} />
                      )
                    )}
                  </List>
                )}
              </>
            )}
            {Boolean(taskData.comments.length) && (
              <>
                <HeadingAccordion
                  id="commentsControl"
                  isOpen={areCommentsOpen}
                  setOpen={() => setCommentsOpen(!areCommentsOpen)}
                  label="Комментарии"
                />
                {areCommentsOpen && (
                  <List margin={0} gap={5}>
                    {taskData.comments.map(
                      (comment: ApiTaskResponses.Comment) => (
                        <TaskComment key={comment.id} {...comment} />
                      )
                    )}
                  </List>
                )}
              </>
            )}
            {isCompletion && (
              <>
                <TextField
                  {...register("comment")}
                  label="Комментарий"
                  multiline
                  minRows={3}
                  error={Boolean(errors.comment)}
                  helperText={errors.comment?.message}
                />
                <Modal.Controls>
                  <Button
                    disabled={isSubmitting}
                    isLoading={isRejecting}
                    type="submit"
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      setValue("status", Statuses.REJECT);
                    }}
                  >
                    Отказать
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    isLoading={isAccepting}
                    type="submit"
                    onClick={() => {
                      setValue("status", Statuses.ACCEPT);
                    }}
                  >
                    Принять
                  </Button>
                </Modal.Controls>
              </>
            )}
          </Modal.Form>
          {Boolean(taskData!.taskUrl) && (
            <TextLink
              url={`${config.localDomain}${taskData!.taskUrl}`}
              target="_blank"
              rightIcon={<ArrowRightCircle />}
              className={styles.taskLink}
            >
              Перейти к задаче
            </TextLink>
          )}
        </>
      )}
    </Modal.Layout>
  );
};
