import React from "react";
import {
  CheckCircleFill,
  ClockFill,
  PatchQuestion,
  XCircleFill,
} from "react-bootstrap-icons";
import { palette } from "src/styles/restyle";

export const taskStatusIcon = (key: number) => {
  switch (key) {
    case 3:
    case 4:
      return <CheckCircleFill color={palette.persianGreen} />;
    case 5:
    case 8:
      return <XCircleFill color={palette.bordeaux} />;
    case 2:
    case 6:
    case 7:
      return <ClockFill color={palette.silverGrey} />;
    default:
      return <PatchQuestion color={palette.silverGrey} />;
  }
};
