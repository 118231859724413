import cn from "classnames";
import { StatusColors } from "src/FSD/shared/lib/constants/statusColors";
import { PrepositionStatuses } from "../lib/constants/prepositionStatuses";
import styles from "./ExecutionTask.module.scss";
import { stringDateToDDMMYYYY } from "../../../../../shared/lib/helpers";
import { ApiTaskResponses } from "../../../../../entities/task/model/responseTypes";

type Props = {
  isRequest?: boolean;
  task: ApiTaskResponses.View;
  status: any;
};

export const ExecutionTaskDates = ({ status, isRequest, task }: Props) => {
  const showPreposition = PrepositionStatuses.includes(status.key);

  if (isRequest) {
    return (
      <div className={cn(styles.dates, styles.warning)}>
        <p>Запрос от заказчика!</p>
        <p>
          <span className={cn(styles.dateStatus, styles.warning)}>
            {status.value}:&nbsp;
          </span>
          <span
            style={{
              color: StatusColors[status.color],
            }}
          >
            {showPreposition && task.date_deadline && <>до</>}{" "}
            {stringDateToDDMMYYYY(task.date_deadline)}
          </span>
        </p>
      </div>
    );
  }

  return (
    <div className={styles.dates}>
      {Boolean(task.date_complete) && (
        <p>
          <span>Выдано:&nbsp;</span>
          <span>{stringDateToDDMMYYYY(task.date_complete!)}</span>
        </p>
      )}
      <p>
        <span className={styles.dateStatus}>{status.value}:&nbsp;</span>
        <span
          style={{
            color: StatusColors[status.color],
          }}
        >
          {showPreposition && status.date && <>до</>} {status.date}
        </span>
      </p>
    </div>
  );
};
