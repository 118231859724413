import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  useProjectChangeExpertiseMutation,
  useProjectViewQuery,
} from "src/FSD/entities/project/api";
import {
  useProjectPartIndexQuery,
  useProjectPartGetTypesQuery,
} from "src/FSD/entities/projectPart/api";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { BuildingFunctionTypes } from "../helpers/getBuildingFunctionType";
import { Nullable } from "../../../../../types/generics";

export type TFieldValues = Record<string, Record<string, boolean> | boolean> & {
  areAllSelected: string[];
  disabled: number[];
  main: number[];
};

const defaultValues = {
  areAllSelected: [],
  disabled: [],
  main: [],
};

type HookProps = {
  projectId: number;
  buildingFunctionType: Nullable<BuildingFunctionTypes>;
};

export const useExpertise = ({
  projectId,
  buildingFunctionType,
}: HookProps) => {
  const { data: projectPartList, isFetching: isProjectPartListFetching } =
    useProjectPartIndexQuery({
      type: 4,
      buildingFunctionType,
    });

  const { data: projectPartTypes, isFetching: isProjectPartTypesFetching } =
    useProjectPartGetTypesQuery();

  const { data: projectData, isFetching: isProjectViewFetching } =
    useProjectViewQuery(
      {
        id: projectId,
        expand: ["expertise"],
      },
      { skip: !projectId }
    );

  const [changeExpertise] = useProjectChangeExpertiseMutation();

  const form = useForm<TFieldValues>({
    // @ts-ignore
    defaultValues,
  });

  useEffect(() => {
    if (!projectPartList) {
      return;
    }

    const mainIds: number[] = [];

    projectPartList.forEach((projectPart) => {
      const { id, mainExpertise } = projectPart;
      const { targetTypes } = projectPart.params;

      if (mainExpertise) {
        mainIds.push(id);
      }

      if (targetTypes.changed) {
        targetTypes.types.forEach((type) => {
          form.setValue(`${id}.${type}`, false);
        });

        return;
      }

      form.setValue(`${id}`, false);
    });

    form.setValue("main", mainIds);
  }, [projectPartList]);

  useEffect(() => {
    if (!projectData || !projectPartList) {
      return;
    }

    const subProcessIds: string[] = [];
    const disabledIds: number[] = [];

    const mainIds = form.watch("main");

    projectData.expertise.forEach((process) => {
      const { targetTypes } = process.params!;

      if (process.status.key !== Statuses.UNREADY) {
        if (mainIds.includes(process.partId)) {
          mainIds.forEach((id) => {
            disabledIds.push(id);
          });
        } else {
          disabledIds.push(process.partId);
        }
      }

      if (targetTypes.changed) {
        targetTypes.types.forEach((type) => {
          const subProcessId = `${process.partId}.${type}`;

          subProcessIds.push(subProcessId);
          form.setValue(subProcessId, true);
        });
      } else {
        form.setValue(String(process.partId), true);
      }
    });

    form.setValue("areAllSelected", subProcessIds);
    form.setValue("disabled", disabledIds);
  }, [projectData, projectPartList]);

  const isExpertiseLoading = Boolean(
    isProjectPartListFetching ||
      isProjectPartTypesFetching ||
      !projectPartList ||
      !projectPartTypes ||
      (projectId && (isProjectViewFetching || !projectData))
  );

  const onSubmit = async (
    dataValues: Partial<TFieldValues>,
    recievedProjectId: number
  ) => {
    delete dataValues.areAllSelected;
    delete dataValues.disabled;
    delete dataValues.main;

    const data: Record<string, number[]> = {};

    Object.entries(dataValues).forEach(([processId, value]) => {
      if (value === true) {
        // преобразуем обычную экспертизу
        data[processId] = [];
      } else if (value !== false) {
        // преобразуем экспертизу с мн. выбором
        const result = Object.entries(value!)
          .filter(([_, isIncluded]) => isIncluded)
          .map(([type, _]) => Number(type));

        if (result.length) {
          data[processId] = result;
        }
      }
    });

    changeExpertise({ id: recievedProjectId!, data });
  };

  return {
    projectPartList,
    projectPartTypes,
    projectData,
    isExpertiseLoading,
    expertiseForm: form,
    onExperiseSubmit: onSubmit,
  } as const;
};
