import React, { memo } from "react";
import {
  CheckCircleFill,
  Clock,
  ExclamationCircle,
  EyeSlashFill,
  QuestionCircleFill,
  XCircleFill,
} from "react-bootstrap-icons";
import classNames from "classnames";
import { palette } from "src/styles/restyle";
import style from "../SourceData.module.scss";

interface SourceDataIconTypeProps {
  critical: boolean;
  status: string;
  className?: string;
}
export const SourceDataIconType = memo(
  ({ critical, status, className }: SourceDataIconTypeProps) => {
    const canTitle = status === "hidden";
    return (
      <div
        className={classNames(style.statusIcon, className)}
        title={
          (canTitle &&
            "Файлы не публикуемые и доступные только участникам проекта") ||
          ""
        }
      >
        {critical && status !== "success" && status !== "hidden" && (
          <ExclamationCircle color={palette.signalOrange} />
        )}
        {status === "success" && (
          <CheckCircleFill color={palette.persianGreen} />
        )}
        {status === "warning" && <XCircleFill color={palette.bordeaux} />}
        {status === "hidden" && <EyeSlashFill color={palette.persianGreen} />}
        {status === "undefined" && <QuestionCircleFill color="orange" />}
        {status === "inprocess" && <Clock />}
      </div>
    );
  }
);
