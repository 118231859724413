import { useProjectProcessGetSubCheckTasksQuery } from "src/FSD/entities/projectProcess/api";

export const useCheckTasks = (projectProcessId: number) => {
  const { data: subCheckTasks = [], isLoading } =
    useProjectProcessGetSubCheckTasksQuery({
      id: projectProcessId,
    });

  const isTasksLoading = isLoading || !subCheckTasks;

  return { subCheckTasks, isTasksLoading } as const;
};
