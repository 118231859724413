import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { Nullable } from "src/types/generics";
import { ApiTags } from "src/FSD/shared/api/tags";

type TParams = {
  id: number;
  data: {
    dateLimit: Nullable<string>;
    content: string;
    processesIds: (number | string)[];
    projectId: number;
  };
};

export const requestOutTask = (builder: QueryBuilder) =>
  builder.mutation<boolean, TParams>({
    query: ({ id, data }) => ({
      url: `v1/project-process/request-out-task`,
      params: {
        id,
      },
      method: HTTPMethods.POST,
      data,
    }),
    invalidatesTags: [ApiTags.ProjectProcess.GET_IN_TASKS],
  });
