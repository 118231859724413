import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { KanbanModel } from "../../../kanban";

export const useModalCreateFreeTask = () => {
  const dispatch = useAppDispatch();

  const createFreeTask = useAppSelector(KanbanModel.getCreateFreeTask);

  const handleCloseCreateFreeTask = useCallback(() => {
    dispatch(
      KanbanModel.setCreateFreeTask({
        isOpen: false,
      })
    );
  }, []);

  const handleOpenCreateFreeTask = useCallback(() => {
    dispatch(
      KanbanModel.setCreateFreeTask({
        isOpen: false,
      })
    );
  }, []);

  return {
    createFreeTask,
    handleOpenCreateFreeTask,
    handleCloseCreateFreeTask,
  } as const;
};
