import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TabsPanel } from "src/FSD/widgets/v2/TabsPanel";
import { Breadcrumbs } from "src/FSD/shared/uiKit/v2/Breadcrumbs";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoisLoad,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { fetchProjectProcessView } from "src/app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getTabShowProcessInfo } from "src/app/feature/ProjectProcessView/Info/selectors/getTabShowProcessInfo";
import { fetchProjectProcessApplications } from "src/app/feature/ProjectProcessView/Applications/services/fetchProjectProcessApplications";
import TitleTabsHamburger from "src/FSD/widgets/v2/projectProcess/TitleTabsHamburger";
import {
  SourceData,
  Documents,
  ExchangeTasks,
  ExecutionTasks,
  Expertise,
  CheckTasks,
  Materials,
  Works,
  Applications,
  Milestone,
  Info,
  Removed,
  ExpertiseDocuments,
  RemarksExpertise,
  ExpertiseConclusion,
} from "src/FSD/widgets/v2/projectProcess/std";
import { PageWrapper } from "src/FSD/shared/uiKit/v2/PageWrapper/PageWrapper";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { Devices } from "src/FSD/widgets/v2/TabsPanel/lib/constants/devices";
import TaskModals from "src/FSD/widgets/v2/task/modals";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { useProjectProcessView } from "../lib/hooks/useProjectProcessView";
import styles from "./ProjectProcessView.module.scss";

export const ProjectProcessView = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const processId = Number(params.id);
  const { device, breadcrumbs } = useProjectProcessView({ id: +params.id! });
  const [tabs, setTabs] = useState<any>([]);
  const infoData = useAppSelector(getProjectProcessInfoData);
  const isLoad = useAppSelector(getProjectProcessInfoisLoad);
  const tabShow = useAppSelector(getTabShowProcessInfo);

  const projectProcessId = Number(params.id);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessView(+params.id));
      dispatch(fetchProjectProcessApplications(+params.id));
    }
  }, [params.id]);

  const isExpertise = infoData?.projectPart.type.key === 4;

  useEffect(() => {
    if (tabShow) {
      setTabs(infoData?.tabs);
    }
  }, [tabShow]);

  const stdComponents = useMemo(() => {
    if (!infoData) {
      return {};
    }

    return {
      info: <Info />,
      sourceFiles: <SourceData can={infoData?.project.can} />,
      documents: <Documents />,
      exchangeTasks: <ExchangeTasks />,
      executionTask: (
        <ExecutionTasks
          projectProcessId={projectProcessId}
          can={infoData.can}
        />
      ),
      expertiseInTasks: <Expertise />,
      checkTasks: <CheckTasks projectProcessId={projectProcessId} />,
      materials: <Materials />,
      works: <Works />,
      applications: <Applications projectProcessId={projectProcessId} />,
      removed: <Removed projectProcessId={projectProcessId} />,
      stages: <Milestone />,
    };
  }, [infoData]);

  const expertiseComponents = useMemo(() => {
    if (!infoData) {
      return {};
    }
    return {
      info: <Info />,
      expertiseDocuments: (
        <ExpertiseDocuments can={infoData.can} processId={processId} />
      ),
      expertiseRemarks: <RemarksExpertise />,
      expertiseConclusion: (
        <ExpertiseConclusion can={infoData.can} processId={processId} />
      ),
    };
  }, [infoData]);

  const { name, handleCloseModal, restData } = useModal();

  return (
    <div className={styles.wrapper}>
      <PageWrapper>
        <Breadcrumbs items={breadcrumbs} />
        {isLoad || !infoData ? (
          <div className={styles.skeleton}>
            {!(device === Devices.MOBILE) && (
              <Skeleton.List
                height={995}
                width={device === Devices.TABLET ? "106px" : "445px"}
                count={1}
              />
            )}
            <Skeleton.Grid height={300} count={2} />
          </div>
        ) : (
          <>
            <Modal
              isActive={name === ModalNames.Task.FEEDBACK}
              handleClose={handleCloseModal}
            >
              <TaskModals.FeedbackForm
                taskId={restData.taskId}
                header={restData.header}
                isExecutor={restData.isExecutor}
              />
            </Modal>
            <TabsPanel
              subTitle={infoData!.status.value}
              title={infoData!.projectPart.name}
              tabsList={tabs}
              componentsList={isExpertise ? expertiseComponents : stdComponents}
              device={device}
              actions={<TitleTabsHamburger />}
            />
          </>
        )}
      </PageWrapper>
    </div>
  );
};
