import {
  Download,
  PlusSquareFill,
  CheckSquareFill,
  CircleFill,
} from "react-bootstrap-icons";
import { config } from "src/app.cofig";
import { Button } from "src/FSD/shared/uiKit/v2";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { stringDateToDDMMYYYY } from "src/FSD/shared/lib/helpers";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import styles from "src/FSD/widgets/v2/projectProcess/std/ExecutionTasks/ui/ExecutionChildren.module.scss";
import { BackToWorkButton } from "src/FSD/features/v2/BackToWorkButton";
import { IconButton } from "@mui/material";
import { palette } from "src/styles/restyle";
import classNames from "classnames";

type TProps = {
  task: ApiProjectProcessResponses.ChildrenObjects;
};

export const ExecutionChildrenItem = ({ task }: TProps) => {
  const { name, date_complete, date_deadline, status, id, parent_id, files } =
    task;

  const dateComplete = date_complete
    ? `от ${stringDateToDDMMYYYY(date_complete)}`
    : "";
  const dateDeadline = date_deadline
    ? `до ${stringDateToDDMMYYYY(date_deadline)}`
    : "";

  const filesCount = files.reduce(
    (count: number, task: any) => count + Number(task.visibility),
    0
  );
  const downloadFilesId = parent_id ?? id;
  const filesLink = filesCount
    ? `${config.localDomain}/task/download-files?id=${downloadFilesId}`
    : "";

  const { handleOpenModal } = useModal();

  return (
    <>
      <div className={styles.divider} />
      <div>
        <div className={styles.item__title}>
          {status?.dot && (
            <CircleFill
              className={classNames(styles.dot, styles[status.dot])}
            />
          )}
          {name}
        </div>
        <div className={styles.item__desc}>{dateComplete}</div>
      </div>
      <div>
        <div className={styles.item__title}>{status.value}</div>
        <div className={styles.item__desc}>{dateDeadline}</div>
      </div>
      <div>
        <Button
          onClick={() => {
            handleOpenModal({
              name: ModalNames.Task.COMMENTS,
              taskId: id,
            });
          }}
          variant="outlined"
          color="success"
          borderRadius={20}
        >
          Комментарии
        </Button>
      </div>
      <div>
        <Button
          variant="outlined"
          color="secondary"
          disabled={!filesCount}
          borderRadius={20}
          endIcon={<Download />}
          target="_blank"
          href={filesLink}
        >
          Вложение ({filesCount})
        </Button>
      </div>
      <div className={styles.item__actions}>
        {task.can.complete && (
          <IconButton
            onClick={() => {
              handleOpenModal({
                name: ModalNames.ProjectProcess.SUB_TASK,
                taskId: task.id,
                header: task.name,
              });
            }}
          >
            <PlusSquareFill color={palette.lightGrey} />
          </IconButton>
        )}
        {task.can.check && (
          <IconButton
            onClick={() => {
              handleOpenModal({
                name: ModalNames.ProjectProcess.CHECK_SUB_TASK,
                taskId: task.id,
                header: task.name,
              });
            }}
          >
            <CheckSquareFill color={palette.lightGrey} />
          </IconButton>
        )}

        {task.can.backToWork && <BackToWorkButton taskId={task.id} />}
      </div>
    </>
  );
};
