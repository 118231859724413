import classNames from "classnames";
import { ReactNode } from "react";
import TabsPanelContext from "./TabsPanelContext";
import styles from "./TabsPanel.module.scss";
import { Devices } from "../../lib/constants/devices";
import { useTabsPanel } from "../../lib/hooks/useTabsPanel";
import { TTabsPanel } from "../../model/types";
import { TabToggle } from "../TabToggle/TabToggle";

type TProps = {
  tabsList?: TTabsPanel.TabsList;
  title: string;
  subTitle: string;
  componentsList: TTabsPanel.ComponentsList;
  setDefaultTab?: () => void;
  device?: Devices;
  actions?: ReactNode;
  className?: string;
};

export const TabsPanel = ({
  tabsList,
  componentsList,
  title,
  subTitle,
  device = Devices.DESKTOP,
  actions,
  className,
}: TProps) => {
  const {
    tabs,
    currentTab,
    setCurrentTab,
    currentTabChildren,
    currentChildTab,
    setCurrentChildTab,
    checkComponent,
  } = useTabsPanel({
    tabsList,
    componentsList,
  });

  return (
    <TabsPanelContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        currentTabChildren,
        currentChildTab,
        setCurrentChildTab,
      }}
    >
      <div className={classNames(styles.wrapper, className)}>
        <div className={classNames(styles.tabs, styles[device])}>
          {device === Devices.DESKTOP && (
            <div className={styles.header}>
              <div className={styles.header_text}>
                <p>{title}</p>
                {subTitle && (
                  <p className={styles.header_status}>({subTitle})</p>
                )}
              </div>
              {actions}
            </div>
          )}
          {tabs.map((item: TTabsPanel.Tab) => {
            return (
              <TabToggle
                key={item.name}
                currentTab={currentTab === item.name}
                item={item}
                device={device}
              />
            );
          })}
        </div>
        <div className={styles.component}>{checkComponent}</div>
      </div>
    </TabsPanelContext.Provider>
  );
};
