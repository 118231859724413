import React, { memo, useEffect } from "react";
import { Button, Select } from "src/FSD/shared/uiKit/v2";
import {
  clearFilter,
  setPartGroupId,
  setProjectPartCode,
  setStatusId,
} from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/slice/expertiseRemarks";
import { fetchExpertiseRemarksFilter } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarksFilter";
import { fetchExpertisePartGroup } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertisePartGroup";
import { fetchExpertiseProjectPartByGroup } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseProjectPartByGroup";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getExpertisePartGroup,
  getExpertiseProjectPart,
} from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarks";
import {
  getProjectProcessInfoId,
  getProjectProcessInfoProjectId,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  getExpertisePartGroupId,
  getExpertiseProjectPartCode,
  getExpertiseProjectStatusId,
} from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarkFilter";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import cls from "./Filter.module.scss";

const status = [
  {
    id: 2,
    title: "В работе",
  },
  {
    id: 3,
    title: "Выполнено",
  },
];

export const Filter = memo(() => {
  const dispatch = useAppDispatch();
  const partGroup = useAppSelector(getExpertisePartGroup);
  const projectPart = useAppSelector(getExpertiseProjectPart);
  const processId = useAppSelector(getProjectProcessInfoId);
  const projectId = useAppSelector(getProjectProcessInfoProjectId);
  const partGroupId = useAppSelector(getExpertisePartGroupId);
  const projectPartCode = useAppSelector(getExpertiseProjectPartCode);
  const statusId = useAppSelector(getExpertiseProjectStatusId);

  useEffect(() => {
    dispatch(fetchExpertisePartGroup());
  }, []);

  useEffect(() => {
    if (partGroupId && projectId) {
      dispatch(
        fetchExpertiseProjectPartByGroup({ projectId, groupId: +partGroupId })
      );
    }
  }, [partGroupId]);

  function handleChangePartGroup(event: TSelectItem) {
    dispatch(setPartGroupId(event.id));
  }

  function handleChangeProjectPart(event: TSelectItem) {
    dispatch(setProjectPartCode(event.id));
  }

  function handleChangeStatus(event: TSelectItem) {
    dispatch(setStatusId(event.id));
  }

  function handleClearClick() {
    dispatch(clearFilter());
    if (processId) {
      dispatch(
        fetchExpertiseRemarksFilter({
          processId,
          partGroupId: "",
          code: "",
          status: "",
        })
      );
    }
  }

  function handleSearchClick() {
    if (processId) {
      dispatch(
        fetchExpertiseRemarksFilter({
          processId,
          partGroupId: +partGroupId,
          code: projectPartCode,
          status: +statusId,
        })
      );
    }
  }

  const mapProjectPart = projectPart
    ? projectPart.map((part) => ({ id: part.code, title: part.name }))
    : [];

  return (
    <div className={cls.filter}>
      <Select
        label="Группа разделов"
        optionLabel="name"
        options={partGroup || []}
        changeHandler={(_, newValue) => {
          handleChangePartGroup(newValue);
        }}
      />
      <Select
        label="Разделы"
        disabled={!partGroupId}
        options={mapProjectPart}
        disableClear
        changeHandler={(_, newValue) => {
          handleChangeProjectPart(newValue);
        }}
      />
      <Select
        label="Статус"
        options={status}
        disableClear
        changeHandler={(_, newValue) => {
          handleChangeStatus(newValue);
        }}
      />
      <div className={cls.filter_btn}>
        <Button onClick={handleSearchClick}>Поиск</Button>
        <Button onClick={handleClearClick}>Сбросить</Button>
      </div>
    </div>
  );
});
