import { useDocumentIndexQuery } from "src/FSD/entities/expertise/document/api";

type TPops = {
  processId: number;
};

export const useExpertiseDocuments = ({ processId }: TPops) => {
  const { data = [], isFetching } = useDocumentIndexQuery(
    {
      id: processId,
      expand: ["file"],
    },
    { skip: !processId }
  );

  const isLoading = isFetching || !data;

  return { data, isLoading } as const;
};
