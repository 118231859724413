import { useCallback } from "react";
import { useSelector } from "react-redux";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { RequiredTypes } from "src/components/newUI/RequiredTypes/RequiredTypes";
import {
  getProjectProcessBadApplications,
  getProjectProcessGoodApplications,
} from "src/app/feature/ProjectProcessView/Applications/selectors/getProjectProcessApplications";
import { useAppSelector } from "src/app/store";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import { Button } from "src/FSD/shared/uiKit/v2";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import styles from "./Executor.module.scss";
import PublishedObjectModals from "../../../../../publishedObject/modals";
import { useTabsPanelContext } from "../../../../../TabsPanel";

export const ExecutorSearch = () => {
  const goodApplications = useSelector(getProjectProcessGoodApplications);
  const badApplications = useSelector(getProjectProcessBadApplications);

  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;

  const countApplications =
    (goodApplications?.length || 0) + (badApplications?.length || 0);

  const { setCurrentTab } = useTabsPanelContext();

  const { id, title } = infoData;

  const nameProject = `«${infoData.project.name}${infoData.project.code ? ` (${infoData.project.code})` : ""}»`;
  const requestExecutionHeader = `Заявка на исполнение раздела «${title}» по проекту ${nameProject}`;

  const { name, handleOpenModal, handleCloseModal } = useModal();

  return (
    <div className={styles.executor}>
      <div className={styles.executor_header}>
        <Modal
          isActive={name === ModalNames.PublishedObject.CREATE_REQUEST}
          handleClose={handleCloseModal}
        >
          <PublishedObjectModals.CreateRequestForm
            id={id}
            header={requestExecutionHeader}
            type={PublishedObjectControllingEntities.PROJECT_PROCESS}
          />
        </Modal>
        <h2>Исполнитель</h2>
        {infoData.can.requestExecution && (
          <>
            <Button
              onClick={() => {
                handleOpenModal({
                  name: ModalNames.PublishedObject.CREATE_REQUEST,
                });
              }}
            >
              Заявиться
            </Button>
          </>
        )}
      </div>
      <div className={styles.executor_info}>
        <div className={styles.executor_text}>
          <span>Требуемая форма собственности</span>
          <RequiredTypes
            types={infoData.executorType}
            classname={styles.executor_type}
          />
        </div>
        {countApplications > 0 && (
          <div className={styles.applications}>
            <div onClick={() => setCurrentTab("applications")}>
              {countApplications}
            </div>
            <p>Кол-во заявок</p>
          </div>
        )}
        <hr className={styles.yellowLine} />
      </div>
    </div>
  );
};

export default ExecutorSearch;
