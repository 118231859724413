import * as Yup from "yup";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { stringMinMaxLengthRequired } from "../../../../../../../../utils/SimplifiedYup";

export const checkExecuteCompleteSchema = Yup.object().shape({
  comment: Yup.string().when("status", {
    is: (status) => status === Statuses.REJECT,
    then: stringMinMaxLengthRequired("Замечания", 15, 2000),
  }),
});
