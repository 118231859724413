import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getNdsText } from "src/FSD/shared/lib/helpers/ndsText";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { dropCompletedTaskReducer } from "src/FSD/entities/kanban/model";
import {
  useTaskViewQuery,
  useTaskCompleteMutation,
} from "src/FSD/entities/task/api";
import { checkCustomerAlignSchema } from "../schemas/checkCustomerAlign";
import { Nullable } from "../../../../../../../../types/generics";
import { useAppDispatch } from "../../../../../../../../app/store";

type TFieldValues = {
  comment: string;
  paymentSum: number;
  status: Nullable<Statuses>;
  hasNds: boolean;
  processBalance: number;
};

type UseModalProps = {
  id: number;
  defaultValues: TFieldValues;
  handleClose: () => void;
};

export const useCheckCustomerAlign = ({
  id,
  defaultValues,
  handleClose,
}: UseModalProps) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(checkCustomerAlignSchema),
  });

  const { data: taskData, isFetching } = useTaskViewQuery({
    id,
    expand: ["parent.files", "taskWork.account"],
  });

  const [completeTask] = useTaskCompleteMutation();

  useEffect(() => {
    if (!taskData) {
      return;
    }

    setValue("comment", taskData.comment);
    setValue("hasNds", !!taskData!.taskWork!.executor_nds);

    if (taskData!.taskWork!.account) {
      setValue("processBalance", taskData!.taskWork!.account.value);
    }
  }, [taskData]);

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      CompleteForm: {
        status: data.status!,
        comment: data.comment,
        paid_out: data.paymentSum,
      },
    };

    completeTask({ id, task: formedData })
      .unwrap()
      .then((result) => {
        dispatch(
          dropCompletedTaskReducer({
            id,
            isCheck: result.isCheck,
            status: result.status,
          })
        );

        handleClose();
      });
  };

  const status = watch("status");
  const hasNds = watch("hasNds");
  const processBalance = watch("processBalance");

  const ndsText = getNdsText(hasNds);

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    setValue,
    ndsText,
    status,
    isSubmitting,
    isTaskViewPending: isFetching || !taskData,
    taskData,
    processBalance,
  } as const;
};
