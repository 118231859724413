import React, { memo, useState } from "react";
import { Uploader } from "src/components/features/Uploader/Uploader";
import { config } from "src/app.cofig";
import { ApiGetTaskFiles } from "src/app/services/api/organisation/organisation";
import { useAppDispatch, useAppSelector } from "src/app/store";
import {
  getProjectProcessInfoData,
  getProjectProcessInfoId,
  getProjectProcessInfoName,
} from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import {
  fetchProjectProcessExpertise,
  fetchProjectProcessExpertiseDuplicate,
} from "src/app/feature/ProjectProcessView/Expertise/services/fetchProjectProcessExpertise";
import { Button } from "src/FSD/shared/uiKit/v2";
import { taskCompleteThunk } from "src/app/feature/taskComplete/services/taskCompleteThunk";
import cls from "./ModalComplete.module.scss";

export const ModalComplete = memo(({ taskId, addText, closeModal }: any) => {
  const processName = useAppSelector(getProjectProcessInfoName);
  const process = useAppSelector(getProjectProcessInfoData);
  const dispatch = useAppDispatch();
  const processId = useAppSelector(getProjectProcessInfoId);
  const [responseCallback, setResponseCallback] = useState<any>({});
  const canBtn =
    !responseCallback?.can?.edit && responseCallback?.can?.complete;
  async function updateFileLoad() {
    if (processId) {
      await dispatch(fetchProjectProcessExpertiseDuplicate(processId));
    }
  }

  async function completeHandler() {
    const data = {
      CompleteForm: {
        status: 6,
      },
    };
    if (processId) {
      await dispatch(taskCompleteThunk({ taskId, data }));
      dispatch(fetchProjectProcessExpertise(processId));
      closeModal();
    }
  }

  return (
    <form className={cls.form}>
      <h1>
        Выдать документацию по разделу «{processName}» по проекту «
        {process?.project.name}»
      </h1>
      <Uploader
        identifier={taskId}
        autoUpload
        canBeEdited
        getDataCallback={ApiGetTaskFiles}
        responseDataCallback={(data) => setResponseCallback(data)}
        postUrlString={(id) =>
          `${config.localDomain}/v1/task/add-file?id=${id}`
        }
        handleUpdate={updateFileLoad}
        uploadAsActive
      />
      {addText && <p className={cls.addText}>{addText}</p>}
      {canBtn && !addText && (
        <Button className={cls.form_submit} onClick={completeHandler}>
          Отправить на проверку
        </Button>
      )}
    </form>
  );
});
