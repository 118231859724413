import React from "react";
import { Controller } from "react-hook-form";
import { Button, Checkbox, TextField } from "src/FSD/shared/uiKit/v2";
import { TAddCheckList, useAddCheckList } from "../lib/hooks/useAddCheckList";
import styles from "./SourceDataUpdateForm.module.scss";

export const AddCheckList = ({ parentId, projectId, type }: TAddCheckList) => {
  const {
    register,
    control,
    setValue,
    errors,
    formShow,
    handleFormShow,
    isLoading,
    handleOnSubmit,
  } = useAddCheckList({ parentId, projectId, type });

  return (
    <form onSubmit={handleOnSubmit}>
      {formShow ? (
        <div>
          <div className={styles.addParent}>
            <TextField
              {...register("name")}
              placeholder="Наименование"
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />
            <Controller
              control={control}
              name="hidden"
              render={({ field: { value } }) => (
                <Checkbox
                  editing
                  checked={value}
                  title="Скрытые - исходные данные, которые доступны только участникам проекта"
                  onChange={() => {
                    setValue("hidden", !value);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="executor"
              render={({ field: { value } }) => (
                <Checkbox
                  editing
                  checked={value}
                  title="Заказчик - за предоставление исходных данных отвечает Заказчик"
                  onChange={() => {
                    setValue("executor", !value);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="critical"
              render={({ field: { value } }) => (
                <Checkbox
                  editing
                  checked={value}
                  title="Критичные - важные исходные данные, которые влияют на длительность выполнения работ"
                  onChange={() => {
                    setValue("critical", !value);
                  }}
                />
              )}
            />
          </div>
          <Button disabled={isLoading} type="submit">
            Добавить
          </Button>
        </div>
      ) : (
        <div className={styles.addNewItem}>
          <Button onClick={handleFormShow}>
            Добавить новый пункт в список +
          </Button>
        </div>
      )}
    </form>
  );
};
