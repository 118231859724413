import React from "react";
import { SourceDataUpdateForm } from "src/FSD/widgets/v2/sourceData/modals/SourceDataUpdateForm";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { useModalGear } from "src/FSD/widgets/v2/projectProcess/modals/ModalGear/libs/hooks/useModalGear";
import { ApiPrimaryProjectType } from "src/types/api/primaryTypes/apiPrimaryProjectType";

interface ModalGearProps {
  parentId: number;
  projectId: number;
  handleDeleteSourceDataName: (id: number) => void;
  name: string;
  type: number;
  can: ApiPrimaryProjectType["can"];
}

export const ModalGear = ({
  projectId,
  parentId,
  name,
  handleDeleteSourceDataName,
  type,
  can,
}: ModalGearProps) => {
  const { isLoading, formSubmitHandler, data, isUpdating } = useModalGear({
    projectId,
    parentId,
    type,
  });

  const canExecutorEdit = !!can?.edit;

  return (
    <Modal.Layout modalSize="xl">
      <Modal.Header>{name}</Modal.Header>
      {isLoading ? (
        <Modal.Preloader />
      ) : (
        <SourceDataUpdateForm
          formSubmitHandler={formSubmitHandler}
          checkList={data}
          canExecutorEdit={canExecutorEdit}
          handleDeleteSourceDataName={handleDeleteSourceDataName}
          parentId={parentId}
          projectId={projectId}
          isUpdating={isUpdating}
          type={type}
        />
      )}
    </Modal.Layout>
  );
};
