import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import styles from "./RemoveRequestExecutionForm.module.scss";
import { useRemoveRequestExecution } from "../lib/hooks/useRemoveRequestExecution";
import { Paragraph } from "../../../../../../shared/uiKit/v2/Paragraph";
import { PublishedObjectControllingEntities } from "../../../../../../entities/publishedObject/model/controllingEntities";

const entityTitles = {
  ProcessWork: "задачи",
  ProjectProcess: "раздела",
};

type Props = {
  taskId: number;
  header: string;
  type: PublishedObjectControllingEntities;
};

export const RemoveRequestExecutionForm = ({ taskId, header, type }: Props) => {
  const { handleClose } = useModalContext();

  const { handleSubmit, onSubmit, isSubmitting } = useRemoveRequestExecution({
    taskId,
    handleClose,
  });

  return (
    <Modal.Layout>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Form onSubmit={handleSubmit(onSubmit)}>
        <Paragraph color="light" className={styles.text} fontSize={18}>
          Вы уверены, что хотите удалить свою заявку на исполнение
          {entityTitles[type]}?
        </Paragraph>
        <Modal.Controls>
          <Button
            disabled={isSubmitting}
            color="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Нет
          </Button>
          <Button
            disabled={isSubmitting}
            isLoading={isSubmitting}
            type="submit"
          >
            Да
          </Button>
        </Modal.Controls>
      </Modal.Form>
    </Modal.Layout>
  );
};
