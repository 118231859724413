import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { create, deleteConclusion, indexConclusion } from "./queries";

export const conclusionApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    conclusionCreate: create(builder),
    conclusionDelete: deleteConclusion(builder),
    conclusionIndex: indexConclusion(builder),
  }),
  overrideExisting: false,
});

export const {
  useConclusionCreateMutation,
  useConclusionDeleteMutation,
  useConclusionIndexQuery,
} = conclusionApi;
