import * as Yup from "yup";
import {
  dateRequired,
  dateFutureRequired,
  dateLimitExceedsDateStartRequired,
  priceMinMaxRequired,
  executorTypesRequired,
  numberRequired,
  messages,
  percentMinMaxRequired,
} from "src/utils/SimplifiedYup";

export const publishContentSchemas = Yup.object().shape({
  dateStart: dateFutureRequired("Начальный срок"),
  dateLimit: dateLimitExceedsDateStartRequired("Конечный срок"),
  price: Yup.number().when("checkNegotiatedPrice", {
    is: (checkNegotiatedPrice) => !checkNegotiatedPrice,
    then: priceMinMaxRequired("Стоимость", 1000, 10000000).test(
      "shouldBeIntNumber",
      messages.shouldBeIntNumber,
      (value: any) => {
        return !Number.isNaN(value) && Number.isInteger(value);
      }
    ),
  }),
  workAccount: Yup.mixed().when(["isProcessWork", "isEdit"], {
    is: (isProcessWork, isEdit) => isProcessWork && !isEdit,
    then: dateRequired("Кошелек"),
  }),
  executorTypes: executorTypesRequired("Форма собственности"),
  userToAssign: Yup.mixed().test(
    "userToAssign",
    messages.requiredField("Исполнитель"),
    function () {
      return !(
        this.parent.isProcessWork &&
        this.parent.userTypeToAssign &&
        !this.parent.userToAssign
      );
    }
  ),
  userTypeToAssign: Yup.mixed().when(["isProcessWork", "userToAssign"], {
    is: (isProcessWork, userToAssign) => !isProcessWork && userToAssign,
    then: dateRequired("Форма собственности"),
  }),
  pay1: Yup.mixed().when("agreementsActs", {
    is: (agreementsActs) => agreementsActs.id,
    then: percentMinMaxRequired("Аванс", 20, 80),
  }),

  pay2: Yup.mixed().when("agreementsActs", {
    is: (agreementsActs) => agreementsActs.id,
    then: numberRequired("Оплата после приемки"),
  }),

  pay3: Yup.mixed().when("agreementsActs", {
    is: (agreementsActs) => agreementsActs.id,
    then: numberRequired("Оплата после экспертизы"),
  }),

  payError: Yup.mixed().when("agreementsActs", {
    is: (agreementsActs) => agreementsActs.id,
    then: Yup.mixed().test(
      "totalAmountPercent",
      messages.totalAmountPercent,
      function () {
        return this.parent.pay1 + this.parent.pay2 + this.parent.pay3 === 100;
      }
    ),
  }),
});
