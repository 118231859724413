import { WorkFlowClasses } from "src/FSD/shared/lib/constants/workFlowClasses";
import { KanbanModel, KanbanType } from "src/FSD/entities/kanban";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/store";

export const useColumn = () => {
  const dispatch = useAppDispatch();

  const groupBy = useAppSelector(KanbanModel.getGroupByValue);

  const handleShowTaskInfo = (task: KanbanType.Task) => {
    if (task.workFlowClass === WorkFlowClasses.FREE_TASK && task.can.edit) {
      dispatch(
        KanbanModel.setEditFreeTask({
          isOpen: true,
          taskId: task.id,
        })
      );

      return;
    }

    dispatch(
      KanbanModel.setViewTask({
        isOpen: true,
        taskId: task.id,
        header: task.header,
        isCompletion: false,
      })
    );
  };

  const noGroupping = groupBy.id === "";

  return { handleShowTaskInfo, noGroupping } as const;
};
