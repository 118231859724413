const C = {
  CONFIRM_DELETE: "Вы действительно хотите удалить запись?",
  NOT_STATED: "Не указано",
  ERRORS_OCCURED: "При обновлении данных возникли ошибки",
  CONFIRM_RETURN_TASK_FOR_REVISION:
    "Вы уверены, что хотите вернуть задание доработку?",
  CONFIRM_ACCEPT_TASK: "Вы уверены, что хотите принять задание?",
  CONFIRM_COMPLETE_TASK: "Вы уверены, что хотите отменить данное задание?",
};

export default C;
