import React from "react";
import TooltipMui, { TooltipProps } from "@mui/material/Tooltip";
import styles from "./Tooltip.module.scss";

type TProps = TooltipProps & {
  content?: React.ReactNode;
};

export const Tooltip = ({ children, title, content, ...props }: TProps) => {
  return (
    <TooltipMui
      title={
        <>
          {title}
          {content && <span> {content}</span>}
        </>
      }
      classes={{
        popper: styles.popper,
        tooltip: styles.tooltip,
      }}
      {...props}
    >
      <div>{children}</div>
    </TooltipMui>
  );
};
