import { Nullable } from "../../../../types/generics";

type TModalsState = {
  requestRemoveGip: {
    isOpen: boolean;
    projectId: Nullable<number>;
  };
};

export const modalsInitialState: TModalsState = {
  requestRemoveGip: {
    isOpen: false,
    projectId: null,
  },
};
