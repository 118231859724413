import { useEffect } from "react";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { ProjectAccessControllingEntities } from "src/FSD/entities/projectAccess/model/controllingEntities";
import { toSelect } from "../../../../../../app/feature/project/projectProcessPublication";
import { useQueries } from "./useQueries";
import { Nullable } from "../../../../../../types/generics";

type UseModalProps = {
  id: number;
  userType?: Nullable<TSelectItem>;
  userId?: number;
  setValue?: any;
  type: "Project" | "ProjectProcess" | "ProcessWork";
};

export const useSelectExecutor = ({
  id,
  userType,
  userId,
  setValue,
  type,
}: UseModalProps) => {
  const { fetchGetExecutor, usersToAssignRaw, isLoading } = useQueries(
    id,
    type,
    userType
  );

  const isProcessWork = type === ProjectAccessControllingEntities.PROCESS_WORK;

  useEffect(() => {
    if (userType && isProcessWork) {
      fetchGetExecutor();
    }
  }, [userType, isProcessWork]);

  useEffect(() => {
    if (!isProcessWork) {
      fetchGetExecutor();
    }
  }, [isProcessWork]);

  const usersToAssign = useDataMapper(usersToAssignRaw, toSelect);

  useEffect(() => {
    if (!usersToAssign) {
      return;
    }

    if (!userId) {
      return;
    }

    const userValue = usersToAssign.find(
      (item: TSelectItem) => +item.id === userId
    );

    setValue("userToAssign", userValue);
  }, [usersToAssign, userId]);

  const isPendingUsersAssign = (isLoading || !usersToAssign) && !!userType;

  return {
    usersToAssign,
    isPendingUsersAssign,
  } as const;
};
