import { memo } from "react";
import cn from "classnames";
import styles from "./Column.module.scss";

type Props = {
  isStatic?: boolean;
};

export const NoTasksFound = memo(({ isStatic }: Props) => {
  return (
    <div
      className={cn(
        styles.column,
        styles.column_notFound,
        isStatic && styles.column_notFound_static
      )}
    >
      Задачи отсутствуют
    </div>
  );
});
