import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import styles from "./TextLink.module.scss";

export type TextLinkProps = {
  children: string | React.ReactNode;
  className?: string;
  download?: boolean;
  redirect?: boolean;
  target?: React.AnchorHTMLAttributes<unknown>["target"];
  title?: string;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  url: string;
  variant?: "white" | "title" | "oceanic" | "";
};

export const TextLink = ({
  children,
  className,
  download,
  target = "_self",
  rightIcon,
  leftIcon,
  title,
  url,
  variant = "",
}: TextLinkProps) => (
  <NavLink
    download={download}
    target={target}
    title={title}
    className={classNames(styles.link, className, styles[variant])}
    to={url}
  >
    {leftIcon}
    {children}
    {rightIcon}
  </NavLink>
);
