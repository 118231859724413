import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { useDevice } from "src/FSD/shared/lib/hooks/useDevice";
import {
  useProcessWorkUnPublishMutation,
  useProcessWorkViewQuery,
} from "src/FSD/entities/processWork/api";
import { mapProcessWork } from "../mappers/mapProcessWork";
import { getBreadcrumbs } from "../helpers/getBreadcrumbs";

type TUsePageProps = {
  processWorkId: number;
};

const MOBILE_SIZE = 1280;
const TABLET_SIZE = 1600;

export const useProcessWorkViewPage = ({ processWorkId }: TUsePageProps) => {
  const { data: processWorkRaw, isLoading } = useProcessWorkViewQuery({
    id: processWorkId,
    expand: [
      "account",
      "files",
      "can",
      "activeAccess.taskRemove",
      "activeAccess.user",
      "activeAccess.userType",
      "project.chatId",
      "tabs",
      "activeContract",
      "task.can",
    ],
  });

  const [unpublishWork] = useProcessWorkUnPublishMutation();

  const processWork = useDataMapper(processWorkRaw, mapProcessWork);

  const device = useDevice(MOBILE_SIZE, TABLET_SIZE);

  const breadCrumbs = getBreadcrumbs(processWork);

  const isProcessWorkLoading = !processWork || isLoading;

  const { publish, unpublish, freezeFunds, pause, unpause, chat, update } =
    processWork?.can ?? {};

  const showThreeDot =
    publish ||
    unpublish ||
    freezeFunds ||
    pause ||
    unpause ||
    chat ||
    update ||
    processWork?.task?.can?.feedbackToController;

  const nameProjectProcess = `«${processWork?.projectPart.name}${processWork?.projectPart.code ? ` (${processWork.projectPart.code})` : ""}»`;

  const publishObjectHeader = `Публикация задачи «${processWork?.title}» по проекту ${nameProjectProcess}`;

  const nameProcessWork = `«${processWork?.title}» по разделу ${nameProjectProcess}`;

  const headerUpdate = `Редактирование задачи ${nameProcessWork}`;

  const handleUnpublishWork = async () => {
    const result = window.confirm(
      "Вы уверены, что хотите вернуть задачу с публикации?"
    );
    if (result && processWorkId) {
      await unpublishWork({ id: processWorkId });
    }
  };

  return {
    processWork,
    device,
    breadCrumbs,
    isProcessWorkLoading,
    showThreeDot,
    publishObjectHeader,
    handleUnpublishWork,
    headerUpdate,
  } as const;
};
