import * as KanbanType from "../types";
import { KanbanSortValues } from "./constants";

export const initialSelectOptions = {
  projects: [],
  workFlows: [],
  groups: [],
  users: [],
};

export const initialTaskPromptData = {
  taskId: null,
  uuid: null,
  projectName: null,
  coordinates: null,
  objects: [],
  url: null,
};

export const initialFilterParams = {
  project: null,
  dateStart: null,
  dateLimit: null,
  partGroup: [],
  workFlow: [],
  group: [],
  executor: [],
};

export const initialState: KanbanType.State = {
  tasks: {
    allTasks: [],
    rowTasks: {},
    cache: {
      destinationColumnId: null,
      destinationRowId: null,
      tasks: {},
    },
  },
  data: {
    sort: KanbanSortValues.DATE_ASC,
    groupBy: {
      id: "",
      title: "Без группипровки",
    },
    createFreeTask: {
      isOpen: false,
    },
    editFreeTask: {
      isOpen: false,
      taskId: null,
    },
    viewTask: {
      isOpen: false,
      taskId: null,
      header: "",
      isCompletion: false,
    },
    selectOptions: { ...initialSelectOptions },
    taskCompletion: null,
    setting: {
      start: "",
      end: "",
    },
  },
  pending: {
    tasks: {
      getTasks: false,
      updateTask: false,
      deleteTask: false,
      creation: false,
      getPrompt: false,
    },
    filter: {
      getProjects: false,
      getGroups: false,
      getWfList: false,
      getUsersList: false,
    },
  },
  error: {
    getTasks: null,
    updateTask: null,
    deleteTask: null,
    creation: null,
    getPrompt: null,
  },
};
