type TMuiSx = {
  [key: string]: any;
};

export const palette = {
  green: "#3BAF6A",
  blue: "#3B93AF",
  common: "#26313A",
  red: "#8e494e",
  bordeaux: "#ce0000",
  warning: "orange",
  grey: "#6B7D8A",
  darkGrey: "#1A2734",
  white: "#FFF",
  lightGrey: "#9DAFBD",
  persianGreen: "#0AA699",
  darkTelegraph: "#838A93",
  oceanic: "linear-gradient(90deg, #0AA699 0%, #46C6E9 102.94%)",
  oceanicSmooth: "#46C6E9",
  darkSlate: "#101920",
  midnight: "#15222B",
  midnightBlack: "#0B1016",
  silverGrey: "#D5D6DE",
  signalOrange: "#F5A623",
};

export const textFieldSX: TMuiSx = {
  autoComplete: "off",
  fullWidth: false,
  size: "small",
  variant: "outlined",
  sx: {
    width: "100%",
    "& .Mui-error": {
      color: "#d32f2f !important",
      "& fieldset": {
        border: `1px solid #d32f2f !important`,
      },
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: palette.lightGrey,
      cursor: "not-allowed",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${palette.lightGrey}`,
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      cursor: "not-allowed",
    },
    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
      padding: "0px 8px",
    },
    "& label": {
      color: palette.lightGrey,
      "&.Mui-focused": {
        color: palette.blue,
      },
      "&.Mui-disabled": {
        color: palette.lightGrey,
      },
    },
    "& .MuiOutlinedInput-root": {
      minHeight: "37px",
      "& fieldset": {
        border: `1px solid ${palette.lightGrey}`,
      },
      "&:hover fieldset": {
        border: `1px solid ${palette.blue}`,
      },
      "&.Mui-focused fieldset": {
        border: `2px solid ${palette.blue}`,
      },
      "& input": {
        color: palette.white,
      },
      "& textarea": {
        color: palette.white,
        fontSize: "14px",
      },
      "& .MuiSelect-select": {
        color: palette.white,
      },
      "& .MuiSvgIcon-root": {
        color: palette.lightGrey,
      },
    },
    "& .MuiInputBase-multiline": {
      height: "auto",
    },
  },
};

export const dateRangeSX: TMuiSx = {
  ...textFieldSX,
  sx: {
    ...textFieldSX.sx,
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -8px) scale(0.75)",
    },
  },
};

export const searchFieldSX: TMuiSx = {
  ...textFieldSX,
  sx: {
    ...textFieldSX.sx,
    width: "160px",
    height: "32px",
    marginBottom: "2px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "20px",
      },
    },
  },
};

export const iconBtnSx = {
  color: "#9DAFBD",
  "&:hover": {
    cursor: "pointer",
    color: "#FFFFFF",
  },
};

export const newIconBtnSx = {
  color: "#d5d6de",
  "&:hover": {
    cursor: "pointer",
    opacity: 0.8,
  },
};

export const menuItemSx = {
  bgcolor: palette.white,
  color: palette.common,
  "&.Mui-selected": {
    bgcolor: "#A7B0B7!important",
  },
};

export const stanardTextFieldSx = {
  borderColor: "white",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  "& .MuiInput-root": {
    borderColor: "white",
  },
  "& .MuiFilledInput-root": {
    borderColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};

export const newMenuItemSx = {
  bgcolor: "#1A2734",
  color: palette.silverGrey,
  padding: "10px 10px",
  "&:not(:last-child)": {
    borderBottom: "1px solid #101920",
  },
  fontSize: "16px",
  fontFamily: "Manrope",
  fontWeight: "400",
};

export const newDesignButtonSx = {
  color: "#0AA699",
  padding: 0,
  transition: "0.3s ease-in-out 0s!important",
  "&:hover": {
    transform: "scale(1.2)",
  },
};
