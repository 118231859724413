import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { processWorkApi } from "src/FSD/entities/processWork/api";
import { projectProcessApi } from "src/FSD/entities/projectProcess/api";
import { ProjectAccessControllingEntities } from "src/FSD/entities/projectAccess/model/controllingEntities";
import { projectApi } from "src/FSD/entities/project/api";
import { Nullable } from "../../../../../../types/generics";

export const useQueries = (
  id: number,
  type: "Project" | "ProjectProcess" | "ProcessWork",
  userType?: Nullable<TSelectItem>
) => {
  const [
    getUsersToAssignWork,
    { data: usersToAssignWorkRaw, isLoading: isLoadingWork },
  ] = processWorkApi.useLazyProcessWorkGetUsersToAssignQuery();

  const [
    getUsersToAssignProcess,
    { data: usersToAssignProcessRaw, isLoading: isLoadingProcess },
  ] = projectProcessApi.useLazyProjectProcessGetUsersToAssignQuery();

  const [
    getUsersToAssignProject,
    { data: usersToAssignProjectRaw, isLoading: isLoadingProject },
  ] = projectApi.useLazyProjectGetUsersToAssignQuery();

  const fetchGetExecutorWork = async () => {
    if (!userType) {
      return;
    }

    await getUsersToAssignWork({
      id,
      firmId: +userType.id,
    });
  };

  const fetchGetExecutorProcess = async () => {
    await getUsersToAssignProcess({
      id,
    });
  };

  const fetchGetExecutorProject = async () => {
    await getUsersToAssignProject({
      id,
    });
  };

  const dataMap = {
    ProcessWork: {
      fetchGetExecutor: fetchGetExecutorWork,
      usersToAssignRaw: usersToAssignWorkRaw,
      isLoading: isLoadingWork,
    },
    ProjectProcess: {
      fetchGetExecutor: fetchGetExecutorProcess,
      usersToAssignRaw: usersToAssignProcessRaw,
      isLoading: isLoadingProcess,
    },
    Project: {
      fetchGetExecutor: fetchGetExecutorProject,
      usersToAssignRaw: usersToAssignProjectRaw,
      isLoading: isLoadingProject,
    },
  };

  const { fetchGetExecutor, usersToAssignRaw, isLoading } = dataMap[type];

  return { fetchGetExecutor, usersToAssignRaw, isLoading };
};
