import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { HTTPMethods } from "src/FSD/shared/api/common";

type TParams = {
  id: number;
  data: FormData;
};

export const create = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/expertise/conclusion/create`,
      params: {
        processId: id,
      },
      method: HTTPMethods.POST,
      data,
      isMultipart: true,
    }),
  });
