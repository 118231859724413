import { Controller, UseFormReturn } from "react-hook-form";
import { ApiProjectPartResponses } from "src/FSD/entities/projectPart/model/responseTypes";
import ProjectModals from "src/FSD/widgets/v2/project/modals";
import { Checkbox, Modal } from "src/FSD/shared/uiKit/v2";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { TFieldValues } from "../lib/hooks/useExpertise";
import styles from "../StandardForm/style.module.scss";
import { MoreInfoButton } from "./MoreInfoButton";
import { checkMainExperise } from "../lib/helpers/checkMainExperise";
import { calculateAllSelected } from "../lib/helpers/calculateAllSelected";

type Props = {
  projectPartList: ApiProjectPartResponses.View[];
  projectPartTypes: ApiProjectPartResponses.GetTypes;
  form: UseFormReturn<TFieldValues>;
};

export const Expertise = ({
  projectPartList,
  projectPartTypes,
  form,
}: Props) => {
  const { control, watch, setValue } = form;

  const processValues = watch();

  const { handleCloseModal, name, restData } = useModal();

  return (
    <>
      <Modal
        isActive={name === ModalNames.Project.VIEW_EXPERTISE_INFO}
        handleClose={handleCloseModal}
      >
        <ProjectModals.ViewExpertiseInfo
          info={restData.info}
          header={restData.header}
        />
      </Modal>
      <div className={styles.expertiseProcesses}>
        {projectPartList!.map((projectPart) => {
          const { id, name } = projectPart;
          const { targetTypes } = projectPart.params;

          const containsTooltip = Boolean(targetTypes.info);

          const disabled = processValues.disabled.includes(id);

          if (typeof processValues[id] === "boolean") {
            return (
              <div className={styles.expertiseCheckbox}>
                <Controller
                  name={`${id}`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      label={name}
                      onChange={(value) => {
                        checkMainExperise(processValues, id, value, setValue);

                        onChange(value);
                      }}
                      checked={value as boolean}
                      editing
                      disabled={disabled}
                    />
                  )}
                />
                {containsTooltip && (
                  <MoreInfoButton name={name} info={targetTypes.info} />
                )}
              </div>
            );
          }

          const areAllSelected = targetTypes.types.some((type) =>
            processValues.areAllSelected.includes(`${id}.${type}`)
          );

          return (
            <>
              <div className={styles.expertiseCheckbox}>
                <Checkbox label={name} checked={areAllSelected} disabled grey />
                {containsTooltip && (
                  <MoreInfoButton name={name} info={targetTypes.info} />
                )}
              </div>
              <div className={styles.expertiseProcesses__subSelect}>
                {targetTypes.types.map((type) => {
                  const subProcessId = `${id}.${type}`;

                  return (
                    <Controller
                      name={subProcessId}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          label={projectPartTypes[type]}
                          onChange={(value) => {
                            checkMainExperise(
                              processValues,
                              id,
                              value,
                              setValue
                            );

                            onChange(value);

                            calculateAllSelected(
                              processValues,
                              subProcessId,
                              setValue
                            );
                          }}
                          editing
                          checked={value as boolean}
                          disabled={disabled}
                        />
                      )}
                    />
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
