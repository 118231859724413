import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectSections,
  setPublishNull,
} from "src/app/feature/ProjectView/SectionsReducer/projectSections";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { useProjectProcessUpdatePublishedMutation } from "src/FSD/entities/projectProcess/api";
import { useProcessWorkUpdatePublishedMutation } from "src/FSD/entities/processWork/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchPublishInfo } from "src/app/feature/ProjectView/SectionsReducer/thunks";
import { toServerDate } from "src/FSD/shared/lib/helpers/toServerDate";
import { Nullable } from "src/types/generics";
import { timestampToDate } from "src/FSD/shared/lib/helpers/timestampToDate";
import { updatePublishProcessSchemas } from "src/FSD/shared/uiKit/v2/UpdatePublishProcess/lib/schemas/updatePublishProcessSchemas";
import { TFieldValues } from "src/FSD/shared/uiKit/v2/UpdatePublishProcess/ui/UpdatePublishProcess";

export interface TUpdatePublishProcessProps {
  id: number;
  hasExpertise: boolean;
  price: string;
  vor: boolean;
  vorPnr: boolean;
  dateLimit: Nullable<string>;
  dateStart: Nullable<string>;
  executorType: { value: string; full: string; key: number }[];
  pay1: number;
  pay2: number;
  pay3: number;
  kac: boolean;
  isWork?: boolean;
}

export const useUpdatePublishProcess = (props: TUpdatePublishProcessProps) => {
  const { update, publishInfo, isLoadPublishInfo } =
    useSelector(getProjectSections);

  const dispatch = useDispatch();

  const { handleClose } = useModalContext();

  const [
    updatePublishedProcess,
    { isLoading: updatePublishedProcessIsLoading },
  ] = useProjectProcessUpdatePublishedMutation();

  const [updatePublishedWork, { isLoading: updatePublishedWorkIsLoading }] =
    useProcessWorkUpdatePublishedMutation();

  const {
    id,
    hasExpertise,
    price,
    vor,
    vorPnr,
    kac,
    dateLimit,
    dateStart,
    executorType,
    pay1,
    pay2,
    pay3,
    isWork,
  } = props;

  const defaultExecutorType = executorType?.map(({ full, key }) => ({
    id: key,
    title: full,
  }));
  const additional = publishInfo.vor || publishInfo.vor_pnr || publishInfo.kac;
  const defaultAgreementPrice = price === "Договорная";

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<TFieldValues>({
    resolver: yupResolver(updatePublishProcessSchemas),
    defaultValues: {
      pay1,
      pay2: hasExpertise ? pay2 : pay2 + pay3,
      pay3,
      price: defaultAgreementPrice ? 0 : +price,
      dateStart: timestampToDate(dateStart),
      dateLimit: timestampToDate(dateLimit),
      vor,
      vorPnr,
      kac,
      executorTypes: defaultExecutorType,
      checkNegotiatedPrice: defaultAgreementPrice,
      hasExpertise,
    },
  });

  const formSubmitHandler = async (data: TFieldValues) => {
    const executorType = data.executorTypes.map((el) => el.id);

    const formData = {
      agreementPrice: data.checkNegotiatedPrice,
      vor: !publishInfo.vor ? false : data.vor,
      vor_pnr: !publishInfo.vor_pnr ? false : data.vorPnr,
      kac: !publishInfo.kac ? false : data.kac,
      price: (!data.checkNegotiatedPrice && data.price) || null,
      date_start: toServerDate(data.dateStart),
      date_limit: toServerDate(data.dateLimit),
      executor_type: executorType,
      pay1: +data.pay1,
      pay2: +data.pay2,
      pay3: hasExpertise ? +data.pay3 : null,
    };

    const updateData = { id, data: formData };
    // if (isWork) {
    //   await updatePublishedWork(updateData);
    // } else {
    //   await updatePublishedProcess(updateData);
    // }
    handleClose();
  };

  useEffect(() => {
    if (update) {
      clearErrors();
      dispatch(setPublishNull());
      handleClose();
    }
  }, [update]);

  useEffect(() => {
    dispatch(fetchPublishInfo({ id, isWork }));
  }, []);

  return {
    additional,
    checkNegotiatedPrice: watch("checkNegotiatedPrice"),
    control,
    errors,
    formSubmitHandler,
    handleSubmit,
    hasExpertise,
    isLoadPublishInfo,
    isWork,
    loading: updatePublishedProcessIsLoading || updatePublishedWorkIsLoading,
    pay1,
    pay2,
    pay3,
    price,
    publishInfo,
    register,
  };
};
