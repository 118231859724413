import React, { memo, useEffect } from "react";
import moment from "moment/moment";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useAppDispatch, useAppSelector } from "src/app/store";
import { fetchExpertiseCommentView } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseCommentView";
import {
  getExpertiseRemarkComment,
  getExpertiseRemarkCommentIsLoad,
} from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/selectors/getExpertiseRemarks";
import { Button, Modal, TextField } from "src/FSD/shared/uiKit/v2";
import { fetchExpertiseCreateComment } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseCreateComment";
import { fetchExpertiseRemarks } from "src/app/feature/ProjectProcessView/ExpertisePage/ExpertiseRemarks/services/fetchExpertiseRemarks";
import { getProjectProcessInfoId } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { commentRemarkRowSchemas } from "src/FSD/widgets/v2/projectProcess/modals/CommentRemarkRow/lib/schemas/checkSubTask";
import cls from "./CommentRemarkRowForm.module.scss";

interface ModalCommentProps {
  rowId: number;
}

type TInputs = {
  text: string;
};

export const CommentRemarkRowForm = memo(({ rowId }: ModalCommentProps) => {
  const dispatch = useAppDispatch();
  const comments = useAppSelector(getExpertiseRemarkComment);
  const isLoadComment = useAppSelector(getExpertiseRemarkCommentIsLoad);
  const processId = useAppSelector(getProjectProcessInfoId);
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TInputs>({
    resolver: yupResolver(commentRemarkRowSchemas),
    mode: "onSubmit",
  });

  useEffect(() => {
    dispatch(fetchExpertiseCommentView(rowId));
  }, [rowId]);

  async function handleSubmitForm(dataValues: TInputs) {
    const data = {
      text: dataValues.text,
    };
    await dispatch(fetchExpertiseCreateComment({ rowId, data }));
    if (processId) {
      dispatch(fetchExpertiseRemarks(processId));
    }
    handleClose();
  }

  return (
    <Modal.Layout>
      <Modal.Header>Комментарии</Modal.Header>
      {isLoadComment ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(handleSubmitForm)}>
          <Controller
            control={control}
            name="text"
            render={({ field: { onChange, name } }) => (
              <TextField
                label="Комментарий"
                placeholder="Введите комментарий"
                multiline
                minRows={2}
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
                onChange={onChange}
              />
            )}
          />
          {comments?.map((comment, index) => (
            <div className={cls.form_comment} key={index}>
              <div className={cls.form_comment_flex}>
                <p>{comment.text}</p>
                <p>
                  {moment(comment.date_create, "DD.MM.YYYY HH:mm:ss Z").format(
                    "DD.MM.YYYY"
                  )}
                </p>
              </div>
            </div>
          ))}
          <Modal.Controls>
            <Button type="submit">Отправить</Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
});
