import React from "react";
import classNames from "classnames";
import styles from "src/FSD/widgets/v2/projectProcess/std/ExchangeTasks/ui/ExchangeTasks.module.scss";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";

type TProps = {
  open: boolean;
  count: number;
  onClick: any;
};

export const ExchangeVersionsCount = ({ open, count, onClick }: TProps) => {
  return (
    <div
      className={classNames(styles.label, styles.versions)}
      onClick={onClick}
    >
      Версии: {count} {open ? <ChevronDown /> : <ChevronUp />}
    </div>
  );
};
