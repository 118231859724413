import { TechnicalSpecificationsForm } from "./TechnicalSpecifications";
import { CommentsForm } from "./Comments";
import { FeedbackForm } from "./FeedbackTask";

const TaskModals = {
  TechnicalSpecificationsForm,
  CommentsForm,
  FeedbackForm,
};

export default TaskModals;
