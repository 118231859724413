import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { TaskIconStatus } from "src/FSD/shared/uiKit/v2";
import { daysFromToday } from "src/FSD/shared/lib/helpers/daysFromToday";

const taskStatuses: Partial<Record<Statuses, TaskIconStatus>> = {
  [Statuses.ACCEPT]: "done",
  [Statuses.DONE]: "done",
  [Statuses.CANCELLED]: "work",
  [Statuses.IS_DONE]: "work",
  [Statuses.IS_REJECT]: "work",
  [Statuses.WORK]: "work",
  [Statuses.REJECT]: "cross",
};

export const getTaskStatus = (statusKey: Statuses, dateDeadline: string) => {
  const iconStatus = taskStatuses[statusKey];

  if (iconStatus === "work" && statusKey !== Statuses.CANCELLED) {
    const daysDiffernce = daysFromToday(dateDeadline);
    return daysDiffernce < 0 ? "expired" : iconStatus;
  }

  return iconStatus || "cross";
};
