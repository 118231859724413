import { useEffect } from "react";
import { TSelectItem } from "src/FSD/shared/uiKit/v2/Select";
import { useDataMapper } from "src/FSD/shared/lib/hooks/useDataMapper";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { toSelect } from "../../../../../../app/feature/project/projectProcessPublication";
import { Nullable } from "../../../../../../types/generics";
import { useQueries } from "./useQueries";

type UseModalProps = {
  id: number;
  type: "Project" | "ProjectProcess" | "ProcessWork";
  executor?: Nullable<TSelectItem>;
  userTypeId?: number;
  setValue?: any;
};

export const useSelectUserTypeToAssign = ({
  id,
  type,
  executor,
  userTypeId,
  setValue,
}: UseModalProps) => {
  const { fetchGetGetUserTypeToAssign, userTypeToAssignRaw, isLoading } =
    useQueries({ id, type, executor });

  const isProcessWork =
    type === PublishedObjectControllingEntities.PROCESS_WORK;

  useEffect(() => {
    if (isProcessWork) {
      fetchGetGetUserTypeToAssign();
    }
  }, [isProcessWork]);

  useEffect(() => {
    if (!isProcessWork && executor) {
      fetchGetGetUserTypeToAssign();
    }
  }, [executor, isProcessWork]);

  const userTypeToAssign = useDataMapper(userTypeToAssignRaw, toSelect);

  useEffect(() => {
    if (!userTypeToAssign) {
      return;
    }

    if (!userTypeId) {
      return;
    }

    const userTypeValue = userTypeToAssign.find(
      (item: TSelectItem) => +item.id === userTypeId
    );

    setValue("userTypeToAssign", userTypeValue);
  }, [userTypeToAssign, userTypeId]);

  const isPendingTypeAssign = (isLoading || !userTypeToAssign) && !!executor;

  return {
    userTypeToAssign,
    isPendingTypeAssign,
  } as const;
};
