import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "src/FSD/shared/api/common";
import { ApiTags } from "src/FSD/shared/api/tags";

type TData = {
  class: string;
  content: string;
  refund: boolean;
  reason: string;
  reason2: number;
  reason3: boolean;
  data: { price: number; id: number }[];
};

type TParams = RequestParams<{
  objectId: number;
  data: TData;
}>;

export const removeExecutor = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ objectId, data }) => ({
      url: `v1/project-access/remove-executor`,
      params: {
        objectId,
      },
      method: HTTPMethods.POST,
      data,
    }),
    invalidatesTags: [
      ApiTags.ProcessWork.VIEW,
      ApiTags.ProjectProcess.VIEW,
      ApiTags.Project.VIEW,
    ],
  });
