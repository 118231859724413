import { Modal, useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { FilesList } from "src/FSD/shared/uiKit/v2/FilesList/FilesList";
import { useCheckSubCheckProcess } from "../lib/hooks/useCheckSubCheckProcess";

const defaultValues = {
  comment: "",
  status: null,
};

type Props = {
  taskId: number;
};

export const CheckSubCheckProcessForm = ({ taskId }: Props) => {
  const { handleClose } = useModalContext();

  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    isRejecting,
    isAccepting,
    isSubmitting,
    isTaskViewPending,
    taskData,
  } = useCheckSubCheckProcess({
    taskId,
    defaultValues,
    handleClose,
  });

  return (
    <Modal.Layout>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <>
          <Modal.Header>{taskData!.name}</Modal.Header>
          <Modal.Form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register("comment")}
              label="Замечания"
              multiline
              minRows={3}
              error={Boolean(errors.comment)}
              helperText={errors.comment?.message}
            />
            <FilesList
              fileLinks={taskData!.parent!.files}
              label="Документация на проверке"
            />
            <Modal.Controls>
              <Button
                disabled={isSubmitting}
                isLoading={isRejecting}
                type="submit"
                color="error"
                variant="outlined"
                width={120}
                onClick={() => {
                  setValue("status", Statuses.REJECT);
                }}
              >
                Отказать
              </Button>
              <Button
                disabled={isSubmitting}
                isLoading={isAccepting}
                type="submit"
                width={120}
                onClick={() => {
                  setValue("status", Statuses.ACCEPT);
                }}
              >
                Принять
              </Button>
            </Modal.Controls>
          </Modal.Form>
        </>
      )}
    </Modal.Layout>
  );
};
