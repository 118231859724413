import React, { useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import { iconBtnSx } from "src/styles/restyle";
import { useAppSelector } from "src/app/store";
import { getProjectProcessInfoName } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import Modal from "src/components/newUI/Modal/Modal";
import { ModalCompleteRow } from "src/components/pages/ProjectProcessView/Expertise/ExpertiseCard/Remark/RemarkTable/RemarkTableContent/ModalCompleteRow/ModalCompleteRow";
import { PencilSquare } from "react-bootstrap-icons";

export const TableCardBodyPencilBtn = ({ task, row }: any) => {
  const [modalEditAnswer, setModalEditAnswer] = useState(false);
  const processName = useAppSelector(getProjectProcessInfoName);

  const closeModal = useCallback(() => {
    setModalEditAnswer(false);
  }, []);

  return (
    <>
      <Modal active={modalEditAnswer} setActive={setModalEditAnswer}>
        <ModalCompleteRow
          row={row}
          task={task}
          closeModal={closeModal}
          expertise={true}
          can={task.can}
          expertiseName={processName}
          mode={"edit"}
        />
      </Modal>
      <IconButton
        title={"Редактировать"}
        sx={iconBtnSx}
        onClick={() => setModalEditAnswer(true)}
      >
        <PencilSquare size={24} />
      </IconButton>
    </>
  );
};

export default TableCardBodyPencilBtn;
