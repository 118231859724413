import React from "react";
import { TWork } from "src/app/feature/ProjectProcessView/Work/types/projectProcessWorkTypes";
import { useAppSelector } from "src/app/store";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { UpdatePublishProcess } from "src/FSD/shared/uiKit/v2/UpdatePublishProcess";
import { useUpdatePublishProcess } from "src/FSD/widgets/v2/projectProcess/modals/TaskPublishUpdate/lib/hooks/useUpdatePublishProcess";

export const TaskPublishUpdate = ({
  date_limit,
  date_start,
  pay2,
  pay1,
  pay3,
  price,
  projectPart,
  id,
  executorType,
  vor,
  vor_pnr,
  title,
}: TWork) => {
  const dataProcess = useAppSelector(getProjectProcessInfoData);

  const props = useUpdatePublishProcess({
    id,
    hasExpertise: dataProcess?.haveExpertise || false,
    price,
    vor,
    vorPnr: vor_pnr,
    kac: false,
    dateLimit: date_limit,
    dateStart: date_start,
    executorType,
    pay1,
    pay2,
    pay3,
    isWork: true,
  });

  return (
    <UpdatePublishProcess
      title={`Редактирование задачи «${title}»`}
      projectPart={projectPart}
      {...props}
    />
  );
};
