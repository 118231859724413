import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BreadcrumbsMui } from "src/components/newUI/BreadcrumbsMui/BreadcrumbsMui";
import style from "../Project/View/style.module.scss";
import { Wrapper } from "../../UI/templates";
import TitlePanel from "../../newUI/TitlePanel/TitlePanel";
import TabsPanel from "../../newUI/TabsPanel/TabsPanel";
import useTabs from "../../hooks/useTabs";
import { Info } from "./Info/Info";
import {
  getProjectProcessInfoName,
  getProjectProcessInfoProject,
  getProjectProcessInfoProjectPartType,
  getProjectProcessInfoStatus,
  getProjectProcessIsPause,
} from "../../../app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { getTabShowProcessInfo } from "../../../app/feature/ProjectProcessView/Info/selectors/getTabShowProcessInfo";
import { Documents } from "./Documents/Documents";
import { Tasks } from "./Tasks/Tasks";
import { RemoveExecutors } from "./RemoveExecutors/RemoveExecutors";
import { CheckTask } from "./CheckTask/CheckTask";
import { Work } from "./Work/Work";
import { Applications } from "./Applications/Applications";
import { Materials } from "./Materials/Materials";
import { Documentations } from "./Documentations/Documentations";
import { InitialData } from "./InitialData/InitialData";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { HeaderBtn } from "./HeaderBtn/HeaderBtn";
import { generateTabsData } from "./helpers/generateTabsData";
import { MileStone } from "./MileStone/MileStone";
import { Expertise } from "./Expertise/Expertise";
import { InfoExpertise } from "./ExpertiseTabs/InfoExpertise/InfoExpertise";
import { ExpertiseHeaderBtn } from "./ExpertiseTabs/ExpertiseHeaderBtn/ExpertiseHeaderBtn";
import { DocumentsExpertise } from "./ExpertiseTabs/DocumentsExpertise/DocumentsExpertise";
import { ConclusionExpertise } from "./ExpertiseTabs/ConclusionExpertise/ConclusionExpertise";
import { RemarksExpertise } from "./ExpertiseTabs/RemarksExpertise/RemarksExpertise";
import { fetchProjectProcessView } from "../../../app/feature/ProjectProcessView/Info/services/fetchProjectProcessView";
import { getApplicationsRemoveRequestSuccess } from "../../../app/feature/ProjectProcessView/Applications/selectors/getApplicationsRemoveRequest";
import { getApplicationsSelectExecutorSuccess } from "../../../app/feature/ProjectProcessView/Applications/selectors/getApplicationsSelectExecutor";

const ProjectProcessView = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const tabShow = useAppSelector(getTabShowProcessInfo);
  const name = useAppSelector(getProjectProcessInfoName);
  const status = useAppSelector(getProjectProcessInfoStatus);
  const projectPartType = useAppSelector(getProjectProcessInfoProjectPartType);
  const pauseStatus = useAppSelector(getProjectProcessIsPause);
  const project = useAppSelector(getProjectProcessInfoProject);
  const removeRequestSuccess = useAppSelector(
    getApplicationsRemoveRequestSuccess
  );
  const selectExecutorSuccess = useAppSelector(
    getApplicationsSelectExecutorSuccess
  );

  const statusText = pauseStatus ? `${status} (приостановлен)` : status;
  const [tabs, setTabs] = useState<any>([]);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchProjectProcessView(+params.id));
    }
  }, [params.id, removeRequestSuccess, selectExecutorSuccess]);

  const isExpertise = projectPartType === 4;

  useEffect(() => {
    if (tabShow && typeof isExpertise === "boolean") {
      const tabsData = generateTabsData(tabShow, isExpertise);
      setTabs(tabsData);
    }
  }, [tabShow]);

  const [currentTab, setCurrentTab] = useState("");
  useTabs(currentTab, setCurrentTab, "info");

  const setApplicationsTab = useCallback(
    () => setCurrentTab("applications"),
    []
  );
  const setInfoTab = useCallback(() => setCurrentTab("info"), []);

  const components = (
    <>
      {currentTab === "info" && (
        <Info setApplicationsTab={setApplicationsTab} />
      )}
      {currentTab === "documents" && <Documents />}
      {currentTab === "inputTask" && <Tasks />}
      {currentTab === "remove" && <RemoveExecutors />}
      {currentTab === "checkTasks" && <CheckTask />}
      {currentTab === "work" && <Work />}
      {currentTab === "applications" && (
        <Applications setInfoTab={setInfoTab} />
      )}
      {currentTab === "materials" && <Materials />}
      {currentTab === "executionTask" && (
        <Documentations currentTab={currentTab} />
      )}
      {currentTab === "sourceFiles" && <InitialData />}
      {currentTab === "stages" && <MileStone />}
      {currentTab === "expertiseInTasks" && <Expertise />}
    </>
  );
  const componentsExpertise = (
    <>
      {currentTab === "info" && <InfoExpertise />}
      {currentTab === "expertiseDocuments" && <DocumentsExpertise />}
      {currentTab === "expertiseConclusion" && <ConclusionExpertise />}
      {currentTab === "expertiseRemark" && <RemarksExpertise />}
    </>
  );

  const breadcrumbs = [
    {
      isActive: true,
      title: `${project?.code} ${project?.name}`,
      url: `/project/${project?.id}`,
    },
    {
      isActive: true,
      title: `Разделы`,
      url: `/project/${project?.id}?tab=project-process`,
    },
  ];

  if (typeof isExpertise === "boolean") {
    return (
      <div className={style.wrapper}>
        <Wrapper>
          {project && (
            <BreadcrumbsMui items={breadcrumbs} variant={style.breadcrumbs} />
          )}
          <TitlePanel
            isContentLoad={false}
            primaryText={name}
            title={name}
            secondaryText={statusText}
            small
            projectView
          >
            {!isExpertise && <HeaderBtn currentTab={currentTab} />}
            {isExpertise && <ExpertiseHeaderBtn currentTab={currentTab} />}
          </TitlePanel>
          <div className={style.contents}>
            <TabsPanel
              isContentLoad={tabs.length === 0}
              tabsInfo={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              setDefaultTab={setInfoTab}
              components={isExpertise ? componentsExpertise : components}
            />
          </div>
        </Wrapper>
      </div>
    );
  }
  return <></>;
};

export default ProjectProcessView;
