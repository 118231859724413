import parse from "html-react-parser";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { CanCheckType } from "src/app/feature/ProjectProcessView/Documentations/types/projectProcessDocumentationsTypes";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import styles from "./DepositFunds.module.scss";
import { useDepositFunds } from "../../lib/hooks/useDepositFunds";

type Props = {
  canCheckData: CanCheckType;
  id: number;
  type: PublishedObjectControllingEntities;
};

export const DepositFunds = ({ canCheckData, id, type }: Props) => {
  const { transferDepositFunds } = useDepositFunds({ id, type });

  return (
    <>
      <div
        className={
          canCheckData.showBtn ? styles.checkParseBtn : styles.checkParse
        }
      >
        {parse(canCheckData.msg)}
      </div>
      {canCheckData.showBtn && (
        <Modal.Controls>
          <Button width={250} onClick={transferDepositFunds}>
            Перевести средства
          </Button>
        </Modal.Controls>
      )}
    </>
  );
};
