import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { KanbanModel } from "../../../kanban";

export const useModalEditFreeTask = () => {
  const dispatch = useAppDispatch();

  const editFreeTask = useAppSelector(KanbanModel.getEditFreeTask);

  const handleCloseEditFreeTask = useCallback(() => {
    dispatch(
      KanbanModel.setEditFreeTask({
        isOpen: false,
        taskId: null,
      })
    );
  }, []);

  return { editFreeTask, handleCloseEditFreeTask } as const;
};
