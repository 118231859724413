import React, { memo } from "react";
import { CheckCircle, Clock, ExclamationCircle } from "react-bootstrap-icons";
import { palette } from "src/styles/restyle";
import cls from "./SourceDataView.module.scss";

interface CommentIconTypeProps {
  status: string;
}

export const CommentIconType = memo(({ status }: CommentIconTypeProps) => {
  return (
    <div className={cls.commentIcon}>
      {status === "success" && (
        <CheckCircle size={16} color={palette.persianGreen} />
      )}
      {status === "undefined" && (
        <ExclamationCircle size={16} color={palette.signalOrange} />
      )}
      {status === "inprocess" && <Clock size={16} />}
    </div>
  );
});
