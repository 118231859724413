import { Controller } from "react-hook-form";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { TextField } from "src/FSD/shared/uiKit/v2/TextField";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Uploader } from "src/FSD/shared/uiKit/v2/Uploader";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import { getTaskFiles } from "src/FSD/shared/lib/helpers/getTaskFiles";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { useExecuteComplete } from "../lib/hooks";

type Props = {
  id: number;
  header: string;
  isCustomerAlign: boolean;
};

export const ExecuteCompleteForm = ({ id, header, isCustomerAlign }: Props) => {
  const {
    handleSubmit,
    onSubmit,
    register,
    setValue,
    control,
    errors,
    isRejecting,
    isAccepting,
    isSubmitting,
    isTaskViewPending,
    textBtnIsReject,
  } = useExecuteComplete({
    id,
    isCustomerAlign,
    defaultValues: {
      comment: "",
      status: null,
      files: [],
    },
  });

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>
      {isTaskViewPending ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="files"
            control={control}
            render={({ field: { onChange } }) => (
              <Uploader
                canEdit
                autoUpload
                postUrl={`${config.localDomain}/v1/task/add-file?id=${id}`}
                getDataCallback={() => getTaskFiles(id)}
                setFiles={(files) => {
                  onChange(files);
                }}
                error={Boolean(errors.files)}
                helperText={errors.files?.message}
                uploadAsActive
              />
            )}
          />
          <TextField
            {...register("comment")}
            label="Комментарий"
            multiline
            minRows={3}
            error={Boolean(errors.comment)}
            helperText={errors.comment?.message}
          />
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              type="submit"
              onClick={() => {
                setValue("status", Statuses.IS_REJECT);
              }}
              variant="outlined"
              color="primary"
              isLoading={isRejecting}
            >
              {textBtnIsReject}
            </Button>
            <Button
              disabled={isSubmitting}
              type="submit"
              onClick={() => {
                setValue("status", Statuses.IS_DONE);
              }}
              color="primary"
              isLoading={isAccepting}
            >
              Отправить на проверку
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
