import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRequestRemoveGipStartMutation } from "src/FSD/entities/requestRemoveGip/api";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { requestRemoveGipSchema } from "../schema/requestRemoveGip";

type TFieldValues = {
  comment: string;
};

type UseModalProps = {
  projectId: number;
  defaultValues: TFieldValues;
};

export const useRequestRemoveGip = ({
  projectId,
  defaultValues,
}: UseModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(requestRemoveGipSchema),
  });

  const { handleClose } = useModalContext();

  const [removeGip] = useRequestRemoveGipStartMutation();

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      StartForm: {
        content: data.comment,
      },
    };

    await removeGip({ projectId, data: formedData });

    handleClose();
  };

  return { handleSubmit, errors, isSubmitting, onSubmit, register } as const;
};
