import React, { useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { SkeletonGip } from "src/components/UI/components/Project/Skeleton";
import { ScrollWrap } from "src/components/newUI/ScrollWrap/ScrollWrap";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { useResolution } from "src/FSD/shared/lib/hooks/useResolution";
import { InfoGip } from "./InfoGip/InfoGip";
import { InfoObject } from "./InfoObject/InfoObject";
import { InfoDescription } from "./InfoDescription/InfoDescription";
import { InfoFiles } from "./InfoFiles/InfoFiles";
import { InfoDesign } from "./InfoDesign/InfoDesign";
import InfoExecutor from "./InfoCustomer/InfoCustomer";
import { SkeletonInfo } from "./SkeletonInfo";
import styles from "./Info.module.scss";
import { useTabsPanelContext } from "../../TabsPanel/ui/TabsPanel/TabsPanelContext";

export const Info = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setCurrentTab } = useTabsPanelContext();
  const isTablet = useResolution(1600);

  const {
    project,
    isLoad,
    searchGipStatus,
    cancelSearchGip,
    isLoadGip,
    removeGip,
    removeGipComplete,
    requestGip,
    cancelRequestGip,
  } = useSelector(getProjectViewData);

  useEffect(() => {
    if (searchGipStatus.length !== 0) {
      enqueueSnackbar(searchGipStatus, {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (cancelSearchGip) {
      enqueueSnackbar("Поиск ГИП отменен", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (removeGip) {
      enqueueSnackbar("Запрос на снятие ГИПа прошел успешно", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (removeGipComplete) {
      enqueueSnackbar("Запрос на рассмотрение прошел успешно", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (requestGip) {
      enqueueSnackbar("Заявка на ГИП прошла успешно", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
    if (cancelRequestGip) {
      enqueueSnackbar("Заявка на ГИП отозвана", {
        variant: "success",
        autoHideDuration: 4000,
      });
    }
  }, [
    searchGipStatus,
    cancelSearchGip,
    removeGip,
    removeGipComplete,
    project,
    requestGip,
    cancelRequestGip,
  ]);

  if (isLoad && !searchGipStatus) {
    return <SkeletonInfo />;
  }

  if (project) {
    const { files, description } = project;
    return (
      <ScrollWrap
        height={isTablet ? "auto" : "994px"}
        classname={styles.wrapper}
      >
        <InfoDescription description={description} name={project.name} />
        <div className={styles.object}>
          <div className={styles.object_block1}>
            <InfoDesign />
            <InfoObject />
          </div>
          <div className={styles.object_block1}>
            <InfoExecutor />
            {isLoadGip ? (
              <SkeletonGip />
            ) : (
              <InfoGip key={project.id} setCurrentTab={setCurrentTab} />
            )}
          </div>
        </div>
        <InfoFiles files={files} />
        <div className={classNames(styles.documents)}>
          <h1>Документация</h1>
          {project.can.downloadDocs && (
            <Button
              color="primary"
              target={"_blank"}
              redirect
              href={`/project/download-files?id=${project.id}&withTasks=0`}
            >
              Скачать
            </Button>
          )}
        </div>
      </ScrollWrap>
    );
  }
  return null;
};
