import { RequestCustomerAlignForm } from "./RequestCustomerAlignForm";
import { useEditRequestCustomerAlign } from "../lib/hooks/useEditRequestCustomerAlign";

export type Props = {
  taskId: number;
};

export const EditRequestCustomerAlignForm = ({ taskId }: Props) => {
  const { form, onSubmit, isFetching } = useEditRequestCustomerAlign({
    taskId,
  });

  return (
    <RequestCustomerAlignForm
      form={form}
      onSubmit={onSubmit}
      isFetching={isFetching}
    />
  );
};
