import { List } from "src/FSD/shared/uiKit/v2/List";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import { TabChildrenHeader } from "../../../../TabsPanel";
import { useRemoved } from "../lib/hooks/useRemoved";
import { RemovedExecutor } from "./RemovedExecutor";

type Props = {
  projectProcessId: number;
};
export const Removed = ({ projectProcessId }: Props) => {
  const { removedExecutors, isExecutorsLoading } = useRemoved(projectProcessId);

  return (
    <>
      <TabChildrenHeader title="Снятые исполнители" />
      <List maxHeight="865px">
        {isExecutorsLoading ? (
          <Skeleton.List />
        ) : (
          <ListMapper>
            {removedExecutors!.map((removed) => (
              <RemovedExecutor key={removed.id} removed={removed} />
            ))}
          </ListMapper>
        )}
      </List>
    </>
  );
};
