import React, { memo, useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import ProjectModals from "src/FSD/widgets/v2/project/modals";
import { iconBtnSx } from "src/styles/restyle";
import { setCheckInput } from "src/app/feature/ProjectView/projectView";
import { useAppDispatch } from "src/app/store";
import { PlusSquareFill } from "react-bootstrap-icons";
import { useParams } from "react-router";

interface AddFilesButtonProps {
  task_id: number;
  parentId: number;
  index?: number;
  projectId: number;
  type: "process" | "project";
}

export const AddFilesButton = memo(
  ({ task_id, parentId, index, projectId, type }: AddFilesButtonProps) => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const infoId = Number(params.id);

    const [modalFiles, setModalFiles] = useState(false);

    const handleUploader = () => {
      setModalFiles(true);
      dispatch(setCheckInput(true));
    };

    const handleClose = useCallback(() => {
      setModalFiles(false);
    }, []);

    return (
      <>
        <Modal isActive={modalFiles} handleClose={handleClose}>
          <ProjectModals.AddFilesModal
            setModalFiles={setModalFiles}
            taskId={task_id}
            parentId={parentId}
            index={index}
            projectId={projectId}
            infoId={infoId}
            type={type}
          />
        </Modal>
        <IconButton onClick={handleUploader} sx={{ ...iconBtnSx, padding: 0 }}>
          <PlusSquareFill size={32} />
        </IconButton>
      </>
    );
  }
);
