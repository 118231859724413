import { useState } from "react";
import { useProjectSourceDataIndexQuery } from "src/FSD/entities/projectSourceData/api";

type TProps = {
  id: number;
  parentId: number;
  type: number;
  searchValue: string;
};

export const useSourceDataCard = ({
  id,
  parentId,
  type,
  searchValue,
}: TProps) => {
  const [showSourceData, setShowSourceData] = useState(false);
  const { data = [], isLoading: isLoadingData } =
    useProjectSourceDataIndexQuery(
      {
        id,
        parentId,
        type,
        searchValue,
      },
      { skip: !showSourceData }
    );

  const isLoading = isLoadingData || !data;

  const handleClick = () => {
    setShowSourceData((prevState) => !prevState);
  };

  return {
    showSourceData,
    handleClick,
    isLoading,
    data,
  };
};
