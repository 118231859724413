import { memo, ReactNode } from "react";
import { SelectMultiple } from "src/FSD/shared/uiKit/v2/SelectMultiple";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { TSelectItem } from "../../../../../components/features/Forms/SetGipForm/types";
import { useSelectExecutorTypes } from "../lib/hooks/useSelectExecutorTypes";

type Props = {
  value: TSelectItem[];
  changeHandler: (newValue: TSelectItem[]) => void;
  error?: boolean;
  helperText?: ReactNode;
  setValue?: any;
  processWork?: ApiProcessWorkResponses.View;
};

export const SelectExecutorTypesMultiple = memo(
  ({
    value,
    changeHandler,
    error = false,
    helperText = "",
    setValue,
    processWork,
  }: Props) => {
    const { executorTypes, isExecutorTypesLoading } = useSelectExecutorTypes({
      setValue,
      processWork,
    });

    return (
      <SelectMultiple
        value={value}
        options={executorTypes ?? []}
        isLoading={isExecutorTypesLoading}
        changeHandler={changeHandler}
        label="Форма собственности"
        showSelectAll
        error={error}
        helperText={helperText}
      />
    );
  }
);
