import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from "src/app/store";
import { useThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton/useThreeDotButton";
import { getProjectProcessInfoData } from "src/app/feature/ProjectProcessView/Info/selectors/getProjectProcessInfo";
import { ProjectProcessInfoTypes } from "src/app/feature/ProjectProcessView/Info/types/projectProcessInfoTypes";
import { CheckIconType } from "src/components/newUI/CheckIconType/CheckIconType";
import { ThreeDotButton } from "src/FSD/shared/uiKit/v2/ThreeDotButton";
import { Modal } from "src/FSD/shared/uiKit/v2";
import { useModalAdditionalRequirementsUpdate } from "src/FSD/entities/publishedObject/lib/hooks/useModalAdditionalRequirements";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import styles from "./Requirements.module.scss";
import PublishedObjectModals from "../../../../../publishedObject/modals";

export const Requirements = () => {
  const { threeDotButtonRef, closeThreeDot } = useThreeDotButton();

  const infoData = useAppSelector(
    getProjectProcessInfoData
  ) as ProjectProcessInfoTypes;

  const { kac, vor, vor_pnr, published } = infoData.projectPart;

  const isAdditionalRequirements = kac || vor || vor_pnr;

  const showOptions =
    infoData.can.update && published && isAdditionalRequirements;

  const CheckIconTypeParams = {
    colorCheck: "#0AA699",
    colorClose: "#CE0000",
    fontSize: "20px",
  };

  const {
    additionalRequirementsUpdate,
    handleOpenAdditionalRequirementsUpdate,
    handleCloseAdditionalRequirementsUpdate,
  } = useModalAdditionalRequirementsUpdate();

  return (
    <>
      <Modal
        isActive={additionalRequirementsUpdate.isOpen}
        handleClose={handleCloseAdditionalRequirementsUpdate}
      >
        <PublishedObjectModals.AdditionalRequirementsUpdateForm
          id={infoData.id}
          header="Дополнительные требования к разделу"
          type={PublishedObjectControllingEntities.PROJECT_PROCESS}
        />
      </Modal>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <h2>Требования к разделу</h2>
          {showOptions && (
            <ThreeDotButton ref={threeDotButtonRef} color="success">
              <MenuItem
                onClick={() => {
                  closeThreeDot();
                  handleOpenAdditionalRequirementsUpdate();
                }}
                disableRipple
              >
                Редактировать
              </MenuItem>
            </ThreeDotButton>
          )}
        </div>
        {infoData.projectPart.published && (
          <div className={styles.container_body}>
            <h3>Договор</h3>
            <h3>Разработка</h3>
            <h3 />
            <CheckIconType
              {...CheckIconTypeParams}
              isCheck={infoData.secure}
              text="Безопасная сделка"
              className={styles.container_check}
            />
            <CheckIconType
              {...CheckIconTypeParams}
              isCheck={infoData.bim}
              text="BIM"
              className={styles.container_check}
            />
            <CheckIconType
              {...CheckIconTypeParams}
              isCheck={infoData.vor}
              text="Ведомость объемов работ"
              className={styles.container_check}
            />
            <CheckIconType
              {...CheckIconTypeParams}
              isCheck={infoData.with_docs}
              text="Заключение договора"
              className={styles.container_check}
            />
            <CheckIconType
              {...CheckIconTypeParams}
              isCheck={infoData.kac}
              text="Конъюнктурный анализ цен"
              className={styles.container_check}
            />
            <CheckIconType
              {...CheckIconTypeParams}
              isCheck={infoData.vor_pnr}
              text="Ведомость пусконаладочных работ"
              className={styles.container_check}
            />
          </div>
        )}
        <hr className={styles.yellowLine} />
      </div>
    </>
  );
};
