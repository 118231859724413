import React from "react";
import { Eye } from "react-bootstrap-icons";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import ProjectProcessModals from "src/FSD/widgets/v2/projectProcess/modals";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { taskStatusIcon } from "../lib/helpers/taskStatusIcon";
import styles from "./ExecutionTasks.module.scss";

type TProps = {
  modalHeader: string;
  checks: ApiProjectProcessResponses.TabChecks;
};

export const ExecutionChecks = ({ checks = {}, modalHeader }: TProps) => {
  const checksArray = Object.values(checks);
  const firstFiveElements = checksArray.slice(0, 6);
  const { length } = checksArray;
  const [showModal, setShowModal] = React.useState(false);
  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <div className={styles.checks}>
      <div className={styles.checks__header}>Согласующие({length})</div>
      {!!length && (
        <>
          <div className={styles.checks__list}>
            {firstFiveElements.map(({ header, status: { value, key } }) => (
              <div className={styles.checks__item} key={header}>
                <div className={styles.checks__item__icon}>
                  {taskStatusIcon(key)}
                </div>
                <div className={styles.checks__item__title}>{header}</div>
                <div className={styles.checks__item__desc}>{value}</div>
              </div>
            ))}
          </div>
          {length > 3 && (
            <>
              <button
                type="button"
                className={styles.checks__show}
                onClick={() => setShowModal(true)}
              >
                Посмотреть всех <Eye />
              </button>
              <Modal isActive={showModal} handleClose={hideModal}>
                <ProjectProcessModals.Checks
                  header={`Согласующие "${modalHeader}"`}
                  checks={checksArray}
                />
              </Modal>
            </>
          )}
        </>
      )}
    </div>
  );
};
