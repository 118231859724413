import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { create, deleteDocument, indexDocument } from "./queries";

export const documentApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    documentCreate: create(builder),
    documentDelete: deleteDocument(builder),
    documentIndex: indexDocument(builder),
  }),
  overrideExisting: false,
});

export const {
  useDocumentCreateMutation,
  useDocumentDeleteMutation,
  useDocumentIndexQuery,
} = documentApi;
