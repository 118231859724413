import {
  KanbanColumns,
  KanbanModel,
  KanbanStatuses,
} from "src/FSD/entities/kanban";
import {
  pushErrorMessage,
  pushSuccessMessage,
} from "../../../../../../../app/feature/errorTrace";
import { ColumnShift, Errors } from "./useKanbanDnD";
import { getTaskRowId } from "../../helpers/getTaskRowId";

export const isDoneToInWork = ({
  dispatch,
  sourceColumnId,
  destinationColumnId,
  sourceRowId,
  destinationRowId,
  rowTasks,
  result,
  backToWork,
  groupBy,
}: ColumnShift) => {
  // из "Проверка" в "В работе"
  if (
    sourceColumnId === KanbanStatuses[KanbanColumns.IS_DONE] &&
    destinationColumnId === KanbanStatuses[KanbanColumns.IN_WORK]
  ) {
    // получаем задачи из "В работе" и "Проверка"
    const sourceColumnTasks = [
      ...rowTasks[sourceRowId].columns[sourceColumnId],
    ];
    const destinationColumnTasks = [
      ...rowTasks[destinationRowId].columns[destinationColumnId],
    ];

    // удаляем задачу из колонки-истока "В работе", добавляем в колонку-сток "Проверка"
    const [reorderedTask] = sourceColumnTasks.splice(result.source.index, 1);
    destinationColumnTasks.splice(result.destination!.index, 0, reorderedTask);

    if (!reorderedTask.can.complete && !reorderedTask.can.backToWork) {
      dispatch(pushErrorMessage(Errors.ACTION_NOT_ALLOWED));
      return;
    }

    const taskRowId = getTaskRowId(reorderedTask, destinationRowId, groupBy);

    if (taskRowId !== destinationRowId) {
      dispatch(pushErrorMessage(Errors.NO_SUCH_DIRECTION));
      return;
    }

    // обновляем колонки для группы
    const updatedTasks = {
      ...rowTasks[sourceRowId].columns,
      [sourceColumnId]: sourceColumnTasks,
      [destinationColumnId]: destinationColumnTasks,
    };

    // возвращаем задачу в работу
    backToWork!({
      id: reorderedTask.id,
      data: { status: destinationColumnId },
    })!.then(() => {
      dispatch(pushSuccessMessage());
    });

    // записываем обновленные колонки группы в state
    dispatch(
      KanbanModel.setRowTasks({
        rowId: sourceRowId,
        updatedTasks,
      })
    );
  }
};
