import React, { memo } from "react";
import { TabChildrenHeader } from "src/FSD/widgets/v2/TabsPanel";
import { ExpertiseConclusionHeader } from "src/FSD/widgets/v2/projectProcess/std/ExpertiseConclusion/ui/ExpertiseConclusionHeader";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { useExpertiseConclusion } from "src/FSD/widgets/v2/projectProcess/std/ExpertiseConclusion/lib/hooks/useExpertiseConclusion";
import { ConclusionCard } from "./ConclusionCard";
import styles from "./ExpertiseConclusion.module.scss";

type TProps = {
  can: ApiProjectProcessResponses.View["can"];
  processId: number;
};

export const ExpertiseConclusion = memo(({ can, processId }: TProps) => {
  const { data, isLoading } = useExpertiseConclusion({ processId });

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Skeleton.List height={65} />
      ) : (
        <>
          <TabChildrenHeader
            title="Заключение"
            media={
              <ExpertiseConclusionHeader processId={processId} can={can} />
            }
            className={styles.header}
          />
          {data.map((conclusion) => (
            <ConclusionCard key={conclusion.id} can={can} {...conclusion} />
          ))}
        </>
      )}
    </div>
  );
});
