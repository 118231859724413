import React from "react";
import { ApiProjectProcessResponses } from "src/FSD/entities/projectProcess/model/responseTypes";
import { Processes } from "./Processes";
import { Project } from "./Project";

type TProps = {
  data: ApiProjectProcessResponses.GetFilesDto;
};

export const TabDataByProcess = ({ data }: TProps) => {
  const projects = Object.entries(data?.projects);
  const processes = data?.process;

  return (
    <>
      {projects &&
        projects.map(([id, project]) => (
          <Project key={id} {...project} id={id} />
        ))}
      {processes && (
        <Processes
          canAdd={processes?.canAdd}
          name={processes?.name}
          files={processes?.files}
        />
      )}
    </>
  );
};
