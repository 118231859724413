import KanbanModals from "src/FSD/widgets/v2/kanban/modals";
import { Filter, TaskCompletionModals } from "src/FSD/widgets/v2/kanban";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import ProjectModals from "src/FSD/widgets/v2/project/modals";
import { useModalViewKanbanTask } from "src/FSD/entities/kanban/lib/hooks/useModalViewKanbanTask";
import { useModalCreateFreeTask } from "src/FSD/entities/project/lib/hooks/useModalCreateFreeTask";
import { useModalEditFreeTask } from "src/FSD/entities/project/lib/hooks/useModalEditFreeTask";
import { useKanbanPage } from "../lib/hooks";
import KanbanDesktop from "./KanbanDesktop";

export const KanbanPage = () => {
  const { isMobile, onFreeTaskDelete, onFreeTaskUpdate } = useKanbanPage();

  const { viewKanbanTask, handleCloseKanbanViewTask } =
    useModalViewKanbanTask();

  const { createFreeTask, handleCloseCreateFreeTask } =
    useModalCreateFreeTask();

  const { editFreeTask, handleCloseEditFreeTask } = useModalEditFreeTask();

  if (isMobile) {
    return null;
  }

  return (
    <>
      <TaskCompletionModals />

      <Modal
        isActive={viewKanbanTask.isOpen}
        handleClose={handleCloseKanbanViewTask}
      >
        <KanbanModals.ViewKanbanTask
          taskId={viewKanbanTask.taskId!}
          header={viewKanbanTask.header!}
          isCompletion={viewKanbanTask.isCompletion!}
        />
      </Modal>
      <Modal
        isActive={createFreeTask.isOpen}
        handleClose={handleCloseCreateFreeTask}
      >
        <ProjectModals.CreateFreeTaskForm />
      </Modal>
      <Modal
        isActive={editFreeTask.isOpen}
        handleClose={handleCloseEditFreeTask}
      >
        <ProjectModals.EditFreeTaskForm
          taskId={editFreeTask.taskId!}
          onTaskDelete={onFreeTaskDelete}
          onTaskUpdate={onFreeTaskUpdate}
        />
      </Modal>
      <Filter />
      <KanbanDesktop />
    </>
  );
};
