import React, { memo, MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { getProjectGraphData } from "src/app/feature/ProjectView/GraphReducer/graphReducer";
import { ToggleAccordion } from "src/components/newUI/ToggleAccordion/ToggleAccordion";
import TransitionShowContent from "src/components/newUI/TransitionShowContent/TransitionShowContent";
import { newIconBtnSx } from "src/styles/restyle";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import ProjectModals from "src/FSD/widgets/v2/project/modals";
import { PencilSquare } from "react-bootstrap-icons";
import { FactChild } from "./FactChild/FactChild";
import { GraphRender } from "./GraphRender/GraphRender";
import style from "../style.module.scss";

export const PerformanceGraph = memo(() => {
  const params = useParams<{ id: string }>();

  const { project } = useSelector(getProjectViewData);
  const { isLoadUpdateDate, fact, forecast, plan, today, factChild } =
    useSelector(getProjectGraphData);

  const [editDateModal, setEditDateModal] = useState(false);
  const [showGraph, setShowGraph] = useState({
    plan: true,
    forecast: true,
    fact: true,
  });

  const closeEditDateModal = () => {
    setEditDateModal(false);
  };

  const toggleGraph = (name: string) => {
    if (name === "plan") {
      setShowGraph((prevState) => {
        return {
          ...prevState,
          plan: !prevState.plan,
        };
      });
    }
    if (name === "forecast") {
      setShowGraph((prevState) => {
        return {
          ...prevState,
          forecast: !prevState.forecast,
        };
      });
    }
    if (name === "fact") {
      setShowGraph((prevState) => {
        return {
          ...prevState,
          fact: !prevState.fact,
        };
      });
    }
  };

  function openModalClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setEditDateModal(true);
  }

  return (
    <>
      {!!plan?.length && (
        <div className={style.basic}>
          <div onClick={() => toggleGraph("plan")} className={style.flex}>
            <p>План (расчётный)</p>
            <ToggleAccordion toggle={showGraph.plan} secondRoot />
          </div>
          <TransitionShowContent open={showGraph.plan}>
            <div className={style.graphBlock}>
              {plan.map(([name, task], index: number) => (
                <GraphRender
                  key={index}
                  graph={task}
                  name={name}
                  today={today}
                />
              ))}
            </div>
          </TransitionShowContent>
        </div>
      )}
      <div className={style.basic}>
        {project?.can.updateGanttData && params.id && (
          <Modal isActive={editDateModal} handleClose={closeEditDateModal}>
            <ProjectModals.EditDate
              id={+params.id}
              title="Редактировать сроки"
              isLoad={isLoadUpdateDate}
            />
          </Modal>
        )}
        <div
          className={classNames(
            style.accordion,
            forecast?.length === 0 && style.noCursor
          )}
          onClick={() => toggleGraph("forecast")}
        >
          <div className={style.flex}>
            <p>План по договору</p>
            {!!forecast?.length && (
              <ToggleAccordion toggle={showGraph.forecast} secondRoot />
            )}
          </div>
          {project?.can.updateGanttData && (
            <div>
              <IconButton
                onClick={(e) => openModalClick(e)}
                title="Редактировать даты"
                sx={newIconBtnSx}
              >
                <PencilSquare size={20} />
              </IconButton>
            </div>
          )}
        </div>
        {!!forecast?.length && (
          <TransitionShowContent open={showGraph.forecast}>
            <div className={style.graphBlock}>
              {forecast.map(([name, task], index: number) => (
                <GraphRender
                  key={index}
                  graph={task}
                  name={name}
                  today={today}
                />
              ))}
            </div>
          </TransitionShowContent>
        )}
      </div>

      {!!fact?.length && (
        <div className={style.basic}>
          <div className={style.flex} onClick={() => toggleGraph("fact")}>
            <p>Факт-прогноз</p>
            <ToggleAccordion toggle={showGraph.fact} secondRoot />
          </div>
          <TransitionShowContent open={showGraph.fact}>
            <div className={style.graphBlock}>
              {fact.map(([name, task], index: number) => (
                <GraphRender
                  key={index}
                  graph={task}
                  name={name}
                  today={today}
                />
              ))}
              <>
                {factChild && !!factChild.length && (
                  <div className={style.factChild}>
                    <h2>Факт-прогноз по сооружениям</h2>
                    {factChild.map((child: any) => (
                      <FactChild key={child.name} child={child} today={today} />
                    ))}
                  </div>
                )}
              </>
            </div>
          </TransitionShowContent>
        </div>
      )}
    </>
  );
});
