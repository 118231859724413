import {
  useProcessWorkUpdateAdditionMutation,
  useProcessWorkViewQuery,
} from "src/FSD/entities/processWork/api";
import {
  useProjectProcessUpdateAdditionMutation,
  useProjectProcessViewQuery,
} from "src/FSD/entities/projectProcess/api";

export const useQueries = (isProcessWork: boolean, id: number) => {
  const useViewQuery = isProcessWork
    ? useProcessWorkViewQuery
    : useProjectProcessViewQuery;

  const useUpdateAdditionMutation = isProcessWork
    ? useProcessWorkUpdateAdditionMutation
    : useProjectProcessUpdateAdditionMutation;

  const { data: processData, isFetching } = useViewQuery({
    id,
  });

  const [updateAddition] = useUpdateAdditionMutation();

  return {
    isFetching,
    processData,
    updateAddition,
  };
};
