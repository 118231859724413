import * as Yup from "yup";
import {
  dateRequired,
  dateFutureRequired,
  dateLimitExceedsDateStartRequired,
  priceMinMaxRequired,
  executorTypesRequired,
  numberRequired,
  messages,
  percentMinMaxRequired,
} from "src/utils/SimplifiedYup";
import { userIsAdmin } from "src/app/services/user/userRoles";

const isAdmin = userIsAdmin();

export const updatePublishProcessSchemas = Yup.object().shape({
  dateStart: isAdmin
    ? dateRequired("Начальный срок")
    : dateFutureRequired("Начальный срок"),
  dateLimit: isAdmin
    ? dateRequired("Конечный срок")
    : dateLimitExceedsDateStartRequired("Конечный срок"),
  price: Yup.number().when("checkNegotiatedPrice", {
    is: (checkNegotiatedPrice) => !checkNegotiatedPrice,
    then: priceMinMaxRequired("Стоимость", 1000, 10000000).test(
      "shouldBeIntNumber",
      messages.shouldBeIntNumber,
      (value: any) => {
        return !Number.isNaN(value) && Number.isInteger(value);
      }
    ),
  }),
  executorTypes: executorTypesRequired("Форма собственности"),
  pay1: percentMinMaxRequired("Аванс", 20, 80),
  pay2: numberRequired("Оплата после приемки"),
  paymentSum: Yup.number()
    .transform((value) => (Number.isNaN(value) ? 0 : value))
    .test("max", messages.totalAmountPercent, function () {
      const { hasExpertise, pay1, pay2, pay3 } = this.parent;

      if (hasExpertise) {
        return pay1 + pay2 + pay3 === 100;
      }
      return pay1 + pay2 === 100;
    }),
});
