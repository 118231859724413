import { forwardRef } from "react";
import { Select, SelectProps } from "src/FSD/shared/uiKit/v2/Select";
import { useRequestUpdate } from "../lib/hooks/useSelectUserTypes";

type Props = {
  setValue?: any;
  userTypeId?: number;
  onBimUserChange?: (isBimUser: boolean) => void;
} & Omit<SelectProps, "label" | "options">;

export const SelectUserTypes = forwardRef(
  (
    {
      value,
      setValue,
      userTypeId,
      className = "",
      disabled = false,
      error = false,
      helperText = "",
      onBimUserChange = () => {},
      ...props
    }: Props,
    ref
  ) => {
    const { userTypes, isPendingUserTypes } = useRequestUpdate({
      setValue,
      userTypeId,
      onBimUserChange,
    });

    return (
      <Select
        {...props}
        ref={ref}
        value={value}
        options={userTypes ?? []}
        isLoading={isPendingUserTypes}
        label="Форма собственности"
        className={className}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />
    );
  }
);
