import { SkeletonWorks } from "./SkeletonWorks";
import { WorksHeader } from "./WorksHeader";
import { GroupAccordion } from "./GroupAccordion";
import { useProjectProcessWorks } from "../lib/hooks/useProjectProcessWorks";

export const Works = () => {
  const { fullCost, isLoading, groups } = useProjectProcessWorks();

  if (isLoading) {
    return <SkeletonWorks />;
  }

  return (
    <>
      <WorksHeader title="Задачи" fullCost={fullCost} isLoading={isLoading} />
      {groups && (
        <>
          {groups.map(([userId, group]) => (
            <GroupAccordion key={userId} userId={+userId} {...group} />
          ))}
        </>
      )}
    </>
  );
};
