type TUser = {
  surname: string;
  name: string;
  patronymic: string;
};

export const formFullUserName = (user: TUser) => {
  return [user?.surname, user?.name, user?.patronymic]
    .filter(Boolean)
    .join(" ");
};
