import classNames from "classnames";
import { TTabsItem } from "src/app/feature/ProjectView/thunks";
import styles from "./TabToggle.module.scss";
import { Devices } from "../../lib/constants/devices";
import { Dot } from "./Dot";
import { useTabsPanelContext } from "../TabsPanel/TabsPanelContext";

type TProps = {
  currentTab: boolean;
  item: TTabsItem;
  device: Devices;
};

export const TabToggle = ({ currentTab, item, device }: TProps) => {
  const { setCurrentTab } = useTabsPanelContext();

  const handleClick = () => {
    if (currentTab) {
      return;
    }
    setCurrentTab(item.name);
  };

  if (device === Devices.DESKTOP) {
    return (
      <div
        onClick={handleClick}
        className={classNames(styles.toggle, {
          [styles.active]: currentTab,
        })}
      >
        {item.dot && <Dot />}
        {item.icon && (
          <span className={classNames(styles.icon, item.icon)}></span>
        )}
        <div>{item.title}</div>
        {item.stop && <span className={styles.stop}>(stop!)</span>}
      </div>
    );
  }
  if (device === Devices.TABLET) {
    return (
      <div className={styles.tablet} onClick={handleClick}>
        <div
          className={classNames(styles.iconTablet, {
            [styles.iconTabletActive]: currentTab,
          })}
        >
          {item.icon && <span className={item.icon}></span>}
        </div>
        <p>{item.title}</p>
      </div>
    );
  }
  return null;
};
