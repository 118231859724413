import { Button, Heading } from "src/FSD/shared/uiKit/v2";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { WorkFlowClasses } from "src/FSD/shared/lib/constants/workFlowClasses";
import styles from "./CheckTasks.module.scss";
import { Process } from "./Process";
import { Props as DetailsProps } from "./Details";

const modalNameByWorkflowClass: Partial<Record<WorkFlowClasses, string>> = {
  [WorkFlowClasses.CHECK_SUB_CHECK_PROCESS]:
    ModalNames.ProjectProcess.CHECK_SUB_CHECK_PROCESS,
  [WorkFlowClasses.CHECK_SUB_CHECK]: ModalNames.ProjectProcess.CHECK_SUB_CHECK,
};

export type Props = DetailsProps & {
  height: number;
};

export const Processes = ({ id, checks, task, height }: Props) => {
  const { handleOpenModal } = useModal();

  return (
    <div className={styles.processes}>
      <div className={styles.processes__header}>
        <Heading className={styles.processes__header__title}>
          Согласующие ({checks.length})
        </Heading>
        {task.can.check && (
          <Button
            onClick={() =>
              handleOpenModal({
                name: modalNameByWorkflowClass[checks[0].workflow_class]!,
                taskId: id,
              })
            }
          >
            Проверить
          </Button>
        )}
      </div>
      <div className={styles.processes__list} style={{ height }}>
        {checks.map((check) => (
          <Process key={check.id} {...check} />
        ))}
      </div>
    </div>
  );
};
