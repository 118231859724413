import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { HTTPMethods } from "../../../../shared/api/common";

type TParams = RequestParams<{
  id: number;
  data: Record<string, number[]>;
}>;

export const changeExpertise = (builder: QueryBuilder) =>
  builder.mutation<void, TParams>({
    query: ({ id, data }) => ({
      url: `v1/project/change-expertise`,
      params: {
        id,
      },
      method: HTTPMethods.POST,
      data,
    }),
  });
