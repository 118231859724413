import { ReactNode } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import {
  Button,
  DateField,
  Modal,
  Paragraph,
  TextField,
} from "src/FSD/shared/uiKit/v2";
import { ApiCommonResponses } from "src/FSD/entities/apiCommonResponses";
import { SelectBankAccountsUserType } from "src/FSD/features/v2/SelectBankAccountsUserType/ui/SelectBankAccountsUserType";
import { SelectUserTypes } from "src/FSD/features/v2/SelectUserTypes";
import { ApiTaskResponses } from "src/FSD/entities/task/model/responseTypes";
import styles from "./RequestForm.module.scss";
import { MissingAttributesError } from "./MissingAttributesError";
import { WarningMessage } from "./WarningMessage";
import { TFieldValues } from "../lib/hooks/useRequest";

type Props = {
  taskData?: ApiTaskResponses.View;
  header: string;
  entityTitle: string;
  form: UseFormReturn<TFieldValues>;
  onSubmit: (dataValues: TFieldValues) => void;
  isEditing?: boolean;
  isFetching?: boolean;
  warningMessages: ReactNode;
  typeMissingAttributes: ApiCommonResponses.SecureMissingAttributes[];
  showBimWarning: boolean;
  showUserTypeWarning: boolean;
  showBankAccountField: boolean;
};

export const RequestForm = ({
  taskData,
  header,
  entityTitle,
  form,
  onSubmit,
  isEditing,
  isFetching,
  warningMessages,
  typeMissingAttributes,
  showBimWarning,
  showUserTypeWarning,
  showBankAccountField,
}: Props) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    register,
    formState: { isSubmitting, errors },
  } = form;

  const showWarningMessage = showBimWarning || showUserTypeWarning;

  const userTypeValue = watch("userType");

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>{header}</Modal.Header>
      {isFetching ? (
        <Modal.Preloader />
      ) : (
        <Modal.Form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.requestUpdateForm}
        >
          <Paragraph color="light">
            Срок и стоимость исполнения {entityTitle}
          </Paragraph>
          <div className={styles.wrapper}>
            <Controller
              control={control}
              name="dateStart"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Начальный срок"
                  startDateProp={value}
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  error={!!errors.dateStart}
                  helperText={errors?.dateStart?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="dateLimit"
              render={({ field: { value, onChange } }) => (
                <DateField
                  label="Конечный срок"
                  startDateProp={value}
                  changeDateHandler={(date) => {
                    onChange(date);
                  }}
                  error={!!errors.dateLimit}
                  helperText={errors?.dateLimit?.message}
                />
              )}
            />
            <TextField
              {...register("price")}
              label="Стоимость"
              error={!!errors.price}
              helperText={errors?.price?.message}
            />
            <Paragraph fontSize={14} className={styles.wrapper__text}>
              Обращаем ваше внимание, что вы имеете возможность изменить
              указанные заказчиком сроки и стоимость исполнения {entityTitle}
            </Paragraph>
          </div>
          <div>
            <Controller
              name="userType"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <SelectUserTypes
                    value={value}
                    changeHandler={(_, newValue) => {
                      onChange(newValue);
                    }}
                    setValue={setValue}
                    userTypeId={taskData?.workflow_data?.executor_type}
                    error={!!errors.userType}
                    helperText={errors?.userType?.message}
                    onBimUserChange={(isBimUser) => {
                      setValue("isBimUser", isBimUser);
                    }}
                  />
                  {Boolean(typeMissingAttributes.length && value) && (
                    <MissingAttributesError
                      missingAttributes={typeMissingAttributes}
                    >
                      {warningMessages}
                    </MissingAttributesError>
                  )}
                </>
              )}
            />
          </div>
          {showBankAccountField && (
            <Controller
              name="bankAccount"
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectBankAccountsUserType
                  value={value}
                  changeHandler={(_, newValue) => {
                    onChange(newValue);
                  }}
                  disableClear
                  userType={userTypeValue}
                  disabled={!userTypeValue}
                  setValue={setValue}
                  bankAccountId={taskData?.workflow_data?.bank_account_id}
                  error={!!errors.bankAccount}
                  helperText={errors?.bankAccount?.message}
                  isBankAccount={!!value}
                />
              )}
            />
          )}
          <TextField {...register("comment")} label="Комментарий" multiline />
          {showWarningMessage && (
            <WarningMessage
              showBimWarning={showBimWarning}
              showUserTypeWarning={showUserTypeWarning}
            />
          )}
          <Modal.Controls>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              {isEditing ? "Изменить" : "Отправить"}
            </Button>
          </Modal.Controls>
        </Modal.Form>
      )}
    </Modal.Layout>
  );
};
