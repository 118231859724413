import { ReactNode, memo } from "react";
import styles from "./Column.module.scss";

type Props = {
  children: ReactNode;
};

const ColumnRange = memo(({ children }: Props) => {
  return <p className={styles.header__range}>{children}</p>;
});

export default ColumnRange;
