import { ApiUserPageContent } from "../../../../../types/api/responseTypes/userIndividualPage/apiUserContent";
import { UserAboutProps } from "../../../../features/User/UserAbout/UserAbout";
import { primaryType } from "../../../../../types/other/components/componentPropsType";
import { UserJobPlaceListProps } from "../../../../features/User/UserJobPlaceList/UserJobPlaceList";
import { getGapDateString } from "../../../../../app/services/date/date";
import { UserEducationProps } from "../../../../features/User/UserEducation/UserEducation";
import { UserPortfolioProps } from "../../../../features/User/PortfolioUser/UserPortfolio";
import { config } from "../../../../../app.cofig";
import { UserFeedbackProps } from "../../../../features/User/UserFeedback/UserFeedback";
import { UserRewardsProps } from "../../../../features/User/UserRewards/UserRewards";

type KeysComponentProps = keyof primaryType;

type UIContentType = Omit<UserAboutProps, KeysComponentProps> & {
  dataJobPlaces: UserJobPlaceListProps["data"];
  dataEducations: UserEducationProps["data"];
  dataPortfolios: UserPortfolioProps["data"];
  dataRewards: UserRewardsProps["data"];
  dataFeedbacks: UserFeedbackProps["data"];
};

const createUserJobPlacesData = (
  data: ApiUserPageContent["jobPlaces"]
): UIContentType["dataJobPlaces"] =>
  data.map((item) => ({
    date: getGapDateString(item.date_start, item.date_end),
    description: item.job_functions,
    firm: item.firm_name,
    prof: item.prof,
  }));

const createUserEducationData = (
  data: ApiUserPageContent["educations"]
): UIContentType["dataEducations"] =>
  data.map((item) => ({
    ...item,
    date: getGapDateString(item.date_start, item.date_end),
  }));

const createUserPortfolioData = (
  data: ApiUserPageContent["portfolios"]
): UIContentType["dataPortfolios"] =>
  data.map((item) => ({
    id: item.id,
    buildingType: item.buildingFunction.name || "",
    description: item.description || "",
    name: item.name || "",
    photo: item.photo ? `${config.localDomain}${item.photo}` : "",
    files: !item.files
      ? []
      : item.files.map((item) => ({
          title: item.name || "",
          url: item.url || "",
        })),
  }));

const createUserFeedbacksData = (
  data: ApiUserPageContent["feedbacks"]
): UIContentType["dataFeedbacks"] =>
  data.map((item) => ({
    comment: item.comment || "",
    loyalty: item.loyalty.toString() || "",
    quality: item.quality.toString() || "",
    reliability: item.reliability.toString() || "",
    task: {
      fullName:
        item.task && item.task.taskProcess
          ? `${item.task.taskProcess.projectPart.name}`
          : "",
      title:
        item.task && item.task.taskProcess
          ? `${item.task.taskProcess.projectPart.code}`
          : "",
      url: `${config.localDomain}/project-process/${
        item.task && item.task.taskProcess ? item.task.taskProcess.id : ""
      }`,
    },
    date: item.date_in.slice(0, 10),
    toPersonRole: item.type ? item.type.key : 0,
    author: {
      src: `/user/${item.author.id}?tab=info`,
      photo: `${config.localDomain}${item.author.photo}`,
      name: `${item.author.name} ${item.author.surname}`,
    },
  }));

const createUserRewards = (
  data: ApiUserPageContent["rewards"]
): UIContentType["dataRewards"] =>
  data.map((item) => ({
    title: item.name,
    src: `${config.localDomain}${item.file_id}`,
  }));

export const createContentData = (
  data: ApiUserPageContent
): UIContentType | null => {
  try {
    return {
      about: data.about,
      dataJobPlaces: createUserJobPlacesData(data.jobPlaces),
      dataEducations: createUserEducationData(data.educations),
      dataPortfolios: createUserPortfolioData(data.portfolios),
      dataFeedbacks: createUserFeedbacksData(data.feedbacks),
      dataRewards: createUserRewards(data.rewards),
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return null;
  }
};
