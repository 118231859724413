import { IconButton, LinearProgress } from "@mui/material";
import { Trash } from "react-bootstrap-icons";
import parse from "html-react-parser";
import cn from "classnames";
import { config } from "src/FSD/shared/lib/configs/app.config";
import { DefaultFileIcon } from "../../../../../../components/features/Uploader/UploaderUtils/UI";
import styles from "./styles.module.scss";
import { FileProps } from "../model/types";
import { useAppSelector } from "../../../../../../app/store";
import { getProjectViewData } from "../../../../../../app/feature/ProjectView/projectView";
import { truncateString } from "../../../../lib/helpers/truncateString";
import { Checkbox } from "../../Checkbox";

export const File = ({
  id,
  name,
  svgIcon,
  visibility,
  handleChangeActive,
  handleDelete,
  canEdit,
  progresses,
  serverId,
}: FileProps) => {
  const { actually } = useAppSelector(getProjectViewData);

  const formattedName = truncateString(name, 30);

  const isUploaded = progresses[id] === 100 || serverId;

  const handleDownloadFile = () => {
    window.open(`${config.localDomain}/v1/file/get?id=${serverId}`, "Download");
  };

  const showDeleteIcon = canEdit && (!visibility || !actually);

  const showCheck = Boolean(actually && canEdit);

  return (
    <div onClick={handleDownloadFile} className={styles.file}>
      <div className={cn(styles.svg, Boolean(svgIcon) && styles.isUploaded)}>
        {svgIcon ? parse(svgIcon) : <DefaultFileIcon />}
      </div>
      <div className={styles.controls}>
        {isUploaded ? (
          <div
            className={cn(
              styles.controls__main,
              !showCheck && styles.controls__main_noCheck
            )}
          >
            <p>{formattedName}</p>
            {showCheck && (
              <Checkbox
                label="Актуальный"
                editing
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onChange={() => {
                  handleChangeActive(id, serverId, !visibility);
                }}
                checked={visibility}
              />
            )}
          </div>
        ) : (
          <LinearProgress
            color="success"
            variant="determinate"
            value={progresses[id] ?? 0}
          />
        )}
      </div>
      {showDeleteIcon && (
        <IconButton
          className={styles.deleteFile}
          onClick={(event) => {
            event.stopPropagation();
            handleDelete(id, serverId);
          }}
        >
          <Trash color="white" />
        </IconButton>
      )}
    </div>
  );
};

export default File;
