import { rootApi } from "src/FSD/shared/api/common/rootApi";
import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { start } from "./queries";

export const requestRemoveGipApi = rootApi.injectEndpoints({
  endpoints: (builder: QueryBuilder) => ({
    requestRemoveGipStart: start(builder),
  }),
  overrideExisting: false,
});

export const { useRequestRemoveGipStartMutation } = requestRemoveGipApi;
