import { TPropsObject } from "../hooks/useObjects";

export const formStageObject = ({ object }: TPropsObject) => {
  const objectStages = Object.entries(object.stage).map(
    ([key, value]: any) => ({
      label: key,
      work: value.work,
      done: value.done,
      enable: value.enable,
      showStage: value.work || value.done || value.enable,
    })
  );

  return objectStages;
};

export const areAllStagesValuesFalse = ({ object }: TPropsObject) => {
  const stages = formStageObject({ object });

  return stages.every((stage) => !stage.showStage);
};
