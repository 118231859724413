import React from "react";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { config } from "src/app.cofig";
import { getProjectViewData } from "src/app/feature/ProjectView/projectView";
import { useIsMobile } from "src/components/hooks/useIsMobile";
import { SearchBar } from "src/FSD/shared/uiKit/v2/SearchBar/SearchBar";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Menu } from "src/FSD/shared/uiKit/v2/Menu";
import { iconBtnSx, palette } from "src/styles/restyle";
import { CaretDownFill, FileArrowDownFill } from "react-bootstrap-icons";

type TProps = {
  setSearchValue: (value: string) => void;
};

export const HeaderActions = ({ setSearchValue }: TProps) => {
  const { project } = useSelector(getProjectViewData);
  const showMenu = project?.can.view;
  const params = useParams<{ id: string }>();
  const isMobile = useIsMobile(681);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs md="auto">
        <SearchBar onChange={setSearchValue} />
      </Grid>
      {showMenu && (
        <Grid item xs="auto">
          {isMobile ? (
            <IconButton
              onClick={handleClick}
              sx={iconBtnSx}
              title={"Скачать отчет"}
            >
              <FileArrowDownFill />
            </IconButton>
          ) : (
            <Button
              id="initial-button"
              aria-controls={isOpen ? "initial-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isOpen ? "true" : undefined}
              onClick={handleClick}
              endIcon={<CaretDownFill color={palette.midnightBlack} />}
            >
              Скачать отчет
            </Button>
          )}
          <Menu
            id="initial-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "initial-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={handleClose}
              component={"a"}
              href={`${config.localDomain}/project-source-data/pdf?projectId=${params.id}`}
            >
              Общий
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component={"a"}
              href={`${config.localDomain}/project-source-data/pdf?projectId=${params.id}&executor=1`}
            >
              По заказчику
            </MenuItem>
          </Menu>
        </Grid>
      )}
    </Grid>
  );
};
