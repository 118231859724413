import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Statuses } from "src/FSD/shared/lib/constants/statuses";
import {
  useTaskCompleteMutation,
  useTaskViewQuery,
} from "src/FSD/entities/task/api";
import { useModalContext } from "src/FSD/shared/uiKit/v2/Modal";
import { useAppDispatch } from "../../../../../../../../app/store";
import { Nullable } from "../../../../../../../../types/generics";
import { executeCompleteSchema } from "../schemas";
import { pushSuccessMessage } from "../../../../../../../../app/feature/errorTrace";

type TFieldValues = {
  comment: string;
  status: Nullable<Statuses>;
  files: File[];
};

type UseModalProps = {
  id: number;
  isCustomerAlign: boolean;
  defaultValues: TFieldValues;
};

export const useExecuteComplete = ({
  id,
  isCustomerAlign,
  defaultValues,
}: UseModalProps) => {
  const { handleClose } = useModalContext();

  const dispatch = useAppDispatch();

  const { data: taskData, isFetching } = useTaskViewQuery({
    id,
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(executeCompleteSchema),
  });

  useEffect(() => {
    if (!taskData) {
      return;
    }

    setValue("comment", taskData.comment);
  }, [taskData]);

  const [taskComplete] = useTaskCompleteMutation();

  const status = watch("status");

  const textBtnIsReject = isCustomerAlign
    ? "Не требуется"
    : "Отказаться от исполнения";

  const onSubmit = async (data: TFieldValues) => {
    const formedData = {
      CompleteForm: {
        comment: data.comment,
        status: data.status!,
      },
    };

    const result = await taskComplete({ id, task: formedData });

    // @ts-ignore
    if (!result?.error) {
      handleClose();
      dispatch(pushSuccessMessage());
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
    setValue,
    isSubmitting,
    status,
    isRejecting: isSubmitting && status === Statuses.IS_REJECT,
    isAccepting: isSubmitting && status === Statuses.IS_DONE,
    isTaskViewPending: isFetching || !taskData,
    textBtnIsReject,
  } as const;
};
