import moment from "moment";
import { stringDateToDDMMYYYY } from "./stringDateToDDMMYYYY";
import { Nullable } from "../../../../types/generics";

/*
  Принимает timestamp, который может быть строкой или null (Nullable<string>). Если timestamp не null, 
  функция преобразует его в дату формат DD.MM.YYYY. Если timestamp равен null, функция возвращает null.
  
  Функция не предполагает работу с точным временем, а учитывает только время с начала дня.
  (функция stringDateToDDMMYYYY не учитывает время, а только дату).
*/

export const timestampToDate = (timestamp?: Nullable<string>) =>
  timestamp
    ? moment(stringDateToDDMMYYYY(timestamp), "DD.MM.YYYY").toDate()
    : null;
