import { IconButton } from "@mui/material";
import { PencilSquare } from "react-bootstrap-icons";
import { List } from "src/FSD/shared/uiKit/v2/List";
import { Skeleton } from "src/FSD/shared/uiKit/v2/Skeleton";
import { Button } from "src/FSD/shared/uiKit/v2/Button";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { ListMapper } from "src/FSD/shared/uiKit/v2/ListMapper/ListMapper";
import { ModalNames, useModal } from "src/FSD/shared/lib/hooks/useModal";
import { ApiProcessWorkResponses } from "src/FSD/entities/processWork/model/responseTypes";
import { PublishedObjectControllingEntities } from "src/FSD/entities/publishedObject/model/controllingEntities";
import { Tooltip } from "src/FSD/shared/uiKit/v2/Tooltip";
import { formFullUserName } from "src/FSD/shared/lib/helpers";
import { TabChildrenHeader } from "../../../TabsPanel";
import { useExecutionTasks } from "../lib/hooks/useExecutionTasks";
import { ExecutionTask } from "./ExecutionTask";
import styles from "./ExecutionTask.module.scss";
import TaskModals from "../../../task/modals";
import { BackToWorkButton } from "../../../../../features/v2/BackToWorkButton";
import { ExecutionTaskDates } from "./ExecutionTaskDates";
import PublishedObjectModals from "../../../publishedObject/modals";
import { palette } from "../../../../../../styles/restyle";
import ProcessWorkModals from "../../modals";

type Props = {
  processWorkId: number;
  can: ApiProcessWorkResponses.View["can"];
};

export const ExecutionTasks = ({ processWorkId, can }: Props) => {
  const {
    isExecutionTaskLoading,
    executionTaskList,
    canRequestCustomerAlignStart,
    canCustomerAlignStart,
    isCustomerAlignTab,
    modalNameCheckWork,
  } = useExecutionTasks({ processWorkId, can });

  const { name, handleOpenModal, handleCloseModal, restData } = useModal();

  return (
    <>
      <Modal
        isActive={name === ModalNames.Task.COMMENTS}
        handleClose={handleCloseModal}
      >
        <TaskModals.CommentsForm taskId={restData.taskId} />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProcessWork.CHECK_CUSTOMER_ALIGN}
        handleClose={handleCloseModal}
      >
        <ProcessWorkModals.CheckCustomerAlignForm
          id={restData.taskId}
          header={restData.header}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.ProcessWork.CHECK_EXECUTE_WORK}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.CheckExecuteCompleteObject
          taskId={restData.taskId}
          id={processWorkId}
          header={restData.header}
          type={PublishedObjectControllingEntities.PROCESS_WORK}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.EXECUTE_COMPLETE}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.ExecuteCompleteForm
          id={restData.taskId}
          header={restData.header}
          isCustomerAlign={isCustomerAlignTab}
        />
      </Modal>
      <Modal
        isActive={name === ModalNames.PublishedObject.CUSTOMER_ALIGN_START}
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.CustomerAlignStartForm
          id={processWorkId}
          type={PublishedObjectControllingEntities.PROCESS_WORK}
        />
      </Modal>
      <Modal
        isActive={
          name === ModalNames.PublishedObject.REQUEST_CUSTOMER_ALIGN_START
        }
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.RequestCustomerAlignStartForm
          id={processWorkId}
          type={PublishedObjectControllingEntities.PROCESS_WORK}
        />
      </Modal>
      <Modal
        isActive={
          name === ModalNames.PublishedObject.EDIT_REQUEST_CUSTOMER_ALIGN
        }
        handleClose={handleCloseModal}
      >
        <PublishedObjectModals.EditRequestCustomerAlignForm
          taskId={restData.taskId}
        />
      </Modal>
      <TabChildrenHeader
        title="Документация"
        mediaClassName={styles.mediaClassName}
        media={
          <>
            {canRequestCustomerAlignStart && (
              <Button
                onClick={() => {
                  handleOpenModal({
                    name: ModalNames.PublishedObject
                      .REQUEST_CUSTOMER_ALIGN_START,
                  });
                }}
              >
                Запросить
              </Button>
            )}
            {canCustomerAlignStart && (
              <Button
                onClick={() => {
                  handleOpenModal({
                    name: ModalNames.PublishedObject.CUSTOMER_ALIGN_START,
                  });
                }}
              >
                Выдать
              </Button>
            )}
          </>
        }
      />
      <List maxHeight="865px">
        {isExecutionTaskLoading ? (
          <Skeleton.List />
        ) : (
          <ListMapper>
            {executionTaskList.map((executionTask: any) => {
              const { task, status } = executionTask;

              const executorName = formFullUserName(task.executor);

              const actions = (
                <div className={styles.actions}>
                  {task.can.check && (
                    <Button
                      color="primary"
                      onClick={() => {
                        handleOpenModal({
                          name: modalNameCheckWork,
                          taskId: task.id,
                          header: task.name,
                        });
                      }}
                    >
                      Проверить
                    </Button>
                  )}
                  {task.can.complete && (
                    <Button
                      color="primary"
                      onClick={() => {
                        handleOpenModal({
                          name: ModalNames.PublishedObject.EXECUTE_COMPLETE,
                          taskId: task.id,
                          header: task.name,
                          comment: task.comment,
                        });
                      }}
                    >
                      Выдать
                    </Button>
                  )}
                  {task.can.edit && (
                    <IconButton
                      onClick={() => {
                        handleOpenModal({
                          name: ModalNames.PublishedObject
                            .EDIT_REQUEST_CUSTOMER_ALIGN,
                          taskId: task.id,
                        });
                      }}
                    >
                      <PencilSquare color={palette.lightGrey} />
                    </IconButton>
                  )}
                  {task.can.backToWork && (
                    <Tooltip title="Вернуть на доработку" followCursor>
                      <BackToWorkButton taskId={task.id} />
                    </Tooltip>
                  )}
                </div>
              );

              return (
                <ExecutionTask
                  key={task.id}
                  taskId={task.id}
                  parentId={task.parent_id}
                  filesCount={task.files.length}
                  dates={
                    <ExecutionTaskDates
                      status={status}
                      task={task}
                      isRequest={executionTask?.isRequest}
                    />
                  }
                  taskName={task.name}
                  userName={executorName}
                  actions={actions}
                />
              );
            })}
          </ListMapper>
        )}
      </List>
    </>
  );
};
