import moment from "moment";
import * as Yup from "yup";
import { declensionOfDay } from "src/FSD/shared/lib/helpers";
import { formatLongNumber } from "./formatLongNumber";
import { Nullable } from "../types/generics";

export const messages = {
  requiredField: (field: string) => `Необходимо заполнить «${field}»`,
  requiredNDigits: (n: number) => `Поле состоит из ${n} цифр`,
  maxSymbols: (max: number) => `Максимум ${max} символов`,
  minSymbols: (min: number) => `Минимум ${min} символов`,
  passedDate: "Дата должна быть прошедшей",
  futureDate: (field: string) => `«${field}» должен быть позже текущей даты`,
  dateLimitExceedsDateStart: "Дата окончания должна быть раньше даты начала",
  dateStartBeforeDateLimit:
    "«Конечный срок» не должен быть раньше начального срока",
  dateStartCustomerDate: (field: string) =>
    `Нельзя предлагать «${field}» раньше, чем в предложении закзачика!`,
  filesField: "Выберите нужные файлы. Дождитесь окончания загрузки!",
  filesFieldActually: "Необходибо загрузить минимум 1 актуальный файл",
  shouldBeNumber: "Значение должно быть числом",
  shouldBeIntNumber: "Значение должно быть целым числом",
  commentStub: "Не заполнено",
  minPrice: (field: Nullable<string>, minPrice: number | string) =>
    field
      ? `«${field}» не может быть меньше ${minPrice} рублей!`
      : `Сумма не может быть меньше ${minPrice} рублей!`,
  maxPrice: (field: Nullable<string>, maxPrice: number | string) =>
    field
      ? `«${field}» не может быть больше ${maxPrice} рублей!`
      : `Сумма не может быть больше ${maxPrice} рублей!`,
  totalAmountPercent: "Общая сумма оплаты должна быть равна 100%",
  minPercent: (field: string, minPercent: number) =>
    `«${field}» не может быть меньше ${minPercent} процентов!`,
  maxPercent: (field: string, maxPercent: number) =>
    `«${field}» не может быть больше ${maxPercent} процентов!`,
  durationWork: (field: string, daysInterval: number) =>
    `${field} не может быть меньше ${daysInterval} ${daysInterval > 1 ? "дн." : "дня"}!`,
};

export const stringRequired = (field: string) =>
  Yup.string().required(messages.requiredField(field));

export const dateRequired = (field: string) =>
  Yup.mixed().nullable().required(messages.requiredField(field));

export const datePassedRequired = (field: string) =>
  Yup.mixed()
    .nullable()
    .required(messages.requiredField(field))
    .test(
      "passed",
      messages.passedDate,
      (value) => value && value <= new Date()
    );

export const dateFutureRequired = (field: string) =>
  Yup.mixed()
    .nullable()
    .required(messages.requiredField(field))
    .test(
      "isFuture",
      messages.futureDate(field),
      (value) => value && value > new Date()
    );

export const dateCurrentOrFutureRequired = (field: string) =>
  Yup.mixed()
    .nullable()
    .required(messages.requiredField(field))
    .test("isFuture", messages.futureDate(field), (value) => {
      return (
        value && (moment(value).isSame(new Date(), "day") || value > new Date())
      );
    });

export const datePassed = () =>
  Yup.mixed()
    .nullable()
    .test("passed", messages.passedDate, (value) =>
      value ? value <= new Date() : true
    );

export const dateLimitExceedsDateStartRequired = (field: string) =>
  dateRequired(field)
    .test(
      "dateStartBeforeDateLimit",
      messages.dateStartBeforeDateLimit,
      function (value) {
        if (!this.parent.dateStart) {
          return true;
        }

        return value ? moment(value) >= moment(this.parent.dateStart) : true;
      }
    )
    .test({
      name: "durationWork",
      test(value) {
        if (!this.parent.dateStart) {
          return true;
        }

        const dateStart = moment(this.parent.dateStart, "DD.MM.YYYY");
        const dateLimit = moment(value, "DD.MM.YYYY");
        const daysInterval: number = this.parent?.daysInterval || 1;

        return dateLimit.diff(dateStart, "days") > daysInterval
          ? true
          : this.createError({
              message: messages.durationWork("Продолжительность", daysInterval),
            });
      },
    });

export const stringMinMaxLengthRequired = (
  field: string,
  minLength: number,
  maxLength: number
) =>
  Yup.string()
    .required(messages.requiredField(field))
    .test(
      "minLen",
      messages.minSymbols(minLength),
      (value: any) => value && value.length >= minLength
    )
    .max(maxLength, messages.maxSymbols(maxLength));

export const numberOfNDigitsRequired = (field: string, n: number) =>
  Yup.string()
    .test("isNum", messages.requiredNDigits(n), (value) =>
      Boolean(value && !Number.isNaN(Number(value)) && value.length === n)
    )
    .required(messages.requiredField(field));

export const stringMaxLength = (max: number) =>
  Yup.string().max(max, messages.maxSymbols(max));

export const numberRequired = (field: string) =>
  Yup.number()
    .required(messages.requiredField(field))
    .typeError(messages.shouldBeNumber);

export const stringMaxLengthRequired = (field: string, max: number) =>
  Yup.string()
    .required(messages.requiredField(field))
    .max(max, messages.maxSymbols(max));

export const regionRequired = (field: string) =>
  Yup.mixed().test(
    "requiredRegion",
    messages.requiredField(field),
    (value) => !Number.isNaN(Number(value)) && value > 0
  );

export const bankAccountRequired = (field = "Банковский счет") =>
  Yup.number()
    .required(messages.requiredField(field))
    .typeError(messages.requiredField(field));

export const notNullRequired = (field: string) =>
  Yup.mixed().required(messages.requiredField(field));

export const filesAreRequired = () => Yup.array().min(1, messages.filesField);

export const priceMinMaxRequired = (
  field: string,
  minPrice: number,
  maxPrice: number
) =>
  numberRequired(field)
    .min(minPrice, messages.minPrice(field, formatLongNumber(minPrice)))
    .max(maxPrice, messages.maxPrice(field, formatLongNumber(maxPrice)));

export const executorTypesRequired = (field: string) =>
  Yup.array().min(1, messages.requiredField(field));

export const percentMinMaxRequired = (
  field: string,
  minPercent: number,
  maxPercent: number
) =>
  numberRequired(field)
    .min(minPercent, messages.minPercent(field, minPercent))
    .max(maxPercent, messages.maxPercent(field, maxPercent));
