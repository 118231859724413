import { QueryBuilder } from "src/FSD/shared/lib/types/QueryBuilder";
import { RequestParams } from "src/FSD/shared/lib/services/api/requestParams";
import { ApiProjectAccessResponses } from "../../model/responseTypes";

type TParams = RequestParams<{
  id: number;
}>;

export const dataRemoveExecutor = (builder: QueryBuilder) =>
  builder.query<ApiProjectAccessResponses.DataRemoveExecutor, TParams>({
    query: ({ id }) => ({
      url: `v1/project-access/data-remove-executor`,
      params: {
        processId: id,
      },
    }),
    providesTags: [],
  });
