import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchProjectSourceDataView,
  fetchProjectViewInitialData,
} from "src/app/feature/ProjectView/InitialDataReducer/thunks";
import { fetchProjectProcessSourceDataDuplicate } from "src/app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessSourceData";
import { useAppSelector } from "src/app/store";
import { getProjectChecklist } from "src/app/feature/project/projectChecklist";
import { fetchProjectProcessInitialData } from "src/app/feature/ProjectProcessView/InitialData/services/fetchProjectProcessInitialData";
import { Modal } from "src/FSD/shared/uiKit/v2/Modal";
import { FileUploader } from "./FileUploader";

type TModalAddFilesProps = {
  taskId: number;
  parentId: number;
  index?: number;
  setModalFiles: (data: boolean) => void;
  projectId: number;
  infoId: number;
  type: "process" | "project";
};

export const AddFilesModal = ({
  taskId,
  setModalFiles,
  parentId,
  index,
  projectId,
  infoId,
  type: modalType,
}: TModalAddFilesProps) => {
  const dispatch = useDispatch();

  const {
    data: { type },
  } = useAppSelector(getProjectChecklist);

  const handleUpdate = async () => {
    if (projectId && (index || index === 0) && modalType === "project") {
      await dispatch(
        fetchProjectSourceDataView({
          idx: { parentId, id: projectId, index, type },
        })
      );
    }
    if (projectId && (index || index === 0) && modalType === "process") {
      await dispatch(
        fetchProjectProcessSourceDataDuplicate({
          projectId,
          parentId,
          index,
          type,
        })
      );
    }
  };

  useEffect(
    () => () => {
      handleUpdate();
      if (infoId) {
        dispatch(fetchProjectProcessInitialData(infoId as number));
        return;
      }

      if (infoId) {
        dispatch(
          fetchProjectViewInitialData({
            id: infoId,
            type,
          })
        );
      }
    },
    [infoId]
  );

  return (
    <Modal.Layout modalSize="lg">
      <Modal.Header>Загрузить файлы</Modal.Header>
      <FileUploader
        taskId={taskId}
        setModalFiles={setModalFiles}
        handleUpdate={handleUpdate}
      />
    </Modal.Layout>
  );
};
