import { KanbanType } from "src/FSD/entities/kanban";
import * as KanbanFilterRepository from "./filterRepository";
import { getUserId } from "../../../../../../../app/services/auth/auth";

export const saveFilterValuesInLocalStorage = (
  userTypeId: number,
  filterParams: KanbanType.FilterValues
) => {
  const userId = getUserId();
  const persistedFilterParams = KanbanFilterRepository.getValues();

  KanbanFilterRepository.setValues({
    ...persistedFilterParams,
    [userId]: {
      ...persistedFilterParams[userId],
      [userTypeId]: { ...filterParams },
    },
  });
};
